<template>
    <v-card-text>
<!-- FOR FRAUD REVIEW STATUS -->
        <div v-if="forFraudReviewStatus">
            <v-btn @click="approve" color="info" block :loading="loading" :disabled="loading">Approve</v-btn>
            <v-btn @click="emitToOpenDisapprovalModal" class="mt-1" color="error" block>Disapprove</v-btn>
        </div>
<!-- FOR LOAN EVALUATION STATUS-->
        <div v-if="order.status == 'For Loan Evaluation'">
            <v-btn v-if="loanDetails.downpayment && role.roleCode == 'HD'" color="info" block class="mt-1" @click="approveLoan" :loading="loading" :disabled="loading">
                Accept offer
            </v-btn>
            <v-btn v-if="forDecliningLoanEvaluation" color="error" block class="mt-1" @click="emitToOpenCADeclineDialog">
                Decline
            </v-btn>
        </div>
<!-- FOR STOCK ALLOCATION STATUS -->
        <div v-if="forStockAllocationStatus">
            <!-- <SelectBranches 
                isObjectValue
                v-model="deliveryBranch" 
                v-if="order.shippingMethod == 'delivery'" 
                :filters="branchFilter" 
            /> -->
            <v-btn @click="emitStocksChecking" class="mt-2" color="success" block>Allocate Stocks</v-btn>
        </div>
<!-- FOR STOCK ALLOCATED STATUS -->
        <div v-if="forStockAllocatedStatus" >
            <v-btn @click="prepareOrder" color="info" block :loading="loading" :disabled="loading">Prepare Order</v-btn>
        </div>
<!-- FOR ORDER PREPARATION STATUS Delivery OR FOR READY FOR PICKUP STATUS -->
        <div v-if="forOrderPreparationDelivery || forReadyForPickUpStatus" >
            <v-text-field 
                dense 
                outlined 
                :key="n" 
                :rules="[rules.required]"
                v-for="n in salesInvoiceInputCount" 
                v-model="siNumbers[n-1]" 
                label="Input SI No." 
            ></v-text-field>
            <v-text-field
                v-model="siDate"
                label="Input SI Date"
                outlined
                readonly
                dense
            ></v-text-field>

            <v-btn color="info" block @click="readyToShip" :loading="loading" :disabled="loading" v-if="forOrderPreparationDelivery">
                Ready to Ship
            </v-btn>
            <v-btn color="info" block :loading="loading" :disabled="loading" @click="pickUp" v-if="forReadyForPickUpStatus">
                Picked Up
            </v-btn>
        </div>
<!-- FOR ORDER PREPARATION STATUS Pickup-->
        <div v-if="forOrderPreparationPickup">
            <v-btn color="info" block :loading="loading" :disabled="loading" @click="readyForPickUp">
                Ready for Pick Up
            </v-btn>
        </div>
<!-- FOR READY TO SHIP STATUS -->
        <div v-if="forReadyToShipStatus">
            <span>Choose delivery option:</span>
            <v-radio-group v-model="deliveryMode" column>
                <v-radio label="Internal delivery" value="internal"></v-radio>
                <v-radio label="Third Party Courier" value="external" :disabled="orderType !== 'Parts'"></v-radio>
            </v-radio-group>
            <v-text-field
                v-model="waybillTrackingNo"
                v-if="deliveryMode == 'external'"
                label="Waybill Tracking No."
                outlined
                dense
                :rules="[rules.required]"
            ></v-text-field>
            <v-btn color="info" block @click="shipOrder" :loading="loading" :disabled="loading || !deliveryMode">Ship Order</v-btn>
        </div>
<!-- FOR SHIPPED STATUS -->
        <div v-if="order.status === 'Shipped' && role.roleCode == 'LOGIA'">
            <v-btn color="info" block :loading="loading" :disabled="loading" @click="delivered">Delivered</v-btn>
        </div>
<!-- FOR DELIVERED STATUS -->
        <div v-if="forDeliveredStatus">
            <v-menu
                ref="acceptanceDateMenu"
                v-model="acceptanceDateMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="acceptanceDate"
                        label="Acceptance Date"
                        prepend-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="acceptanceDate" no-title scrollable>
                </v-date-picker>
            </v-menu>
            
            <v-btn color="info" block :loading="loading" :disabled="loading" @click="closeDeliveryRequest">Submit</v-btn>
        </div>
<!-- FOR CLOSING DELIVERY ORDER -->
        <div v-if="forClosingDeliveryOrder">
            <span>Open Bill ID</span>
            <v-text-field v-model="vendorBillId" outlined dense :rules="[rules.required]"></v-text-field>
            <span>Supplier Invoice No.</span>
            <v-text-field v-model="openBillId" outlined dense :rules="[rules.required]"></v-text-field>
            <v-btn color="info" block :loading="loading" :disabled="loading" @click="inputVendorBillDetails">Submit</v-btn>
        </div>
            
        <!-- Comment for commit -->
        <div v-else>
            <p class="body-1" v-if="order.status == 'For Document Checking'">For Validation</p>

            <p v-if="order.status == 'For Payment Review' && order.paymentMethod == 'Pay in Installment'">Please validate downpayment first</p>

            <p v-if="order.status == 'Offer Accepted' && payment.status != 'Completed'">Please validate downpayment first</p>

            <p v-if="order.status == 'Offer Accepted' && payment.status == 'Completed'">Incomplete Offer Acceptance Information</p>

            <p class="body-1" v-if="order.status == 'Closed' && order.shippingMethod == 'pickup'">Order was picked up</p>

            <p class="body-1" v-if="order.status == 'Closed' && order.shippingMethod == 'delivery' && order.courierPaymentStatus === 'Open Bill'">Order was delivered</p>

            <p class="body-1" v-if="order.status == 'Cancelled'">Order was closed</p>

            <p class="body-1" v-if="order.status == 'Loan Disapproved'">Order was closed</p>
        </div>
    </v-card-text>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-business-days'
import { mapGetters } from 'vuex'
// import SelectBranches from '@/components/Select/Branches'

export default {
    name: 'user-controls',
    components: {
        // SelectBranches
    },
    props: {
        order: Object,
        payment: Object,
        loanDetails: Object,
        entries: Array,
        validStocks: Boolean
    },
    data() {
        return {
            loader: null,
            loading: false,
            role: {},
            vendorBillId: "",
            openBillId: "",
            waybillTrackingNo: "",
            acceptanceDateMenu: false,
            siDateMenu: false,
            siDate: moment().format('YYYY-MM-DD'),
            showCurrent: true,
            disabled: true,
            siNumbers: [],
            deliveryMode: null,
            deliveryBranch: null,
            acceptanceDate: moment().format('YYYY-MM-DD hh:mm:ss'),
            rules: {
                required: value => !!value || 'Required.',
            },
        }
    },
    computed: {
        ...mapGetters(['user']),

        entriesCount() {
            return this.entries.length
        },
        salesInvoiceInputCount() {
            let maxItemsSI = 5
            let mcCount = 0, itemCount = 0

            
            this.entries.forEach((rec) => {
                if(rec.department === 'Motorcycles') {
                    mcCount += parseInt(rec.quantity)
                }
                else itemCount++
            });
            let r = (itemCount % maxItemsSI) > 0 ? 1 : 0
            let q = parseInt(itemCount / maxItemsSI)

            return r + q + mcCount
        },
        siNumbersStrValue() {
            return this.siNumbers.toString()
        },
        siFieldValidated() {
            let type = this.orderType
            let filteredArray = this.siNumbers.filter(function (element) {
                return element != ""
            })

            if(type === 'Motorcycles') {
                return this.entries.length == filteredArray.length
            } else {
                return this.salesInvoiceInputCount == filteredArray.length
            }
        },
        orderType() {
            let items = this.entries
            let filteredArray = []
            let type = ""
            
            for(let i = 0; i < items.length; i++) {
                if(!checkItemType(items[i].department)) {
                    items[i].department = 'Parts'
                    filteredArray.push(items[i].department)
                } else {
                    filteredArray.push(items[i].department)
                }
            }
            
            if(filteredArray.length > 1) {
                let mcOnly = filteredArray.every((val) => val === 'Motorcycles')
                let partsOnly = filteredArray.every((val) => val === 'Parts')

                if(mcOnly) type = "Motorcycles"
                else if(partsOnly) type = "Parts"
                else type = "Combo"
            } else {
                if(filteredArray[0] === 'Motorcycles') type = 'Motorcycles'
                else type = 'Parts'
            }

            return type
        },
        branchFilter() {
            let filter
            let forMC = 1, forItems= 1

            switch (this.orderType) {
                case 'Motorcycles':
                    filter = { forMC }
                    break;
                case 'Parts':
                    filter = { forItems }
                    break;
            
                default:
                    filter = { forMC, forItems }
                    break;
            }
            
            return filter
        },
        isValidPartsRole() {
            return this.role.roleCode == 'PC' && this.orderType == 'Parts'
        },
        isValidMcRole() {
            return this.role.roleCode == 'BM' && this.orderType == 'Motorcycles'
        },
        isValidComboItemRole() {
            return this.orderType === 'Combo' && (this.role.roleCode == 'BM' || this.role.roleCode == 'PC')
        },
        isValidProductOrderController() {
            return this.isValidMcRole || this.isValidPartsRole || this.isValidComboItemRole
        },
        isValidOrderPickupRole() {
            return this.order.shippingMethod == 'pickup' && this.isValidProductOrderController
        },
        isValidOrderDeliveryRole() {
            return this.order.shippingMethod == 'delivery' && this.role.roleCode == 'LOGIA'
        },
        forFraudReviewStatus() {
            return this.order.status == 'For Fraud Review' && this.role.roleCode == 'HD'
        },
        forDecliningLoanEvaluation() {
            const condition = (
                (this.loanDetails.status == 'Pending' && this.role.roleCode == 'CA') ||
                (this.loanDetails.status != 'Pending' && this.role.roleCode == 'HD')
            )

            return condition
        },
        forStockAllocationStatus() {
            const condition = this.order.status == "For Stock Allocation" && (this.isValidOrderPickupRole || this.isValidOrderDeliveryRole)

            return condition 
        },
        forStockAllocatedStatus() {
            return this.order.status == 'Stock Allocated' && (this.isValidOrderPickupRole || this.isValidOrderDeliveryRole)
        },
        forOrderPreparationDelivery() {
            const condition = (
                this.order.status === 'For Order Preparation' &&
                this.order.shippingMethod === 'delivery' &&
                this.isValidProductOrderController
            )

            return condition
        },
        forOrderPreparationPickup() {
            const condition = (
                this.order.status == 'For Order Preparation' && 
                this.order.shippingMethod === 'pickup' &&
                this.isValidProductOrderController
            )

            return condition
        },
        forReadyToShipStatus() {
            const condition = (
                this.order.status === 'Ready to Ship' &&
                this.order.shippingMethod == 'delivery' &&
                this.isValidProductOrderController
            )

            return condition
        },
        forReadyForPickUpStatus() {
            const condition = (
                this.order.status == 'Ready for Pick up' &&
                this.role.roleCode == 'BAA' &&
                this.order.shippingMethod == 'pickup'
            )

            return condition
        },
        forDeliveredStatus() {
            const condition = (
                this.order.status === 'Delivered' && 
                (this.order.deliveryMode === 'internal' || this.order.deliveryMode === 'external') && 
                this.role.roleCode == 'LOGIA' && this.order.acceptedAt === null
            )

            return condition
        },
        forClosingDeliveryOrder() {
            const condition = (
                (this.order.status === 'Delivered' || this.order.status === 'Closed') && 
                !this.order.openBill && !this.order.vendorBill && 
                this.order.deliveryMode === 'external' && this.order.courierPaymentStatus !== 'Open Bill' &&
                this.role.roleCode == 'HOA' && 
                this.order.acceptedAt != null
            )

            return condition
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
        validStocks: {
            handler: 'doStocksAllocation'
        }
    },
    mounted() {
        this.getRoleDetails(this.$store.getters.user.uid)
    },
    methods: {
        getRoleDetails(userId) {
			const self =  this
			
			axios.get(`/role/${userId}`)
			.then(response => {
				self.role = response.data.result
			})
			.catch(this.showErrorMessage)
        },
        emitToOpenDisapprovalModal() {
            const self = this
            self.$emit('disapprovalModal')
        },
        emitToOpenCADeclineDialog() {
            const self = this
            self.$emit('openCADeclineDialog')
        },
        emitToOpenDeclineDialog() {
            const self = this
            self.$emit('openDeclineDialog')
        },
        emitStocksChecking() {
            // let branch = null

            // if(this.order.shippingMethod == 'delivery' && this.deliveryBranch == null) {
            //     this.showErrorMessage("Please select branch.")
            //     return
            // } else {
            //     branch = this.deliveryBranch.nsId
            // }
            this.$emit('allocateStocks')
        },
        doOrderStatusUpdate(path, data, callback) {
            this.$axios.put(path, data)
            .then(response => {
                if(callback) callback(response)
                this.orderUpdateSuccessCb(response)
            })
            .catch(this.showErrorMessage)
        },
        approve() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            this.doOrderStatusUpdate('/order/fraud/approval', {
                orderId,
                status: 'For Stock Allocation'
            })
        },
        openDisapproveModal() {
            const self = this
            self.$emit('disapprovalModal', true)
        },
        doStocksAllocation(valid) {
            let self = this
            let status = "Stock Allocated"
            let orderId = self.$route.params.orderId
            let branch = this.order.branch

            // if(this.order.shippingMethod == 'delivery') {
            //     branch = this.deliveryBranch.uid
            // } else {
                // branch = this.order.branch
            // }

            if(!valid) return

            this.doOrderStatusUpdate(`/order/stock_allocated`, {
                status, orderId, branch
            })
        },
        readyToShip() {
            const self = this
            let isValidated = this.siFieldValidated
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            if(isValidated) {
                this.doOrderStatusUpdate('/order/ready/ship', {
                    orderId: orderId,
                    salesInvoiceNumber: self.siNumbersStrValue,
                    status: 'Ready to Ship'
                })
            } else {
                self.$emit('failureAlert', 'Sales Invoice No. is required!', false)
            } 
        },
        readyForPickUp() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            this.doOrderStatusUpdate(`/order/ready/pickup`, {
                orderId: orderId,
                status: "Ready for Pick up"
            })
        },
        shipOrder() {
            const self = this
            let orderId = self.$route.params.orderId
            let isWayBillEmpty = self.waybillTrackingNo === ''
            self.loader = 'loading'

            if(self.deliveryMode == 'external' && isWayBillEmpty) {
                this.$emit('failureAlert', 'Waybill Tracking No. is required!', false)
            } else {
                this.doOrderStatusUpdate('/order/ship', {
                    orderId: orderId,
                    status: 'Shipped',
                    deliveryMode: self.deliveryMode,
                    waybillTrackingNo: self.waybillTrackingNo
                })
            } 
        },
        prepareOrder() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = "loading"

            this.doOrderStatusUpdate(`/order/prepare`, {
                orderId: orderId,
                status: 'For Order Preparation'
            })
        },
        delivered() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            this.doOrderStatusUpdate(`/order/delivered`, {
                orderId: orderId,
                status: "Delivered",
            })
        },
        pickUp() {
            const self = this
            let orderId = self.$route.params.orderId
            let isValidated = this.siFieldValidated
            self.loader = 'loading'

            if(isValidated) {
                this.doOrderStatusUpdate(`/order/pickedup`, {
                    orderId: orderId,
                    salesInvoiceNumber: self.siNumbersStrValue,
                    status: "Closed",
                })
            } else {
                self.$emit('failureAlert', 'Sales Invoice No. is required!', false)
            }
            
        },
        inputVendorBillDetails() {
            const self = this
            let isValidated = self.openBillId && self.vendorBillId
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            if(isValidated) {
                this.doOrderStatusUpdate(`/order/delivered/vendor/details`, {
                    orderId: orderId,
                    vendorBill: self.vendorBillId,
                    openBill: self.openBillId,
                    courierPaymentStatus: "Open Bill",
                    status: "Closed"
                }, () => {
                    self.openBill = null
                    self.vendorBill = null
                })
            } else {
                this.$emit('failureAlert', 'All fields are required!', false)
            }
        },
        closeDeliveryRequest() {
            const self = this
            const orderId = self.$route.params.orderId
            const isInternal = self.order.deliveryMode == 'internal'
            let data = {
                orderId: orderId,
                courierPaymentStatus: "Pending Invoice",
                status: (isInternal ? 'Closed' : 'Delivered'),
            }

            self.loader = 'loading'

            this.doOrderStatusUpdate(`/order/delivery/closed`, data, () => {
                if(isInternal) self.openBill = null
            })
        },
        closePickUpRequest() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            this.doOrderStatusUpdate(`/order/pickup/closed`, {
                orderId: orderId,
                status: "Closed"
            })
        },
        approveLoan() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            if(self.loanDetails.status == 'Pending') {
                self.loader = null
                alert('Loan is pending for approval')
            } else {
                this.doOrderStatusUpdate(`/order/mcloan/approval`, {
                    orderId: orderId,
                    status: "Offer Accepted"
                })
            }
        },
        orderUpdateSuccessCb(response) {
            this.loader = null
            this.$emit('successAlert')
            this.$emit('getOrder', this.$route.params.orderId)
            this.$emit('show', true)
        },
        showErrorMessage(error) {
            this.$emit('failureAlert', error.message, false)
        }
    }
}
function checkItemType(type) {
    let types = ['Motorcycles', 'Parts']
    return types.find(el => type === el) !== undefined
}
</script>