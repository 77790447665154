<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Branch Catalog</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field clearable dense 
                label="Search by Branch Name or Branch Code" 
                class="mt-3" v-model="filters.search"
                append-icon="search"
                single-line
            ></v-text-field>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>

                <v-menu offset-y>
                    <template v-slot:activator="{on: menu, attrs}">
                        <v-tooltip left>
                            <template v-slot:activator="{on: tooltip}">
                                <v-btn icon v-bind="attrs" v-on="{...tooltip, ...menu}"><v-icon>more</v-icon></v-btn>
                            </template>
                            <span>Branch Tagging</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item 
                            v-for="(label, key) in taggingLabels" :key="key" 
                            @click="setBranchFor(label.value)">
                            {{label.text}}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table 
            dense 
            show-select
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            @update:options="loadNextPage"
            :footer-props="footerProps"
            v-model="selected"
        >
            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-icon>filter_list</v-icon>
                        <v-checkbox
                            v-for="(tag, key) in taggingLabels"
                            :key="key"
                            dense
                            v-model="filters[tag.value]"
                            class="mt-3 mr-5"
                            :label="tag.text"
                            true-value="1"
                            :false-value="null"
                        />
                    </v-toolbar-items>
                </v-toolbar>
            </template>

            <template v-slot:item.mcDelivery="{ item }">
                <v-simple-checkbox :value="item.mcDelivery" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.mcPickup="{ item }">
                <v-simple-checkbox :value="item.mcPickup" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.itemDelivery="{ item }">
                <v-simple-checkbox :value="item.itemDelivery" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.itemPickup="{ item }">
                <v-simple-checkbox :value="item.itemPickup" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.comboDelivery="{ item }">
                <v-simple-checkbox :value="item.comboDelivery" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.comboPickup="{ item }">
                <v-simple-checkbox :value="item.comboPickup" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.status="{ item }">
                <v-simple-checkbox :value="Boolean(item.status)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon small @click="editBranch(item)" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>Edit Branch</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import { camelCase } from "lodash"

export default {
    components: {
        Snackbar
    },
    data() {
        const taggingLabels = ['MC Delivery', 'MC Pickup', 'Item Delivery', 'Item Pickup', 'Combo Pickup'].map(el => {
            return {text: el, value: camelCase(el), sortable: false }
        }) //to be added 'Combo Delivery'
        const tagFilters = taggingLabels.map(el => { return { [el.value]: null } })

        return {
            showFilter: false,
            loading: true,
            serverItemTotal: 0,
            taggingLabels,
            filters: {
                search: null,
                ...tagFilters,
                branchType: 1 //Retail
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            selected: [],
            records: [],
            headers: [
                { text: 'Branch Code', value: 'branchCode', sortable: false },
                { text: 'Branch Name', value: 'readableName', sortable: false },
                ...taggingLabels,
                { text: 'Active', value: 'status', align: 'center', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false }
            ]
        }
    },
    watch: {
        filters: {
            handler: function() {
                this.loadBranches()
            },
            deep: true
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
        selectedIds() {
            return this.selected.map(rec => rec.id )
        }
    },
    mounted() {
        this.loadBranches();
    },
    methods: {
        loadBranches() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.selected = []

            this.$axios.get(`/branches/paged`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.count
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        setBranchFor(path) {
            let self = this
            if(this.selectedIds.length < 1) {
                this.showSnackbarMessage("Please select item(s)", false)
                return
            }

            this.$axios.put(`/branches/enable/${path}`, {
                enable: true,
                branchIds: self.selectedIds
            })
            .then(response => {
                let responseData = response.data
                
                self.showSnackbarMessage(responseData.message, responseData.success)
                self.loadBranches()
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        editBranch(branch) {
            let self = this
            const branchId = branch.id

            setTimeout(() => {
                self.$router.push({ name: 'BranchEdit', params: { branchId }})
            }, 1000)
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        loadNextPage() {
            this.loadBranches()
        }
    }
}
</script>