<template>
    <!-- <v-card outlined dense>
        <v-card-title>Setups</v-card-title>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col col cols="2" md="2" class="border-left">
                <v-list outlined dense>
                    <v-list-item-group>
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            :to="{ name: item.code }"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-col col cols="9" md="10">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-card> -->
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Setups</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="addSetup" v-on="on"><v-icon>add</v-icon></v-btn>
                    </template>
                    <span>Add Setup</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            :footer-props="footerProps"
        >

            <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon v-if="['DLVRY_LEAD_TIME'].includes(item.code)" size="25" @click="editSetup(item)" v-on="on">edit</v-icon>
                        <v-icon v-if="['DLVRY_WIN_TIME'].includes(item.code)" size="25" @click="editSetup(item)" v-on="on">edit</v-icon>
                        <v-icon v-if="['DLVRY_ECQ_NOTICE'].includes(item.code)" size="25" @click="editSetup(item)" v-on="on">edit</v-icon>
                        <v-icon v-if="['DLVRY_FEE'].includes(item.code)" @click="routeToSetup('/setups/delivery-fee')">open_in_new</v-icon>
                        <v-icon v-if="['DLVRY_LEAD_TIMES'].includes(item.code)" @click="routeToSetup('/delivery-dates-details')">open_in_new</v-icon>
                        <v-icon v-if="['PACKAGING'].includes(item.code)" @click="routeToSetup('/setups/packaging')">open_in_new</v-icon>
                        <v-icon v-if="['TRD_PARTY_DLVR'].includes(item.code)" @click="routeToSetup('/setups/thirdparty')">open_in_new</v-icon>
                    </template>
                    <span>Edit Setup</span>
                </v-tooltip>
            </template>
            
            <template v-slot:[`item.amount`]="{ item }">
                <span>{{item.amount}}</span>
            </template>

            <template v-slot:[`item.active`]="{ item }">
                <v-simple-checkbox :value="Boolean(item.active)" disabled></v-simple-checkbox>
            </template>
        </v-data-table>

        <v-dialog v-model="showDialog" width="500" height="500">
            <Details 
                :action="action"
                :setupRecord="setupRecord"
                v-on:setup-response="showActionResponse"
                v-on:close-form="closeDialog"
            ></Details>
        </v-dialog>

    </v-container>
</template>

<script>
import Details from './Details'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Details,
        Snackbar
    },
    data() {
        return {
            action: null,
            showDialog: false,
            loading: false,
            serverItemTotal: 0,
            setupRecord: null,
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            records: [],
            headers: [
                { text: 'Name', value: 'name', sortable: false },
                { text: 'Value', value: 'value', sortable: false },
                { text: 'Active', value: 'active', align: 'center', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false }
            ],
            items: []
        }
    },
    mounted() {
        this.loadSetups();
        // this.getReports();
    },
    watch: {
        showDialog(value) {
            if(!value) this.loadSetups()
        }
    },
    methods: {
        getReports() {
            let self = this

            this.$axios.get(`/lovs`, {
                params: { groupCode: 'SETUPS' }
            }).then(result => {
                self.items = result.data.data    
            })
        },
        loadSetups() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`/setups`).then((result) => {
                self.records = result.data.data
                self.serverItemTotal = result.data.data.length
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        addSetup() {
            this.action = 'add'
            this.showDialog = true
        },
        editSetup(item) {
            this.action = 'update'
            this.setupRecord = item
            this.showDialog = true
        },
        routeToSetup(endpoint) {
            this.$router.push(endpoint)
        },
        showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
        closeDialog() {
            this.showDialog = false
            this.setupRecord = null
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    },
}
</script>

<style scoped>
.border-left {
    border-right-color: #EEEEEE !important;
    border-right-style: solid;
}
</style>