import {VSelect} from 'vuetify/lib'
import axios from 'axios'
import { remove } from 'lodash'
import { api } from '../../configs/api'

export default {
    extends: VSelect,
    props: {
        itemText: {
            type: String,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        label: {
            type: String,
            default: 'Product'
        },
        dense: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        exclude: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            let self = this

            axios.get(`${api}/departments`)
            .then(response => {
                let responseData = response.data

                if(!responseData.success) alert(responseData.message)

                self.items = [ { id: null, name: "--- Not Applicable ---" }, ...responseData.data]

                if(this.exclude.length > 0) {
                    this.exclude.forEach((val) => {
                        remove(self.items, function(rec) {
                            return rec.name === val
                        })
                    })
                }
            }).catch((error) => {
                alert(error.message)
            })
        }
    }
}