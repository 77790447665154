<template>
    <v-container>
        <v-layout>
            <v-flex>
                <v-card>
                    <v-card-title>
                        Reviews
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details>
                        </v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :search="search" :items="reviews" :headers="headers">
                            <template v-slot:item.actions="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" class="ml-2" @click="openItem(item.sku)">open_in_new</v-icon>
                                    </template>
                                    <span>View Review</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="dialog" hide-overlay persistent width="300">
                            <v-card color="primary" dark>
                                <v-card-text>
                                    Loading data
                                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
                            <v-card color="primary" dark>
                                <v-card-text>
                                    Please wait
                                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
import moment from 'moment-business-days'
export default {
    data() {
        return {
            dialog: false,
            beforeRouteDialog: false,
            search: '',
            headers: [
                { text: 'Review ID', value: 'reviewId', sortable: false },
                { text: 'Product Name', value: 'product', sortable: false },
                { text: 'Material No.', value: 'sku', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            ready: false,
            reviews: []
        }
    },
    mounted() {
        let self = this
		self.getReviews()
    },
    methods: {
        async getReviews() {
            let self = this
            self.dialog = true
            
            axios.get(`${api}/reviews`)
            .then(response => {
                self.reviews = response.data.results
                self.dialog = false
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },

        openItem(sku) {
            let self = this
            self.beforeRouteDialog = true

            // const fixedDecodeURIComponent = (product) => {
			//   return decodeURIComponent(product).replace(/[!'()*]/g, (c) => {
			//     return '%' + c.charCodeAt(0).toString(16)
			//   })
            // }
            
            self.$router.push(`/review/view/${sku}`)
        },
        showErrorMessage(message) {
            alert(message);
        },
    }
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
</style>