<template>
    <v-container>
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Reservations</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field
                    class="mt-2"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                >
                </v-text-field>
            </v-toolbar-items>
        </v-toolbar>        
        <v-data-table :search="search" :items="reservations" :headers="headers">
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-2" @click="openItem(item)">open_in_new</v-icon>
                    </template>
                    <span>View Reservation</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Loading data
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please wait
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import Tabulator from 'tabulator-tables'
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
export default {
	data () {
		return {
            beforeRouteDialog: false,
            dialog: false,
            reservations: [],
            search: '',
            headers: [
                { text: 'Order ID', value: 'orderId', sortable: false },
                { text: 'Branch', value: 'branchName', sortable: false },
                { text: 'Amount', value: 'amount', sortable: false },
                { text: 'Payment Method', value: 'mode', sortable: false },
                { text: 'Reference', value: 'reference', sortable: false },
                { text: 'Date of Reservation', value: 'createdAt', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
		}
	},
	mounted() {
        this.getReservations()
	},
    methods: {
        getReservations() {
            const self = this
            self.dialog = true

            axios.get(`${api}/reservations`)
            .then((result) => {
                self.reservations = result.data.data
                self.dialog = false
            })
            .catch((error) => {
                self.dialog = false
                self.showErrorMessage(error.message)
            })
        },
        openItem(item) {
            let self = this
            self.beforeRouteDialog = true
            setTimeout(() => {
                self.$router.push('/reservation/view/'+item.uid)
            }, 1000)
        },
        showErrorMessage(message) {
            alert(message)
        },
    }
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css'
</style>

