<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Delivery Fee Setups</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="addSetup" v-on="on"><v-icon>add</v-icon></v-btn>
                    </template>
                    <span>Add Delivery Fee Setup</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="formattedRecords"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            :footer-props="footerProps"
        >

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="editSetup(item.id)" v-on="on">edit</v-icon>
                    </template>
                    <span>Edit Setup</span>
                </v-tooltip>
            </template>
            
            <template v-slot:item.activeFreeDelivery="{ item }">
                <v-simple-checkbox :value="Boolean(item.activeFreeDelivery)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.activeFlatRate="{ item }">
                <v-simple-checkbox :value="Boolean(item.activeFlatRate)" disabled></v-simple-checkbox>
            </template>
        </v-data-table>

        <v-dialog v-model="showDialog" width="500" height="500">
            <Details 
                :action="action"
                :setupRecord="setupRecord"
                v-on:setup-response="showActionResponse"
                v-on:close-form="closeDialog"
            ></Details>
        </v-dialog>

    </v-container>
</template>

<script>
import Details from './Details'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Details,
        Snackbar
    },
    data() {
        return {
            action: null,
            showDialog: false,
            loading: false,
            serverItemTotal: 0,
            setupRecord: null,
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            records: [],
            formattedRecords: [],
            headers: [
                { text: 'Product Type', value: 'productType', sortable: false },
                { text: 'Type of Delivery', value: 'type', sortable: false },
                { text: 'Provider', value: 'provider', align: 'center', sortable: false },
                { text: 'Free Delivery', value: 'activeFreeDelivery', sortable: false },
                { text: 'Enable Flat Rate', value: 'activeFlatRate', align: 'center', sortable: false },
                { text: 'Flat Rate', value: 'flatRate', align: 'center', sortable: false },
                { text: 'More', value: 'action', align: 'center', sortable: false }
            ]
        }
    },
    mounted() {
        this.loadSetups()
    },
    watch: {
        showDialog(value) {
            if(!value) this.loadSetups()
        }
    },
    methods: {
        customRecords() {
            let self = this
            self.formattedRecords = []
            if (self.records) {
                self.records.forEach(function(res) {
                    let type = res.external.matrix.couriers[0].courier === "" ? "Internal Delivery" : "Third-Party Courier Service"
                    let provider = type === "Internal Delivery" ? res.internal.matrix.provider :  res.external.matrix.couriers[0].courier
                    self.formattedRecords.push({
                        id: res.id,
                        productType: res.productType,
                        type,
                        activeFreeDelivery: res.freeDelivery.active,
                        activeFlatRate: res.flatRate.active,
                        flatRate: res.flatRate.rate,
                        provider
                    })
                })
            }
        },
        loadSetups() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`/setup/delivery-fee/fetch`).then((result) => {
                self.records = result.data.data
                self.serverItemTotal = result.data.data.length
                self.loading = false
            }).then(()=> {
                self.customRecords()
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        addSetup() {
            this.action = 'add'
            this.$router.push("/setups/delivery-fee/new")
        },
        editSetup(id) {
            this.$router.push(`/setups/delivery-fee/${id}`)
        },
        showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
        closeDialog() {
            this.showDialog = false
            this.setupRecord = null
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    },
}
</script>