<template>
    <v-container>
        <v-toolbar dense short text color="blue">
            <v-toolbar-title>Motorcycles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field 
                    class="mt-2"
                    v-model="search" 
                    append-icon="mdi-magnify" 
                    label="Search" 
                    single-line 
                    hide-details
                    clearable
                >
                </v-text-field>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="enableStorefrontItems" v-on="on"><v-icon>toggle_on</v-icon></v-btn>
                    </template>
                    <span>Enable/Disable Item</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="enableFeaturedItems" v-on="on"><v-icon>mdi-check-decagram</v-icon></v-btn>
                    </template>
                    <span>Enable/Disable featured item</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="openUploadForm"><v-icon>mdi-cloud-upload</v-icon></v-btn>
                    </template>
                    <span>Upload template</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon to="/product/model/top_selling"  v-on="on"><v-icon>mdi-star-circle-outline</v-icon></v-btn>
                    </template>
                    <span>Set Top Selling Model</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon to="/product/lto"  v-on="on"><v-icon>mdi-cash</v-icon></v-btn>
                    </template>
                    <span>Tag LTO Registration Fee</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon to="/product/freebies"  v-on="on"><v-icon>card_giftcard</v-icon></v-btn>
                    </template>
                    <span>Freebies</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            show-select
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :search="search"
            :items="displayedProducts" 
            :items-per-page="20"
            :footer-props="footerOptions"
            :headers="headers"
            :item-key="products.slug"
            dense
            v-model="selected"
        >

            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-icon>filter_list</v-icon>
                    <div>
                        <v-select class="mt-5" v-model="status" :items="items" clearable placeholder="Please select"></v-select>
                    </div>
                </v-toolbar>
            </template>
            
            <template v-slot:item.enable="{ item }">
                <v-simple-checkbox :value="Boolean(item.enable)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.featured="{ item }">
                <v-simple-checkbox :value="Boolean(item.featured)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.active="{ item }">
                <v-simple-checkbox :value="Boolean(item.active)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-2" @click="openProduct(item)">open_in_new</v-icon>
                    </template>
                    <span>View Product</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="uploadDialog" persistent width="500">
            <Upload :history="history" v-on:uploadDialog="uploadDialog = false" v-on:historyDialog="historyDialog = true" v-on:fetchProducts="fetchProducts" v-on:showErrorMessage="showErrorMessage"></Upload>
        </v-dialog>

        <v-dialog v-model="historyDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <History :history="history" v-on:historyDialog="historyDialog = false"></History>
        </v-dialog>

        <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please wait
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import { api } from '@/configs/api'
import { mapGetters } from 'vuex'
import axios from 'axios'
import Upload from '@/components/Products/Upload'
import History from '@/components/Products/History'

export default {
    components: {
        Upload,
        History
    },
	data () {
		return {
            loading: true,
            beforeRouteDialog: false,
            historyDialog: false,
            uploadDialog: false,
            showFilter: false,
            search: '',
            headers: [
                { text: 'Name', value: 'model', sortable: false },
                { text: 'Colour', value: 'colors', sortable: false },
                { text: 'Material Number', value: 'materialNumber', sortable: false },
                { text: 'Category', value: 'category', sortable: false },
                { text: 'Brand', value: 'brand', sortable: false },
                { text: 'Price', value: 'srp', sortable: false },
                { text: 'Enabled', value: 'enable', sortable: false },
                { text: 'Featured Item', value: 'featured', sortable: false },
                { text: 'Active', value: 'active', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            footerOptions: {
                itemsPerPageOptions: [20, 30, 40, 50],
                itemsPerPageText: "Records per page"
            },
            products: [],
            displayedProducts: [],
            history: [],
            selected: [],
            items: ["Enabled", "Disabled", "Featured"],
            status: null
		}
    },

    computed: {
        ...mapGetters(['user']),

        selectedUnits() {
            return this.selected.map(u => u.id)
        },
        selectedStatus() {
            return this.status
        },
    },

    watch: {
        selectedStatus(after) {
            if(after === 'Enabled') {
                this.loadEnabledUnits()
            } else if(after === 'Disabled') {
                this.loadDisabledUnits()
            } else if(after === 'Featured') {
                this.loadFeaturedUnits()
            } else {
                this.displayedProducts = this.products
                this.showFilter = false
            }
        }
    },
    
	mounted() {
        this.fetchProducts()
    },

    methods: {
        async fetchProducts() {
            const self = this

            axios.get(`${api}/mc`)
            .then((result) => {
                self.products = result.data.data
                self.displayedProducts = self.products
                self.fetchUploadHistory()
                self.loading = false
            })
            .catch((error) => {
                self.showErrorMessage(error.message);
            })
        },
        fetchUploadHistory() {
            const self = this

            axios.get(`${api}/mc/upload/history`)
            .then((result) => {
                self.history = result.data.results
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        loadEnabledUnits() {
            const self = this
            self.displayedProducts = []
            self.loading = true

            let filterProducts = self.products.filter(product => product.enable == true)
            if(!filterProducts) {
                alert('No enabled products was found!')
                self.loading = false
            } else {
                self.displayedProducts = filterProducts
                self.loading = false
            }
        },
        loadDisabledUnits() {
            const self = this
            self.displayedProducts = []
            self.loading = true

            let filterProducts = self.products.filter(product => product.enable == false)
            if(!filterProducts) {
                alert('No disabled products was found!')
                self.loading = false
            } else {
                self.displayedProducts = filterProducts
                self.loading = false
            }
        },
        loadFeaturedUnits() {
            const self = this
            self.displayedProducts = []
            self.loading = true

            let filterProducts = self.products.filter(product => product.featured == true)
            if(!filterProducts) {
                alert('No featured products was found!')
                self.loading = false
            } else {
                self.displayedProducts = filterProducts
                self.loading = false
            }
        },
        openUploadForm() {
            let self = this
            self.uploadDialog = true
        },
        enableStorefrontItems() {
            const self = this
            if(!self.selectedUnits.length) {
                alert('Please select item(s)')
                return
            }

            self.selected.forEach(unit => {
                if(unit.enable == true) {
                    axios.put(`${api}/mc/storefront/disable`, {
                        enable: false,
                        itemIds: self.selectedUnits
                    })
                    .then(response => {
                        let responseData = response.data
                        if(!responseData.success) return
                        
                        self.selected = []
                        self.fetchProducts()
                    }).catch((error) => {
                        alert(error.message)
                    })
                } else {
                    axios.put(`${api}/mc/storefront/enable`, {
                        enable: true,
                        itemIds: self.selectedUnits
                    })
                    .then(response => {
                        let responseData = response.data
                        if(!responseData.success) return
                        
                        self.selected = []
                        self.fetchProducts()
                    }).catch((error) => {
                        alert(error.message)
                    })
                }
            })
            
            alert("Items(s) has been successfully updated!")
        },
        enableFeaturedItems() {
            const self = this
            if(!self.selectedUnits.length) {
                alert('Please select item(s)')
                return
            }

            self.selected.forEach(unit => {
                if(unit.featured == true) {
                    axios.put(`${api}/mc/featured/disable`, {
                        featured: false,
                        itemIds: self.selectedUnits
                    })
                    .then(response => {
                        let responseData = response.data
                        if(!responseData.success) return
                        
                        alert(responseData.message)
                        self.selected = []
                        self.fetchProducts()
                    }).catch((error) => {
                        alert(error.message)
                    })
                } else {
                    axios.put(`${api}/mc/featured/enable`, {
                        featured: true,
                        itemIds: self.selectedUnits
                    })
                    .then(response => {
                        let responseData = response.data
                        if(!responseData.success) return
                        
                        alert(responseData.message)
                        self.selected = []
                        self.fetchProducts()
                    }).catch((error) => {
                        alert(error.message)
                    })
                }
            })
        },
        openProduct(item) {
            const self = this
            let slug = item.slug

            self.beforeRouteDialog = true

            setTimeout(() => {
                self.$router.push(`/product/view/${slug}`)
            }, 1000)
        },
        showErrorMessage(message){
            alert(message);
        },
    }
}
</script>

<style>
@import '../../../node_modules/tabulator-tables/dist/css/tabulator.min.css';
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
</style>