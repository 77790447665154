<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Branch Details</v-toolbar-title>
        </v-toolbar>
        <v-subheader light><h1>{{details.readableName}}</h1></v-subheader>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="6">
                    <v-text-field dense disabled label="Netsuite Name" v-model="details.name"></v-text-field>
                    <v-text-field dense disabled label="Netsuite Internal ID" v-model="details.nsId"></v-text-field>
                    <v-text-field dense disabled label="Branch Code" v-model="details.branchCode"></v-text-field>
                    <v-text-field dense disabled label="Subsidiary" v-model="details.subsidiary"></v-text-field>
                    <v-text-field dense disabled label="Latitude" v-model="details.latitude"></v-text-field>
                    <v-text-field dense disabled label="Longitude" v-model="details.longitude"></v-text-field>
                    <v-text-field dense disabled label="Region" v-model="details.region"></v-text-field>
                    <v-textarea dense disabled label="Address" v-model="details.address"></v-textarea>
                </v-col>
                <v-col cols="6">
                    <v-text-field dense label="Branch Schedule" v-model="details.branchSchedule"></v-text-field>
                    <v-text-field dense label="Branch Contact No" v-model="details.branchContactNo"></v-text-field>
                    <v-text-field dense label="Contact Person" v-model="details.contactName"></v-text-field>
                    <v-text-field dense label="Contact Number" v-model="details.contactNumber"></v-text-field>
                    <!-- <v-text-field dense label="Province" v-model="details.Province"></v-text-field> -->
                    <Provinces v-model="details.province" />
                    <!-- <v-text-field dense label="Town" v-model="details.town"></v-text-field> -->
                    <Municipalities v-model="details.town" :province="details.province" />
                    <v-text-field dense label="Geography" v-model="details.geography"></v-text-field>
                    <v-select dense multiple chips deletable-chips small-chips
                        label="Brands" 
                        item-text="manufacturer" 
                        item-value="manufacturer" 
                        :items="brandOptions"
                        v-model="details.brands.mc"
                    />

                    <v-row dense no-gutters>
                        <v-col cols="6" >
                            <v-checkbox class="mr-10" dense label="For MC Delivery" v-model="details.mcDelivery" />
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mr-10" dense label="For MC Pickup" v-model="details.mcPickup"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mr-10" dense label="For Item Delivery" v-model="details.itemDelivery"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mr-10" dense label="For Item Pickup" v-model="details.itemPickup"></v-checkbox>
                        </v-col>
                        <!-- <v-col cols="6">
                            <v-checkbox class="mr-10" dense label="For Combo Delivery" v-model="details.comboDelivery"></v-checkbox>
                        </v-col> -->
                        <v-col cols="6">
                            <v-checkbox class="mr-10" dense label="For Combo Pickup" v-model="details.comboPickup"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox class="mr-10" dense disabled label="Active" v-model="details.status"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row dense><v-divider></v-divider></v-row>
            <v-row class="pt-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3" color="primary" @click="saveBranch">
                    <v-icon left>save</v-icon>
                    Submit
                </v-btn>
                <v-btn color="warning" @click="resetForm">
                    <v-icon left>refresh</v-icon>
                    Reset
                </v-btn>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import Provinces from '@/components/Select/Provinces'
import Municipalities from '@/components/Select/Municipalities'
import { isURL } from 'validator'

export default {
    components: {
        Snackbar, Provinces, Municipalities
    },
    data() {
        return {
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            details: {
                brands: { mc: [] }
            },
            brandOptions: [],
            rules: {
                required: value => !!value || 'Required',
                validUrl: value => isURL(value) || "Invalid URL"
            }
        }
    },
    mounted() {
        this.setBranchDetails()
        this.setBrandOptions()
    },
    methods: {
        setBranchDetails() {
            const self = this
            const branchId = self.$route.params.branchId

            this.$axios.get(`/branch/${branchId}`)
            .then((result) => {
                let responseData = result.data

                self.details = responseData.data
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        setBrandOptions() {
            const self = this

            this.$axios.get(`/products/manufacturers`)
            .then((result) => {
                let responseData = result.data
                self.brandOptions = responseData
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        saveBranch() {
            let self = this
            const isValid = this.$refs.form.validate()
            const branchId = this.$route.params.branchId

            if(!isValid) return

            this.$axios.put(`/branch/details/${branchId}`, this.details)
            .then(response => {
                let responseData = response.data
                self.showSnackbarMessage(responseData.message, responseData.success)
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        resetForm() {
            this.$refs.form.resetValidation()
        }
    }
}
</script>