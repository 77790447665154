<template>
    <v-card flat tile>
        <v-toolbar flat dense color="grey lighten-2">
            <v-spacer />
            <v-toolbar-items>
                <v-menu
                    v-model="dateFromMenu"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="mt-4 mr-1"
                            prepend-icon="event"
                            label="Date From"
                            justify-right
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                            v-model="filters.date_from"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.date_from" no-title :max="filters.date_to" @input="dateFromMenu = false" ></v-date-picker>
                </v-menu>

                <v-menu
                    v-model="dateToMenu"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        class="mt-4 mr-1"
                            prepend-icon="event"
                            label="Date To"
                            justify-right
                            readonly
                            dense
                            v-bind="attrs"
                            v-on="on"
                            v-model="filters.date_to"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.date_to" no-title :min="filters.date_from" @input="dateToMenu = false" ></v-date-picker>
                </v-menu>
                <ExtractBtn v-on:extract="extractReport"></ExtractBtn>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            @update:options="loadNextPage"
            :footer-props="footerProps"
        >
            <template v-slot:item.DATE="{ item }">
                {{new Date(item.DATE).toLocaleDateString('en-US')}}
            </template>

            <template v-slot:item.ADDRESS="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">{{compressAddress(item.ADDRESS)}}</div>
                    </template>
                    <span>{{item.ADDRESS}}</span>
                </v-tooltip>
            </template>

            <template v-slot:item.INSURANCE_FEE="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">{{parseFloat(item.INSURANCE_FEE)}}</div>
                    </template>
                    <span>{{item.ADDRESS}}</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-divider class="mt-5"/>
    </v-card>
</template>

<script>
import moment from 'moment'
import { api } from '@/configs/api'

import ExtractBtn from '@/components/Buttons/Extraction'

export default {
    components: {
        ExtractBtn
    },
    data() {
        return {
            loading: true,
            dateFromMenu: false,
            dateToMenu: false,
            serverItemTotal: 0,
            footerProps: {
                itemsPerPageOptions: [20, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            filters: {
                date_from: moment().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().endOf('month').format('YYYY-MM-DD'),
                t: 'xlsx'
            },
            records: [],
            headers: [
                { text: 'DATE', value: 'DATE', sortable: false },
                { text: 'ORDER ID', value: 'ORDER_ID', sortable: false },
                { text: 'NAME', value: 'NAME', sortable: false },
                { text: 'CONTACT NUMBER', value: 'CONTACT_NUMBER', sortable: false },
                { text: 'ADDRESS', value: 'ADDRESS', sortable: false },
                { text: 'DECLARE VALUE', value: 'DECLARE_VALUE', align: 'right', sortable: false },
                { text: 'INSURANCE FEE', value: 'INSURANCE_FEE', align: 'right', sortable: false }
            ]
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
    },
    watch: {
        "filters.date_from": 'loadRecords',
        "filters.date_to": 'loadRecords'
    },
    methods: {
        loadRecords() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }
            this.filters.t = 'json'

            this.$axios.get(`/reports/insurance`, {
                params: {
                    ...paging,
                    ...self.filters,
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.total
                self.loading = false
            }).catch((error) => {
                // self.showSnackbarMessage(error.message, false)
            })
        },
        compressAddress(addStr) {
            if(addStr == null) return
            return (addStr.length > 15) ? `${addStr.substring(0, 14)}...` : addStr
        },
        extractReport(type) {
            this.filters.t = type
            let params = new URLSearchParams(this.filters).toString()
            window.open(`${api}/reports/insurance?${params}`, '_blank')
        },
        loadNextPage() {
            this.loadRecords()
        }
    }
}
</script>