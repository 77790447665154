<template>
    <v-container>
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Payments</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field
                    class="mt-2"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                >
                </v-text-field>
            </v-toolbar-items>
        </v-toolbar>   
        <v-data-table :search="search" :items="payments" :headers="headers">
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-2" @click="openItem(item)">open_in_new</v-icon>
                    </template>
                    <span>View Payment</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Loading data
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please wait
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import moment from 'moment-business-days'
import Tabulator from 'tabulator-tables'
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
import { mapGetters } from 'vuex'
import DOMParser from 'dom-parser'
import xmlToJson from 'xml-to-json-stream'

export default {
	data () {
		return {
            beforeRouteDialog: false,
            dialog: false,
            payments: [],
            role: {},
            branch: {},
            search: '',
            headers: [
                { text: 'Order ID', value: 'orderId', sortable: false },
                { text: 'Amount', value: 'amount', sortable: false },
                { text: 'Payment Method', value: 'mode', sortable: false },
                { text: 'Payment Type', value: 'type', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Response Timestamp', value: 'createdAt', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ]
		}
    },
    computed: {
        ...mapGetters(['user']),

        checkRole() {
            let branchRoles = ["BM", "PC", "SE", "BAA"]
            return branchRoles.includes(this.role.roleCode)
        },
        filterPaymentsTable() {
            let payments = this.payments
            let filteredArray = []
            
            for(let i = 0; i < payments.length; i++) {
                payments[i].branch === this.branch.uid ? filteredArray.push(payments[i]) : console.log("Different branch")
            }
            this.payments = filteredArray

            return this.payments
        }
    },
	mounted() {
        this.getPayments()
        this.getRoleDetails(this.$store.getters.user.uid)
	},
    methods: {
        getRoleDetails(userId) {
            const self =  this

            axios.get(`${api}/role/${userId}`)
            .then(response => {
                self.role = response.data.result

                if(self.checkRole) self.getUserBranchDetails(userId)
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getUserBranchDetails(userId) {
            const self =  this

            axios.get(`${api}/user/branch/details/${userId}`)
            .then((result) => {
                self.branch = result.data.result

                self.filterPaymentsTable
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getPayments() {
            const self = this
            self.dialog = true

            axios.get(`${api}/payments`)
            .then((result) => {
                self.payments = result.data.data
                self.dialog = false

                self.decodeRawHTML()
            })
            .catch((error) => {
                self.dialog = false
                self.showErrorMessage(error.message)
            })
        },
        openItem(item) {
            let self = this
            self.beforeRouteDialog = true
            setTimeout(() => {
                self.$router.push('/payment/view/'+item.orderId)
            }, 1000)
        },
        decodeRawHTML() {
            const self = this
            let entries = this.payments
            let parser = xmlToJson({ attribute: false })

            for(let i = 0; i < entries.length; i++) {
                if(entries[i].xmlFile) {
                    parser.xmlToJson(entries[i].xmlFile, (err, json) => {
                        if(err) {
                            alert(err)
                        } else {
                            let response = json.ServiceResponseWPF
                            entries[i].createdAt = moment(response.application.timestamp).format("YYYY-MM-DD hh:mm:ss")
                        }
                    })
                } 
            }

        },
        showErrorMessage(message) {
            alert(message)
		},
    }
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css'
</style>

