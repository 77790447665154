<template>
    <v-card>
        <v-toolbar flat color="error" dark>
            <v-toolbar-title>Loan Disapproval</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="emitToDeclineDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <h2 class="body-1 mb-2 mt-5">Please provide details below:</h2>
            <v-text-field v-model="applicationId" outlined label="Application ID"></v-text-field>
            <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Application Date"
                            append-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            :rules="[rules.required]"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable></v-date-picker>
                </v-menu>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" color="error" @click="disapproveLoan">Submit</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { api } from '@/configs/api'

export default {
    name: 'decline-modal',
    data() {
        return {
            loader: null,
            loading: false,
            menu: null,
            applicationId: null,
            date: new Date().toISOString().substr(0, 10),
            rules: {
                required: value => !!value || 'Required.',
            },
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    },
    methods: {
        emitToDeclineDialog() {
            this.$emit('openDeclineDialog', false)
        },
        disapproveLoan() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            axios.put(`${api}/order/mcloan/disapproval`, {
                orderId: orderId,
                applicationId: self.applicationId,
                applicationDate: self.date,
                status: "Loan Disapproved"
            })
            .then(response => {
                let responseData = response.data
                self.loader = null
                self.$emit('openDeclineDialog', false)
                self.$emit('getOrder', orderId)
                setTimeout(() => {
                    self.$emit('errorAlert', true)
                }, 1000)  
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        showErrorMessage(message) {
            alert(message)
        }
    }
}
</script>