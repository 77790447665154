import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import Login from '../views/Login.vue'
import LoginError from '../views/LoginError.vue'
import Logout from '../views/Logout.vue'
import PageNotFound from '../views/PageNotFound.vue'

import Index from '../views/Dashboard/Index.vue'
import Products from '../views/Products/Index.vue'
import ProductView from '../views/Products/ProductView.vue'
import ProductEdit from '../views/Products/ProductEdit.vue'
import ProductNew from '../views/Products/ProductNew.vue'
import ProductVariantNew from '../views/Products/ProductVariantNew.vue'
import ProductVariantEdit from '../views/Products/ProductVariantEdit.vue'
import ProductLtoDetails from '../views/Products/LtoDetails.vue'
import Items from '../views/Items/Index.vue'
import ItemEdit from '../views/Items/ItemEdit.vue'
import TopSellingModel from '@/components/Products/TopSellingModel.vue'
import Orders from '../views/Orders/Index.vue'
import OrderView from '../views/Orders/OrderView.vue'
import Payments from '../views/Payments/Index.vue'
import PaymentView from '../views/Payments/PaymentView.vue'
import Reservations from '../views/Reservations/Index.vue'
import ReservationView from '../views/Reservations/ReservationView.vue'
import Discounts from '../views/Discounts/Index.vue'
import DiscountView from '../views/Discounts/DiscountView.vue'
import BranchCatalog from '../views/BranchCatalog/Index.vue'
import BranchEdit from '../views/BranchCatalog/BranchEdit.vue'
import Users from '../views/Users/Index.vue'
import UserEdit from '../views/Users/Edit.vue'
import Roles from '../views/Roles/Index.vue'
import Fees from '../views/Fees/Index.vue'
import Setups from '../views/Setups/Index.vue'
import Customers from '../views/Customers/Index.vue'
import CustomerView from '../views/Customers/View.vue'
import Reviews from '../views/Reviews/Index.vue'
import ReviewView from '../views/Reviews/ReviewView.vue'
import ReviewItem from '../views/Reviews/ReviewItem.vue'
import Reports from '../views/Reports/Index.vue'
import ReportInsurance from '../views/Reports/Insurance.vue'
import ReportOrders from '../views/Reports/Orders.vue'
import SetupDeliveryFee from '../views/Setups/DeliveryFee.vue'
import NewProductType from '../views/Setups/NewProductType.vue'
import MCTemplate from '../views/Setups/MCTemplate.vue'
import Website from '../views/Website/Index.vue'
import Emails from '../views/Email/Index.vue'
import DeliveryDates from '../views/Setups/DeliveryDates.vue'
import DeliveryDateDetails from '../views/Setups/DeliveryDateDetails.vue'
import ThirdPartyDeliveryDetails from '../views/Setups/ThirdPartyDeliveryDetails.vue'
import ThirdPartyDeliveryIndex from '../views/Setups/ThirdPartyDeliveryIndex.vue'
import PackagingDetails from '../views/Setups/PackagingDetails.vue'
import PackagingIndex from '../views/Setups/PackagingIndex.vue'
import DeliveryFeeDetails from '../views/Setups/DeliveryFeeDetails.vue'
import DeliveryFeeIndex from '../views/Setups/DeliveryFeeIndex.vue'
import ProductFreebiesDetails from '../views/Products/ProductFreebiesDetails.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			protected: false
		}
	},
	{
		path: "/login/error",
		name: "LoginError",
		component: LoginError,
		props: true
	},
	{
		path: "/logout",
		name: "Logout",
		component: Logout,
		meta: {
			protected: false
		}
	},
	{
		path: "/page-not-found",
		name: "PageNotFound",
		component: PageNotFound,
		props: true
	},
	{
		path: "/",
		name: 'Layout',
		component: Layout,
		redirect: '/index',
		children: [
			{
				path: "/index",
				name: 'Index',
				component: Index,
				meta: {
					protected: true
				}
			},
			{
				path: "/products",
				name: 'Products',
				component: Products,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/view/:slug",
				name: 'ProductView',
				component: ProductView,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/new",
				name: 'ProductNew',
				component: ProductNew,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/variant/new/:slug",
				name: 'ProductVariantNew',
				component: ProductVariantNew,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/edit/:slug",
				name: 'ProductEdit',
				component: ProductEdit,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/variant/edit/:slug",
				name: 'ProductVariantEdit',
				component: ProductVariantEdit,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/model/top_selling",
				name: 'ProductTopSellingModel',
				component: TopSellingModel,
				props: {
					department: [1],
					title: "Motorcycles"
				},
				meta: {
					protected: true
				}
			},
			{
				path: "/product/lto",
				name: 'ProductLtoDetails',
				component: ProductLtoDetails,
				meta: {
					protected: true
				}
			},
			{
				path: "/product/freebies",
				name: 'ProductFreebiesDetails',
				component: ProductFreebiesDetails,
				meta: {
					protected: true
				}
			},
			{
				path: "/items",
				name: 'Items',
				component: Items,
				meta: {
					protected: true
				}
			},
			{
				path: "/item/view/:itemid",
				name: 'ItemEdit',
				component: ItemEdit,
				meta: {
					protected: true
				}
			},
			{
				path: "/item/model/top_selling",
				name: 'ItemTopSellingModel',
				component: TopSellingModel,
				props: {
					department: [2,3,4,5,6],
					title: "Items"
				},
				meta: {
					protected: true
				}
			},
			{
				path: "/orders",
				name: 'Orders',
				component: Orders,
				meta: {
					protected: true
				}
			},
			{
				path: "/order/view/:orderId",
				name: 'OrderView',
				component: OrderView,
				meta: {
					protected: true
				}
			},
			{
				path: "/payments",
				name: 'Payments',
				component: Payments,
				meta: {
					protected: true
				}
			},
			{
				path: "/payment/view/:orderId",
				name: 'PaymentView',
				component: PaymentView,
				meta: {
					protected: true
				}
			},
			{
				path: "/reservations",
				name: 'Reservations',
				component: Reservations,
				meta: {
					protected: true
				}
			},
			{
				path: "/reservation/view/:uid",
				name: 'ReservationView',
				component: ReservationView,
				meta: {
					protected: true
				}
			},
			{
				path: "/discounts",
				name: 'Discounts',
				component: Discounts,
				meta: {
					protected: true
				}
			},
			{
				path: "/discount/view/:uid",
				name: 'DiscountView',
				component: DiscountView,
				meta: {
					protected: true
				}
			},
			{
				path: "/branch-catalog",
				name: 'BranchCatalog',
				component: BranchCatalog,
				meta: {
					protected: true
				}
			},
			{
				path: '/branch-catalog/edit/:branchId',
				name: 'BranchEdit',
				component: BranchEdit,
				meta: {
					protected: true
				}
			},
			{
				path: '/users',
				name: 'UserMgmt',
				component: Users,
				meta: {
					protected: true
				}
			},
			{
				path: '/user/:id',
				name: 'UserEdit',
				component: UserEdit,
				meta: {
					protected: true
				}
			},
			{
				path: '/roles',
				name: 'RoleMgmt',
				component: Roles,
				meta: {
					protected: true
				}
			},
			{
				path: '/fees',
				name: 'Fees',
				component: Fees,
				meta: {
					protected: true
				}
			},
			{
				path: "/delivery-dates/:id",
				name: 'DeliveryDates',
				component: DeliveryDates,
				meta: {
					protected: true
				}
			},
			{
				path: '/setups',
				name: 'Setups',
				component: Setups,
				meta: {
					protected: true
				}
			},
			{
				path: '/setups/delivery_fee',
				name: 'SetupDeliveryFee',
				component: SetupDeliveryFee,
				meta: {
					protected: true
				}
			},
			{
				path: '/setups/new/product_type',
				name: 'NewProductType',
				component: NewProductType,
				meta: {
					protected: true
				}
			},
			{
				path: '/setups/view/product_type/mc/:id',
				name: 'MCTemplate',
				component: MCTemplate,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/delivery-fee",
				name: 'DeliveryFeeIndex',
				component: DeliveryFeeIndex,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/delivery-fee/:id",
				name: 'DeliveryFeeDetails',
				component: DeliveryFeeDetails,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/packaging",
				name: 'PackagingIndex',
				component: PackagingIndex,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/packaging/:id",
				name: 'PackagingDetails',
				component: PackagingDetails,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/thirdParty",
				name: 'ThirdPartyDeliveryIndex',
				component: ThirdPartyDeliveryIndex,
				meta: {
					protected: true
				}
			},
			{
				path: "/delivery-dates-details",
				name: 'DeliveryDateDetails',
				component: DeliveryDateDetails,
				meta: {
					protected: true
				}
			},
			{
				path: "/setups/thirdParty/:id",
				name: 'ThirdPartyDeliveryDetails',
				component: ThirdPartyDeliveryDetails,
				meta: {
					protected: true
				}
			},
			{
				path: '/customers',
				name: 'Customers',
				component: Customers,
				meta: {
					protected: true
				}
			},
			{
				path: '/customer/:customerId/details',
				name: 'CustomerView',
				component: CustomerView,
				meta: {
					protected: true
				}
			},
			{
				path: "/reviews",
				name: 'Reviews',
				component: Reviews,
				meta: {
					protected: true
				}
			},
			{
				path: "/review/view/:sku",
				name: 'ReviewView',
				component: ReviewView,
				meta: {
					protected: true
				}
			},
			{
			  path: "/review/item/:reviewId",
			  name: 'ReviewItem',
			  component: ReviewItem,
			  meta: {
				  protected: true
			  }
			},
			{
				path: "/reports",
				name: 'Reports',
				component: Reports,
				meta: {
					protected: true
				},
				children: [
					{
						path: 'insurance',
						name: 'ReportInsurance',
						component: ReportInsurance
					},
					{
						path: 'orders',
						name: 'ReportOrders',
						component: ReportOrders
					}
				]
			},
			{
				path: "/website",
				name: 'Website',
				component: Website,
				meta: {
					protected: true
				}
			},
			{
				path: "/emails",
				name: 'EmailMgmt',
				component: Emails,
				meta: {
					protected: true
				}
			}
		]
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;



