<template>
	<div>
		<div class="container-fluid mt--7">
            <div class="row">
				<div class="col-md-12">
                    <v-card>
                        <v-card-title>{{order.orderId}}</v-card-title>
                        <v-card-subtitle>{{order.customerName}}</v-card-subtitle>
                        <v-simple-table class="table">
                           <tr>
                                <th>Shipping Fee:</th>
                                <td>{{order.shipping}}</td>
                                <th>Mode:</th>
                                <td>{{reservation.mode}}</td>
                                <th>Amount:</th>
                                <td>{{reservation.amount | thousandSeparator}}</td>
                            </tr>
                        </v-simple-table>
                    </v-card>

                    <v-row>
                        <v-col md="9">
                            <v-card title="Items">
                                <v-simple-table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="entry in entries" :key="entry.uid">
                                            <td>{{ entry.name }}</td>
                                            <!-- <td><router-link :to="`/viewproducts/${entry.name}`">{{entry.name}}</router-link></td> -->
                                            <td>{{ parseInt(entry.quantity) }}</td>
                                            <td>{{ entry.price | thousandSeparator }}</td>
                                            <td>{{ entry.discount | thousandSeparator }}</td>
                                            <td>{{ entry.total | thousandSeparator }}</td>
                                            <td>{{ entry.status }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-card-title v-show="show">Reservation</v-card-title>
                                    <v-card-text>
                                        <p v-if="reservation.status === 'Completed'">Reservation completed</p>
                                        <p v-if="reservation.status === 'Pending'">Reservation incomplete</p>
                                    </v-card-text>
                            </v-card>
                            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                                <v-card color="primary" dark>
                                    <v-card-text>
                                        Processing reservation
                                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import firebase from 'firebase'
import axios from 'axios'
import { api } from '@/configs/api'
export default {
     data: () => ({
        dialog: false,
        order: {},
        reservation: {},
        entries: [],
        waiting: false,
        show: true
    }),
    mounted() {
        this.getReservation(this.$route.params.uid)
    },
    methods: {
        getOrder(orderId) {
            const self = this

            axios.get(`${api}/reservation/order/view/${orderId}`)
            .then((result) => {
                self.order = result.data.data
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getOrderEntries(orderId) {
           const self = this
           
           axios.get(`${api}/reservation/order/entries/${orderId}`)
           .then((result) => {
                self.entries = result.data.data
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getReservation(uid) {
            const self = this

            axios.get(`${api}/reservation/details/${uid}`)
            .then((result) => {
                self.reservation = result.data.data
                self.getOrder(self.reservation.orderId)
                self.getOrderEntries(self.reservation.orderId)
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        async validateReservation() {
            let self = this
            self.waiting = true
            self.dialog = true

            let updateReservation = firebase.functions().httpsCallable('updateReservation')
            let result = await updateReservation({
                uid: self.$route.params.uid, 
                reference: self.reservation.reference,
                orderId: self.reservation.orderId,
                amount: self.reservation.amount,
                mode: self.reservation.mode,
                user: self.$store.getters.user.uid
            }).then(() => {
                self.waiting = false
                self.dialog = false
                self.getReservation(self.$route.params.uid)
                self.show = true
            })
        },
        showErrorMessage(message) {
            alert(message)
        },
    }
}
</script>
