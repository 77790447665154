<template>
	<div>
		<div class="container-fluid mt--7">
            <div class="row">
				<div class="col-md-12">
					<v-card header-tag="header" footer-tag="footer">
						<v-row class="ml-2">
							<v-col cols="12" sm="6" md="3">
                                <label for="ccnumber">Variant Attribute</label>
                                <v-text-field type="text" disabled v-model="selectedVariant.variantId" size="sm" class="mt-3" outlined></v-text-field>
							</v-col>
						</v-row>
						<v-row class="ml-2">
							<v-col cols="12" sm="6" md="3">
                                <label for="name">Variant Value</label>
                                <v-text-field type="text" v-model="selectedVariant.variantValue" required placeholder="" outlined></v-text-field>
							</v-col>
						</v-row>
						<v-row class="ml-2">
							<v-col cols="12" sm="6" md="3">
                                <label for="name">Variant Display Name</label>
                                <v-text-field type="text" v-model="selectedVariant.variantName" required placeholder="Product display name" outlined></v-text-field>
							</v-col>
						</v-row>
						<v-row class="ml-2">
							<v-col cols="12" sm="6" md="3">
                                <label for="name">SKU</label>
                                <v-text-field type="text" v-model="selectedVariant.sku" required placeholder="Product SKU" outlined></v-text-field>
							</v-col>
						</v-row>
                        <v-row class="ml-2">
							<v-col cols="12" sm="10" md="8">
                                <label for="name">Photo</label>
                                <v-text-field type="url" v-model="selectedVariant.photo" required placeholder="Photo" outlined></v-text-field>
							</v-col>
						</v-row>
						<v-row class="ml-2 pb-2">
                            <div class="text-center">
                                <v-btn class="ma-2" outlined color="blue" @click="submit">Submit</v-btn>
                            </div>
                        </v-row>
					</v-card>
				</div>
			</div>
			<div>
				<Snackbar v-model="alert" type="success" message="Motorcycle variants has been updated." />
			</div>
		</div>
	</div>
</template>

<script>
import slug from 'slug'
import firebase from 'firebase'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
	data () {
		return {
			selectedVariant: {
				variantId: null,
				variantValue: null,
				variantName: "",
				sku: null,
				photo: ""
			},
			alert: false,
		}
	},
	async mounted() {
		let self = this
		self.getProductVariant(self.$route.params.slug)
	},
	methods: {
		async getProductVariant(slug) {
			let self = this
            let getProductVariant = firebase.functions().httpsCallable('getProductVariant')
            let result = await getProductVariant({slug: slug})
            self.selectedVariant = result.data
		},
		
		async submit() {
			let self = this;
			this.selectedVariant.slug = slug(self.selectedVariant.variantName.toLowerCase())
			let updateProductVariant = firebase.functions().httpsCallable('updateProductVariant')
			let result = await updateProductVariant(self.selectedVariant)
			self.alert = true
			history.back()
		}
    },
	watch: {
		'selectedVariant.variantValue': {
            handler: function (after, before) {
                this.selectedVariant.variantName = `${this.selectedVariant.name} - ${after}`
            },
            deep: true
        }
	}
}
</script>
