<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Delivery Lead Time and Window Time Setups</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="addSetup" v-on="on"><v-icon>add</v-icon></v-btn>
                    </template>
                    <span>Add Setup</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>

        <v-container fluid>
            <v-layout row v-if="records.length">
                <v-flex xs2>
                    <v-card>
                        <br>
                        <v-card-text class="subtitle-2 text-center pb-5 pt-4">Product Type</v-card-text>
                        <br>
                        <v-card v-if="records[0].leadTime.values[0].productType==='Motorcycles'">
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center text-uppercase">{{records[0].leadTime.values[0].productType}}</v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        <v-card v-else>
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center text-uppercase">{{records[0].leadTime.values[0].productType}}</v-card-text>
                            <br><br><br><br>
                        </v-card>
                        <div v-if="records.length>1">
                            <v-card v-if="records[1].leadTime.values[0].productType==='Parts and Accessories'">
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center text-uppercase">{{records[1].leadTime.values[0].productType}}</v-card-text>
                                <br><br><br><br>
                            </v-card>
                            <v-card v-else>
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center text-uppercase">{{records[1].leadTime.values[0].productType}}</v-card-text>
                                <br><br><br><br><br>
                            </v-card>
                        </div>
                    </v-card> 
                </v-flex>

                <v-flex xs1>
                    <v-card>
                        <br>
                        <v-card-text class="subtitle-2 text-center pb-2 pt-1">Window Time</v-card-text>
                        <br>
                        <v-card v-if="records[0].leadTime.values[0].productType==='Motorcycles'">
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">{{records[0].leadTime.windowTime}}</v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        <v-card v-else>
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">{{records[0].leadTime.windowTime}}</v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        <div v-if="records.length>1">
                        <v-card v-if="records[1].leadTime.values[0].productType==='Parts and Accessories'">
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">{{records[1].leadTime.windowTime}}</v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        <v-card v-else>
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">{{records[1].leadTime.windowTime}}</v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        </div>
                    </v-card> 
                </v-flex>

                <v-flex xs2>
                    <v-card>
                        <br>
                        <v-card-text class="subtitle-2 text-center pb-5 pt-4">Origin</v-card-text>
                        <br>
                    </v-card> 
                    <v-card v-for="province in provincials" :key="province">
                        <v-card-text class="subtitle-2 text-center">{{province}}</v-card-text>
                    </v-card>
                    <div v-if="records.length>1">
                        <v-card v-for="province in provincials" :key="province">
                            <v-card-text class="subtitle-2 text-center">{{province}}</v-card-text>
                        </v-card>
                    </div>
                </v-flex>

                <v-flex xs6>
                    <v-card>
                        <v-card-text class="subtitle-2 text-center">Destination</v-card-text>
                    </v-card>
                    <v-layout>
                        <v-flex xs2 class="mr-5">
                        </v-flex>
                    </v-layout>
                    <v-layout>
                        <v-flex v-for="province in provincials" :key="province" xs3>
                            <v-card color="white" light>
                            <v-card-text class="subtitle-2 text-center">{{province}}</v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="records[0].leadTime.values[0].origin==='NCR'">
                        <v-flex v-for="(record,index) in records[0].leadTime.values[0].leadTime" :key="index" xs3>
                            <v-card light height="54px">
                                <v-card-text class="subtitle-2 text-center">
                                    {{record.days}}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="records[0].leadTime.values[1].origin==='LUZON'">
                        <v-flex v-for="(record,index) in records[0].leadTime.values[1].leadTime" :key="index" xs3>
                            <v-card light height="54px">
                                <v-card-text class="subtitle-2 text-center">
                                    {{record.days}}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="records[0].leadTime.values[2].origin==='VISAYAS'">
                        <v-flex v-for="(record,index) in records[0].leadTime.values[2].leadTime" :key="index" xs3>
                            <v-card light height="54px">
                                <v-card-text class="subtitle-2 text-center">
                                    {{record.days}}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="records[0].leadTime.values[3].origin==='MINDANAO'">
                        <v-flex v-for="(record,index) in records[0].leadTime.values[3].leadTime" :key="index" xs3>
                            <v-card light height="54px">
                                <v-card-text class="subtitle-2 text-center">
                                    {{record.days}}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-layout v-if="records[0].leadTime.values[4].origin==='ISLAND'">
                        <v-flex v-for="(record,index) in records[0].leadTime.values[4].leadTime" :key="index" xs3>
                            <v-card light height="54px">
                                <v-card-text class="subtitle-2 text-center">
                                    {{record.days}}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <div v-if="records.length>1">
                        <v-layout v-if="records[1].leadTime.values[0].origin==='NCR'">
                            <v-flex v-for="(record,index) in records[1].leadTime.values[0].leadTime" :key="index" xs3>
                                <v-card light height="54px">
                                    <v-card-text class="subtitle-2 text-center">
                                        {{record.days}}
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div v-if="records.length>1">
                        <v-layout v-if="records[1].leadTime.values[1].origin==='LUZON'">
                            <v-flex v-for="(record,index) in records[1].leadTime.values[1].leadTime" :key="index" xs3>
                                <v-card light height="54px">
                                    <v-card-text class="subtitle-2 text-center">
                                        {{record.days}}
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div v-if="records.length>1">
                        <v-layout v-if="records[1].leadTime.values[2].origin==='VISAYAS'">
                            <v-flex v-for="(record,index) in records[1].leadTime.values[2].leadTime" :key="index" xs3>
                                <v-card light height="54px">
                                    <v-card-text class="subtitle-2 text-center">
                                        {{record.days}}
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div v-if="records.length>1">
                        <v-layout v-if="records[1].leadTime.values[3].origin==='MINDANAO'">
                            <v-flex v-for="(record,index) in records[1].leadTime.values[3].leadTime" :key="index" xs3>
                                <v-card light height="54px">
                                    <v-card-text class="subtitle-2 text-center">
                                        {{record.days}}
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </div>

                    <div v-if="records.length>1">
                        <v-layout v-if="records[1].leadTime.values[4].origin==='ISLAND'">
                            <v-flex v-for="(record,index) in records[1].leadTime.values[4].leadTime" :key="index" xs3>
                                <v-card light height="54px">
                                    <v-card-text class="subtitle-2 text-center">
                                        {{record.days}}
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-flex>

                <v-flex xs1>
                    <v-card>
                        <br>
                        <v-card-text class="subtitle-2 text-center pb-4 pt-4">Action</v-card-text>
                        <br>
                        <div v-if="records.length==1">
                        <v-card v-if="records[0].leadTime.values[0].productType==='Motorcycles'">
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                        <v-icon size="25" @click="editSetup(records[0].id)" v-on="on">edit</v-icon>
                                    </template>
                                    <span>Edit Setup</span>
                                </v-tooltip>
                            </v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        <v-card v-else>
                            <br><br><br><br>
                            <v-card-text class="subtitle-2 text-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                        <v-icon size="25" @click="editSetup(records[0].id)" v-on="on">edit</v-icon>
                                    </template>
                                    <span>Edit Setup</span>
                                </v-tooltip>
                            </v-card-text>
                            <br><br><br><br><br>
                        </v-card>
                        </div>

                        <div v-if="records.length>1">
                            <v-card v-if="records[0].leadTime.values[0].productType==='Parts and Accessories'">
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-icon size="25" @click="editSetup(records[0].id)"  v-on="on">edit</v-icon>
                                        </template>
                                        <span>Edit Setup</span>
                                    </v-tooltip>
                                </v-card-text>
                                <br><br><br><br><br>
                            </v-card>

                            <v-card v-else>
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-icon size="25" @click="editSetup(records[0].id)"  v-on="on">edit</v-icon>
                                        </template>
                                        <span>Edit Setup</span>
                                    </v-tooltip>
                                </v-card-text>
                                <br><br><br><br><br>
                            </v-card>

                            <v-card v-if="records[1].leadTime.values[0].productType==='Motorcycles'">
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-icon size="25" @click="editSetup(records[1].id)"  v-on="on">edit</v-icon>
                                        </template>
                                        <span>Edit Setup</span>
                                    </v-tooltip>
                                </v-card-text>
                                <br><br><br><br><br>
                            </v-card>

                            <v-card v-else>
                                <br><br><br><br>
                                <v-card-text class="subtitle-2 text-center">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-icon size="25" @click="editSetup(records[1].id)"  v-on="on">edit</v-icon>
                                        </template>
                                        <span>Edit Setup</span>
                                    </v-tooltip>
                                </v-card-text>
                                <br><br><br><br><br>
                            </v-card>
                        </div>
                    </v-card> 
                </v-flex>
            </v-layout>

            <v-layout v-else>
                <v-card-text class="subtitle-2 text-center pt-5 mt-5">
                    No Data Found
                </v-card-text>
            </v-layout>
        </v-container>
      
        <v-dialog v-model="showDialog" width="500" height="500">
            <Details 
                :action="action"
                :setupRecord="setupRecord"
                v-on:setup-response="showActionResponse"
                v-on:close-form="closeDialog"
            ></Details>
        </v-dialog>
    </v-container>
</template>

<script>
import Details from './Details'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Details,
        Snackbar
    },
    data() {
        return {
            action: null,
            showDialog: false,
            loading: false,
            serverItemTotal: 0,
            setupRecord: null,
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            records: [],
            formattedData: [],
            provincials: ["NCR","LUZON","VISAYAS","MINDANAO","ISLAND"],
        }
    },
    mounted() {
        this.loadSetups();
    },
    watch: {
        showDialog(value) {
            if(!value) this.loadSetups()
        }
    },
    methods: {
        async loadSetups() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            await this.$axios.get(`/setup/delivery-dates/fetch`).then((result) => {
                self.records = result.data.data
                self.serverItemTotal = result.data.data.length
                self.loading = false
            }).then(()=> {
                console.log(self.records)
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },

        addSetup() {
            this.$router.push("/delivery-dates/new")
        },
        editSetup(id) {
            this.$router.push(`/delivery-dates/${id}`)
        },
        showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
        closeDialog() {
            this.showDialog = false
            this.setupRecord = null
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    },
}
</script>