<template>
    <div class="container-fluid mt--7">
        <div class="row">
            <div class="col-md-12">
                <div class="row mx-auto mb-2">
                    <span class="title" color="blue">View Order Details</span>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="$router.push('/orders')">Back</v-btn>
                </div>
                <v-divider></v-divider>
                <v-card class="mt-3 pa-3">
                    <v-row>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text">
                                    Order ID: {{ $route.params.orderId }}
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2">
                                    <strong>Order Status:</strong>&emsp;{{ order.status }}<br>
                                    <strong>Order Date:</strong>&emsp;{{ order.checkedOutAt }}<br>
                                    <strong>Last Update:</strong>&emsp;{{ order.updatedAt }}<br>
                                    <div v-if="order.paymentMethod === 'Pay in Installment'">
                                        <strong>Loan Status:</strong>&emsp;{{ loanDetails.status }}<br>
                                        <strong>Application Form:</strong>&emsp;<a @click="openFormDialog = true">View Offer Acceptance Information Form</a>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text subheading">
                                    Buyer Information
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2">
                                    <strong>Customer Name:</strong>&emsp;{{ order.customerName }}<br>
                                    <strong>Customer Email:</strong>&emsp;{{ order.email }}<br>
                                    <strong>Mobile Number:</strong>&emsp;{{ order.userNumber }}<br>
                                    <strong>Registered Address:</strong>&emsp;{{ order.fullAddress }}<br>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text">
                                    Payment Information
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2">
                                    <strong>Payment Status:</strong>&emsp;{{ payment.status }}<br>
                                    <strong>Payment Amount:</strong>&emsp;{{ order.total }}<br>
                                    <strong>Payment Method:</strong>&emsp;{{ order.paymentMethod }}<br>
                                    <div v-if="order.paymentMethod == 'Pay in Installment'">
                                        <strong>Downpayment Amount:</strong>&emsp;{{ loanDetails.downpayment }}<br>
                                    </div>
                                    <strong>Response ID / Payment Reference:</strong>&emsp;{{ payment.responseId }}<br>
                                    <strong>Discount:</strong>&emsp;{{ order.discount }}<br>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text subheading">
                                    Shipping and Handling Information
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2">
                                    <strong>Shipping Method:</strong>&emsp;{{ order.shippingMethod }}<br>
                                    <div v-if="order.deliveryAddress && order.shippingMethod == 'delivery'">
                                        <strong>Shipping Address:</strong>&emsp;{{ order.deliveryAddress }}
                                    </div>
                                    <div v-if="order.branchName">
                                        <strong>Branch:</strong>&emsp;{{ order.branchName }}
                                    </div>
                                    <div v-if="order.salesInvoiceDate">
                                        <strong>SI Date:</strong>&emsp;{{ order.salesInvoiceDate }}
                                    </div>
                                    <div v-if="order.salesInvoiceNumber">
                                        <strong>SI Number:</strong>&emsp;{{ order.salesInvoiceNumber }}
                                    </div>
                                    <div v-if="order.acceptedAt">
                                        <strong>Acceptance Date:</strong>&emsp;{{ order.acceptedAt }}
                                    </div>
                                    <div v-if="order.openBill">
                                        <strong>Supplier Invoice No.:</strong>&emsp;{{ order.openBill }}
                                    </div>
                                    <!-- New field -->
                                    <div v-if="order.waybillTrackingNo">
                                        <strong>Waybill Tracking No.:</strong>&emsp;{{ order.waybillTrackingNo }}
                                    </div>
                                    <div v-if="order.vendorBill">
                                        <strong>Open Bill ID:</strong>&emsp;{{ order.vendorBill }}
                                    </div>
                                    <div v-if="order.deliveryRefNo">
                                        <strong >Delivery Reference No:</strong>&emsp;{{ order.deliveryRefNo }}
                                    </div>
                                    <div v-if="order.shippingMethod == 'delivery'">
                                        <strong >Estimated Delivery Date:</strong>&emsp;{{ order.deliveryDateEnd }}
                                    </div>
                                    <div v-if="order.deliveryMode == 'external'">
                                        <strong >Courier Payment Status:</strong>&emsp;{{ order.courierPaymentStatus }}
                                    </div>
                                    <div v-if="order.packaging && isPartsOnly">
                                        <strong >Packaging:</strong>&emsp;
                                        <span v-for="(pack,index) in formattedPackaging" :key="index"> {{pack.package}} - QTY: {{pack.count}}<br> </span>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text subheading">
                                    Discount/Freebies
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2" v-if="order.paymentMethod == 'Pay in Installment'">
                                    <div v-if="order.ltoRegistrationFees == '0.00'">&emsp;Free LTO Registration Fee
                                    </div>
                                    <div v-if="entries.length">&emsp;
                                        <span v-for="(entry,index) in entries" :key="index">
                                            <span v-for="(freebie,index) in entry.freebies" :key="index">
                                                <span v-if="freebie!='LTO'">
                                                    {{freebie}} <br>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <!-- <div v-if="promos.length">&emsp;
                                        <span v-for="(promo,index) in promos" :key="index"> {{promo}} <br></span>
                                    </div> -->
                                </div>
                            </v-card>
                        </v-col>
                        <v-col md="6">
                            <v-card flat tile max-height="300">
                                <v-system-bar text color="#D3D3D3" class="black--text subheading">
                                    Other Fees
                                </v-system-bar>
                                <div class="ml-2 mt-2 body-2">
                                    <strong>LTO Registration Fee:</strong>&emsp;{{ order.ltoRegistrationFees }}<br>
                                    <div v-if="order.shippingMethod == 'delivery'">
                                        <strong>Shipping Fee:</strong>&emsp;{{ order.shipping }}
                                    </div>
                                    <strong>Online Transaction Fee:</strong>&emsp;{{ order.onlineTransactionFee }}<br>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row>
                    <v-col md="9">
                        <v-card>
                            <v-card-title>Items
                                <v-spacer />
                                <template v-if="stocks.loading && forStocksCheckingStatus">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        
                                    /><v-card-subtitle>Checking Netsuite stocks...</v-card-subtitle>
                                </template>
                                <v-card-subtitle class="red--text" style="width: 610px" v-if="stocks.checked && !stocks.valid && forStocksCheckingStatus" >
                                    <p class="text-justify caption">Highlighted items in red do not have enough stocks. Please send an email request to Logistics 
                                    team so they can process the Transfer Order and Item Fulfillment (TO/IF) in Netsuite.</p>
                                </v-card-subtitle>
                            </v-card-title>
                                <v-simple-table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">SKU</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="entry in entries" :key="entry.uid" :ref="'entry'+entry.sku">
                                            <td>{{ entry.name }}</td>
                                            <td>{{ entry.sku }}</td>
                                            <td>{{ parseInt(entry.quantity) }}</td>
                                            <td>{{ entry.price | thousandSeparator }}</td>
                                            <td>{{ entry.discount | thousandSeparator }}</td>
                                            <td>{{ entry.total | thousandSeparator }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card>
                    </v-col>
                    <v-col md="3">
                        <v-card border="top" colored-border type="info">
                            <!-- <v-card-title class="heading-6">Actions</v-card-title> -->
                            <Control 
                                :order="order" 
                                :entries="entries"
                                :payment="payment" 
                                :loanDetails="loanDetails"  
                                :validStocks="stocks.valid"
                                v-on:successAlert="showSnackbarMessage(successSnackBarMessage)"
                                v-on:failureAlert="showSnackbarMessage"
                                v-on:disapprovalModal="disapprovalModal = true" 
                                v-on:openDeclineDialog="openDeclineDialog = true" 
                                v-on:show="show = true" 
                                v-on:getOrder="getOrder" 
                                v-on:openCADeclineDialog="openCADeclineDialog = true"
                                v-on:allocateStocks="getStocks"
                            ></Control>
                        </v-card>
                        <v-dialog v-model="dialog" hide-overlay persistent width="300">
                            <v-card color="primary" dark>
                                <v-card-text>
                                    Processing order
                                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.message" />

                <v-dialog v-model="disapprovalModal" persistent max-width="600">
                    <Disapprove v-on:disapprovalModal="disapprovalModal = false" v-on:errorAlert="showSnackbarMessage" v-on:getOrder="getOrder"></Disapprove>
                </v-dialog>

                <v-dialog 
                    v-model="openFormDialog"
                    class="mx-auto"
                    max-width="600"
                    persistent
                    :no-click-animation="true"
                >
                    <Form :loanDetails='loanDetails' :user="user" :payment="payment" :order="order" v-on:openFormDialog="openFormDialog = false" v-on:show="show = true" v-on:getOrder="getOrder" v-on:openCADeclineDialog="openCADeclineDialog = true" v-on:successAlert="showSnackbarMessage(successSnackBarMessage)" v-on:errorAlert="showSnackbarMessage"></Form>
                </v-dialog>

                <v-dialog v-model="openDeclineDialog"
                    class="mx-auto"
                    max-width="350"
                    persistent
                    :no-click-animation="true"
                >
                    <Decline v-on:openDeclineDialog="openDeclineDialog = false" v-on:errorAlert="showSnackbarMessage(errorSnackBarMessage, false)" v-on:getOrder="getOrder"></Decline>
                </v-dialog>

                <v-dialog v-model="openCADeclineDialog"
                    class="mx-auto"
                    max-width="290"
                    persistent
                    :no-click-animation="true"
                >
                    <v-card>
                        <v-toolbar flat color="error" dark>
                            <v-toolbar-title>Loan Disapproval</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="openCADeclineDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="mt-5 body-1">
                            <span>Are you sure you want to disapprove this application?</span>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="openCADeclineDialog = false">Cancel</v-btn>
                            <v-btn @click="disapprove" color="error" :loading="loading" :disabled="loading">Confirm</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment-business-days'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { api } from '@/configs/api'
import _ from 'lodash'
import DOMParser from 'dom-parser'
import xmlToJson from 'xml-to-json-stream'
import Form from '@/components/Order/Form'
import Control from '@/components/Order/Control'
import Decline from '@/components/Order/Decline'
import Disapprove from '@/components/Order/Disapprove'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Form,
        Control,
        Decline,
        Disapprove,
        Snackbar
    },
    data: () => ({
        loader: null,
        loading: false,
        waiting: false,
        dialog: false,
        show: true,
        disapprovalModal: false,
        openFormDialog: false,
        openDeclineDialog: false,
        openCADeclineDialog: false,
        successSnackBarText: "was updated.",
        errorSnackBarText: "meet an error.",
        role: {},
        order: {},
        entries: [],
        payments: [],
        snackbar: {
            message: '',
            type: 'success',
            alert: false
        },
        stocks: {
            records: [],
            valid: null,
            loading: false,
            checked: false
        },
        payment: {},
        loanDetails: {},
        response: {},
        formattedPackaging: [],
        isPartsOnly: false,
        promos: []
    }),
    computed: {
        ...mapGetters(['user']),

        errorSnackBarMessage: function() {
            return `Order #${this.order.orderId} ${this.errorSnackBarText}`
        },
        successSnackBarMessage: function() {
            return `Order #${this.order.orderId} ${this.successSnackBarText}`
        },
        forStocksCheckingStatus() {
            let availableStatus = ['For Stock Allocation']
            return availableStatus.find(el => this.order.status === el) !== undefined
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
        'stocks.records': function (value) {
            let self = this

            this.stocks.valid = _.every(this.entries, (rec) => {
                let sku = rec.sku
                let qty = parseInt(rec.quantity)
                
                let stockRecord = _.find(value, { sku })

                if(stockRecord == undefined) return false
                
                let stocks = parseInt(stockRecord.stocks)
                return qty <= stocks
            })

            this.entries.forEach(rec => {
                let sku = rec.sku
                let qty = parseInt(rec.quantity)
                let stockRecord = _.find(value, { sku })

                let stocks = (stockRecord == undefined) ? 0 : parseInt(stockRecord.stocks)
                stocks = isNaN(stocks) ? 0 : stocks
 
                if(qty > stocks) {
                    self.$refs[`entry${sku}`][0].className = 'red lighten-4'
                }
            })

            this.stocks.loading = false
            this.stocks.checked = true
        }
    },
    mounted() {
        let self = this
        let orderId = self.$route.params.orderId
        self.getRoleDetails(self.$store.getters.user.uid)
        self.getOrder(orderId)
        self.getOrderEntries(orderId)
    },
    methods: {
        async getRoleDetails(userId) {
            const self =  this

            try {
                let response = await axios.get(`${api}/role/${userId}`)
                self.role = response.data.result
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async getOrder(orderId) {
            const self = this

            try {
                let result = await axios.get(`${api}/order/view/${orderId}`)
                self.order = result.data.data
                if(self.order.acceptedAt) self.order.acceptedAt = moment(self.order.acceptedAt).format("YYYY-MM-DD hh:mm:ss");
                if(self.order.branch != null) await self.getBranch(self.order.branch)
                if(self.order.paymentMethod == 'Pay in Installment') await self.getLoanDetails(orderId)
                self.getPayment(orderId)

                if(self.order.packaging) {
                    console.log(self.order.packaging)
                    self.formatPackaging(self.order.packaging)
                }
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        formatPackaging(arr) {
            let self = this
            self.formattedPackaging = []
            var count = {};
            for (let i = 0; i < arr.length; i++) {
                var val = arr[i].name;
                if (val in count) {
                    count[val] = count[val] + 1;
                } else {
                    count[val] = 1;
                }
            }

            for (let key in count) {
                self.formattedPackaging.push({package:key,count:count[key]})
            }
        },

        async getLoanDetails(orderId) {
            const self = this

            try {
                let result = await axios.get(`${api}/order/loan/details/${orderId}`)
                self.loanDetails = result.data.data
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async getBranch(branchUid) {
            const self = this

            try {
                let result = await axios.get(`${api}/order/branch/${branchUid}`)
                self.order.branchName = result.data.result.readableName
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async getOrderEntries(orderId) {
            const self = this
            self.promos = []
            try {
                let response = await axios.get(`${api}/order/entries/${orderId}`)
                self.entries = response.data.data

                self.isPartsOnly =  self.entries.find(element => element["department"] !== "Motorcycles");

                // if (self.entries) {
                //     self.entries.forEach(function(res) {
                //         try {
                //             let apiResult = axios.get(`${api}/mc/freebies/view/${res.sku}`).then(function(result) {
                //                 console.log(result.data.data)
                //                 result.data.data.freebies.forEach(function(list) {
                //                     self.promos.push(list)
                //                 })
                //                 console.log(self.promos)
                //             })
                //         } catch (error) {
                            
                //         }
                //     })
                // }
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async getStocks() {
            let orderId = this.$route.params.orderId
            let self = this
            self.stocks.loading = true
            this.stocks.checked = false

            try {
                let response = await axios.get(`${api}/order/stocks/${orderId}`)
                self.stocks.records = response.data.data
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async getPayment(orderId) {
			const self = this
            
            try {
                if(self.order.paymentMethod == 'Pay Online') {
                    let result = await axios.get(`${api}/payment/details/payOnline/${orderId}`)
                    self.payment = result.data.data
                    self.xmlDoc()
                } else {
                    let result = await axios.get(`${api}/payment/details/payInInstallment/${orderId}`)
                    self.payment = result.data.data

                    if(self.payment == null) {
                        self.payment = {
                            status: 'Pending',
                            mode: null,
                            reference: null
                        }
                    } else {
                        if(self.payment.mode === 'Pay Online') {
                            let result = await axios.get(`${api}/payment/details/payOnline/${orderId}`)
                            self.payment = {}
                            self.payment = result.data.data
                            self.xmlDoc()
                        } else {
                            let result = await axios.get(`${api}/payment/details/cashOverTheCounter/${orderId}`)
                            self.payment = result.data.data
                        }
                    }
                }
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        disapprove() {
            const self = this
            self.loader = 'loading'
            let orderId = self.order.orderId

            axios.put(`${api}/order/ca/disapproval`, {
                orderId: orderId,
                status: "Loan Disapproved",
                applicationStatus: 'Loan Disapproved'
            })
            .then(response => {
                let responseData = response.data
                self.openCADeclineDialog = false

                setTimeout(() => {
                    self.loader = null
                    self.showSnackbarMessage('Loan Disapproved' ,false)
                    self.getOrder(orderId)
                }, 1000) 
            })
            .catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        xmlDoc() {
			const self = this
			let content = self.payment.responseDocument
			let buffer = new Buffer(content, "base64");
			let decodedContent = buffer.toString("ascii");
			let parser = new DOMParser()
			let xmlDoc = parser.parseFromString(decodedContent, "text/xml")

			self.parseXMLDoc(xmlDoc.rawHTML)
		},
		parseXMLDoc(xml) {
			const self = this
			let parser = xmlToJson({ attribute: false })

			parser.xmlToJson(xml, (err, json) => {
				if(err) {
					alert(err)
				} else {
					self.response = json.ServiceResponseWPF
					self.payment.responseId = self.response.application.response_id
				}
			})
		},
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    }
}
</script>


<style>
.border-class {
    border-top: 5px solid lightblue !important;
}
</style>