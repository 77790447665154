<template>
    <v-card flat>
        <v-card-title>{{title}} Role</v-card-title>
        <v-divider></v-divider>
            
        <v-form ref="createRoleForm" class="px-8">
            <v-text-field 
                label="Role Code"
                v-model="record.roleCode"
                :disabled="action === 'editrole'"
                :rules="[rules.required, rules.alphabet]"
                @input="capitalizeRoleCode"
            ></v-text-field>
            <v-text-field 
                label="Name"
                v-model="record.name"
                :rules="[rules.required]"
            ></v-text-field>
            <v-checkbox
                label="Active"
                v-model="record.active"
            ></v-checkbox>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-show="action === 'createrole'" color="primary" @click="saveRole">&nbsp; Save</v-btn>
            <v-btn v-show="action === 'editrole'" color="primary" @click="updateRole">&nbsp; Update</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'
import { required, alphabet } from '@/plugins/validation'

export default {
    props: {
        roleDetails: { type: Object },
        action: String
    },
    data() {
        return {
            record: {
                roleCode: null,
                name: null,
                active: true
            },
            rules: {
                required,
                alphabet
            }
        }
    },
    computed: {
        title() {
            let title

            switch (this.action) {
                case 'createrole':
                    title = "Create"
                    break;
                case 'editrole':
                    title = "Edit"
                    break;
            
                default:
                    break;
            }

            return title
        }
    },
    watch: {
        roleDetails: {
            handler(rec) {
                if(!rec) return
                
                this.record = {...rec}
            },
            immediate: true
        }
    },
    methods: {
        saveRole() {
            let self = this
            const isValid = this.$refs.createRoleForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/role`, this.record)
            .then(response => {
                let responseData = response.data
                self.$emit('action-role-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('action-role-response', error.message, false)
            })
        },
        updateRole() {
            let self = this
            const isValid = this.$refs.createRoleForm.validate()

            if(!isValid) return

            this.$axios.put(`${api}/admin/role/${this.roleDetails.id}`, this.record)
            .then(response => {
                let responseData = response.data
                self.$emit('action-role-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('action-role-response', error.message, false)
            })
        },
        capitalizeRoleCode(value) {
            if(!value) return
            this.record.roleCode = value.toUpperCase()
        },
        resetForm() {
            this.$refs.createRoleForm.reset()
            this.$refs.createRoleForm.resetValidation()
            this.$emit('close-create-form')
        }
    }
}
</script>