import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import "../node_modules/vuetify/dist/vuetify.min.css";

import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/functions'
import { config } from './configs/firebase'
import VueGoodTablePlugin from 'vue-good-table'
import axios from 'axios'
import isEmpty from 'is-empty'
import {api} from '@/configs/api'

export const firebaseApp = firebase.initializeApp(config)
export const db = firebase.firestore();
export const storage = firebase.storage().ref()
export const functions = firebase.functions()

firebase.auth().onAuthStateChanged(function(user) {
	updateToken(user)
});

router.beforeEach(async (to, from, next) => {
	if (!to.matched.some(record => record.meta.protected)) {
		//route is public, don't check for authentication
			next();
	} else {
		//route is protected, if authenticated, proceed. Else, login
		
		if(!isEmpty(store.getters.user)) {
			//generate new refreshed token 
			await updateToken(await firebase.auth().currentUser)
			// check first if token is still valid
			axios.get(`${api}/verify/${store.getters.token}`).then(async (verifyResponse) => {
				if(verifyResponse.data.success){
					// check of user has access to the route
					let response = await axios.get(`/admin/check/user/${store.getters.user.uid}/route/${to.name}`)
				
					if(response.data.success) {
						next()
					} else {
						//redirect to page not found router
						router.push({ name: 'PageNotFound', params: { errorMsg: response.data.message }})
					}
				} else {
					router.push({ name: 'Logout' })
				}
			}).catch(error => {
				const response = error.response

				if(response.status === 401) {
					router.push({ name: 'PageNotFound', params: { errorCode: response.status, errorMsg: response.data.message }})
				}
			})
		} else {
			router.push({ name: 'Login' })
		}
	}
});

async function updateToken(user) {
	if(user) store.commit('SET_TOKEN', await user.getIdToken(true))
}

Vue.filter('thousandSeparator', (value) => {
	var splitNum
	var number = Math.abs(value)
	number = number.toFixed(2)
	splitNum = number.split('.')
	splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	return splitNum.join(".")
})

Vue.filter('readableDateTime', (value) => {
	return moment(value).format("MMMM DD, YYYY hh:mm A")
})

//set axios global configs
axios.defaults.baseURL = api;
axios.defaults.headers.common['Authorization'] = store.getters.bearerToken
axios.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	const response = error.response

	if(response.status === 401) {
		router.push({ name: 'PageNotFound', params: { errorCode: response.status, errorMsg: response.data.message }})
	}
	return Promise.reject(error);
});

Vue.prototype.$axios = axios
Vue.use(VueGoodTablePlugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
