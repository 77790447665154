<template>
	<v-card>
		<Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
		<v-card-title>
			<v-row no-gutters>
				<v-col col cols="1">
					<v-img :src="user.photo" max-height="50" max-width="50"></v-img>
				</v-col>
				<v-col col cols="10">{{user.socialName}}</v-col>
			</v-row>
		</v-card-title>
		<v-divider/>
		<v-card-text>
			<v-row no-gutters>
				<v-col cols="5">
					<v-text-field 
						persistent-hint
						outlined densed
						:disabled="!edit"
						class="mr-2" 
						hint="Only SyCha's GSuite account is available."
						label="Email"
						prepend-inner-icon="alternate_email"
						v-model="user.email"
						:rules="[rules.required]"
					/>
				</v-col>
				<v-col cols="5">
					<v-text-field 
						persistent-hint
						outlined densed
						:disabled="!edit"
						class="mr-2" 
						hint="Enter 11-digit number. Ex: 09091234567"
						label="Contact Number"
						prepend-inner-icon="call"
						v-model="user.contactNumber"
						:rules="[rules.required, rules.numeric, rules.minMaxLength(1,11)]"
					/>
				</v-col>
				<v-col col cols="2">
					<v-checkbox :disabled="!edit" label="Active" v-model="user.status"></v-checkbox>
				</v-col>
			</v-row>
			<v-row>
				<v-col col cols="12" sm="12" md="6" lg="6">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr class="blue-grey lighten-5">
									<th><h3>Roles</h3></th>
									<th style="text-align:center;">
										<v-btn :disabled="!edit" color="success" x-small @click="addRole">
											<v-icon small>add</v-icon> Add Role
										</v-btn>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="role in user.roles" :key="role.id">
									<td>{{role.roledef.name}}</td>
									<td align="center">
										<v-btn :disabled="!edit" small icon color="error" @click="removeRole(role.id)">
											<v-icon>remove_circle_outline</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
				<v-col col cols="12" sm="12" md="6" lg="6">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr class="blue-grey lighten-5">
									<th><h3>Branches</h3></th>
									<th style="text-align:center;">
										<v-btn :disabled="!edit" color="success" x-small @click="addBranch">
											<v-icon small>add</v-icon> Add Branch
										</v-btn>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="branch in user.branches" :key="branch.id">
									<td>{{branch.branchdef.readableName}}</td>
									<td align="center">
										<v-btn :disabled="!edit" small icon color="error" @click="removeBranch(branch.id)">
											<v-icon>remove_circle_outline</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
				<v-col col cols="6" sm="12" md="6" lg="6"></v-col>
			</v-row>
		</v-card-text>
		<v-divider/>
		<v-card-actions>
			<v-spacer/>
			<v-btn v-show="!edit" color="secondary" @click="edit=true">
				Edit
			</v-btn>
			<v-btn v-show="edit" color="primary" @click="updateUser">
				<!-- {{Boolean(user.status) ? "Deactivate" : "Activate"}} -->
				Update
			</v-btn>
			<v-btn v-show="edit" color="white" @click="edit=false">
				Cancel
			</v-btn>
		</v-card-actions>

		<v-dialog v-model="showDialog" width="500" height="500">
            <AddRole
                v-if="userAction === 'addrole'"
                :user-roles="userRoles"
                :user-id="user.id"
                v-on:add-role-response="showActionResponse"
                v-on:cancel-add-role="closeDialog"
            ></AddRole>

            <AddBranch
                v-if="userAction === 'addbranch'"
                :user-id="user.id"
                v-on:add-branch-response="showActionResponse"
                v-on:cancel-add-branch="closeDialog"
            >
            </AddBranch>
        </v-dialog>
	</v-card>
</template>

<script>
import AddRole from './AddRole'
import AddBranch from './AddBranch'
import Snackbar from '@/components/Snackbar'
import { required, validUrl, numeric, minMaxLength } from '@/plugins/validation'

export default {
	components: {
        AddRole,
        AddBranch,
		Snackbar
    },
	data() {
		return {
			edit: false,
			userAction: null,
			showDialog: false,
			userRoles: [],
			user: {
				roles: [],
				branches: []
			},
			snackbar: {
				responseMessage: "",
                show: false,
                type: "success"
            },
			rules: { required, numeric, minMaxLength }
		}
	},
	watch: {
		showDialog(value) {
            if(!value) this.setUserDetails()
		},
		user() {
			this.edit = false
		}
	},
	mounted() {
		this.setUserDetails()
	},
	methods: {
		async setUserDetails() {
			let userDetails = await this.getUserDetails()
			this.user = userDetails
		},
		async getUserDetails() {
			const userId = this.$route.params.id
			let response
			
			try {
				response = await this.$axios.get(`/admin/user/${userId}`)
			} catch (error) {
				this.showSnackbarMessage(error.message, false)
			}

			return response.data.data
		},
		addRole(){
            this.showDialog = true
            this.userAction = 'addrole'
            this.userRoles = this.user.roles.map(rec => rec.roleCode)
        },
        addBranch(){
            this.showDialog = true
            this.userAction = 'addbranch'
            // this.userRoles = item.roles.map(rec => rec.roleCode)
		},
		removeRole(id) {
			let self = this

			this.$axios.delete(`/admin/user/role/${id}`)
			.then(response => {
				let responseBody = response.data
				self.showSnackbarMessage(responseBody.message)
				self.setUserDetails()
			}).catch(error => {
				self.showSnackbarMessage(error.message, false)
			})
		},
		removeBranch(id) {
			let self = this

			this.$axios.delete(`/admin/user/branch/${id}`)
			.then(response => {
				let responseBody = response.data
				self.showSnackbarMessage(responseBody.message)
				self.setUserDetails()
			}).catch(error => {
				self.showSnackbarMessage(error.message, false)
			})
		},
		updateUser() {
			let self = this
			const userId = this.$route.params.id

			this.$axios.put(`/admin/user/${userId}`, { 
				status: this.user.status,
				email: this.user.email,
				contactNumber: this.user.contactNumber
			}).then(response => {
				let responseBody = response.data
				self.setUserDetails()
				self.showSnackbarMessage(responseBody.message, responseBody.success)
			}).catch(error => {
				self.showSnackbarMessage(error.message, false)
			})
		},
		showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
		showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
		},
		closeDialog() {
            this.showDialog = false
        }
	}
}
</script>