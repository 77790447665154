<template>
    <v-menu
        close-on-content-click
        offset-y
        :offset-x="false"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"
                depressed
                v-bind="attrs"
                v-on="on"
            >Extract<v-icon right>get_app</v-icon></v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(type, index) in buttons"
                :key="index"
                @click="emitExtraction(type)"
            >
                <v-list-item-content><v-list-item-title v-text="type.toUpperCase()"></v-list-item-title></v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data() {
        return {
            buttons: ['xlsx', 'csv']
        }
    },
    methods: {
        emitExtraction(type) {
            this.$emit('extract', type)
        }
    }
}
</script>