<template>
    <v-card outlined dense>
        <v-card-title>Reports</v-card-title>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col col cols="2" md="2" class="border-left">
                <v-list outlined dense>
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            :to="{ name: item.code }"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-col col cols="9" md="10">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.getReports()
    },
    methods: {
        getReports() {
            let self = this

            this.$axios.get(`/lovs`, {
                params: { groupCode: 'REPORTS' }
            }).then(result => {
                self.items = result.data.data    
            })
        }
    }
}
</script>

<style scoped>
.border-left {
    border-right-color: #EEEEEE !important;
    border-right-style: solid;
}
</style>