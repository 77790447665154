<template>
    <v-card flat>
        <v-card-title>Revoke User Session</v-card-title>
        <v-divider></v-divider>
        <v-form ref="revokeUserForm" class="px-8">
            <v-select
                item-text="name"
                item-value="type"
                label="Type"
                :items="userTypes"
                v-model="userType"
            >
                <!-- :rules="[rules.required]" -->
            </v-select>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="revokeUser">Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'

export default {
    props: {
        userIds: { 
            type: Array, 
            default: function () {
                return []
            } 
        }
    },

    data() {
        return {
            userTypes: [
                { name: 'Customers', type: 'customer' },
                { name: 'Admins', type: 'admin' },
            ],
            userType: 'customer'
        }
    },

    watch: {},

    methods: {
        revokeUser() {
            let self = this
            const isValid = this.$refs.revokeUserForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/user/revoke/all`, {
                type: this.userType
            }).then(response => {
                let responseData = response.data
                self.$emit('revoke-user-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('revoke-user-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.revokeUserForm.reset()
            this.$refs.revokeUserForm.resetValidation()
            this.$emit('cancel-revoke-user')
        }
    }
}
</script>