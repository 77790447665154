<template>
	<v-card-text>
		<div v-if="role.roleCode === 'BAA' && payment.mode === 'Cash over the Counter' && payment.type === 'Downpayment' && payment.status === 'Pending'">
			<v-text-field ref="dpTextField" v-model="downpayment" outlined dense label="Enter Amount Receivable" :rules="[rules.required]"></v-text-field>
			<v-btn color="info" @click="receiveDownpayment" :loading="loading" :disabled="loading" block>
			Receive
			</v-btn>
		</div>

		<div v-else class="body-1">
			<p v-if="payment.status === 'Completed'">Order was paid</p>
		</div>

	</v-card-text>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { api } from '@/configs/api'

export default {
	name: 'user-controls',
	props: {
		order: Object,
		payment: Object,
		user: Object,
		response_id: String
	},
	data () {
		return {
			loader: false,
			loading: false,
			role: {},
			downpayment: null,
			rules: {
				required: value => !!value || 'Required.',
			},
		}
	},
	mounted() {
		this.getRoleDetails(this.user.uid)
	},
	watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
	methods: {
		getRoleDetails(userId) {
			const self =  this
			
			axios.get(`${api}/role/${userId}`)
			.then(response => {
				self.role = response.data.result
			})
			.catch((error) => {
				self.showErrorMessage(error.message)
			})
			
        },
        receiveDownpayment() {
            const self = this
			let orderId = self.$route.params.orderId

			self.loader = 'loading'

			if(self.$refs.dpTextField.validate() && self.response_id) {
				if(parseFloat(self.downpayment) === parseFloat(self.payment.amount)) {
					axios.put(`${api}/payment/receive/downpayment/validation`, {
						orderId: orderId,
						responseId: self.response_id,
						amount: self.downpayment,
						status: "Offer Accepted",
						type: "Downpayment",
						paymentStatus: "Completed"
					})
					.then(response => {
						let responseData = response.data
						self.downpayment = null
						self.loader = null
						self.$emit('alert', true)
						setTimeout(() => {
							self.$emit('getOrder', orderId)
						}, 1000)
					})
					.catch((error) => {
						self.showErrorMessage(error.message)
					})
				} else {
					alert('Please enter exact amount!')
					self.loader = null
				}
			} else if(self.$refs.dpTextField.validate() && !self.response_id) {
				alert('PT Number is requred!')
				self.loader = null
			} else if(!self.$refs.dpTextField.validate() && self.response_id) {
				alert('Downpayment Amount is requred!')
				self.loader = null
			} else {
				alert('Please check required fields!')
				self.loader = null
			}
		},
		showErrorMessage(message) {
			alert(message)
		}
	}
}
</script>