<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Items</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field clearable dense 
                label="Search by Item Name or Material Code" 
                class="mt-3" v-model="filters.search"
                append-icon="search"
                single-line
            ></v-text-field>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>
                <v-menu offset-y>
                    <template v-slot:activator="{on: menu, attrs}">
                        <v-tooltip left>
                            <template v-slot:activator="{on: tooltip}">
                                <v-btn icon v-bind="attrs" v-on="{...tooltip, ...menu}"><v-icon>toggle_on</v-icon></v-btn>
                            </template>
                            <span>Enable/Disable Storefront Items</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="enableStorefrontItems(true)">Enable</v-list-item>
                        <v-list-item @click="enableStorefrontItems(false)">Disable</v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{on: menu, attrs}">
                        <v-tooltip left>
                            <template v-slot:activator="{on: tooltip}">
                                <v-btn icon v-bind="attrs" v-on="{...tooltip, ...menu}"><v-icon>new_releases</v-icon></v-btn>
                            </template>
                            <span>Enable Featured Items</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="enableFeaturedItems(true)">Enable</v-list-item>
                        <v-list-item @click="enableFeaturedItems(false)">Disable</v-list-item>
                    </v-list>
                </v-menu>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="setProductGroups" v-on="on"><v-icon>table_chart</v-icon></v-btn>
                    </template>
                    <span>Set Product Groups</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon to="/item/model/top_selling"  v-on="on"><v-icon>mdi-star-circle-outline</v-icon></v-btn>
                    </template>
                    <span>Set Top Selling Model</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-dialog v-model="dialog" persistent width="500" height="500" >
            <v-card>
                <v-card-title>Select Item Group</v-card-title>
                <v-container>
                    <ProductGroup v-model="selectedGroup" is-object-value></ProductGroup>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveItemGroup">Save</v-btn>
                    <v-btn @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table 
            dense 
            show-select
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            @update:options="loadNextPage"
            :footer-props="footerProps"
            v-model="selected"
        >
            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-icon>filter_list</v-icon>
                        <Product class="mt-3" :exclude="['Motorcycles']" v-model="filters.department"></Product>
                        <v-icon>filter_list</v-icon>
                        <ProductGroup class="mt-3" v-model="filters.itemGroup"></ProductGroup>
                        <v-icon>filter_list</v-icon>
                        <v-checkbox label="Enabled" class="mt-3" v-model="filters.enable" true-value="1" false-value="0"></v-checkbox>
                    </v-toolbar-items>
                </v-toolbar>
            </template>

            <template v-slot:item.enable="{ item }">
                <v-simple-checkbox :value="item.enable" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.active="{ item }">
                <v-simple-checkbox :value="item.active" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.featured="{ item }">
                <v-simple-checkbox :value="item.featured" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-icon small @click="viewItem(item)" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>Edit Item</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import ProductGroup from '@/components/Select/ProductGroup'
import Product from '@/components/Select/Product'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        ProductGroup,
        Product,
        Snackbar
    },
    data() {
        return {
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            dialog: false,
            showFilter: false,
            selected: [],
            selectedGroup: null,
            filters: {
                itemGroup: null,
                department: null,
                search: null,
                enable: null
            },
            loading: true,
            footerProps: {
                itemsPerPageOptions: [10, 25, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 10
            },
            serverItemTotal: 0,
            records: [],
            headers: [
                { text: 'Product', value: 'departmentName', sortable: false },
                { text: 'Parts Group', value: 'itemGroupName', sortable: false },
                { text: 'Category', value: 'category', sortable: false },
                { text: 'Name', value: 'nsSalesDescription', sortable: false },
                { text: 'Brand', value: 'brand', sortable: false},
                { text: 'Material No', value: 'sku', sortable: false },
                { text: 'SRP', value: 'srp', sortable: false },
                { text: 'Enabled', value: 'enable', align: 'center', sortable: false },
                { text: 'Featured Item', value: 'featured', align: 'center', sortable: false },
                { text: 'Active', value: 'active', align: 'center', sortable: false },
                { text: 'Actions', value: 'action', align: 'center', sortable: false }
            ]
        }
    },

    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
        selectedIds() {
            return this.selected.map(rec => rec.id )
        }
    },

    watch: {
        filters: {
            handler: 'loadItems',
            deep: true
        }
    },

    mounted() {
        this.loadItems();
    },

    methods: {
        loadItems() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            self.$axios.get(`/parts`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.count
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)

            })
        },
        viewItem(item) {
            let self = this
            const itemId = item.id

            self.beforeRouteDialog = true

            setTimeout(() => {
                self.$router.push(`/item/view/${itemId}`)
            }, 1000)
        },
        enableStorefrontItems(enable) {
            const self = this
            if(self.selectedIds.length < 1) {
                this.showSnackbarMessage("Please select item(s)", false)
                return
            }

            self.$axios.put(`/parts/storefront/enable`, {
                enable,
                itemIds: self.selectedIds
            })
            .then(response => {
                let responseData = response.data
                
                self.showSnackbarMessage(responseData.message, responseData.success)
                self.loadItems()
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        enableFeaturedItems(featured) {
            const self = this
            if(self.selectedIds.length < 1) {
                this.showSnackbarMessage("Please select item(s)", false)
                return
            }

            self.$axios.put(`/parts/featured/enable`, {
                featured,
                itemIds: self.selectedIds
            })
            .then(response => {
                let responseData = response.data
                
                self.showSnackbarMessage(responseData.message, responseData.success)
                self.loadItems()
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        setProductGroups() {
            const self = this
            if(self.selectedIds.length < 1) {
                self.showSnackbarMessage("Please select item(s)", false)
                return
            }

            this.dialog = true
        },
        saveItemGroup() {
            const self = this
            const data = this.selectedGroup
            let notApplicable = (data === null)

            self.$axios.put(`/parts/grouping/update`, {
                values: {
                    department: notApplicable ? data : data.department.id,
                    itemGroup: notApplicable ? data : data.id
                },
                itemIds: this.selectedIds
            })
            .then(response => {
                let responseData = response.data

                self.showSnackbarMessage(responseData.message, responseData.success)

                self.loadItems()
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        loadNextPage() {
            this.selected = []
            this.loadItems()
        }
    }
}
</script>