<template>
    <v-card flat>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-card-title class="pl-0">Packaging Type Setup</v-card-title>
        <v-divider></v-divider>
        
        <v-form ref="thirdPartyForm" class="px-8">
        <v-layout>
            <v-container>
                <v-flex xs12>
                    <v-card-text class="pl-0  pb-0 subtitle-2">Type</v-card-text>
                    <v-select
                        v-model="type"
                        outlined dense hide-details
                        :rules="[rules.required]"
                        :items="types"
                    >
                    </v-select>
                </v-flex>

                <v-flex xs12>
                    <v-card-text class="pl-0  pb-0 subtitle-2">Packaging</v-card-text>
                    <v-text-field
                        v-model="packaging"
                        outlined dense hide-details
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-flex>

                <v-flex xs12>
                    <v-card-text class="pl-0 pb-0 subtitle-2">Display Name</v-card-text>
                    <v-text-field
                        v-model="displayName"
                        outlined dense hide-details
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs6>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Length (cm)</v-card-text>
                            <v-text-field
                                v-model="dimension.length"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>

                            <v-card-text class="pl-0 pb-0 subtitle-2">Width (cm)</v-card-text>
                            <v-text-field
                                v-model="dimension.width"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Height (cm)</v-card-text>
                            <v-text-field
                                v-model="dimension.height"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                            
                            <v-card-text class="pl-0 pb-0 subtitle-2">Volume (cm)</v-card-text>
                            <v-text-field
                                v-model="dimension.volume"
                                outlined dense hide-details
                                type="number"
                                readonly
                            >
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container>
                <v-flex xs12>
                    <v-card-text class="pl-0 pb-0 subtitle-2">Provider</v-card-text>
                   <v-select
                        v-model="provider"
                        outlined dense hide-details
                        :rules="[rules.required]"
                        :items="providers"
                        item-value="nsVendorId"
                        item-text="courier"
                    >
                   </v-select>
                </v-flex>

                <v-flex xs12>
                    <v-card-text class="pl-0 pb-0 subtitle-2">Remarks</v-card-text>
                    <v-text-field
                        v-model="remarks"
                        outlined dense hide-details
                    >
                    </v-text-field>
                </v-flex>

                <v-layout>
                    <v-flex xs3 v-if="action==='update'">
                        <v-checkbox
                            label="Active"
                            v-model="active"
                        >
                        </v-checkbox>
                    </v-flex>

                    <v-flex xs3>
                        <v-checkbox
                            label="Promo"
                            v-model="promo"
                        >
                        </v-checkbox>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="action === 'update'" @click="updateSetup">Update</v-btn>
            <v-btn color="primary" v-if="action === 'add'" @click="saveSetup">Save</v-btn>
            <v-btn color="warning"  @click="resetForm(),$router.push('/setups/packaging')">Cancel</v-btn>
        </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import {required, minMaxValue} from '@/plugins/validation'
import Snackbar from '@/components/Snackbar'
export default {
    components: {
        Snackbar
    },
    data() {
        return {
            types:["Pouch", "Box"],
            type: "",
            lists:["Small Pouch", "Medium Pouch", "Large Pouch","Extra Large Pouch","Small Box", "Medium Box", "Large Box","Extra Large Box"],
            packagings: [],
            packaging: "",
            displayName: "",
            dimension: {height:"",width: "", length:"", volume:""},
            providers: [],
            provider: "",
            remarks: "",
            record: {
                code: null,
                amount: 0,
                active: true
            },
            records: [],
            rules: {
                required,
                minMaxValue
            },
            id: null,
            action: "",
            snackbar: {
				responseMessage: "",
				show: false,
				type: "success"
			},
            active: 0,
            promo: 0,
        }
    },
    watch: {
        // type(val) {
        //     this.packagings = []
        //     this.lists.forEach((res)=>{
        //         if (res.includes(val)) {
        //             this.packagings.push(res)
        //         }
        //     })
        // },
        dimension: {
            handler: function(val) {
                if (this.dimension.length && this.dimension.height && this.dimension.width) {
                    this.dimension.volume = Math.ceil(Number(this.dimension.length) * Number(this.dimension.height) * Number(this.dimension.width))
                } else {
                    this.dimension.volume = ""
                }
            },
            deep: true
        },
    },
    async mounted() {
        await this.fetchProviders()
        await this.fetchLoad()
		this.id = this.$route.params.id
		if (this.id !== "new"){
			// let result = this.records.find(o => o.id === Number(this.id));
            this.loadSetup()
		} else {
            this.action = "add"
        }
    },
    methods: {
        async fetchLoad() {
            await this.$axios.get(`/setup/packaging-type/fetch`).then((result) => {
                this.records = result.data.data
            })
        },
        async fetchProviders() {
            let self = this
            self.providers = []
            let lists = []
            await self.$axios.get(`/setup/third-party-deliveries/fetch`).then((result) => {
                lists = result.data.data
            }).then(()=>{
                lists.forEach(function(res) {
                    if(res.active === 1) {
                        self.providers.push(res)
                    }
                })
                self.providers.push({courier: "Transcycle", nsVendorId: "000000",})      
            })
        },
        async loadSetup() {
            const self = this
            await this.$axios.get(`/setup/packaging-type/${this.id}`).then((result) => {
                self.records = result.data.data
            }).then(()=> {
                self.action = "update"
                self.type = self.records.type
                self.packaging = self.records.packaging
                self.displayName = self.records.name
                self.dimension.length = self.records.dimension.length
                self.dimension.width = self.records.dimension.width
                self.dimension.height = self.records.dimension.height
                self.dimension.volume = self.records.dimension.volume
                self.provider = self.records.provider.nsVendorId
                self.remarks = self.records.remarks
                self.active = self.records.active
                self.promo = self.records.tag
            }).catch((error) => {
                console.log(error)
                self.showSnackbarMessage(error.message, false)
            })
        },
        saveSetup() {
			const isValid = this.$refs.thirdPartyForm.validate()
			let self = this
            let load = []
            let selectedProvider= self.providers.find(element => element.nsVendorId == self.provider);

            self.records.forEach((res)=> {
                if (res.courier === self.courier) {
                    load.push(res)
                }
            })

            let body = {
                type: self.type,
                packaging: self.packaging,
                name: self.displayName,
                provider: {name: selectedProvider.courier, nsVendorId: selectedProvider.nsVendorId},
                dimension: self.dimension,
                remarks: self.remarks,
                tag: Number(self.promo)
            }

            if(!isValid) return
            self.$axios.post(`/setup/packaging-type/create`, body).then(response => {
                if (response.data.message === "Validation error") {
                    self.showSnackbarMessage("This Packaging is already exist", false)
                } else {
                    self.resetForm()
                    self.$router.push("/setups/packaging")
                }
            })
        },
        updateSetup() {
            let self = this
            let selectedProvider= self.providers.find(element => element.nsVendorId == self.provider);
			let body = {
                type: self.type,
                packaging: self.packaging,
                name: self.displayName,
                dimension: self.dimension,
                provider: {name: selectedProvider.courier, nsVendorId: selectedProvider.nsVendorId},
                remarks: self.remarks,
                active: self.active,
                tag: self.promo
            }
            const isValid = this.$refs.thirdPartyForm.validate()

            if(!isValid) return

            this.$axios.put(`/setup/packaging-type/update/${this.id}`, body).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
				this.$router.push("/setups/packaging")
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.thirdPartyForm.reset()
            this.$refs.thirdPartyForm.resetValidation()
            this.$emit('close-form')
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    }
}
</script>