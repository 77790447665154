<template>
    <v-card flat>
        <v-card-title>Create User</v-card-title>
        <v-divider></v-divider>
            
        <v-form ref="createUserForm" class="px-8">
            <v-row>
                <v-text-field 
                    persistent-hint
                    hint="Only SyCha's GSuite account is available."
                    label="Email"
                    prepend-inner-icon="alternate_email"
                    :rules="[rules.required]"
                    v-model="record.email"
                >

                </v-text-field>
            </v-row>
            <v-row>
                <v-text-field 
                    persistent-hint
                    hint="Enter 11-digit number. Ex: 09091234567"
                    label="Contact Number"
                    prepend-inner-icon="call"
                    :rules="[rules.required, rules.numeric, rules.minMaxLength(1,11)]"
                    v-model="record.contactNumber"
                >

                </v-text-field>
            </v-row>
            <v-row>
                <v-select
                    prepend-inner-icon="wc"
                    label="Gender" :items="['Male', 'Female']"
                    :rules="[rules.required]"
                    v-model="record.gender"
                >
                </v-select>
            </v-row>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveUser">&nbsp; Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'
import { required, validUrl, numeric, minMaxLength } from '@/plugins/validation'

export default {
    data() {
        return {
            record: {
                email: null,
                contactNumber: null,
                gender: null
            },
            rules: { required, numeric, minMaxLength }
        }
    },
    methods: {
        saveUser() {
            let self = this
            const isValid = this.$refs.createUserForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/user`, this.record)
            .then(response => {
                let responseData = response.data
                self.$emit('create-user-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('create-user-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.createUserForm.reset()
            this.$refs.createUserForm.resetValidation()
            this.$emit('cancel-create-user')
        }
    }
}
</script>