<template>
    <v-app>
        <v-content>
            <v-container class="orange lighten-5" fluid fill-height>
                <v-layout align-center justify-center style="text-align: center;">
                    <v-flex xs12 sm8 md4>
                        <v-icon size="200" color="warning">warning</v-icon>
                        <h2>{{errorMsg}}</h2>
                        <v-btn outlined @click="returnToLoginPage">Back to Login Page</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
export default {
    props: ['errorCode', 'errorMsg'],
    data(){
        return {
            alignment: "center",
            justify: "center"
        }
    },
    methods: {
        returnToLoginPage(){
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>