<template>
    <v-container>
        <v-layout class="mt-5">
            <v-flex class="pt-3 mr-4" xs3>
                <input @change="$emit('radio-select', radio)" v-model="radio" type="radio" id="external" name="method" value="Third-party Courier Service">
                <label class="ml-3 body-2 text--secondary" for="external">Third-party Courier Service</label>
            </v-flex>
            <v-flex xs5> 
                <v-select
                    class="pt-3 ml-5" 
                    v-if="radio == 'Third-party Courier Service'"
                    v-model="courierHolder" 
                    :items="couriers"
                    item-text="alias"
                    item-value="companyName"
                    return-object 
                    dense 
                    outlined
                ></v-select>
            </v-flex>
            <v-flex class="ml-5 pt-3" xs2>
                <v-btn v-if="radio == 'Third-party Courier Service'" tile depressed color="primary" @click="addCourier">ADD COURIER SETUP</v-btn>
            </v-flex>
        </v-layout>

        <v-layout v-if="radio == 'Third-party Courier Service'">
            <v-flex xs12>
                <v-card
                    v-for="(courier, index) in parentData.setupMatrix.externalProvider.selectedCouriers"
                    :key="index" 
                    flat 
                    tile
                >
                    <v-card-title class="subtitle-2">
                        {{ courier.alias }}
                        <v-spacer></v-spacer>
                        <v-icon @click="updateCourier(index)">edit</v-icon>
                        <v-icon @click="deleteCourier(courier)">delete</v-icon>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout v-if="radio == 'Third-party Courier Service' && courierDetails">
            <v-flex x6>
                <v-card flat>
                    <v-card-title class="body-2">
                        {{ courierDetails.alias }}
                        <v-spacer></v-spacer>
                        <v-icon v-if="courierDetails.companyName" color="red" @click="removeCourier">mdi-minus-circle-outline</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <div v-if="courierDetails.companyName">
                            <v-layout>
                                <v-flex xs12>
                                    <v-layout class="ml-5">
                                        <v-flex xs3>
                                            <v-checkbox v-model="courierDetails.isNormalRates" label="Normal Rates"></v-checkbox>
                                        </v-flex>

                                        <v-flex xs1></v-flex>

                                        <v-flex class="pt-5" xs2>
                                            <p v-if="courierDetails.isNormalRates" class="text--secondary">Dates Covered</p>
                                        </v-flex>

                                        <v-flex xs2>
                                            <v-text-field
                                                v-if="courierDetails.isNormalRates"
                                                v-model="courierDetails.normalRatesEffectivityDate"
                                                label="Effectivity Date"
                                                prepend-icon="mdi-calendar"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>

                                        <v-flex xs2>
                                            <v-text-field
                                                v-if="courierDetails.isNormalRates"
                                                v-model="courierDetails.normalRatesExpiryDate"
                                                label="Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                disabled
                                            ></v-text-field>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-layout class="mt-3" v-if="courierDetails.isNormalRates">
                                <v-flex class="ml-5" xs3>
                                    <p class="ml-5 mt-2 subtitle-1 text--secondary">Select Coverage Area</p>
                                </v-flex>
                                
                                <v-flex class="ml-4" xs3>
                                    <v-select @change="$emit('area-select', courierDetails.coverageArea)" v-model="courierDetails.coverageArea" :items="coverageAreas" dense outlined></v-select>
                                </v-flex>
                            </v-layout>

                            <v-layout v-if="courierDetails.isNormalRates">
                                <v-flex class="ml-5" xs3>
                                    <p class="ml-5 mt-2 subtitle-1 text--secondary">Point of Origin</p>
                                </v-flex>

                                <v-flex class="ml-4" xs2>
                                    <v-select
                                        v-model="originHolder" 
                                        :items="courierDetails.coverageArea === 'Island Group' ? islandGroupOrigins : clusterOrigin" 
                                        dense 
                                        outlined
                                    ></v-select>
                                </v-flex>

                                <v-flex class="ml-5" xs3>
                                    <v-btn tile depressed color="primary" @click="addOrigin" :disabled="!emptyOrigin">ADD POINT OF ORIGIN</v-btn>
                                </v-flex>
                            </v-layout>

                            <v-layout v-if="!emptyOrigin && courierDetails.isNormalRates">
                                <v-flex xs1></v-flex>
                                <v-flex xs10>
                                    <v-card flat>
                                        <v-card-title class="subtitle-2" style="background-color: #E0E0E0E0;">
                                            {{ courierDetails.selectedOrigin.area }}
                                            <v-spacer></v-spacer>
                                            <v-card-actions>
                                                <v-icon @click="removeOrigin" color="red">mdi-minus-circle-outline</v-icon>
                                            </v-card-actions>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-radio-group v-model="courierDetails.based">
                                                <v-radio
                                                    :dense="true"
                                                    class="caption"
                                                    v-for="base in basedOn"
                                                    :key="base"
                                                    :label="`Based on ${base}`"
                                                    :value="base"
                                                >
                                                </v-radio>
                                            </v-radio-group>
                                            <div v-if="courierDetails.based === 'kilogram'">
                                                <v-row>
                                                    <v-spacer></v-spacer>
                                                    <v-icon @click="openModal" class="mt-5">mdi-plus-circle</v-icon>
                                                </v-row>
                                                <v-simple-table dense class="mt-5 ml-5">
                                                    <caption v-if="courierDetails.coverageArea === 'Island Group'" class="caption text--secondary">Base Amount</caption>
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3" v-if="courierDetails.coverageArea === 'Island Group'" class="text--secondary">
                                                                Weight
                                                            </th>
                                                            <th colspan="3" v-else>Distance Covered</th>
                                                            <th colspan="26" class="text--secondary">
                                                                Provincial
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <th class="text--secondary">
                                                            Min.
                                                        </th>
                                                        <th class="text--secondary">
                                                            Max.
                                                        </th>
                                                        <th class="text--secondary">
                                                            unit
                                                        </th>
                                                        <th
                                                            class="text--secondary"
                                                            v-for="item in headerTableBuilderExternal" :key="item"
                                                        >
                                                            {{ item }}
                                                        </th>
                                                        <tr
                                                            v-for="(item, id) in referenceTableForExternal" 
                                                            :key="id"
                                                        >
                                                            <td>{{ item.metric.min}}</td>
                                                            <td>{{ item.metric.max}}</td>
                                                            <td>{{ item.metric.unit}}</td>
                                                            <td
                                                                v-for="(area, index) in item.values" :key="index"
                                                            >
                                                                <v-edit-dialog
                                                                    :return-value.sync="area.amount"
                                                                    @save="save(item)"
                                                                    > {{ area.amount }}
                                                                    <template v-slot:input>
                                                                        <v-text-field
                                                                            v-model="area.amount"
                                                                            label="Edit"
                                                                            single-line
                                                                        ></v-text-field>
                                                                    </template>
                                                                </v-edit-dialog>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </div>
                                            <div v-if="courierDetails.based === 'kilometer'">
                                                <v-row>
                                                    <v-spacer></v-spacer>
                                                    <v-icon @click="openModal" class="mt-5">mdi-plus-circle</v-icon>
                                                </v-row>
                                                <v-simple-table dense class="mt-5 ml-5">
                                                    <caption class="caption text--secondary">Base Amount</caption>
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3" class="text--secondary">
                                                                Distance Covered
                                                            </th>
                                                            <th colspan="26" class="text--secondary">
                                                                Provincial
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <th class="text--secondary">
                                                            Min.
                                                        </th>
                                                        <th class="text--secondary">
                                                            Max.
                                                        </th>
                                                        <th class="text--secondary">
                                                            unit
                                                        </th>
                                                        <th
                                                            class="text--secondary"
                                                            v-for="item in headerTableBuilderExternal" :key="item"
                                                        >
                                                            {{ item }}
                                                        </th>
                                                        <tr
                                                            v-for="(item, id) in referenceTableForExternal" 
                                                            :key="id"
                                                        >
                                                            <td>{{ item.metric.min}}</td>
                                                            <td>{{ item.metric.max}}</td>
                                                            <td>{{ item.metric.unit}}</td>
                                                            <td
                                                                v-for="(area, index) in item.values" :key="index"
                                                            >
                                                                <v-edit-dialog
                                                                    :return-value.sync="area.amount"
                                                                    @save="save(item)"
                                                                    > {{ area.amount }}
                                                                    <template v-slot:input>
                                                                        <v-text-field
                                                                            v-model="area.amount"
                                                                            label="Edit"
                                                                            single-line
                                                                        ></v-text-field>
                                                                    </template>
                                                                </v-edit-dialog>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </div>
                                            <v-layout v-if="courierDetails.selectedOrigin">
                                                <v-flex xs12>
                                                    <Summary
                                                        :courier.sync="courierDetails"
                                                    ></Summary>
                                                </v-flex>  
                                            </v-layout>
                                            <v-row v-if="courierDetails.selectedOrigin">
                                                <v-spacer></v-spacer>
                                                <v-btn @click="saveOrigin" class="white--text" color="info" tile depressed>Save</v-btn>
                                            </v-row>   
                                        </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex xs1></v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs12>
                                    <v-layout class="ml-5">
                                        <v-flex xs3>
                                            <v-checkbox v-if="courierDetails.companyName === 'PH GLOBAL JET EXPRESS, INC.'" v-model="courierDetails.isPromoRates" label="Promo Rates"></v-checkbox>
                                        </v-flex>

                                        <v-flex xs1></v-flex>

                                        <v-flex class="pt-5" xs2>
                                            <p v-if="courierDetails.isPromoRates" class="text--secondary">Dates Covered</p>
                                        </v-flex>

                                        <v-flex xs2>
                                            <v-text-field
                                                v-if="courierDetails.isPromoRates"
                                                v-model="courierDetails.promoRatesEffectivityDate"
                                                label="Effectivity Date"
                                                prepend-icon="mdi-calendar"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>

                                        <v-flex xs2>
                                            <v-text-field
                                                v-if="courierDetails.isPromoRates"
                                                v-model="courierDetails.promoRatesExpiryDate"
                                                label="Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                disabled
                                            ></v-text-field>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-layout v-if="courierDetails.isPromoRates">
                                <v-flex xs12>
                                    <table style="width:100%">
                                        <caption class="subtitle-2 text--secondary">Promo Rates for Pouches</caption>
                                        <thead>
                                            <tr>
                                                <th class="caption text--secondary">Origin</th>
                                                <th class="caption text--secondary">Packaging</th>
                                                <th
                                                    class="caption text--secondary"
                                                    v-for="header in islandGroupOrigins" :key="header"
                                                >
                                                    {{ header }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="caption text--secondary" v-for="(item, id) in promoRates" :key="id">
                                                <td>{{ item.origin }}</td>
                                                <td>{{ item.metric.toString() }}</td>
                                                <td
                                                    v-for="(amount, index) in item.values" :key="index"
                                                >{{ amount.values.toString() }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios'
import { api } from '@/configs/api'
import _ from 'lodash'
import Summary from '@/components/Setups/Summary'

export default {
    name: 'external-details',
    components: {
        Summary
    },
    props: {
        parentData: Object,
        radio: String,
        normalRatesEffectivityMenu: Boolean,
        normalRatesExpiryMenu: Boolean,
        promoRatesEffectivityMenu: Boolean,
        promoRatesExpiryMenu: Boolean,
        promoRates: Array,
        buildTable: Boolean
    },
    data() {
        return {
            editing: false,
            updating: false,
            snackbar: {
                message: '',
                type: 'success',
                alert: false
            },
            courierHolder: '',
            originHolder: '',
            origin: {
                area: '',
                based: '',
                matrix: {}
            },
            couriers: [],
            courierDetails: {},
            selectedCour: [],
            islands: [],
            clusters: [],
            coverageAreas: ['Island Group'],
            islandGroupOrigins: ['NCR', 'LUZON', 'VISAYAS', 'MINDANAO', 'ISLAND'],
            clusterOrigin: [
                'NCR/SOUTH', 
                'NCR/NORTH', 
                'NCR/RIZAL', 
                'BULACAN', 
                'CAVITE', 
                'MINDORO', 
                'PALAWAN', 
                'BATANGAS', 
                'LAGUNA', 
                'BICOL', 
                'BATAAN', 
                'PAMPANGA', 
                'TARLAC', 
                'NUEVA ECIJA', 
                'ISABELA/NUEVA VIZCAYA', 
                'PANGASINAN',
                'LAOAG',
                'PANAY ISLAND',
                'NEGROS',
                'CEBU',
                'SAMAR LEYTE',
                'SOUTHERN MIN',
                'CENTRAL MIN',
                'EASTERN MIN',
                'CARAGA',
                'NORTHEN MIN' 
            ],
            basedOn: ['kilogram', 'kilometer'],
            referenceTableForExternal: []
        }
    },
    computed: {
        emptyOrigin() {
            let empty = _.isEmpty(this.courierDetails.selectedOrigin);
            return empty;
        },
        headerTableBuilderExternal() {
            const self = this

            if(self.courierDetails.based === 'kilogram') {
                self.referenceTableForExternal = self.islands.filter(e => e.area == 'island' && e.metric.unit == 'kg')
                self.$emit('metric-change', 'kg')
                // to be passed to parent component
                // this.newMetric.metric.unit = 'kg'

                return self.islandGroupOrigins
            } else {
                self.referenceTableForExternal = self.islands.filter(e => e.type === 'Parts and Accessories' && (e.area == 'island' && e.metric.unit == 'km'))
                self.$emit('metric-change', 'km')
                // to be passed to parent component
                // this.newMetric.metric.unit = 'km'

                return self.islandGroupOrigins
            }
            // if(self.courierDetails.coverageAreas === 'Island Group') {
            //     if(self.courierDetails.based === 'kilogram') {
            //         self.referenceTableForExternal = self.islands.filter(e => e.area == 'island' && e.metric.unit == 'kg')
            //         // to be passed to parent component
            //         // this.newMetric.metric.unit = 'kg'

            //         return self.islandGroupOrigins
            //     } else {
            //         self.referenceTableForExternal = self.islands.filter(e => e.type === 'Parts and Accessories' && (e.area == 'island' && e.metric.unit == 'km'))
            //         // to be passed to parent component
            //         // this.newMetric.metric.unit = 'km'

            //         return self.islandGroupOrigins
            //     }
                
            // } else {
            //     if(self.courierDetails.based === 'kilogram') {
            //         self.referenceTableForExternal = self.clusters.filter(e => e.area == 'cluster' && e.metric.unit == 'kg')
            //         // to be passed to parent component
            //         // this.newMetric.metric.unit = 'kg'

            //         return self.clusterOrigin
            //     } else {
            //         self.referenceTableForExternal = self.clusters.filter(e => e.type === 'Parts and Accessories' && (e.area == 'cluster' && e.metric.unit == 'km'))
            //         // to be passed to parent component
            //         // this.newMetric.metric.unit = 'km'

            //         return self.clusterOrigin
            //     }
                
            // }
        }, 
    },
    watch: {
        'courierDetails.based': function() {
            this.headerTableBuilderExternal
        },
        buildTable() {
            this.externalTableBuilder()
        }
    },
    async mounted() {
        await this.fetchCouriers()
        await this.fetchCoverageGroups()

        if(this.buildTable) this.externalTableBuilder()
    },
    methods: {
        async fetchCouriers() {
            const self = this

            try {
                let response = await axios.get(`${api}/setups/couriers`)
                self.couriers = response.data.data
                self.couriers.forEach(courier => {
                    courier.coverageArea = 'Island Group'
                })
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchCoverageGroups() {
            let self = this
            
            try {
                let response = await axios.get(`${api}/setup/base/amount`)
                self.islands = response.data.islands
                self.clusters = response.data.clusters
                console.log(response)
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        addOrigin() {
            let isEmpty = this.originHolder === ''

            if(!isEmpty) {
                this.origin = {
                    area: this.originHolder,
                    ninjaVan: {
                        routes: [],
                    },
                    jnt: {
                        routes: [],
                    }
                }
                this.courierDetails.selectedOrigin = this.origin
                this.courierDetails = Object.assign({}, this.courierDetails)
            } else {
                alert('Please select origin')
            }
        },
        removeOrigin() {
            if(!this.updating) {
                this.courierDetails.selectedOrigin = {}
                this.courierDetails = Object.assign({}, this.courierDetails)

                console.log(this.courierDetails)
            } else {
                this.courierDetails.selectedOrigin = {}
                this.courierDetails.based = ''
                this.courierDetails = Object.assign({}, this.courierDetails)

                console.log(this.courierDetails)
            }
        },
        addCourier() {
            let isEmpty = this.courierHolder == ''

            if(isEmpty) alert('Please select courier')
            else this.courierDetails = this.courierHolder
        },
        updateCourier(index) {
            this.updating = true
            this.courierDetails = this.parentData.setupMatrix.externalProvider.selectedCouriers[index]
        },
        deleteCourier(courier){
            var index = this.parentData.setupMatrix.externalProvider.selectedCouriers.indexOf(courier)
            this.parentData.setupMatrix.externalProvider.selectedCouriers.splice(index, 1)
            this.$emit('origin-changes', 'delete-courier')
        },
        removeCourier() {
            this.courierDetails = {}
        },
        openModal() {
            this.$emit('openMetricModal');
        },
        saveOrigin() {
            const self = this

            if(this.updating == true) {
                self.courierDetails = {}
            } else {
                self.parentData.setupMatrix.externalProvider.selectedCouriers.push(self.courierDetails)
                self.courierDetails = {}
                self.courierHolder = ''
                self.originHolder = ''
                self.$emit('origin-changes', 'save-origin')
            }
        },
        externalTableBuilder() {
            if(this.courierDetails.coverageArea == 'Island Group') {
                if(this.courierDetails.based === 'kilogram') {
                    this.referenceTableForExternal = this.islands.filter(e => e.area == 'island' && e.metric.unit == 'kg')
                } else {
                    this.referenceTableForExternal = this.islands.filter(e => e.type === 'Parts and Accessories' && (e.area == 'island' && e.metric.unit == 'km'))
                    // this.newMetric.metric.unit = 'km'
                }
            } else {
                if(this.courierDetails.based === 'kilogram') {
                    this.referenceTableForExternal = this.clusters.filter(e => e.area == 'cluster' && e.metric.unit == 'kg')
                } else {
                    this.referenceTableForExternal = this.clusters.filter(e => e.type === 'Parts and Accessories' && (e.area == 'cluster' && e.metric.unit == 'km'))
                    // this.newMetric.metric.unit = 'km'
                }
            }
        },
        save(item) {
            this.$emit('update-matrices', item)
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>