<template>
	<div>
		<div class="container-fluid mt--7">
            <div class="row" v-show="variantOptions.length">
				<div class="col-md-12">
					<v-card header-tag="header" footer-tag="footer">
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-row class="ml-2">
								<v-col cols="12" sm="6" md="3">
									<label for="ccnumber">Variant Attribute</label>
									<v-select v-model="newProductVariant.variantId" :items="variantOptions" size="sm" class="mt-3" outlined :rules="[rules.required]"></v-select>
								</v-col>
							</v-row>
							<v-row class="ml-2">
								<v-col cols="12" sm="6" md="3">
									<label for="name">Variant Value</label>
									<v-text-field type="text" v-model="newProductVariant.variantValue" required placeholder="" outlined :rules="[rules.required]"></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2">
								<v-col cols="12" sm="6" md="3">
									<label for="name">Variant Display Name</label>
									<v-text-field type="text" v-model="newProductVariant.variantName" :rules="[rules.required]" placeholder="Product display name" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2">
								<v-col cols="12" sm="6" md="3">
									<label for="name">SKU</label>
									<v-text-field type="text" v-model="newProductVariant.sku" :rules="[rules.required]" placeholder="Product SKU" @change="checkSku" outlined></v-text-field>
									<small v-show="this.skuQuery === 1"><font color="red">Existing SKU was found!</font></small>
									<small v-show="this.skuQuery === 0 && this.newProductVariant.sku !== '' "><font color="blue">No existing SKU was found.</font></small>
								</v-col>
							</v-row>
							<v-row class="ml-2">
								<v-col cols="12" sm="6" md="3">
									<label for="name">Price</label>
									<v-text-field type="text" v-model="newProductVariant.price" :rules="[rules.required]" placeholder="Price" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2">
								<v-col cols="12" sm="10" md="8">
									<label for="name">Photo</label>
									<v-text-field type="url" v-model="newProductVariant.photo" :rules="[rules.required]" placeholder="Photo" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2 pb-2">
								<v-col sm="12">
									<v-btn class="ma-2" outlined color="blue" variant="outline-success" @click="submit">
										Submit
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-card>
				</div>
			</div>
			<div>
				<Snackbar v-model="alert" type="success" message="New variant was successfully added." />
			</div>
		</div>
	</div>
</template>

<script>
import slug from 'slug'
import firebase from 'firebase'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
	data () {
		return {
            product: {
                name: ''
            },
			newProductVariant: {
				slug: null,
				variantName: null,
				variantId: null,
				variantValue: '',
				sku: '',
                price: '',
                photo: null,
                materialCode: '',
			},
			variants: [],
			variantOptions: [],
			skuQuery: 0,
			showAlert: false,
			rules: {
				required: value => !!value || 'This field is required',
			},
			valid: true,
		}
	},
	async mounted() {
        let self = this
        
		let getVariants = firebase.functions().httpsCallable('getVariants')
		let result = await getVariants()
        self.variants = result.data
		self.getProduct(self.$route.params.slug)
	},
	methods: {
        async getProduct(slug) {
			let self = this
            let getProduct = firebase.functions().httpsCallable('getProduct')
            let result = await getProduct({slug: slug})
			self.product = result.data
		},

		async checkSku() {
			let self = this
			let checkSku = firebase.functions().httpsCallable('checkSku')
			let result = await checkSku({newSku: self.newProductVariant.sku})
			self.skuQuery = result.data
		},

		async submit() {
			let self = this

			if(self.$refs.form.validate()) {
				self.newProductVariant.slug = slug(self.newProductVariant.variantName.toLowerCase())
				self.newProductVariant.price = parseFloat(self.newProductVariant.price)
				self.newProductVariant.productId = self.product.id

				let newProductVariant = firebase.functions().httpsCallable('newProductVariant')
				let result = await newProductVariant(self.newProductVariant)

				self.showAlert = true
				self.$router.push(`/product/view/${self.product.slug}`)
			}
			
		}
	},

	// computed: {
	// 	state() {
	// 		return this.skuQuery === 0 ? true : false
	// 	},
	// 	state1() {
	// 		return this.matCodeQuery === 0 ? true : false
	// 	},
	// 	invalidSku() {
	// 		if(this.skuQuery === 1) {
	// 			return 'Existing SKU was found!'
	// 		} else {
	// 			return 'Please enter SKU'
	// 		}
	// 	},
	// 	validSku() {
	// 		return this.state === true ? 'No existing SKU found' : ''
	// 	},
	// 	invalidMatCode() {
	// 		if(this.matCodeQuery === 1) {
	// 			return 'Existing SKU was found!'
	// 		} else {
	// 			return 'Please enter SKU'
				
	// 		}
	// 	},
	// 	validMatCode() {
	// 		return this.state1 === true ? 'No existing SKU found' : ''
	// 	},

	// },
	watch: {
		variants(newValue) {
			let self = this
			let list = []
			newValue.forEach(variant => {
				list.push({
					text: variant.attribute,
					value: variant.id
				})

				self.variantOptions = list
			})
		},
		'newProductVariant.variantValue': {
            handler: function (after, before) {
                this.newProductVariant.variantName = `${this.product.name} - ${after}`
            },
            deep: true
        }
	}
}
</script>
