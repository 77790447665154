<template>
    <div>
        <v-select 
            :items="items"
            :return-object="isObjectValue"
            v-model="_value"
            label="Product Group"
            item-value="id"
            dense
            @change="changeValue"
        >
            <template v-slot:item="{item}">
                <span v-if="item.id === null">
                    --- Not applicable ---
                </span>
                <span v-else>
                    {{ item.department.name }} - {{ item.name }}
                </span>
            </template>

            <template v-slot:selection="{item}">
                <span v-if="item.id === null">
                    --- Not applicable ---
                </span>
                <span v-else>
                    {{ item.department.name }} - {{ item.name }}
                </span>
            </template>
        </v-select>
    </div>
</template>

<script>
import axios from 'axios'
import { api } from '../../configs/api'

export default {
    name: 'select-item-group',
    props: {
        isObjectValue: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: Number,
            default: null
        }
    },
    data() {
        return {

            items: []
        }
    },
    computed: {
        _value: {
            get: function () {
                return this.defaultValue
            },
            set: function (newVal) {
                this.changeValue(newVal)
            }
        }
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems(){
            let self = this

            axios.get(`${api}/item-groups`)
            .then(response => {
                let responseData = response.data

                if(!responseData.success) alert(responseData.message)

                self.items = [ { id: null, department: { id: null } }, ...responseData.data]
            }).catch((error) => {
                alert(error.message)
            })
        },
        changeValue(value){
            this.$emit('input', value)
        }
    }
}
</script>