<template>
    <v-card flat tile>
        <v-card>
            <div class="scrollmenu">
                <iframe width="1300" height="600" :src="report_url" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
        </v-card>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            report_url: process.env.VUE_APP_ORDER_REPORT
        }
    }
}
</script>

<style scoped>
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}
</style>