<template>
    <v-container>
        <v-layout>
            <v-flex class="pt-5" xs2>
                <input @change="$emit('radio-select', radio)" v-model="radio" type="radio" id="internal" name="method" value="Internal Delivery">
                <label for="internal" class="ml-3 body-2 text--secondary">Internal Delivery</label>
            </v-flex>

            <v-flex v-if="radio == 'Internal Delivery'" xs2> 
                <v-text-field class="pt-3 ml-5" v-model="parentData.setupMatrix.internalDelivery.internalProvider" outlined dense></v-text-field>
            </v-flex>

            <v-flex xs2>
                <p v-if="radio == 'Internal Delivery'" class="mt-5 ml-5 text--secondary">Dates Covered</p>
            </v-flex>

            <v-flex xs2>
                <v-text-field
                    v-if="radio == 'Internal Delivery'"
                    v-model="parentData.setupMatrix.internalDelivery.InternalDeliveryEffectivityDate"
                    label="Effectivity Date"
                    prepend-icon="mdi-calendar"
                    disabled
                ></v-text-field>
                <!-- <v-menu
                    v-if="parentData.matrixRadio == 'Internal Delivery'"
                    v-model="effectiveMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="parentData.internalDelivery.InternalDeliveryEffectivityDate"
                        label="Effectivity Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="parentData.internalDelivery.InternalDeliveryEffectivityDate"
                    @input="effectiveMenu = false"
                    ></v-date-picker>
                </v-menu> -->
            </v-flex>

            <v-flex xs2>
                <v-text-field
                    v-if="radio == 'Internal Delivery'"
                    v-model="parentData.setupMatrix.internalDelivery.InternalDeliveryExpiryDate"
                    label="Expiry Date"
                    prepend-icon="mdi-calendar"
                    disabled
                ></v-text-field>
                <!-- <v-menu
                    v-if="parentData.matrixRadio == 'Internal Delivery'"
                    v-model="expiryMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="parentData.internalDelivery.InternalDeliveryExpiryDate"
                        label="Expiry Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="parentData.internalDelivery.InternalDeliveryExpiryDate"
                    @input="expiryMenu = false"
                    ></v-date-picker>
                </v-menu> -->
            </v-flex>
        </v-layout>

        <v-layout class="mt-3" v-if="radio == 'Internal Delivery'">
            <v-flex class="ml-5" xs3>
                <p class="ml-5 mt-2 subtitle-1 text--secondary">Select Coverage Area</p>
            </v-flex>
            
            <v-flex class="ml-4" xs2>
                <v-select v-model="parentData.setupMatrix.internalDelivery.coverageArea" :items="coverageAreas" dense outlined></v-select>
            </v-flex>
        </v-layout>

        <v-layout v-if="radio == 'Internal Delivery'">
            <v-flex class="ml-5" xs3>
                <p class="ml-5 mt-2 subtitle-1 text--secondary">Point of Origin</p>
            </v-flex>

            <v-flex class="ml-4" xs2>
                <v-select 
                    v-model="originHolder" 
                    :items="itemsBuilder" 
                    dense 
                    outlined
                ></v-select>
            </v-flex>

            <v-flex class="ml-5" xs3>
                <v-btn tile depressed color="primary" @click="addOrigin" :disabled="parentData.setupMatrix.internalDelivery.selectedOrigin === originHolder">ADD POINT OF ORIGIN</v-btn>
            </v-flex>
        </v-layout>

        <div v-if="parentData.setupMatrix.internalDelivery.selectedOrigin.length && radio == 'Internal Delivery'">
            <v-layout >
                <v-flex xs12>
                    <v-card flat>
                        <v-card-title class="subtitle-2" style="background-color: #E0E0E0E0;">
                            {{ parentData.setupMatrix.internalDelivery.selectedOrigin }}
                            <v-spacer></v-spacer>
                            <v-card-actions>
                                <v-icon color="red" @click="removeOrigin">mdi-minus-circle-outline</v-icon>
                            </v-card-actions>
                        </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs11></v-flex>
                <v-flex xs1>
                    <v-icon @click="openModal" class="mt-5" v-if="radio == 'Internal Delivery'">mdi-plus-circle</v-icon>
                </v-flex>  
            </v-layout>
            <v-layout> 
                <v-flex xs12>
                    <v-simple-table dense class="mt-5 ml-5">
                        <caption v-if="parentData.setupMatrix.externalProvider.coverageArea === 'Island Group'" class="caption text--secondary">Base Amount</caption>
                        <thead>
                            <tr>
                                <th colspan="3" class="text--secondary">
                                    Distance Covered
                                </th>
                                <th colspan="26" class="text--secondary">
                                    Provincial
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <th class="text--secondary">
                                Min.
                            </th>
                            <th class="text--secondary">
                                Max.
                            </th>
                            <th class="text--secondary">
                                unit
                            </th>
                            <th
                                class="text--secondary"
                                v-for="item in tableHeader" :key="item"
                            >
                                {{ item }}
                            </th>
                            <tr
                                v-for="(item, id) in tableReference" 
                                :key="id"
                            >
                                <td>{{ item.metric.min}}</td>
                                <td>{{ item.metric.max}}</td>
                                <td>{{ item.metric.unit}}</td>
                                <td
                                    v-for="(area, index) in item.values" :key="index"
                                >
                                    <v-edit-dialog
                                        :return-value.sync="area.amount"
                                        @save="save(item)"
                                        > {{ area.amount }}
                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="area.amount"
                                                label="Edit"
                                                single-line
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-flex>
            </v-layout>
            <v-layout class="mt-5 ml-5">
                <v-flex xs3>
                    <p class="mt-3 subtitle-2 text--secondary">Additional amount per km</p>
                </v-flex>
                <v-flex xs2>
                    <v-text-field type="number" v-model="parentData.setupMatrix.internalDelivery.baseAmountChart.additionalAmountperKm" outlined dense></v-text-field>
                </v-flex>    
            </v-layout>

            <v-layout class="mt-5 ml-5">
                <v-flex xs3>
                    <p class="mt-3 subtitle-2 text--secondary">Maximum ceiling amount</p>
                </v-flex>
                <v-flex xs2>
                    <v-text-field type="number" v-model="parentData.setupMatrix.internalDelivery.baseAmountChart.maximumCeilingAmount" outlined dense></v-text-field>
                </v-flex>    
            </v-layout>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'internal-details',
    props: {
        parentData: Object,
        radio: String,
        effectiveMenu: Boolean,
        expiryMenu: Boolean,
        tableHeader: Array,
        tableReference: Array
    },
    data() {
        return {
            snack: false,
            snackColor: '',
            snackText: '',
            originHolder: 'NCR',
            coverageAreas: ['Island Group'],
            islandGroupOrigins: ['NCR', 'LUZON', 'VISAYAS', 'MINDANAO', 'ISLAND'],
            clusterOrigin: [
                'NCR/SOUTH', 
                'NCR/NORTH', 
                'NCR/RIZAL', 
                'BULACAN', 
                'CAVITE', 
                'MINDORO', 
                'PALAWAN', 
                'BATANGAS', 
                'LAGUNA', 
                'BICOL', 
                'BATAAN', 
                'PAMPANGA', 
                'TARLAC', 
                'NUEVA ECIJA', 
                'ISABELA/NUEVA VIZCAYA', 
                'PANGASINAN',
                'LAOAG',
                'PANAY ISLAND',
                'NEGROS',
                'CEBU',
                'SAMAR LEYTE',
                'SOUTHERN MIN',
                'CENTRAL MIN',
                'EASTERN MIN',
                'CARAGA',
                'NORTHEN MIN' 
            ],
        }
    },
    computed: {
        itemsBuilder() {
            return this.parentData.setupMatrix.internalDelivery.coverageArea == 'Island Group' ? this.islandGroupOrigins : this.clusterOrigin
        },
    },
    methods: {
        addOrigin() {
            let isEmpty = this.originHolder == ''
            
            if(!isEmpty) {
                this.parentData.setupMatrix.internalDelivery.selectedOrigin = this.originHolder
            } else {
                alert('Please select origin')
            }
        },
        removeOrigin() {
            this.parentData.setupMatrix.internalDelivery.selectedOrigin = {}
            // let index = this.parentData.internalDelivery.selectedOrigin.indexOf(item);
            // if(index > -1) this.parentData.internalDelivery.selectedOrigin.splice(index, 1);
        },
        openModal() {
            this.$emit('openMetricModal');
        },
        save(item){
            
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Data saved'
            
            this.$emit('update-matrices', item)
        }
    }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>