<template>
	<div>
		<div class="container-fluid mt--7">
            <div class="row">
				<div class="col-md-12">
                    <v-card>
                        <v-card-title>{{discount.code}}</v-card-title>
                        <v-simple-table class="table">
                           <tr>
                                <th>Description:</th>
                                <td>{{discount.description}}</td>
                                <th>Type:</th>
                                <td>{{discount.type}}</td>
                                <th>Value:</th>
                                <td>{{discount.value | thousandSeparator}}</td>
                                <td colspan="2" class="text-right">
                                    <v-btn v-if="discount.status === 1" class="ma-2 mb-2" outlined color="blue" @click="deactivate">
                                        Deactivate
                                    </v-btn>
                                    <v-btn v-else class="ma-2 mb-2" outlined color="blue" @click="activate">
                                        Activate
                                    </v-btn>
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-card>

                    <v-row>
                        <v-col md="12">
                            <v-card>
                                <v-card-title>Orders</v-card-title>
                                <div ref="table"></div>
                            </v-card>
                        </v-col>
                    </v-row>
                    
                    <v-dialog v-model="dialog" hide-overlay persistent width="300">
                        <v-card color="primary" dark>
                            <v-card-text>
                                Loading data
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import firebase from 'firebase'
import Tabulator from 'tabulator-tables'
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
export default {
     data: () => ({
        dialog: false,
        discount: {},
        ready: false,
        orders: [],
        searchTerm: '',
        activeRow: {},
        activeCell: null,
        activeColumn: null,
        activeColumnTitle: null,
    }),
    mounted() {
        this.getDiscount(this.$route.params.uid)
		// this.generateTable()
        // window.generateTable = this.generateTable
    },
    methods: {
        getDiscount(uid) {
            const self = this
            self.dialog = true

            axios.get(`${api}/discount/details/${uid}`)
            .then((result) => {
                self.discount = result.data.data
                self.getOrders()
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        async getOrders() {
            const self = this

            axios.get(`${api}/discounts/orders`)
            .then((result) => {
                self.orders = result.data.data
                self.dialog = false
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
                self.dialog = false
            })
        },
        generateTable() {
            const self = this
            self.tabulator = new Tabulator(self.$refs.table, {
                height: '500px',
                history:true,
                selectable:true,
                pagination:'remote',
                layout:'fitColumns',
                ajaxURL: 'https://us-central1-scgc-transcycle-marketplace.cloudfunctions.net/searchDiscountOrders/',
                ajaxContentType: 'json',
                ajaxParams: {
                    search: self.searchTerm,
                    discount: self.$route.params.uid
                },
                ajaxSorting: true,
                ajaxFiltering: true,
                ajaxConfig:{
                    method:"POST",
                    headers: {
                        "Authorization": "Bearer " + self.$store.state.Auth.token
                    },
                },
                ajaxError(xhr, textStatus, errorThrown){
                    if(xhr.status === 403){
                        firebase.auth().onAuthStateChanged(function(user) {
                            if (user) {
                                user.getIdToken(true).then(token => {
                                    self.$store.dispatch('setToken', token)
                                    localStorage.setItem('interval', interval)
                                    self.generateTable()
                                })
                            } else {
                                clearInterval(interval)
                                reject(Error('fail'))
                            }
                        })
                    }
                },
                paginationSize: 25,
                columns: [
                    { title: 'Order ID', field: 'orderId', align:'center' },
                    { title: 'Branch', field: 'branchName', align:'center' },
                    { title: 'Amount', field: 'total', align:'center' },
                    { title: 'Payment Method', field: 'paymentMethod', align:'center'},
                    { title: 'Status', field: 'status', align:'center' },
                    { title: 'Date of Checkout', field: 'checkedOutAt', align:'center' }
                ],
                cellDblClick:function(e, cell){
                    let definition = cell.getColumn().getDefinition()
                    if(definition.field !== 'remarks') {
                        self.activeRow =  cell.getRow().getData()
                        self.activeColumn = definition.field
                        self.activeColumnTitle = definition.title
                        self.activeCell = cell
                        //self.dialog = true
                    }
                },
                rowClick:(e, row) => {
                    self.$router.push(`/order/view/${row.getData().orderId}`)
                }
            })
        },
        async deactivate() {
            let self = this
            let proceed = confirm(`Are you sure want to deactivate ${self.discount.code}?`)
            if(confirm) {
                let deactivateDiscount = firebase.functions().httpsCallable('deactivateDiscount')
                await deactivateDiscount({uid: self.$route.params.uid})
                self.discount.status = 0
            }
        },
        async activate() {
            let self = this
            let proceed = confirm(`Are you sure want to activate ${self.discount.code}?`)
            if(confirm) {
                let activateDiscount = firebase.functions().httpsCallable('activateDiscount')
                await activateDiscount({uid: self.$route.params.uid})
                self.discount.status = 1
            }
        },
        showErrorMessage(message) {
            alert(message)
        },
        search: _.debounce(() => {
            window.generateTable(false)
        }, 1000)
    }
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css'
</style>

