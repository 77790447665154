<template>
    <v-snackbar dense top right :color="type" v-model="_value">
        {{message}}
        <v-btn text @click="_value = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    model: {
        prop: 'show',
        event: 'change'
    },
    props: {
        type: {
            type: String,
            default: 'success',
        },
        message: String,
        show: {
            type: Boolean,
            default: false
        },

    },
    computed: {
        _value: {
            get: function () {
                return this.show
            },
            set: function (newVal) {
                this.$emit('change', newVal)
            }
        }
    }
}
</script>