<template>
    <v-container>
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Orders</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field
                    class="mt-3"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search by Order ID"
                    single-line
                    hide-details
                    dense
                >
                </v-text-field>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn class="mt-1" icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :search="search" :items="orders" :headers="headers">
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-2" @click="openItem(item)">open_in_new</v-icon>
                    </template>
                    <span>View Order</span>
                </v-tooltip>
            </template>

            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-icon class="mt-3">filter_list</v-icon>
                        <OrderFilters class="mt-3 ml-1" v-model="filters.status"></OrderFilters>
                        <v-icon class="mt-3 ml-3">mdi-calendar</v-icon>
                        <DateRangeFilters 
                            class="mt-2 ml-5"
                            v-bind:dateFrom="filters.dateFrom"
                            v-on:update:dateFrom="filters.dateFrom = $event"
                            v-bind:dateTo="filters.dateTo"
                            v-on:update:dateTo="filters.dateTo = $event"
                            v-on:getOrders="getOrders"
                        ></DateRangeFilters>
                    </v-toolbar-items>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Loading data
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please wait
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
import { mapGetters } from 'vuex'
import OrderFilters from '@/components/Order/Status'
import DateRangeFilters from '@/components/Order/DateRange'

export default {
    components: {
        OrderFilters,
        DateRangeFilters
    },
	data () {
		return {
            beforeRouteDialog: false,
            dialog: false,
            showFilter: false,
            search: '',
            orders: [],
            role: {},
            branch: {},
            headers: [
                { text: 'Order ID', value: 'orderId', sortable: false },
                { text: 'Product', value: 'entries[0].primaryProductName', filterable: false, sortable: false },
                { text: 'Amount', value: 'total', filterable: false, sortable: false },
                { text: 'Shipping Method', value: 'shippingMethod', filterable: false, sortable: false },
                { text: 'Status', value: 'status', filterable: false, sortable: false },
                { text: 'Date of Checkout', value: 'checkedOutAt', filterable: false, sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            filters: {
                status: null,
                dateFrom: null,
                dateTo: null
            }
		}
    },
    computed: {
        ...mapGetters(['user']),

        checkRole() {
            let branchRoles = ["BM", "PC", "SE", "BAA"]
            return branchRoles.includes(this.role.roleCode)
        },
        filterOrdersTable() {
            let orders = this.orders
            let filteredArray = []
            
            for(let i = 0; i < orders.length; i++) {
                orders[i].branch === this.branch.uid ? filteredArray.push(orders[i]) : console.log("Different branch")
            }
            this.orders = filteredArray

            return this.orders
        }
    },
    watch: {
        filters: {
            handler: function() {
                this.getOrders()
            },
            deep: true
        }
    },
	mounted() {
        this.getOrders()
        this.getRoleDetails(this.$store.getters.user.uid)
	},
    methods: {
        getRoleDetails(userId) {
            const self =  this

            axios.get(`${api}/role/${userId}`)
            .then(response => {
                self.role = response.data.result

                if(self.checkRole) self.getUserBranchDetails(userId)
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getUserBranchDetails(userId) {
            const self =  this

            axios.get(`${api}/user/branch/details/${userId}`)
            .then((result) => {
                self.branch = result.data.result

                self.filterOrdersTable
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getOrders() {
            const self = this
            self.dialog = true
            
            axios.get(`${api}/orders`, {
                params: {
                    ...self.filters
                }
            })
            .then((result) => {
                self.orders = result.data.data
                self.dialog = false
            })
            .catch((error) => {
                self.dialog = false
                self.showErrorMessage(error.message)
            })
        },
        openItem(item) {
            let self = this
            self.beforeRouteDialog = true
            setTimeout(() => {
                self.$router.push('/order/view/'+item.orderId)
            }, 1000)
        },
        showErrorMessage(message){
            alert(message);
        },
    },
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
</style>

