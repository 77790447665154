<template>
	<v-container>
		<v-toolbar dense short flat color="blue">
			<v-toolbar-title>Edit Product</v-toolbar-title>
		</v-toolbar>
		<v-layout row justify-space-between>
			<v-flex xs12 md4 class="mt-5 ml-4">
				<h1 class="title">Product Model: {{ product.model }}</h1>
				<img :alt="`Photo for ${product.model}`" class="img-fluid" :src="product.photo" style="width: 350px; height: 300px;"/>
				<v-checkbox v-model="product.enable" :label="product.enable == 0 ? 'Disabled' : 'Enabled'"></v-checkbox>
				<v-checkbox v-model="product.featured" :label="product.featured == 0 ? 'Not Featured' : 'Featured'"></v-checkbox>
				<v-btn
					class="info"
					text
					@click="save"
				>
					Save
				</v-btn>
				<v-btn
					text
					@click="$router.push(`/product/view/${product.slug}`)"
					
				>
					Back
				</v-btn>
			</v-flex>
			<v-flex xs12 md7>
				<form>
					<v-text-field
						v-model="product.brand"
						label="Brand"
						disabled
					></v-text-field>
					<v-text-field
						v-model="product.category"
						label="Category"
						disabled
					></v-text-field>
					<v-text-field
						v-model="product.materialNumber"
						label="Material Number"
						disabled
					></v-text-field>
					<v-text-field
						v-model="product.colors"
						label="Color"
						disabled
					></v-text-field>
					<v-text-field
						v-model="product.purchaseDescription"
						label="Purchase Description"
						disabled
					></v-text-field>
					<v-text-field
						v-model="product.srp"
						label="SRP"
					></v-text-field>
					<v-text-field
						v-model="product.photo"
						label="Photo"
					></v-text-field>
				</form>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { api } from '../../configs/api'
import axios from 'axios'
export default {
	data: () => ({
		product: {}
	}),
	computed: {
		...mapGetters([
			"user"
		])
	},
	watch: {

	},
	mounted() {
		this.fetchProduct()
	},
	methods: {
		fetchProduct() {
			const self = this
			let slug = self.$route.params.slug

            axios.get(`${api}/mc/model/view/${slug}`)
            .then((result) => {
                let responseData = result.data

                self.product = responseData.data
            }).catch((error) => {
                alert(error.message)
            })
		},
		save() {
			const self = this
			
			axios.put(`${api}/mc/model/edit/`, {
				nsItemId: self.product.nsItemId,
				srp: self.product.srp,
				photo: self.product.photo,
				featured: self.product.featured,
				enable: self.product.enable,
				itemIds: self.product.id
			})
			.then((response) => {
				let responseData = response.data

				if(!responseData.success) return
                alert(responseData.message)
			})
			.catch((error) => {
				self.showErrorMessage(error.message)
			})
		},
		showErrorMessage(message) {
			alert(message)
		}
	}
}
</script>