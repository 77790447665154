<template>
    <div>
        <v-select
            v-model="_value"
            :return-object="isObjectValue"
            :items="items"
            item-value="status"
            dense
            clearable
            label="Order Selection"
            @change="changeValue"
        >

            <template v-slot:item="{item}">
                <span v-if="item.status === null">
                    --- Not applicable ---
                </span>
                <span v-else>
                    {{ item.status }}
                </span>
            </template>

            <template v-slot:selection="{item}">
                <span v-if="item.status === null">
                    --- Not applicable ---
                </span>
                <span v-else>
                    {{ item.status }}
                </span>
            </template>
        </v-select>
    </div>
</template>

<script>
import axios from 'axios'
import { api } from '../../configs/api'

export default {
    name: 'filter-by-status',
    props: {
        isObjectValue: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: Number,
            default: null
        }
    },
    computed: {
        _value: {
            get: function () {
                return this.defaultValue
            },
            set: function (newVal) {
                this.changeValue(newVal)
            }
        }
    },
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            let self = this

            axios.get(`${api}/order/status`)
            .then(response => {
                let responseData = response.data

                self.items = responseData.results
            }).catch((error) => {
                alert(error.message)
            })
        },
        changeValue(value){
            this.$emit('input', value)
        }
    }
}
</script>