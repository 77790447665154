<template>
	<v-app id="inspire">
		<v-content>
			<v-container fluid fill-height>
				<v-layout align-center justify-center style="text-align: center;">
					<v-flex xs12 sm8 md4>
						<h1 class="font-weight-light">Transcycle Online Store Admin</h1>
						<v-form>
							<v-layout>
								<v-flex xs12>
									<div id="firebaseui-auth-container"></div>
								</v-flex>
							</v-layout>
							<v-layout class="mt-3">
								<v-flex xs11 offset-xs1>
									<p></p>
								</v-flex>
							</v-layout>
						</v-form>
					</v-flex>
				</v-layout>
			</v-container>
		</v-content>
	</v-app>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import { db } from "../main";
import "../../node_modules/firebaseui/dist/firebaseui.css";
import axios from "axios"
import { api } from '@/configs/api'

export default {
	mounted() {
		let self = this;
		let uiConfig = {
            signInFlow: 'popup',
			signInOptions: [
				{
					provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
				}
			],
			callbacks: {
				async signInSuccessWithAuthResult() {
					let user = firebase.auth().currentUser
					let token = await user.getIdToken()
					let validationResp = await self.logInValidation(token)

					if(!validationResp.success) {
						self.$router.push({ 
							name: 'LoginError',
							params: {
								errorCode: validationResp.error_code,
								errorMsg: validationResp.message
							} 
						});
						console.log(1111)
					} else {
						self.proceed(token, user)
						console.log(2222)
					}
					
					return false;
				}
			}
		};
		let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
		ui.start("#firebaseui-auth-container", uiConfig);
	},
	methods: {
		async logInValidation(token) {
			console.log(3333)
			let response = await axios.post(`${api}/admin/user/verify/${token}`)
			console.log(response)
			return response.data
		},
		proceed(token, user) {
			let self = this
			self.$store.dispatch('login', { token, user })
			self.$router.push("/");
		}
	}
};
</script>