<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" class="ma-auto pa-1">
			<v-spacer></v-spacer>
			<v-btn-toggle v-model="toggle" mandatory dense color="info">
				<v-btn value="today">
					Today
				</v-btn>
				<v-btn value="month">
					Month
				</v-btn>
			</v-btn-toggle>
		</v-row>
    	<v-layout row wrap justify-space-between>
			<v-flex xs12 md3 class="ma-auto pa-1">
				<v-card v-if="toggle == 'month'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Sales</v-list-item-title>
									<v-list-item-subtitle>{{thousandSeparator(monthlyTotalSales)}} in total</v-list-item-subtitle>
										<div v-if="previousMonthlyTotalSales">
											<span v-if="previousMonthlyTotalSales" :class="`text-${monthlyTotalSalesGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${monthlyTotalSalesGrowth >= 0 ? 'up' : 'down'}`"></i> {{monthlyTotalSalesGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since last month</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
						<v-list-item-avatar>
							<v-icon class="mb-4 mb-xl-0">mdi-currency-php</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>

				<v-card v-if="toggle == 'today'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Sales</v-list-item-title>
									<v-list-item-subtitle>{{thousandSeparator(dailyTotalSales)}} in total</v-list-item-subtitle>
										<div v-if="previousDailyTotalSales">
											<span v-if="previousDailyTotalSales" :class="`text-${dailyTotalSalesGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${dailyTotalSalesGrowth >= 0 ? 'up' : 'down'}`"></i> {{dailyTotalSalesGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since yesterday</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
						<v-list-item-avatar>
							<v-icon class="mb-4 mb-xl-0">mdi-currency-php</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-flex>

			<v-flex xs12 md3 class="ma-auto pa-1">
				<v-card v-if="toggle == 'month'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Orders</v-list-item-title>
									<v-list-item-subtitle>{{monthlyTotalOrders}} order(s) completed</v-list-item-subtitle>
									<div v-if="previousMonthlyTotalOrders">
										<span :class="`text-${monthlyTotalOrdersGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${monthlyTotalOrdersGrowth >= 0 ? 'up' : 'down'}`"></i> {{monthlyTotalOrdersGrowth | thousandSeparator}}%</span>
										<span class="text-no-wrap">Since last month</span>
									</div>
									<div v-else>
										<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
										<span class="text-no-wrap">Insufficient data</span>
									</div>
						</v-list-item-content>
						<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-plus</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>

				<v-card v-if="toggle == 'today'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Orders</v-list-item-title>
									<v-list-item-subtitle>{{dailyTotalOrders}} order(s) completed</v-list-item-subtitle>
									<div v-if="previousDailyTotalOrders">
										<span :class="`text-${dailyTotalOrdersGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${dailyTotalOrdersGrowth >= 0 ? 'up' : 'down'}`"></i> {{dailyTotalOrdersGrowth | thousandSeparator}}%</span>
										<span class="text-no-wrap">Since yesterday</span>
									</div>
									<div v-else>
										<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
										<span class="text-no-wrap">Insufficient data</span>
									</div>
						</v-list-item-content>
						<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-plus</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-flex>

			<v-flex xs12 md3 class="ma-auto pa-1">
				<v-card v-if="toggle == 'month'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Pending</v-list-item-title>
									<v-list-item-subtitle>{{monthlyTotalPending}} order(s) pending</v-list-item-subtitle>
										<div v-if="previousMonthlyTotalPending">
											<span :class="`text-${monthlyTotalPendingGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${monthlyTotalPendingGrowth >= 0 ? 'up' : 'down'}`"></i> {{monthlyTotalPendingGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since last month</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
							<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-arrow-down</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>

				<v-card v-if="toggle == 'today'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Pending</v-list-item-title>
									<v-list-item-subtitle>{{dailyTotalPending}} order(s) pending</v-list-item-subtitle>
										<div v-if="previousDailyTotalPending">
											<span :class="`text-${dailyTotalPendingGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${dailyTotalPendingGrowth >= 0 ? 'up' : 'down'}`"></i> {{dailyTotalPendingGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since yesterday</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
							<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-arrow-down</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-flex>

			<v-flex xs12 md3 class="ma-auto pa-1">
				<v-card v-if="toggle == 'month'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Abandoned Cart</v-list-item-title>
									<v-list-item-subtitle>{{monthlyTotalAbandonedCart}} cart(s) abandoned</v-list-item-subtitle>
										<div v-if="previousMonthlyTotalAbandonedCart">
											<span :class="`text-${monthlyTotalAbandonedCartGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${monthlyTotalAbandonedCartGrowth >= 0 ? 'up' : 'down'}`"></i> {{monthlyTotalAbandonedCartGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since last month</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
						<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-off</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>

				<v-card v-if="toggle == 'today'" height="120" max-width="300">
					<v-list-item three-line>
						<v-list-item-content>
							<div class="overline mb-4"></div>
								<v-list-item-title class="headline mb-1">Abandoned Cart</v-list-item-title>
									<v-list-item-subtitle>{{dailyTotalAbandonedCart}} cart(s) abandoned</v-list-item-subtitle>
										<div v-if="previousDailyTotalAbandonedCart">
											<span :class="`text-${dailyTotalAbandonedCartGrowth >= 0 ? 'success' : 'danger'} mr-2`"><i :class="`fa fa-arrow-${dailyTotalAbandonedCartGrowth >= 0 ? 'up' : 'down'}`"></i> {{dailyTotalAbandonedCartGrowth | thousandSeparator}}%</span>
											<span class="text-no-wrap">Since yesterday</span>
										</div>
										<div v-else>
											<span class="text-info mr-2"><i class="fa fa-minus"></i></span>
											<span class="text-no-wrap">Insufficient data</span>
										</div>
						</v-list-item-content>
						<v-list-item-avatar
							><v-icon class="mb-4 mb-xl-0">mdi-cart-off</v-icon>
						</v-list-item-avatar>
					</v-list-item>
				</v-card>
			</v-flex>
    	</v-layout>

		<v-layout class="mt-5" row wrap justify-space-between>
			<v-flex xs12 md7 class="ma-auto pa-1">
				<v-card type="default" header-classes="bg-transparent">
					<v-card-title class="headline">Overview</v-card-title>
					<v-card-subtitle>Performance</v-card-subtitle>
					<v-card-text>
						<line-chart :height="350" ref="bigChart" :chart-data="bigLineChart.chartData" :extra-options="bigLineChart.extraOptions"></line-chart>
					</v-card-text>
				</v-card>
				<v-card-actions>
					<v-spacer></v-spacer>
					<a class="caption" href="" @click.prevent="getFullYearSales">View all</a>
				</v-card-actions>
			</v-flex>

			<v-flex xs12 md5 class="ma-auto pa-1">
				<v-card header-classes="bg-transparent">
					<v-card-title class="headline">Performance</v-card-title>
					<v-card-subtitle>Total orders</v-card-subtitle>
					<v-card-text>
						<bar-chart :height="350" ref="barChart" :chart-data="redBarChart.chartData">
						</bar-chart>
					</v-card-text>
				</v-card>
				<v-card-actions>
					<v-spacer></v-spacer>
					<a class="caption" href="" @click.prevent="getFullYearOrders">View all</a>
				</v-card-actions>
			</v-flex>
		</v-layout>
	</v-container>  
</template>

<script>
// Charts
import * as chartConfigs from '@/components/Charts/config'
import LineChart from '@/components/Charts/LineChart'
import BarChart from '@/components/Charts/BarChart'

// Tables
import SocialTrafficTable from '../Dashboard/SocialTrafficTable'
import PageVisitsTable from '../Dashboard/PageVisitsTable'

import moment from 'moment'
import firebase from 'firebase'
import Pusher from 'pusher-js'
import _ from 'lodash'
import { api } from '@/configs/api'
import axios from 'axios'

export default {
	components: {
		LineChart,
		BarChart,
		PageVisitsTable,
		SocialTrafficTable,
	},
    data: () => ({
		items: [
			{
				text: 'Home',
				disabled: false,
				href: '',
			},
			{
				text: 'Dashboard',
				disabled: false,
				href: '',
			},
		],
		bigLineChart: {
			chartData: {},
			extraOptions: chartConfigs.blueChartOptions,
		},
		redBarChart: {
			chartData: {}
		},
		monthlyTotalSales: 0,
		previousMonthlyTotalSales: 0,
		monthlyTotalSalesGrowth: 0,
		dailyTotalSales: 0,
		previousDailyTotalSales: 0,
		dailyTotalSalesGrowth: 0,
    	monthlyTotalOrders: 0,
		previousMonthlyTotalOrders: 0,
		monthlyTotalOrdersGrowth: 0,
		dailyTotalOrders: 0,
		previousDailyTotalOrders: 0,
		dailyTotalOrdersGrowth: 0,
		monthlyTotalPending: 0,
		previousMonthlyTotalPending: 0,
		monthlyTotalPendingGrowth: 0,
		dailyTotalPending: 0,
		previousDailyTotalPending: 0,
    	dailyTotalPendingGrowth: 0,
    	monthlyTotalAbandonedCart: 0,
		previousMonthlyTotalAbandonedCart: 0,
		monthlyTotalAbandonedCartGrowth: 0,
		dailyTotalAbandonedCart: 0,
		previousDailyTotalAbandonedCart: 0,
    	dailyTotalAbandonedCartGrowth: 0,
		currentMonthStart: null,
		currentMonthEnd: null,
		previousMonthStart: null,
		previousMonthEnd: null,
		previousDayStart: null,
		previousDayEnd: null,
		currentDayStart: null,
		currentDayEnd: null,
		yearStart: null,
		yearEnd: null,
		toggle: "today"
	}),
	
	mounted() {
		let self = this
		self.yearStart = moment().startOf('year').format('YYYY-MM-DD hh:mm:ss')
		self.yearEnd = moment().endOf('year').format('YYYY-MM-DD hh:mm:ss')
		self.currentMonthStart = moment().startOf('month').format('YYYY-MM-DD hh:mm:ss')
		self.currentMonthEnd = moment().endOf('month').format('YYYY-MM-DD hh:mm:ss')
		self.previousMonthStart = moment(self.currentMonthStart).subtract(1, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss')
		self.previousMonthEnd = moment(self.currentMonthEnd).subtract(1, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss')
		self.currentDayStart = moment().startOf('day').format('YYYY-MM-DD hh:mm:ss')
		self.currentDayEnd = moment().endOf('day').format('YYYY-MM-DD hh:mm:ss')
		self.previousDayStart = moment(self.currentDayStart).subtract(1, 'days').startOf('day').format('YYYY-MM-DD hh:mm:ss')
		self.previousDayEnd = moment(self.currentDayEnd).subtract(1, 'days').endOf('day').format('YYYY-MM-DD hh:mm:ss')


		self.getMonthlyRangeSales(self.currentMonthStart, self.currentMonthEnd, 'current')
		self.getDailyRangeSales(self.currentDayStart, self.currentDayEnd, 'current')
		self.getMonthlyRangeOrdersCount(self.currentMonthStart, self.currentMonthEnd, 'current')
		self.getDailyRangeOrdersCount(self.currentDayStart, self.currentDayEnd, 'current')
		self.getMonthlyRangePendingOrdersCount(self.currentMonthStart, self.currentMonthEnd, 'current')
		self.getDailyRangePendingOrdersCount(self.currentDayStart, self.currentDayEnd, 'current')
		self.getMonthlyRangeAbandonedCartCount(self.currentMonthStart, self.currentMonthEnd, 'current')
		self.getDailyRangeAbandonedCartCount(self.currentDayStart, self.currentDayEnd, 'current')

		let pusher = new Pusher('6e556e4996d99875c5f0', {
			cluster: 'ap1',
			forceTLS: true
		})

		let channel = pusher.subscribe('wire-system-001')
		channel.bind('completedOrderSignal', () => {
			self.getMonthlyRangeSales(self.currentMonthStart, self.currentMonthEnd, 'current')
			self.getMonthlyRangeOrdersCount(self.currentMonthStart, self.currentMonthEnd, 'current')
		})

		self.getMonthlySales()
		self.getMonthlyOrders()
	},

    methods: {
		getMonthlyRangeSales(start, end, period) {
			const self = this
			
			axios.get(`${api}/dashboard/sales/range/${start}/${end}`)
			.then((result) => {
				let res = result.data.result
				
				if(res.totalAmount === null) {
					res.totalAmount = 0
				}
				
				period === 'current' ? this.monthlyTotalSales = res.totalAmount : this.previousMonthlyTotalSales = res.totalAmount

				if(period === 'previous') {
					this.monthlyTotalSalesGrowth = res.totalAmount > 0 ? ((this.monthlyTotalSales - res.totalAmount) / res.totalAmount) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getDailyRangeSales(start, end, period) {
			const self = this
			let today = moment().format('YYYY-MM-DD')
	
			axios.get(`${api}/dashboard/daily/sales/range/${today}`)
			.then((result) => {
				let res = result.data.result
				
				if(res.totalAmount === null) {
					res.totalAmount = 0
				}
				
				period === 'current' ? this.dailyTotalSales = res.totalAmount : this.previousDailyTotalSales = res.totalAmount

				if(period === 'previous') {
					this.dailyTotalSalesGrowth = res.totalAmount > 0 ? ((this.dailyTotalSales - res.totalAmount) / res.totalAmount) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getMonthlyRangeOrdersCount(start, end, period) {
			const self = this

			axios.get(`${api}/dashboard/orders/range/${start}/${end}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.monthlyTotalOrders = res.count : this.previousMonthlyTotalOrders = res.count

				if(period === 'previous') {
					this.monthlyTotalOrdersGrowth = res.count > 0 ? ((this.monthlyTotalOrders - res.count) / res.count) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getDailyRangeOrdersCount(start, end, period) {
			const self = this
			let today = moment().format('YYYY-MM-DD')

			axios.get(`${api}/dashboard/daily/orders/range/${today}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.dailyTotalOrders = res.count : this.previousDailyTotalOrders = res.count

				if(period === 'previous') {
					this.dailyTotalOrdersGrowth = res.count > 0 ? ((this.dailyTotalOrders - res.count) / res.count) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},
	  
		getMonthlyRangeAbandonedCartCount(start, end, period) {
			const self = this

			axios.get(`${api}/dashboard/abandoned/cart/${start}/${end}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.monthlyTotalAbandonedCart = res.count : this.previousMonthlyTotalAbandonedCart = res.count

				if(period === 'previous') {
					this.monthlyTotalAbandonedCartGrowth = res.count > 0 ? ((this.monthlyTotalAbandonedCart - res.count) / res.count) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getDailyRangeAbandonedCartCount(start, end, period) {
			const self = this
			let today = moment().format('YYYY-MM-DD')

			axios.get(`${api}/dashboard/daily/abandoned/cart/${today}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.dailyTotalAbandonedCart = res.count : this.previousDailyTotalAbandonedCart = res.count

				if(period === 'previous') {
					this.dailyTotalAbandonedCartGrowth = res.count > 0 ? ((this.dailyTotalAbandonedCart - res.count) / res.count) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getMonthlyRangePendingOrdersCount(start, end, period) {
			const self = this

			axios.get(`${api}/dashboard/pending/orders/${start}/${end}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.monthlyTotalPending = res : this.previousMonthlyTotalPending = res

				if(period === 'previous') {
					this.monthlyTotalPendingGrowth = res > 0 ? ((this.monthlyTotalPending - res) / res) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},

		getDailyRangePendingOrdersCount(start, end, period) {
			const self = this
			let today = moment().format('YYYY-MM-DD')

			axios.get(`${api}/dashboard/daily/pending/orders/${today}`)
			.then((result) => {
				let res = result.data.result

				period === 'current' ? this.dailyTotalPending = res.count : this.previousDailyTotalPending = res.count

				if(period === 'previous') {
					this.dailyTotalPendingGrowth = res.count > 0 ? ((this.dailyTotalPending - res.count) / res.count) * 1 : null
				}
			})
			.catch((error) => {
				alert(error.message)
			})
		},
	  
		thousandSeparator(value) {
			var splitNum
			var number = Math.abs(value)
			number = number.toFixed(2)
			splitNum = number.split('.')
			splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			return splitNum.join(".")
		},
	  
		getMonthlySales(start, end) {
			let monthlyData = []
			let monthlyLabels = []

			for(let i = 7; i >= 0; i--) {
				let formattedStart = moment(self.currentMonthStart).subtract(i, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss');
				let formattedEnd = moment(self.currentMonthStart).subtract(i, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss');
				
				axios.get(`${api}/dashboard/sales/range/${formattedStart}/${formattedEnd}`)
				.then((result) => {
					let res = result.data.result
					if(res.totalAmount == null) res.totalAmount = 0

					monthlyData[i] = parseFloat(res.totalAmount).toFixed(2)
					monthlyLabels[i] = moment(self.currentMonthStart).subtract(i, 'months').format('MMM')

					let chartData = {
						datasets: [
							{
								label: 'Monthly Sales',
								data: monthlyData 
							}
						],
						labels: monthlyLabels,
					}

					this.bigLineChart.chartData = chartData
				})
				.catch((error) => {
					alert(error.message)
				})
			}
		},

		getFullYearSales() {
			const self = this
			let start = self.yearStart
			let end = moment(start).endOf('month').format('YYYY-MM-DD hh:mm:ss');
			let monthlyData = []
			let monthlyLabels = []

			for(let i = 0; i <= 11; i++) {
				let formattedStart = moment(start).add(i, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss');
				let formattedEnd = moment(end).add(i, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss');

				axios.get(`${api}/dashboard/sales/${formattedStart}/${formattedEnd}`)
				.then(response => {
					let res = response.data.result
					if(res.totalAmount == null) res.totalAmount = 0

					monthlyData[i] = parseFloat(res.totalAmount).toFixed(2)
					monthlyLabels[i] = moment(res.month).format('MMM')

					let chartData = {
						datasets: [
							{
								label: 'Monthly Sales',
								data: monthlyData
							}
						],
						labels: monthlyLabels,
					}

					this.bigLineChart.chartData = {}
					this.bigLineChart.chartData = chartData
				})
				.catch((error) => {
					alert(error.message)
				})
			}
		},

		getFullYearOrders() {
			const self = this
			let start = self.yearStart
			let end = moment(start).endOf('month').format('YYYY-MM-DD hh:mm:ss');
			let monthlyData = []
			let monthlyLabels = []

			for(let i = 0; i <= 11; i++) {
				let formattedStart = moment(start).add(i, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss');
				let formattedEnd = moment(end).add(i, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss');

				axios.get(`${api}/dashboard/orders/${formattedStart}/${formattedEnd}`)
				.then(response => {
					let res = response.data.result

					monthlyData[i] = res.count
					monthlyLabels[i] = moment(res.month).format('MMM')

					let chartData = {
						datasets: [
							{
								label: 'Monthly Sales',
								data: monthlyData
							}
						],
						labels: monthlyLabels,
					}

					this.redBarChart.chartData = {}
					this.redBarChart.chartData = chartData
				})
				.catch((error) => {
					alert(error.message)
				})
			}
		},

		getMonthlyOrders(start, end) {
			let monthlyData = []
			let monthlyLabels = []

			for(let i = 7; i >= 0; i--) {
				let formattedStart = moment(self.currentMonthStart).subtract(i, 'months').startOf('month').format('YYYY-MM-DD hh:mm:ss');
				let formattedEnd = moment(self.currentMonthStart).subtract(i, 'months').endOf('month').format('YYYY-MM-DD hh:mm:ss');

				axios.get(`${api}/dashboard/orders/range/${formattedStart}/${formattedEnd}`)
				.then((result) => {
					let res = result.data.result
					monthlyData[i] = res.count
					monthlyLabels[i] = moment(self.currentMonthStart).subtract(i, 'months').format('MMM')

					let chartData = {
						datasets: [
							{
								label: 'Monthly Orders',
								data: monthlyData
							}
						],
						labels: monthlyLabels,
					}

					this.redBarChart.chartData = chartData
				})
				.catch((error) => {
					alert(error.message)
				})
			}
		}
	},

	watch: {
		monthlyTotalSales() {
			this.getMonthlyRangeSales(this.previousMonthStart, this.previousMonthEnd, 'previous')
		},
		dailyTotalSales() {
			this.getDailyRangeSales(this.previousDayStart, this.previousDayEnd, 'previous')
		},
		monthlyTotalOrders() {
			this.getMonthlyRangeOrdersCount(this.previousMonthStart, this.previousMonthEnd, 'previous')
		},
		dailyTotalOrders() {
			this.getDailyRangeOrdersCount(this.previousDayStart, this.previousDayEnd, 'previous')
		},
		monthlyTotalPending() {
			this.getMonthlyRangePendingOrdersCount(this.previousMonthStart, this.previousMonthEnd, 'previous')
		},
		dailyTotalPending() {
			this.getDailyRangePendingOrdersCount(this.previousDayStart, this.previousDayEnd, 'previous')
		},
		monthlyTotalAbandonedCart() {
			this.getMonthlyRangeAbandonedCartCount(this.previousMonthStart, this.previousMonthEnd, 'previous')
		},
		dailyTotalAbandonedCart() {
			this.getDailyRangeAbandonedCartCount(this.previousDayStart, this.previousDayEnd, 'previous')
		},
	}
}
</script>