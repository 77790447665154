<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.message" />
        <v-row no-gutters>
            <v-col cols="12"><h2>{{ title }}</h2></v-col>
            <v-col cols="12">
                <v-toolbar dense short flat color="blue">
                    <v-toolbar-title>Least Selling Models</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{on}">
                                <v-btn icon @click="action = 'add'" v-on="on"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                            </template>
                            <span>Add Model</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-col cols="3">
                    <v-text-field 
                        v-model="nonTopSellingsearch"
                        append-icon="mdi-magnify"
                        label="Search by Item SKU" 
                        single-line
                        clearable 
                        dense 
                        class="mt-3"
                    ></v-text-field>
                </v-col>
                <div v-if="$props.title === 'Motorcycles'">
                    <v-data-table
                        dense
                        show-select
                        loading-text="Loading Items from server, please wait..."
                        :loading="nonTopSellingModel.loading"
                        :search="nonTopSellingsearch"
                        :items="nonTopSellingModel.records"
                        :items-per-page="nonTopSellingModel.pageOptions.itemsPerPage"
                        :footer-props="nonTopSellingModel.footerOptions"
                        :headers="nonTopSellingModel.mcHeaders"
                        item-key="materialNumber"
                        v-model="nonTopSellingModel.selectedModel"
                    />
                </div>
                <div v-else>
                    <v-data-table
                        dense
                        show-select
                        loading-text="Loading Items from server, please wait..."
                        :loading="nonTopSellingModel.loading"
                        :search="nonTopSellingsearch"
                        :items="nonTopSellingModel.records"
                        :items-per-page="nonTopSellingModel.pageOptions.itemsPerPage"
                        :footer-props="nonTopSellingModel.footerOptions"
                        :headers="nonTopSellingModel.partsHeaders"
                        item-key="sku"
                        v-model="nonTopSellingModel.selectedModel"
                    />
                </div>
                
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-toolbar dense short flat color="blue">
                    <v-toolbar-title>Top Selling Setup</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{on}">
                                <v-btn icon @click="action = 'remove'" v-on="on"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                            </template>
                            <span>Remove Model</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-col cols="3">
                    <v-text-field 
                        v-model="topSellingSearch"
                        append-icon="mdi-magnify"
                        label="Search by Item SKU" 
                        single-line
                        clearable 
                        dense 
                        class="mt-3"
                    ></v-text-field>
                </v-col>
                <div v-if="$props.title === 'Motorcycles'">
                    <v-tabs
                        v-model="tab"
                        fixed-tabs
                        align-with-title
                    >
                        <v-tabs-slider color="blue"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            v-for="item in tabItems"
                            :key="item"
                        >
                            <v-data-table
                                dense
                                show-select
                                loading-text="Loading Items from server, please wait..."
                                :loading="topSellingModel.loading"
                                :search="topSellingSearch"
                                :items="topSellingModel.records"
                                :items-per-page="topSellingModel.pageOptions.itemsPerPage"
                                :footer-props="topSellingModel.footerOptions"
                                :headers="topSellingModel.mcHeaders"
                                item-key="sku"
                                v-model="topSellingModel.selectedModel"
                            >
                                <template v-slot:[`item.position`]="props">
                                    <v-edit-dialog
                                        :return-value.sync="props.item.position"
                                        @save="save(props.item)"
                                        @cancel="cancel"
                                        @open="open"
                                        @close="close"
                                    >
                                    {{ props.item.position }}
                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="props.item.position"
                                                label="Edit"
                                                single-line
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <div v-else>
                    <v-data-table
                        dense
                        show-select
                        loading-text="Loading Items from server, please wait..."
                        :loading="topSellingModel.loading"
                        :search="topSellingSearch"
                        :items="topSellingModel.records"
                        :items-per-page="topSellingModel.pageOptions.itemsPerPage"
                        :footer-props="topSellingModel.footerOptions"
                        :headers="topSellingModel.partsHeaders"
                        item-key="sku"
                        v-model="topSellingModel.selectedModel"
                    />
                </div>
                
            </v-col>
        </v-row>
        <v-dialog v-model="actionModal" persistent width="500" height="500" >
            <v-card>
                <div v-if="$props.title === 'Motorcycles'">
                    <v-container v-if="action == 'add'">
                        <h4>Select which group to be listed.</h4>
                        <v-select class="mx-auto" v-model="positionBy" :items="tabItems" outlined></v-select>
                    </v-container>
                    <v-container v-else>
                        <h4>Do you want to proceed?</h4>
                    </v-container>
                </div>
                <div v-else>
                    <v-container>
                        <h4>Do you want to proceed?</h4>
                    </v-container>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small @click="saveActionModal" color="success">Save</v-btn>
                    <v-btn small @click="actionModal= false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBarMessage.snack"
            :timeout="3000"
            :color="snackBarMessage.snackColor"
            right
            top
        >
         {{ snackBarMessage.snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snackBarMessage.snack = false"
                    >
                        Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
    props: {
        department: Array,
        title: String
    },
    data() {
        return {
            nonTopSellingsearch: '',
            topSellingSearch: '',
            action: null,
            actionModal: false,
            snackbar: {
                show: false,
                message: '',
                type: 'success'
            },
            topSellingModel: {
                loading: false,
                mcHeaders: [
                    { text: 'Position', value: 'position', sortable: false },
                    { text: 'SKU', value: 'sku', sortable: false },
                    { text: 'Model Name', value: 'model', sortable: false },
                    { text: 'Brand', value: 'brand', sortable: false },
                    { text: 'Category', value: 'category', sortable: false },
                    { text: 'Description', value: 'description', sortable: false }
                ],
                partsHeaders: [
                    { text: 'SKU', value: 'sku', sortable: false },
                    { text: 'Model Name', value: 'model', sortable: false },
                    { text: 'Brand', value: 'brand', sortable: false },
                    { text: 'Category', value: 'category', sortable: false },
                    { text: 'Description', value: 'description', sortable: false }
                ],
                records: [],
                serverTotal: 0,
                pageOptions: {
                    itemsPerPage: 20
                },
                footerOptions: {
                    itemsPerPageOptions: [20, 30, 40, 50],
                    itemsPerPageText: "Records per page"
                },
                selectedModel: []
            },
            nonTopSellingModel: {
                loading: false,
                mcHeaders: [
                    { text: 'SKU', value: 'materialNumber', sortable: false },
                    { text: 'Model Name', value: 'model', sortable: false },
                    { text: 'Description', value: 'purchaseDescription', sortable: false },
                    { text: 'Brand', value: 'brand', sortable: false },
                    { text: 'Category', value: 'category', sortable: false }  
                ],
                partsHeaders: [
                    { text: 'SKU', value: 'sku', sortable: false },
                    { text: 'Model Name', value: 'model', sortable: false },
                    { text: 'Description', value: 'salesDescription', sortable: false },
                    { text: 'Brand', value: 'brand', sortable: false },
                    { text: 'Category', value: 'category', sortable: false } 
                ],
                records: [],
                serverTotal: 0,
                pageOptions: {
                    itemsPerPage: 20
                },
                footerOptions: {
                    itemsPerPageOptions: [20, 30, 40, 50],
                    itemsPerPageText: "Records per page"
                },
                selectedModel: []
            },
            tab: 0,
            tabItems: [
                "All Category & All Brand",
                "All Brand & Solo/Cub/Moped",
                "All Brand & Scooter/Automatic",
                "All Brand & Business",
                "All Brand & Sports/Street",
            ],
            positionBy: '',
            snackBarMessage: {
                snack: false,
                snackColor: '',
                snackText: '',
            },
        }
    },
    computed: {
        isValidMcs() {
            let category = ''
            let categories = []

            if(this.positionBy === "All Brand & Solo/Cub/Moped") category = 'SOLO/CUB/MOPED'
            else if(this.positionBy === "All Brand & Scooter/Automatic") category = 'SCOOTER/AUTOMATIC'
            else if(this.positionBy === "All Brand & Business") category = 'BUSINESS'
            else category = 'SPORTS/STREET'

            this.nonTopSellingModel.selectedModel.forEach(mc => {
                categories.push(mc.category)
            })

            let isMatchToAll = (currentValue) => currentValue === category;
            return categories.every(isMatchToAll);
        }
    },
    watch: {
        action: function(val) {
            if(val == null) return

            this.showActionModal()
        },
        'actionModal': function(val) {
            if(!val) {
                if(this.$props.title === 'Motorcycles') {
                    this.loadNonTopSellingMCModels()
                    this.loadTopSellingMCModels(this.tab)
                    this.positionBy = ''
                    this.action = null
                } else {
                    this.loadNonTopSellingPartsModels()
                    this.loadTopSellingPartsModels()
                    this.action = null
                }
            }
        },
        tab: function(val) {
            this.loadTopSellingMCModels(val)
        },
        positionBy: function(position) {
            let category = (el) => el === position
            this.tab = this.tabItems.findIndex(category)
        }
    },
    mounted() {
        if(this.$props.title === 'Motorcycles') {
            this.loadTopSellingMCModels(this.tab);
            this.loadNonTopSellingMCModels();
        } else {
            this.loadTopSellingPartsModels();
            this.loadNonTopSellingPartsModels();
        }
        
    },
    methods: {
        loadTopSellingMCModels(index) {
            const self = this
            self.topSellingModel.records = []
            self.loading = true

            self.$axios.get(`/products/top_selling_mc/model`, {
                params: {
                    index,
                    department: self.department
                }
            }).then((result) => {
                self.topSellingModel.records = result.data.rows
                self.topSellingModel.serverTotal = result.data.count
                self.topSellingModel.loading = false
                self.topSellingModel.selectedModel = []

                self.topSellingModel.records.forEach(rec => {
                    if(rec.positionToSolo != null) {
                        rec.position = rec.positionToSolo
                        rec = Object.assign({}, rec)  
                    } else if(rec.positionToScooter != null) {
                        rec.position = rec.positionToScooter
                        rec = Object.assign({}, rec)
                    } else if(rec.positionToBusiness != null) {
                        rec.position = rec.positionToBusiness
                        rec = Object.assign({}, rec)
                    } else if(rec.positionToBigbike != null) {
                        rec.position = rec.positionToBigbike
                        rec = Object.assign({}, rec)
                    } else {
                        rec.position = rec.positionToAll
                        rec = Object.assign({}, rec)
                    }
                })
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        loadTopSellingPartsModels() {
            const self = this
            self.topSellingModel.records = []
            self.loading = true

            self.$axios.get(`/products/top_selling_parts/model`)
            .then((result) => {
                self.topSellingModel.records = result.data.rows
                self.topSellingModel.serverTotal = result.data.count
                self.topSellingModel.loading = false
                self.topSellingModel.selectedModel = []
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        loadNonTopSellingMCModels() {
            const self = this
            self.loading = true

            self.$axios.get(`/products/non_top_selling_mc/sku`, {
                params: {
                    department: self.department
                }
            }).then((result) => {
                self.nonTopSellingModel.records = result.data.rows
                self.nonTopSellingModel.serverTotal = result.data.count
                self.nonTopSellingModel.loading = false
                self.nonTopSellingModel.selectedModel = []
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        loadNonTopSellingPartsModels() {
            const self = this
            self.loading = true

            self.$axios.get(`/products/non_top_selling_parts/sku/`)
            .then((result) => {
                self.nonTopSellingModel.records = result.data.rows
                self.nonTopSellingModel.serverTotal = result.data.count
                self.nonTopSellingModel.loading = false
                self.nonTopSellingModel.selectedModel = []
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        showActionModal() {
            const length = this.action == 'add' ? this.nonTopSellingModel.selectedModel.length : this.topSellingModel.selectedModel.length

            if(length < 1) {
                this.showSnackbarMessage("Please select model name first.", false)
                this.action = null
                return
            }
            
            this.actionModal = true
        },
        saveActionModal() {
            switch (this.action) {
                case 'add':
                    this.saveTopSellingModels()
                    break;
                case 'remove':
                    this.removeTopSellingModels()
                    break;
                default:
                    break;
            }
        },
        saveTopSellingModels() {
            const self = this

            if(this.$props.title === 'Motorcycles') {
                const data = {
                    group: self.positionBy,
                    models: this.nonTopSellingModel.selectedModel
                }

                if(self.positionBy === 'All Category & All Brand') {
                    this.$axios.post('/product/model/top_selling_mc', data)
                    .then((result) => {
                        self.showSnackbarMessage(result.data.message, result.data.success)
                        self.actionModal = false
                    })
                    .catch((error) => {
                        this.showSnackbarMessage(error.message, false)
                    })
                } else if(this.isValidMcs) {
                    this.$axios.post('/product/model/top_selling_mc', data)
                    .then((result) => {
                        self.showSnackbarMessage(result.data.message, result.data.success)
                        self.actionModal = false
                    })
                    .catch((error) => {
                        this.showSnackbarMessage(error.message, false)
                    })
                }  else {
                    self.showSnackbarMessage("Selected model(s) has different category.", false)
                }
            } else {
                const data = {
                    models: this.nonTopSellingModel.selectedModel
                }

                this.$axios.post('/product/model/top_selling_parts', data)
                .then((result) => {
                    self.showSnackbarMessage(result.data.message, result.data.success)
                    self.actionModal = false
                    setTimeout(() => {
                        self.loadTopSellingPartsModels()
                    }, 2000)
                })
                .catch((error) => {
                    this.showSnackbarMessage(error.message, false)
                })
            }
        },
        removeTopSellingModels() {
            const self = this
            const ids = this.topSellingModel.selectedModel.map(rec => {
                return rec.id
            })

            if(this.$props.title === 'Motorcycles') {
                const skus = this.topSellingModel.selectedModel.map(rec => {
                    return rec.sku
                })

                const group = this.tab

                this.$axios.delete('/product/model/top_selling_mc', {
                    data: { ids, skus, group }
                })
                .then((result) => {
                    self.showSnackbarMessage(result.data.message, result.data.success)
                    self.actionModal = false
                    self.loadTopSellingMCModels(self.tab)
                })
                .catch((error) => {
                    this.showSnackbarMessage(error.message, false)
                })
            } else {
                this.$axios.delete('/product/model/top_selling_parts', {
                    data: { ids }
                })
                .then((result) => {
                    self.showSnackbarMessage(result.data.message, result.data.success)
                    self.actionModal = false
                    self.loadTopSellingPartsModels()
                })
                .catch((error) => {
                    this.showSnackbarMessage(error.message, false)
                })
            }
        },
        save(item) {
            this.snackBarMessage.snack = true
 
            this.$axios.put('/product/top_selling/position/set', item)
                .then((result) => {
                    this.showSnackbarMessage(result.data.message, result.data.success)
                    this.loadTopSellingMCModels(this.tab)
                })
                .catch((error) => {
                    this.showSnackbarMessage(error.message, false)
                })
        },
        cancel() {
            this.snackBarMessage.snack = true
            this.snackBarMessage.snackColor = 'error'
            this.snackBarMessage.snackText = 'Canceled'
        },
        open() {
            this.snackBarMessage.snack = true
            this.snackBarMessage.snackColor = 'info'
            this.snackBarMessage.snackText = 'Dialog opened'
        },
        close() {
            console.log('Dialog closed')
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        }
    }
}
</script>