<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short text color="blue">
            <v-toolbar-title>Motorcycle LTO Registration Fees</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-text-field 
                    class="mt-2"
                    v-model="filters.materialNumber" 
                    append-icon="mdi-magnify" 
                    label="Material Number" 
                    single-line 
                    hide-details
                    clearable
                />
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showDialog('add')" v-on="on"><v-icon>mdi-plus-box</v-icon></v-btn>
                    </template>
                    <span>Include LTO</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showDialog('remove')" v-on="on"><v-icon>mdi-minus-box</v-icon></v-btn>
                    </template>
                    <span>Remove LTO</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense 
            show-select
            loading-text="Loading Items from server, please wait..."
            item-key="materialNumber"
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverTotal"
            :options.sync="pageOptions"
            @update:options="loadItems"
            :footer-props="footerProps"
            v-model="selected"
        >
            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <Brands class="mt-4 mr-2" dropdownIcon="filter_list" v-model="filters.brand" />
                        <Models class="mt-4" dropdownIcon="filter_list" v-model="filters.model" />
                    </v-toolbar-items>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent width="500" height="500">
            <v-card>
                <v-card-title>
                    <span v-if="action == 'add'">Add LTO Fee</span>
                    <span v-if="action == 'remove'">Remove LTO Fee</span>
                </v-card-title>
                <v-divider />
                <v-container>
                    <div v-if="action == 'add'"> 
                        Selected motorcycles will be included with 
                        <v-text-field 
                            label="LTO Registration Fee"
                            prefix="Php"
                            type="number"
                            v-model="LtoFeeAmount"
                            autofocus
                        />

                        <v-flex xs12 v-for="(type,index) in types" :key="index" class="pt-0 pb-0 pl-3">
                            <v-checkbox class="pa-0" type="checkbox" :label="type" v-model="selectedMc.type" :value="type"/>
                        </v-flex>
                        Would you like to proceed?
                    </div>
                    <div v-if="action == 'remove'">
                        Would you like to proceed removing the LTO Fee setup for these motorcycle/s?
                    </div>
                </v-container>
                <v-divider />
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="doAction" color="success">Save</v-btn>
                    <v-btn @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import Brands from '@/components/Select/EnableMcBrands'
import Models from '@/components/Select/EnableMcModels'

export default {
    components: {
        Snackbar,
        Brands,
        Models
    },
    data() {
        return {
            showFilter: false,
            dialog: false,
            loading: false,
            action: null,
            defaultLtoFee: 0,
            LtoFeeAmount: 0,
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            filters: {
                brand: null,
                model: null,
                materialNumber: null
            },
            headers: [
                { text: 'Material Number', value: 'materialNumber', sortable: false },
                { text: 'Description', value: 'purchaseDescription', sortable: false },
                { text: 'Model Name', value: 'model', sortable: false },
                { text: 'Brand', value: 'brand', sortable: false },
                { text: 'LTO Fee', value: 'lto_detail.amount', sortable: false },
                { text: 'Type', value: 'type', sortable: false }
            ],
            records: [],
            serverTotal: 0,
            pageOptions: {
                page: 1,
                itemsPerPage: 10
            },
            footerProps: {
                itemsPerPageOptions: [10, 25, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            selected: [],
            selectedMc: { type: []},
            types: ["CASH","LOAN"]
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
        selectedMaterial() {
            return this.selected.map(rec => rec.materialNumber)
        },
        LtoFeeAmount: {
            get() {
                // this.LtoFeeAmount = this.defaultLtoFee
                return this.defaultLtoFee
            },
            set(value) {
                console.log(value)
                this.LtoFeeAmount = value
            }
        }
    },
    watch: {
        filters: {
            handler: 'loadItems',
            deep: true
        },
        dialog(value) {
            if(!value) this.action = null
        }
    },
    mounted() {
        this.loadItems()
        this.loadDefaultLtoFee()
    },
    methods: {
        loadItems() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            self.$axios.get(`/mc/lto`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                // let data = result.data.rows
                // console.log(data)
                 self.records.forEach(data => {
                    if (data["lto_detail.activeLoan"] == 1 && data["lto_detail.active"] == 1) {
                        data.type = ["LOAN","CASH"]
                    } else if (data["lto_detail.activeLoan"] == 1 && data["lto_detail.active"] == 0) {
                        data.type = ["LOAN"]
                    } else if (data["lto_detail.activeLoan"] == 0 && data["lto_detail.active"] == 1) {
                        data.type = ["CASH"]
                    } else {
                        data.type = []
                    }
                    // self.records.push(data)
                }); 
                self.serverTotal = result.data.count
                self.loading = false
                self.selected = []
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        loadDefaultLtoFee() {
            let self = this
            this.$axios.get("/fee/LTO_FEE")
            .then((response) => {
                self.defaultLtoFee = response.data.data.amount
                self.LtoFeeAmount = self.defaultLtoFee
            }).catch(error => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        doAction() {
            switch (this.action) {
                case 'add':
                    this.incudeLto()
                    break;
                case 'remove':
                    this.removeLto()
                    break;
                default:
                    break;
            }
        },
        async incudeLto() {
            let response
            let self = this
            let loan = self.selectedMc.type.includes("LOAN") ? loan = 1 : loan = 0
            let cash = self.selectedMc.type.includes("CASH") ? cash = 1 : cash = 0
            console.log(self.selectedMc)
            self.LtoFeeAmount = self.LtoFeeAmount == "" || self.LtoFeeAmount == 0 ? self.LtoFeeAmount = 0 : self.LtoFeeAmount = self.LtoFeeAmount
            console.log(self.LtoFeeAmount)
            try{
                response = await self.$axios.post('/mc/lto', {
                    materialNumbers: self.selectedMaterial,
                    ltoFee: self.LtoFeeAmount,
                    activeLoan: loan,
                    activeCash: cash
                })
                self.showSnackbarMessage(response.data.message)
                self.loadItems()
            } catch(error) {
                self.showSnackbarMessage(error.message, false)
            }
            self.LtoFeeAmount = self.defaultLtoFee
            self.dialog = false
        },
        async removeLto() {
            let response
            try{
                this.validateSelectedMaterial()
                response = await this.$axios.delete('/mc/lto', {
                    data: {
                        materialNumbers: this.selectedMaterial
                    }
                })
                this.showSnackbarMessage(response.data.message)
                this.loadItems()
            } catch(error) {
                this.showSnackbarMessage(error.message, false)
            }
            this.dialog = false
        },
        showDialog(action) {
            try {
                this.validateSelectedMaterial()
                this.action = action
                this.dialog = true
            } catch (error) {
                this.showSnackbarMessage(error.message, false)
            }
        },
        validateSelectedMaterial() {
            if(this.selectedMaterial.length < 1)
                throw new Error("Please select motorcycle/s first.")
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
    }
}
</script>