<template>
    <v-container>
        <v-layout>
            <v-flex xs12>
                <v-dialog
                    v-model="summaryDialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <a class="caption" href="javascript:void(0)" v-bind="attrs" v-on="on">
                            Summary page for created matrix on normal rates
                        </a>
                    </template>
                    <v-card>
                        <v-toolbar flat text dark color="primary">
                            <v-btn
                                icon
                                dark
                                @click="summaryDialog = false"
                                >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Delivery Fee</v-toolbar-title>
                        </v-toolbar>
                        <v-layout class="pa-3">
                            <v-flex xs12>
                                <p style="background-color: #E0E0E0;" class="pl-1 subtitle-2">Summary page for Normal Rates</p>
                            </v-flex>
                        </v-layout>
                        <v-layout class="ml-5 caption">
                            <v-flex xs12>
                                <strong>Note: Please select One(1) per courier at a time.</strong>
                            </v-flex>  
                        </v-layout>
                        <v-layout class="pa-3">
                            <v-flex xs12>
                                <table class="caption" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th colspan="6">
                                                Normal Rates for NinjaVan
                                            </th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2">Point of Origin</th>
                                            <th colspan="6">Destination</th>
                                        </tr>
                                        <tr>
                                            <th v-for="(island, id) in islands" :key="id">
                                                {{ island.name }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="text-align:center">
                                            <td>
                                                {{ courier.selectedOrigin.area }}
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.ninjaVan.routes" type="checkbox" value="ncr-ncr">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.ninjaVan.routes" type="checkbox" value="ncr-luzon">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.ninjaVan.routes" type="checkbox" value="ncr-visayas">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.ninjaVan.routes" type="checkbox" value="ncr-mindanao">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.ninjaVan.routes" type="checkbox" value="ncr-island">
                                            </td>
                                        </tr> 
                                    </tbody>    
                                </table>
                            </v-flex>
                        </v-layout>
                        <v-layout class="pa-3">
                            <v-flex class="ml-5 pt-5" xs2>
                                <p class="caption text--secondary">Dates Covered</p>
                            </v-flex>
                            <v-flex class="mt-3" xs2>
                                <v-text-field
                                    v-model="courier.normalRatesEffectivityDate"
                                    disabled
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex class="mt-3 ml-3" xs2>
                                <v-text-field
                                    v-model="courier.normalRatesExpiryDate"
                                    disabled
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>   
                        <v-layout class="pa-3">
                            <v-flex xs12>
                                <table class="caption" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th colspan="6">
                                                Normal Rates for J&T
                                            </th>
                                        </tr>
                                        <tr>
                                            <th rowspan="2">Point of Origin</th>
                                            <th colspan="6">Destination</th>
                                        </tr>
                                        <tr>
                                            <th v-for="(island, id) in islands" :key="id">
                                                {{ island.name }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="text-align:center">
                                            <td>
                                                {{ courier.selectedOrigin.area }}
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.jnt.routes" type="checkbox" value="ncr-ncr">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.jnt.routes" type="checkbox" value="ncr-luzon">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.jnt.routes" type="checkbox" value="ncr-visayas">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.jnt.routes" type="checkbox" value="ncr-mindanao">
                                            </td>
                                            <td>
                                                <input v-model="courier.selectedOrigin.jnt.routes" type="checkbox" value="ncr-island">
                                            </td>
                                        </tr> 
                                    </tbody>    
                                </table>
                            </v-flex>
                        </v-layout>
                        <v-layout class="pa-3">
                            <v-flex class="ml-5 pt-5" xs2>
                                <p class="caption text--secondary">Dates Covered</p>
                            </v-flex>
                            <v-flex class="mt-3" xs2>
                                <v-text-field
                                    v-model="courier.normalRatesEffectivityDate"
                                    disabled
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-flex>
                            <v-flex class="mt-3 ml-3" xs2>
                                <v-text-field
                                    v-model="courier.normalRatesExpiryDate"
                                    disabled
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout class="pa-3 mt-5">
                            <v-flex xs10></v-flex>
                            <v-flex xs1>
                                <v-btn @click="summaryDialog = false" tile depressed>Cancel</v-btn>
                            </v-flex>
                            <v-flex xs>
                                <v-btn @click="submit" class="ml-5 white--text" color="green" tile depressed>Submit</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios'
import { api } from '@/configs/api'
export default {
    name: 'summary-page',
    props: {
        courier: Object,
    },
    data() {
        return {
            summaryDialog: false,
            islands: [],
            snackbar: {
                message: '',
                type: 'success',
                alert: false
            },
            ninjaVanEffectivityMenu: false,
            ninjaVanExpiryDateMenu: false,
            jntEffectivityMenu: false,
            jntExpiryMenu: false,
        }
    },
    mounted() {
        this.fetchIslandGroup()
        
        console.log(this.courier)
    },
    methods: {
        fetchIslandGroup() {
            const self = this

            this.$axios.get(`/lovs`, {
                params: { groupCode: 'ISLAND' }
            }).then(result => {
                self.islands = result.data.data   
            })
        },
        submit() {
            const self = this
            let foundIdentical = self.courier.selectedOrigin.jnt.routes.some(v => self.courier.selectedOrigin.ninjaVan.routes.includes(v));
    
            if(foundIdentical) {
                alert('Same route for NinjaVan and J&t Express is prohibited.')
            } else {
                self.summaryDialog = false
            }
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
td {
  text-align: center;
  vertical-align: middle;
}
</style>