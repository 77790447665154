<template>
    <v-container fluid>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Role Management</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="createRole" v-on="on"><v-icon>add_circle_outline</v-icon></v-btn>
                    </template>
                    <span>Create Roles</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <!-- show-select -->
        <!-- v-model="selected" -->
        <v-data-table
            dense 
            show-expand
            single-expand
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            :footer-props="footerProps"
            @update:options="loadNextPage"
        >
            <template v-slot:expanded-item="{ item }">
                <td colspan="12" class="ma-0 pa-0">
                    <v-card flat tile>
                        <v-row>
                            <v-col cols="2">
                                <h4>Permissions:</h4>
                            </v-col>

                            <v-col cols="9">
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Menu Title</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(route, routeIndex) in item.routes" :key="route.id">
                                            <td>{{ route.routeName }}</td>
                                            <td>
                                                <template v-if="route.routedef.isMenu">
                                                    <v-icon>widgets</v-icon> {{route.routedef.meta.title}}
                                                </template>
                                            </td>
                                            <td>
                                                <v-btn small icon color="error" @click="removePermission(item, route.id, routeIndex)">
                                                    <v-icon>remove_circle_outline</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </td>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="editPermission(item)" v-on="on">edit</v-icon>
                    </template>
                    <span>Edit Role</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="addPermission(item)" v-on="on">library_add</v-icon>
                    </template>
                    <span>Add Permissions</span>
                </v-tooltip>
            </template>

            <template v-slot:item.active="{ item }">
                <v-simple-checkbox :value="Boolean(item.active)" disabled></v-simple-checkbox>
            </template>
        </v-data-table>

        <v-dialog v-model="showDialog" width="500" height="500">
            <CreateEditRole 
                v-if="action === 'createrole' || action === 'editrole'"
                :action="action"
                :role-details="role"
                v-on:action-role-response="showActionResponse"
                v-on:close-create-form="showDialog=false"
            ></CreateEditRole>
                
            <AddPermission
                v-if="action === 'addpermission'"
                :role-details="role"
                v-on:add-perm-response="showActionResponse"
                v-on:close-add-permission-form="showDialog=false"
            ></AddPermission>
        </v-dialog>
    </v-container>
</template>

<script>
import {api} from '@/configs/api'
import CreateEditRole from './CreateEdit'
import AddPermission from './AddPermission'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        CreateEditRole,
        AddPermission,
        Snackbar
    },
    data() {
        return {
            loading: true,
            action: null,
            showDialog: false,
            records: [],
            role: {},
            footerProps: {
                itemsPerPageOptions: [25, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 25
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            serverItemTotal: 0,
            headers: [
                { text: 'Role Code', value: 'roleCode', sortable: false },
                { text: 'Name', value: 'name', sortable: false },
                { text: 'Active', value: 'active', align: 'center', sortable: false },
                { text: 'Actions', value: 'action', align: 'center', sortable: false },
                { text: 'Show Details', value: 'data-table-expand', align: 'center', },
            ]
        }
    },
    watch: {
        showDialog(value) {
            if(!value) this.loadRoles()
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        }
    },
    mounted() {
        this.loadRoles()
    },
    methods: {
        loadRoles() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`${api}/admin/roles/paged`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.count
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        createRole() {
            this.showDialog = true
            this.role = null
            this.action = 'createrole'
        },
        addPermission(role) {
            this.showDialog = true
            this.role = role
            this.action = 'addpermission'
        },
        editPermission(role) {
            this.showDialog = true
            this.role = role
            this.action = 'editrole'
        },
        showActionResponse(message, success = true) {
            this.showDialog = false
            this.showSnackbarMessage(message, success)
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        removePermission(item, permissionId, index) {
            let self = this
            this.$axios.delete(`${api}/admin/role/permission/${permissionId}`)
            .then(({data}) => {
                self.showSnackbarMessage(data.message)
                item.routes.splice(index, 1)
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        loadNextPage() {
            this.loadRoles()
        }
    }
}
</script>