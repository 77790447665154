<template>
	<div id="app">
		<router-view v-if="ready" :key="$route.fullPath" />
	</div>
</template>

<script>
import firebase from 'firebase'
import { mapActions, mapGetters } from 'vuex'
export default {
	data: () => ({
		user: null,
		ready: false
	}),

	computed: {
		...mapGetters({
			token: "token",
		})
	},
	mounted() {
		this.ready = true;
	}
}
</script>

<style>
/* @import "https://cdn.materialdesignicons.com/3.2.89/css/materialdesignicons.min.css"; */
@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
</style>