<template>
    <v-container class="pa-0">
        <v-card>
            <v-toolbar dense text short color="blue">
                <v-toolbar-title>Upload Form</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-tooltip left>
                        <template v-slot:activator="{on}">
                            <v-btn @click="restoreOptions = true" icon v-on="on"><v-icon>mdi-restore-alert</v-icon></v-btn>
                        </template>
                        <span>Restore Previous Pricing</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{on}">
                            <v-btn icon v-on="on" @click="emitToHistoryDialog"><v-icon>mdi-table-clock</v-icon></v-btn>
                        </template>
                        <span>View Upload History</span>
                    </v-tooltip>
                    <v-btn
                        icon 
                        @click="emitToUploadDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-file-input accept=".csv" label="File input" type="file" id="fileUploader" ref="fileUploader" truncate-length="1000" @change="onFileSelect"></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="info" @click="upload">Upload</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="restoreOptions" hide-overlay persistent width="300">
            <v-card>
                <v-toolbar dense flat tileshort>
                    <v-toolbar-title class="body-1">Previous uploads</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-select v-model="selectedDate" :items="distinctDates" clearable outlined></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="restoreOptions = false">Cancel</v-btn>
                    <v-btn color="info" @click="upload">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="savingDialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Saving data
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Papa from 'papaparse'
import { api } from '@/configs/api'
import axios from 'axios'
import moment from 'moment-business-days'
import _ from 'lodash'

export default {
    name: 'mc-upload',
    props: {
        history: Array
    },
    data () {
        return {
            uploadedProducts: [],
            savingDialog: false,
            restoreOptions: false,
            distinctDates: [],
            dates: [],
            selectedDate: null
        }
    },
    computed: {
        
    },
    watch: {
        selectedDate(value) {
            const self = this
            let date = encodeURIComponent(self.selectedDate)

            axios.get(`${api}/mc/uploadedOn/${date}`)
            .then(response => {
                self.uploadedProducts = response.data.results
                console.log(self.uploadedProducts)
            })
            .catch((error) => {
                self.$emit('showErrorMessage', error.message)
            })
        },
    },
    mounted () {
        this.getDistinctDates()
    },
    methods: {
        getDistinctDates() {
            const self = this
            
            axios.get(`${api}/mc/distinct/dates`)
            .then(response => {
                let dates = response.data.results
                dates.forEach(async (date) => {
                    self.distinctDates.push(await formatDate(date.uploadedAt))
                })
            })
            .catch((error) => {
                self.$emit('showErrorMessage', error.message)
            })
        },
        emitToUploadDialog() {
            const self = this
            self.$emit('uploadDialog', false)
        },
        emitToHistoryDialog() {
            const self = this
            self.$emit('historyDialog', true)
        },
        onFileSelect(file) {
            const self = this

            Papa.parse(file, {
                header: true,
                transformHeader: function(value) {
                    return _.camelCase(value)
                },
                complete: function(results) {
                    self.uploadedProducts = results.data
                }
            })
        },
        upload() {
            const self = this
            self.savingDialog = true

            axios.put(`${api}/mc/upload/specifications`, {
                data: self.uploadedProducts
            })
            .then((result) => {
                let responseData = result.data

                self.uploadedProducts = []
                self.$emit('uploadDialog', false)
                self.savingDialog = false
                setTimeout(() => {
                    alert("Uploaded successfully!")
                    self.$emit('fetchProducts')
                }, 1000) 
            })
            .catch((error) => {
                self.$emit('showErrorMessage', error.message);
            })
        },
    }
}

async function formatDate(date) {
    return date = moment(date).format("YYYY/MM/DD hh:mm:ss")
}
</script>