<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="emitToHistoryDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Upload History</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-col cols="12" md="12">
                <v-data-table
                    :headers="headers"
                    :items="history"
                    :items-per-page="20"
                >
                </v-data-table>
            </v-col>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment-business-days'

export default {
    name: 'mc-upload-history',
    props: {
        history: Array,
    },
    data () {
        return {
            headers: [
                { text: 'Row Number', value: 'id', sortable: false },
                { text: 'Material Number', value: 'materialNumber', sortable: false },
                { text: 'Model', value: 'model', sortable: false },
                { text: 'SRP', value: 'srp', sortable: false },
                { text: 'Uploaded Date', value: 'uploadedAt', sortable: false },
                { text: 'Uploaded By', value: 'uploadedBy', sortable: false }
            ]
        }
    },
    computed: {
        
    },
    mounted () {
        
    },
    methods: {
        emitToHistoryDialog() {
            const self = this
            self.$emit('historyDialog', false)
        },
    }
}
</script>