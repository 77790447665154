<template>
    <div>
        <div class="container-fluid mt--2">
            <div class="row">
                <div class="col-md-12">
                    <v-card>
                        <v-card-title class="headline">Reviews for {{ product.purchaseDescription }}</v-card-title>
                            <v-card-text>
                                <div class="row">
                                    <span class="heading mt-1 ml-3 title">User Ratings</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-rating
                                                v-on="on" 
                                                color="yellow darken-2" 
                                                v-model="averageRating"
                                                background-color="grey darken-1"
                                                empty-icon="$ratingFull"
                                                dense
                                                class="mt-1"
                                            ></v-rating>
                                        </template>
                                        <span>{{ averageRating }}</span>
                                    </v-tooltip>
                                </div>
                                <span v-if="averageRating" class="body-1">{{ averageRating }} average based on {{ reviews.length }} reviews.</span>
                                <span v-else>No ratings available yet.</span>
                                <div class="row px-3 col-6 mb-5">
                                    <div class="side">
                                        <div>5 stars</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div id="bar-5" style="height: 18px; background-color: #4CAF50;"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>{{ rating.five }}</div>
                                    </div>
                                    <div class="side">
                                        <div>4 stars</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div id="bar-4" style="height: 18px; background-color: #2196F3;"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>{{ rating.four }}</div>
                                    </div>
                                    <div class="side">
                                        <div>3 stars</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div id="bar-3" style="height: 18px; background-color: #00bcd4;"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>{{ rating.three }}</div>
                                    </div>
                                    <div class="side">
                                        <div>2 stars</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div id="bar-2" style="height: 18px; background-color: #ff9800;"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>{{ rating.two }}</div>
                                    </div>
                                    <div class="side">
                                        <div>1 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div id="bar-1" style="height: 18px; background-color: #f44336;"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>{{ rating.one }}</div>
                                    </div>
                                </div>
                                <v-card v-for="entry in reviews" :key="entry.id" class="mb-5">
                                    <v-card-title>
                                        <v-rating 
                                            color="yellow darken-2" 
                                            v-model="entry.productRating"
                                            background-color="grey darken-1"
                                            empty-icon="$ratingFull"
                                            dense
                                        ></v-rating>
                                        <span class="body-1 ml-2 mt-1">{{ entry.productRating }} out of 5 stars</span>
                                        <v-spacer></v-spacer>
                                        <span class="body-1">{{ entry.product }}</span>
                                    </v-card-title>
                                    <v-card-subtitle class="body-1 ml-2">
                                        {{ entry.name }} - {{ entry.createdAt }}
                                    </v-card-subtitle>
                                    <v-card-text class="body-2 ml-2">
                                        {{ entry.message }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <router-link :to="`/review/item/${entry.reviewId}`">View conversation</router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-pagination
                                        :circle="circle"
                                        :disabled="disabled"
                                        :length="pageCount"
                                        :next-icon="nextIcon"
                                        :prev-icon="prevIcon"
                                        :page="page"
                                    ></v-pagination>
                                </div> 
                            </v-card-actions>
                    </v-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'
import axios from 'axios'
import { api } from '@/configs/api'
import moment from 'moment-business-days'
import isEmpty from 'is-empty'
export default {
    data () {
        return {
            product: {},
            reviews: [],
            circle: false,
            disabled: false,
            length: 5,
            nextIcon: 'navigate_next',
            nextIcons: ['mdi-chevron-right', 'mdi-arrow-right', 'mdi-menu-right'],
            prevIcon: 'navigate_before',
            prevIcons: ['mdi-chevron-left', 'mdi-arrow-left', 'mdi-menu-left'],
            page: 1,
            totalVisible: 5,
            pageCount: 0,
            totalRatingWeight: 0,
            totalRatingsCount: 0,
            ratings: [],
            rating: {
                "five": 0,
                "four": 0,
                "three": 0,
                "two": 0,
                "one": 0
            },
        }
    },
    mounted() {
        let self = this
        self.getProduct(self.$route.params.sku)
        self.getReview(self.$route.params.sku)
        self.pageCount = Math.floor(self.reviews.length / self.length) + 1
    },
    computed: {
        averageRating: function() {
            return this.totalRatingWeight/this.totalRatingsCount
        },
    },
    methods: {
        getProduct(sku) {
            const self = this

            axios.get(`${api}/review/product/${sku}`)
            .then(response => {
                self.product = response.data.result
            })
            .catch((error) => {
               self.showErrorMessage(error.message)
           })
        },
        getRatings(sku) {
            const self = this

            axios.get(`${api}/review/ratings/${sku}`)
            .then(response => {
                self.ratings = response.data.results

                self.ratingDetails()
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getReview(sku) {
            let self = this

           axios.get(`${api}/review/entries/${sku}`)
           .then(response => {
               self.reviews = response.data.results

               self.getRatings(sku)
           })
           .catch((error) => {
               self.showErrorMessage(error.message)
           })
        },
        ratingDetails() {
            const self = this

            self.ratings.forEach(rate => {
                if(rate.rating) {
                    self.totalRatingWeight += rate.rating
                    self.totalRatingsCount++

                    if(rate.rating == 5) self.rating.five++
                    else if(rate.rating == 4) self.rating.four++
                    else if(rate.rating == 3) self.rating.three++
                    else if(rate.rating == 2) self.rating.two++
                    else if(rate.rating == 1) self.rating.one++
                }
            })
            self.progressBar()
            self.getUserStarRating()
        },
        progressBar() {
            const self = this
            let bar5Element = document.getElementById('bar-5')
            let bar4Element = document.getElementById('bar-4')
            let bar3Element = document.getElementById('bar-3')
            let bar2Element = document.getElementById('bar-2')
            let bar1Element = document.getElementById('bar-1')

            bar5Element.style.width = percentageValue(self.rating.five)
            bar4Element.style.width = percentageValue(self.rating.four)
            bar3Element.style.width = percentageValue(self.rating.three)
            bar2Element.style.width = percentageValue(self.rating.two)
            bar1Element.style.width = percentageValue(self.rating.one)
        },
        getUserStarRating() {
            const self = this
            let user = {}
            let userRating = {}
            let emptyTest = false

            for(let i = 0; i < self.reviews.length; i++) {
                user = self.reviews[i].user
                userRating = self.ratings.find(u => u.userId === user)
                emptyTest = !isEmpty(userRating)

                if(emptyTest) self.reviews[i].productRating = userRating.rating
                else self.reviews[i].productRating = 0
            }
        },
        showErrorMessage(message) {
            alert(message)
        },
    }
}

function percentageValue(value) {
    return value = `${(Math.round(value / 10) * 10)}%`
}
</script>

<style>
body{margin-top:20px;}

.comment-wrapper .panel-body {
    max-height:650px;
    overflow:auto;
}

.comment-wrapper .media-list .media img {
    width:64px;
    height:64px;
    border:2px solid #e5e7e8;
}

.comment-wrapper .media-list .media {
    border-bottom:1px dashed #efefef;
    margin-bottom:25px;
}

/* Three column layout */
.side {
  float: left;
  width: 15%;
  margin-top: 10px;
}

.middle {
  float: left;
  width: 70%;
  margin-top: 10px;
}

/* Place text to the right */
.right {
  text-align: right;
}

/* The bar container */
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

/* Individual bars */
/* .bar-5 {width: 60%; height: 18px; background-color: #4CAF50;}
.bar-4 {width: 30%; height: 18px; background-color: #2196F3;}
.bar-3 {width: 10%; height: 18px; background-color: #00bcd4;}
.bar-2 {width: 4%; height: 18px; background-color: #ff9800;}
.bar-1 {width: 15%; height: 18px; background-color: #f44336;} */

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
  .side, .middle {
    width: 100%;
  }
  /* Hide the right column on small screens */
  .right {
    display: none;
  }
}
</style>