<template>
    <v-card flat>
        <v-card-title>Add Branch</v-card-title>
        <v-divider></v-divider>
        <v-form ref="addBranchForm" class="px-8">
            <v-autocomplete
                chips
                deletable-chips
                multiple
                clearable
                hide-selected
                hide-no-data
                label="Branches"
                item-text="readableName"
                item-value="id"
                placeholder="Start typing to Search..."
                :loading="isLoading"
                :search-input.sync="search"
                :items="items"
                :rules="[rules.required]"
                v-model="selected.branchIds"
            >
            </v-autocomplete>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveUserBranches">Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'

export default {
    props: {
    //     userRoles: { type: Array },
        userId: { type: Number }
    },

    data() {
        return {
            isLoading: false,
            search: null,
            items: [],
            selected: {
                branchIds: []
            },
            rules: {
                required(value) {
                    return value.length < 1 ? "Required" : true
                }
            }
        }
    },

    watch: {
        search: {
            handler: 'loadBranches'
        }
    },

    methods: {
        loadBranches(nameSearchValue) {
            let self = this
            if (this.isLoading) return

            this.isLoading = true

            this.$axios.get(`${api}/branches`, {
                params: {
                    branchType: 1,
                    readableName: nameSearchValue
                //     exclude: this.userRoles
                }
            }).then(response => {
                let responseData = response.data.data

                self.items = responseData
            }).finally(() => (self.isLoading = false))
        },
        saveUserBranches() {
            let self = this
            let userId = this.userId
            const isValid = this.$refs.addBranchForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/user/${userId}/branches`, this.selected)
            .then(response => {
                let responseData = response.data
                self.$emit('add-branch-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('add-branch-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.addBranchForm.reset()
            this.$refs.addBranchForm.resetValidation()
            this.$emit('cancel-add-branch')
        }
    }
}
</script>