<template>
    <div>
        <v-card>
            <v-toolbar flat color="info" dark>
                <v-toolbar-title>Offer Acceptance Information</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="emitToFormDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <h2 class="body-1 mb-5">Please make sure that details are correct.</h2>
                <v-form ref="viewingForm" v-if="!checkIfForViewing">
                    <v-text-field v-model="loanDetails.applicationId" outlined label="Application ID" disabled></v-text-field>

                    <v-text-field outlined label="Customer Name" disabled v-model="loanDetails.customerName"></v-text-field>

                    <v-text-field outlined label="Contact Number" disabled v-model="loanDetails.contactNumber"></v-text-field>

                    <v-text-field outlined label="Registered Address" disabled v-model="order.fullAddress"></v-text-field>

                    <v-text-field outlined label="Shipping Address" disabled v-model="order.deliveryAddress"></v-text-field>

                    <v-text-field outlined label="Age" disabled v-model="loanDetails.age"></v-text-field>

                    <v-text-field outlined label="Property Status" disabled v-model="loanDetails.propertyStatus"></v-text-field>

                    <v-text-field outlined label="Monthly Income" disabled v-model="loanDetails.monthlyIncome"></v-text-field>

                    <v-text-field outlined label="Source Income" disabled v-model="loanDetails.incomeSource"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Company" disabled v-model="loanDetails.employmentCompany"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Address" disabled v-model="loanDetails.employmentAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Landline No." disabled v-model="loanDetails.employmentLandlineNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Designation" disabled v-model="loanDetails.employmentDesignation"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Status" disabled v-model="loanDetails.employmentStatus"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Work Tenure" disabled v-model="loanDetails.employmentWorkTenure"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Name" disabled v-model="loanDetails.businessName"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Address" disabled v-model="loanDetails.businessAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Contact No." disabled v-model="loanDetails.businessContactNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Operation in Years" disabled v-model="loanDetails.businessOperationYears"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Remittance'" outlined label="Originating Country" disabled v-model="loanDetails.originatingCountry"></v-text-field>

                    <v-card flat outlined class="mb-5">
                        <v-card-title class="body-2 grey--text">Attachments</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <span class="ml-3">Type</span>
                                    <v-col cols="12" md="12">
                                        <span>Valid ID:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Income:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Billing:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Latest Photo:</span>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <span><v-icon>mdi-magnify</v-icon>File Name</span>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment1">{{ loanDetails.attachment1Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment2">{{ loanDetails.attachment2Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment3">{{ loanDetails.attachment3Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.photo">{{ loanDetails.photoFilename}}</a></span>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-text-field outlined label="Asset Condition" disabled v-model="loanDetails.productType" ></v-text-field>

                    <v-text-field outlined label="Model" disabled v-model="loanDetails.orderName"></v-text-field>

                    <v-text-field outlined label="Color" disabled v-model="loanDetails.orderColor"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Percent" disabled v-model="loanDetails.downpaymentPercent"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Amount" disabled v-model="loanDetails.downpaymentAmount"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.downpayment" outlined label="Accepted Down Payment Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="loanDetails.applicationDate"
                                label="Application Date"
                                append-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                disabled
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="loanDetails.applicationDate" no-title scrollable></v-date-picker>
                    </v-menu>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.financedAmount" outlined label="Finance Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.term" outlined label="Term (Months)" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.monthlyAmortization" outlined label="Monthly Amortization" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.PNAmount" outlined label="PN Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.NOCDReferenceNo" outlined label="NOCD Reference No." disabled></v-text-field>

                    <v-textarea v-model="loanDetails.remarks" auto-grow outlined label="Remarks(Optional)" disabled></v-textarea>
                </v-form>

                <v-form ref="actionForm" v-if="checkIfForChecking">
                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.applicationId" outlined label="Application ID" disabled></v-text-field>

                    <v-text-field outlined label="Customer Name" disabled v-model="loanDetails.customerName"></v-text-field>

                    <v-text-field outlined label="Contact Number" disabled v-model="loanDetails.contactNumber"></v-text-field>

                    <v-text-field outlined label="Registered Address" disabled v-model="order.fullAddress"></v-text-field>

                    <v-text-field outlined label="Shipping Address" disabled v-model="order.deliveryAddress"></v-text-field>

                    <v-text-field outlined label="Age" disabled v-model="loanDetails.age"></v-text-field>

                    <v-text-field outlined label="Property Status" disabled v-model="loanDetails.propertyStatus"></v-text-field>

                    <v-text-field outlined label="Monthly Income" disabled v-model="loanDetails.monthlyIncome"></v-text-field>

                    <v-text-field outlined label="Source Income" disabled v-model="loanDetails.incomeSource"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Company" disabled v-model="loanDetails.employmentCompany"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Address" disabled v-model="loanDetails.employmentAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Landline No." disabled v-model="loanDetails.employmentLandlineNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Designation" disabled v-model="loanDetails.employmentDesignation"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Status" disabled v-model="loanDetails.employmentStatus"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Work Tenure" disabled v-model="loanDetails.employmentWorkTenure"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Name" disabled v-model="loanDetails.businessName"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Address" disabled v-model="loanDetails.businessAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Contact No." disabled v-model="loanDetails.businessContactNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Operation in Years" disabled v-model="loanDetails.businessOperationYears"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Remittance'" outlined label="Originating Country" disabled v-model="loanDetails.originatingCountry"></v-text-field>

                    <v-card flat outlined class="mb-5">
                        <v-card-title class="body-2 grey--text">Attachments</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <span class="ml-3">Type</span>
                                    <v-col cols="12" md="12">
                                        <span>Valid ID:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Income:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Billing:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Latest Photo:</span>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <span><v-icon>mdi-magnify</v-icon>File Name</span>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment1" download>{{ loanDetails.attachment1Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment2" download>{{ loanDetails.attachment2Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment3" download>{{ loanDetails.attachment3Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.photo" download>{{ loanDetails.photoFilename}}</a></span>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-text-field outlined label="Asset Condition" disabled v-model="loanDetails.productType" ></v-text-field>

                    <v-text-field outlined label="Model" disabled v-model="loanDetails.orderName"></v-text-field>

                    <v-text-field outlined label="Color" disabled v-model="loanDetails.orderColor"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Percent" disabled v-model="loanDetails.downpaymentPercent"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Amount" disabled v-model="loanDetails.downpaymentAmount"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.downpayment" outlined label="Accepted Down Payment Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="loanDetails.applicationDate"
                                label="Application Date"
                                append-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                disabled
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="loanDetails.applicationDate" no-title scrollable></v-date-picker>
                    </v-menu>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.financedAmount" outlined label="Finance Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.term" outlined label="Term (Months)" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.monthlyAmortization" outlined label="Monthly Amortization" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.PNAmount" outlined label="PN Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.NOCDReferenceNo" outlined label="NOCD Reference No." disabled></v-text-field>

                    <v-textarea v-model="loanDetails.remarks" auto-grow outlined label="Remarks(Optional)" :disabled="!ohRole"></v-textarea>

                    <!-- ROLE MUST SET TO HD -->
                    <v-select ref="actionOptions" v-if="role.roleCode == 'HD' && order.status == 'For Document Checking'" v-model="action" :items="actions" placeholder="Please select action..." outlined :rules="[actionRules.required]"></v-select> 

                    <div v-if="selectedReasons.length && action == 'For Revision' && order.status == 'For Document Checking'">
                        <span class="body-2">Reason for update:</span><br>
                        <span class="caption" v-for="reason in selectedReasons" :key="reason">
                            &#8226; {{ reason }}<br>
                        </span>
                    </div>
                </v-form>

                <v-form ref="hdForm" v-if="checkIfApproved && checkIfForViewing && !checkIfPaid">
                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.applicationId" outlined label="Application ID" disabled></v-text-field>

                    <v-text-field outlined label="Customer Name" disabled v-model="loanDetails.customerName"></v-text-field>

                    <v-text-field outlined label="Contact Number" disabled v-model="loanDetails.contactNumber"></v-text-field>

                    <v-text-field outlined label="Registered Address" disabled v-model="order.fullAddress"></v-text-field>

                    <v-text-field outlined label="Shipping Address" disabled v-model="order.deliveryAddress"></v-text-field>

                    <v-text-field outlined label="Age" disabled v-model="loanDetails.age"></v-text-field>

                    <v-text-field outlined label="Property Status" disabled v-model="loanDetails.propertyStatus"></v-text-field>

                    <v-text-field outlined label="Monthly Income" disabled v-model="loanDetails.monthlyIncome"></v-text-field>

                    <v-text-field outlined label="Source Income" disabled v-model="loanDetails.incomeSource"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Company" disabled v-model="loanDetails.employmentCompany"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Address" disabled v-model="loanDetails.employmentAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Landline No." disabled v-model="loanDetails.employmentLandlineNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Designation" disabled v-model="loanDetails.employmentDesignation"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Status" disabled v-model="loanDetails.employmentStatus"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Work Tenure" disabled v-model="loanDetails.employmentWorkTenure"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Name" disabled v-model="loanDetails.businessName"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Address" disabled v-model="loanDetails.businessAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Contact No." disabled v-model="loanDetails.businessContactNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Operation in Years" disabled v-model="loanDetails.businessOperationYears"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Remittance'" outlined label="Originating Country" disabled v-model="loanDetails.originatingCountry"></v-text-field>

                    <v-card flat outlined class="mb-5">
                        <v-card-title class="body-2 grey--text">Attachments</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <span class="ml-3">Type</span>
                                    <v-col cols="12" md="12">
                                        <span>Valid ID:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Income:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Billing:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Latest Photo:</span>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <span><v-icon>mdi-magnify</v-icon>File Name</span>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment1">{{ loanDetails.attachment1Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment2">{{ loanDetails.attachment2Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment3">{{ loanDetails.attachment3Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.photo">{{ loanDetails.photoFilename}}</a></span>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-text-field outlined label="Asset Condition" disabled v-model="loanDetails.productType" ></v-text-field>

                    <v-text-field outlined label="Model" disabled v-model="loanDetails.orderName"></v-text-field>

                    <v-text-field outlined label="Color" disabled v-model="loanDetails.orderColor"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Percent" disabled v-model="loanDetails.downpaymentPercent"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Amount" disabled v-model="loanDetails.downpaymentAmount"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.downpayment" outlined label="Accepted Down Payment Amount" :rules="[hdRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="loanDetails.applicationDate"
                                label="Application Date"
                                append-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                disabled
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="loanDetails.applicationDate" no-title scrollable></v-date-picker>
                    </v-menu>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.financedAmount" outlined label="Finance Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.term" outlined label="Term (Months)" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.monthlyAmortization" outlined label="Monthly Amortization" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.PNAmount" outlined label="PN Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.NOCDReferenceNo" outlined label="NOCD Reference No." disabled></v-text-field>

                    <v-textarea v-model="loanDetails.remarks" auto-grow outlined label="Remarks(Optional)" :disabled="!ohRole"></v-textarea>

                    <v-textarea v-if="beforeSubmitForReEvaluate" v-model="loanDetails.reasonForCorrection" auto-grow outlined label="Reason for Correction" :disabled="!ohRole" :rules="[hdRules.required]"></v-textarea>

                    <!-- ROLE MUST SET TO HD -->
                    <v-select ref="actionOptions" v-if="role.roleCode == 'HD' && order.status == 'For Document Checking'" v-model="action" :items="actions" placeholder="Please select action..." outlined :rules="[actionRules.required]"></v-select> 

                    <div v-if="selectedReasons.length && action == 'For Revision' && order.status == 'For Document Checking'">
                        <span class="body-2">Reason for update:</span><br>
                        <span class="caption" v-for="reason in selectedReasons" :key="reason">
                            &#8226; {{ reason }}<br>
                        </span>
                    </div>
                </v-form>

                <v-form ref="evaluationForm" v-if="checkIfForEvaluation && role.roleCode === 'CA'">
                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.applicationId" outlined label="Application ID" disabled></v-text-field>

                    <v-text-field outlined label="Customer Name" disabled v-model="loanDetails.customerName"></v-text-field>

                    <v-text-field outlined label="Contact Number" disabled v-model="loanDetails.contactNumber"></v-text-field>

                    <v-text-field outlined label="Registered Address" disabled v-model="order.fullAddress"></v-text-field>

                    <v-text-field outlined label="Shipping Address" disabled v-model="order.deliveryAddress"></v-text-field>

                    <v-text-field outlined label="Age" disabled v-model="loanDetails.age"></v-text-field>

                    <v-text-field outlined label="Property Status" disabled v-model="loanDetails.propertyStatus"></v-text-field>

                    <v-text-field outlined label="Monthly Income" disabled v-model="loanDetails.monthlyIncome"></v-text-field>

                    <v-text-field outlined label="Source Income" disabled v-model="loanDetails.incomeSource"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Company" disabled v-model="loanDetails.employmentCompany"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Address" disabled v-model="loanDetails.employmentAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Landline No." disabled v-model="loanDetails.employmentLandlineNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Designation" disabled v-model="loanDetails.employmentDesignation"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Status" disabled v-model="loanDetails.employmentStatus"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Work Tenure" disabled v-model="loanDetails.employmentWorkTenure"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Name" disabled v-model="loanDetails.businessName"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Address" disabled v-model="loanDetails.businessAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Contact No." disabled v-model="loanDetails.businessContactNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Operation in Years" disabled v-model="loanDetails.businessOperationYears"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Remittance'" outlined label="Originating Country" disabled v-model="loanDetails.originatingCountry"></v-text-field>

                    <v-card flat outlined class="mb-5">
                        <v-card-title class="body-2 grey--text">Attachments</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <span class="ml-3">Type</span>
                                    <v-col cols="12" md="12">
                                        <span>Valid ID:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Income:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Billing:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Latest Photo:</span>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <span><v-icon>mdi-magnify</v-icon>File Name</span>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment1">{{ loanDetails.attachment1Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment2">{{ loanDetails.attachment2Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment3">{{ loanDetails.attachment3Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.photo">{{ loanDetails.photoFilename}}</a></span>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-text-field outlined label="Asset Condition" disabled v-model="loanDetails.productType" ></v-text-field>

                    <v-text-field outlined label="Model" disabled v-model="loanDetails.orderName"></v-text-field>

                    <v-text-field outlined label="Color" disabled v-model="loanDetails.orderColor"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Percent" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" v-model="loanDetails.downpaymentPercent" :rules="[caRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Amount" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" v-model="loanDetails.downpaymentAmount" :rules="[caRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.downpayment" outlined label="Accepted Down Payment Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="loanDetails.applicationDate"
                                label="Application Date"
                                append-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                disabled
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="loanDetails.applicationDate" no-title scrollable></v-date-picker>
                    </v-menu>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.financedAmount" outlined label="Finance Amount" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" :rules="[caRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-select :items="loanTerms" v-model="loanDetails.term" outlined label="Term (Months)" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" :rules="[caRules.required]"></v-select>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.monthlyAmortization" outlined label="Monthly Amortization" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" :rules="[caRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.PNAmount" outlined label="PN Amount" :disabled="!checkIfForEvaluation && role.roleCode != 'CA'" :rules="[caRules.required]"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.NOCDReferenceNo" outlined label="NOCD Reference No." disabled></v-text-field>

                    <v-textarea v-model="loanDetails.remarks" auto-grow outlined label="Remarks(Optional)" disabled></v-textarea>

                    <v-textarea v-if="loanDetails.reasonForCorrection" v-model="loanDetails.reasonForCorrection" auto-grow outlined label="Reason for Correction" disabled></v-textarea>
                </v-form>

                <v-form ref="updatingForm" v-if="checkIfPaid && checkIfForViewing && role.roleCode == 'HD'">
                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.applicationId" outlined label="Application ID" :disabled="role.roleCode != 'HD ' && !checkIfPaid && !checkIfForViewing" :rules="[hdUpdatingRules.required]"></v-text-field>

                    <v-text-field outlined label="Customer Name" disabled v-model="loanDetails.customerName"></v-text-field>

                    <v-text-field outlined label="Contact Number" disabled v-model="loanDetails.contactNumber"></v-text-field>

                    <v-text-field outlined label="Registered Address" disabled v-model="order.fullAddress"></v-text-field>

                    <v-text-field outlined label="Shipping Address" disabled v-model="order.deliveryAddress"></v-text-field>

                    <v-text-field outlined label="Age" disabled v-model="loanDetails.age"></v-text-field>

                    <v-text-field outlined label="Property Status" disabled v-model="loanDetails.propertyStatus"></v-text-field>

                    <v-text-field outlined label="Monthly Income" disabled v-model="loanDetails.monthlyIncome"></v-text-field>

                    <v-text-field outlined label="Source Income" disabled v-model="loanDetails.incomeSource"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Company" disabled v-model="loanDetails.employmentCompany"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Address" disabled v-model="loanDetails.employmentAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Landline No." disabled v-model="loanDetails.employmentLandlineNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Designation" disabled v-model="loanDetails.employmentDesignation"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Status" disabled v-model="loanDetails.employmentStatus"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Salaried'" outlined label="Employment Work Tenure" disabled v-model="loanDetails.employmentWorkTenure"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Name" disabled v-model="loanDetails.businessName"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Address" disabled v-model="loanDetails.businessAddress"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Contact No." disabled v-model="loanDetails.businessContactNumber"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Self-employed'" outlined label="Business Operation in Years" disabled v-model="loanDetails.businessOperationYears"></v-text-field>

                    <v-text-field v-if="loanDetails.incomeSource == 'Remittance'" outlined label="Originating Country" disabled v-model="loanDetails.originatingCountry"></v-text-field>

                    <v-card flat outlined class="mb-5">
                        <v-card-title class="body-2 grey--text">Attachments</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <span class="ml-3">Type</span>
                                    <v-col cols="12" md="12">
                                        <span>Valid ID:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Income:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Proof of Billing:</span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span>Latest Photo:</span>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <span><v-icon>mdi-magnify</v-icon>File Name</span>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment1">{{ loanDetails.attachment1Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment2">{{ loanDetails.attachment2Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.attachment3">{{ loanDetails.attachment3Filename}}</a></span>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <span><a :href="loanDetails.photo">{{ loanDetails.photoFilename}}</a></span>
                                    </v-col>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-text-field outlined label="Asset Condition" disabled v-model="loanDetails.productType" ></v-text-field>

                    <v-text-field outlined label="Model" disabled v-model="loanDetails.orderName"></v-text-field>

                    <v-text-field outlined label="Color" disabled v-model="loanDetails.orderColor"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Percent" disabled v-model="loanDetails.downpaymentPercent"></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field outlined label="Down Payment Amount" disabled v-model="loanDetails.downpaymentAmount"></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.downpayment" outlined label="Accepted Down Payment Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="loanDetails.applicationDate"
                                label="Application Date"
                                append-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                :disabled="role.roleCode != 'HD ' && !checkIfPaid && !checkIfForViewing"
                                :rules="[hdUpdatingRules.required]"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="loanDetails.applicationDate" no-title scrollable></v-date-picker>
                    </v-menu>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.financedAmount" outlined label="Finance Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.term" outlined label="Term (Months)" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.monthlyAmortization" outlined label="Monthly Amortization" disabled></v-text-field>

                    <!-- ROLE MUST SET TO CA -->
                    <v-text-field v-model="loanDetails.PNAmount" outlined label="PN Amount" disabled></v-text-field>

                    <!-- ROLE MUST SET TO HD -->
                    <v-text-field v-model="loanDetails.NOCDReferenceNo" outlined label="NOCD Reference No." :disabled="role.roleCode != 'HD ' && !checkIfPaid && !checkIfForViewing" :rules="[hdUpdatingRules.required]"></v-text-field>

                    <v-textarea v-model="loanDetails.remarks" auto-grow outlined label="Remarks(Optional)" disabled></v-textarea>
                </v-form>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn text outlined color="error" @click="reset">
                    Reset
                </v-btn> -->
                <v-btn v-if="role.roleCode == 'HD' && order.status == 'For Document Checking'" color="info" @click="action === 'Disapproved' || action === 'Loan Cancelled' ? confirmDialog = true : beforeSubmitAfterCheckingDialog = true" :disabled="loading">
                    Submit
                </v-btn>
                <v-btn v-if="role.roleCode == 'CA' && order.status == 'For Loan Evaluation' && loanDetails.status == 'Pending'" color="info" @click="beforeSubmitForLoanEvaluatedDialog = true" :disabled="loading">
                    Save
                </v-btn>
                <v-btn v-if="role.roleCode == 'HD' && loanDetails.status == 'Loan Approved' && order.status === 'For Loan Evaluation'" color="error" @click="beforeSubmitForReEvaluate = !beforeSubmitForReEvaluate">
                   {{ beforeSubmitForReEvaluate ? 'CANCEL' : 'RE-EVALUATE' }}
                </v-btn>
                <v-btn v-if="role.roleCode == 'HD' && loanDetails.status == 'Loan Approved' && order.status === 'For Loan Evaluation'" color="info" @click="beforeSubmitDownpaymentDialog = true" :disabled="loading">
                    Save
                </v-btn>
                <v-btn v-if="role.roleCode == 'HD' && loanDetails.status == 'Loan Approved' && order.status == 'Offer Accepted'" color="info" @click="beforeSubmitForUpdateFormDialog = true" :disabled="loading">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="updateDialog" hide-overlay persistent width="550">
            <v-card class="mx-auto" max-width="600">
                <v-toolbar
                    flat
                    color="info"
                    dark
                >
                    <v-toolbar-title>Loan Application Update</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="updateDialogClear"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <h2 class="body-1 mb-2 mt-5">Select the reason for revision:</h2>
                    <v-chip-group
                        v-model="selectedReasons"
                        :value="updateReason"
                        column
                        multiple
                        class="mb-5"
                    >
                        <v-chip filter outlined :value="reason" v-for="reason in updateReason" :key="reason">{{ reason }}</v-chip>
                    </v-chip-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" :disabled="!selectedReasons.length" color="info" @click="updateDialog = false">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDialog"
            class="mx-auto"
            max-width="290"
            persistent
            :no-click-animation="true"
        >
            <v-card>
                <v-toolbar flat color="error" dark>
                    <v-toolbar-title v-if="action === 'Disapproved'">Loan Disapproval</v-toolbar-title>
                    <v-toolbar-title v-else-if="action === 'Loan Cancelled'">Loan Cancellation</v-toolbar-title>
                    <v-toolbar-title v-else>Warning</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="confirmDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5 body-1">
                    <span>Are you sure you want to decline this application?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="confirmDialog = false">Cancel</v-btn>
                    <v-btn @click="decline" color="error" :loading="loading" :disabled="loading">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="beforeSubmitAfterCheckingDialog"
            class="mx-auto"
            max-width="290"
            persistent
            :no-click-animation="true"
        >
            <v-card>
                <v-toolbar flat color="info" dark>
                    <v-toolbar-title>Please confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="beforeSubmitAfterCheckingDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5 body-1">
                    <span>Are you sure you want to submit this application?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="beforeSubmitAfterCheckingDialog = false">Cancel</v-btn>
                    <v-btn @click="submitForEvaluation" color="info" :loading="loading" :disabled="loading">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="beforeSubmitForLoanEvaluatedDialog"
            class="mx-auto"
            max-width="290"
            persistent
            :no-click-animation="true"
        >
            <v-card>
                <v-toolbar flat color="info" dark>
                    <v-toolbar-title>Please confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="beforeSubmitForLoanEvaluatedDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5 body-1">
                    <span>Are you sure you want to submit this application?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="beforeSubmitForLoanEvaluatedDialog = false">Cancel</v-btn>
                    <v-btn @click="loanEvaluated" color="info" :loading="loading" :disabled="loading">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="beforeSubmitDownpaymentDialog"
            class="mx-auto"
            max-width="290"
            persistent
            :no-click-animation="true"
        >
            <v-card>
                <v-toolbar flat color="info" dark>
                    <v-toolbar-title>Please confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="beforeSubmitDownpaymentDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5 body-1">
                    <span>Are you sure you want to submit this application?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="beforeSubmitDownpaymentDialog = false">Cancel</v-btn>
                    <v-btn @click="acceptDownpayment" color="info" :loading="loading" :disabled="loading">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="beforeSubmitForUpdateFormDialog"
            class="mx-auto"
            max-width="290"
            persistent
            :no-click-animation="true"
        >
            <v-card>
                <v-toolbar flat color="info" dark>
                    <v-toolbar-title>Please confirm</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="beforeSubmitForUpdateFormDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5 body-1">
                    <span>Are you sure you want to submit this application?</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="beforeSubmitForUpdateFormDialog = false">Cancel</v-btn>
                    <v-btn @click="updateForm" color="info" :loading="loading" :disabled="loading">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import isEmpty from 'is-empty'
import axios from 'axios'
import _ from 'lodash'
import { api } from '@/configs/api'

export default {
    props: {
        loanDetails: Object,
        user: Object,
        order: Object,
        payment: Object
    },
    data: () => ({
        loader: null,
        loading: false,
        updateDialog: false,
        confirmDialog: false,
        beforeSubmitAfterCheckingDialog: false,
        beforeSubmitForLoanEvaluatedDialog: false,
        beforeSubmitDownpaymentDialog: false,
        beforeSubmitForUpdateFormDialog: false,
        beforeSubmitForReEvaluate: false,
        downpayment: null,
        actions: ["Approved", "For Revision", "Disapproved", "Loan Cancelled"],
        action: "",
        updateReason: ["Mismatched customer information between the form and attachment","Unreachable contact number","Unreadable/invalid attachment - Valid ID", "Unreadable/invalid attachment - Proof of Billing", "Unreadable/invalid attachment - Proof of Income"],
        reason: "",
        selectedReasons: [],
        menu: false,
        role: {},
        loanTerms: [6, 12, 18, 24, 30, 36],
        actionRules: {
            required: value => !!value || "Required"
        },
        hdRules: {
            required: value => !!value || "Required"
        },
        caRules: {
            required: value => !!value || "Required"
        },
        hdUpdatingRules: {
            required: value => !!value || "Required"
        },
    }),
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
      action(value) {
        if(value == 'For Revision') {
            this.updateDialog = true
        } else {
            this.selectedReasons = []
        }
      },
    },
    computed: {
        ohRole() {
            return this.role.roleCode == 'HD' ? true : false
        },
        checkIfForChecking() {
            return this.role.roleCode == 'HD' && this.order.status == 'For Document Checking' ? true : false
        },
        checkIfForEvaluation() {
            return this.order.status == 'For Loan Evaluation' ? true : false
        },
        checkIfPaid() {
            return this.payment.status == 'Completed' ? true : false
        },
        checkIfApproved() {
            return this.role.roleCode == 'HD' && this.loanDetails.status == 'Loan Approved' ? true : false 
        },
        checkIfForViewing() {
            let status = ['For Document Checking', 'For Loan Evaluation', 'Offer Accepted']
            return status.includes(this.order.status)
        }
    },
    mounted() {
        this.getRoleDetails(this.user.uid)
    },
    methods: {
        getRoleDetails(userId) {
			const self =  this
			
			axios.get(`${api}/role/${userId}`)
			.then(response => {
				self.role = response.data.result
			})
			.catch((error) => {
				self.showErrorMessage(error.message, false)
			})
			
        },
        emitToFormDialog() {
            this.$emit('openFormDialog', false)
        },
        reset () {
            const self = this
            self.$refs.form.reset()
        },
        submitForEvaluation() {
            const self = this
            let orderId = self.$route.params.orderId

            if(self.$refs.actionForm.validate()) {
                if(self.action == 'Approved') {
                    console.log('approved')
                    self.loader = 'loading'

                    axios.put(`${api}/order/mcloan/prescreening/action`, {
                        orderId: orderId,
                        status: "For Loan Evaluation",
                        remarks: self.loanDetails.remarks
                    })
                    .then((response) => {
                        self.action = ""
                        self.loader = null
                        self.$emit('successAlert')
                        self.$emit('openFormDialog', false)
                        self.$emit('getOrder', orderId)
                        self.$emit('show', true)
                    })
                    .catch((error) => {
                        self.showErrorMessage(error.message, false)
                    })

                } else if(self.action == 'For Revision') {
                    console.log('for revision')
                    self.loader = 'loading'

                    axios.put(`${api}/order/mcloan/prescreening/action`, {
                        orderId: orderId,
                        status: "For Loan Application Update",
                        reasonForUpdate: self.selectedReasons.toString(),
                        remarks: self.loanDetails.remarks
                    })
                    .then((response) => {
                        self.action = ""
                        self.loader = null
                        self.$emit('successAlert')
                        self.selectedReasons = []
                        self.$emit('openFormDialog', false)
                        self.$emit('getOrder', orderId)
                        self.$emit('show', true)
                    })
                    .catch((error) => {
                        self.showErrorMessage(error.message, false)
                    })
                } else if(self.action == "" || self.action == null) {
                    self.beforeSubmitAfterCheckingDialog = false
                    setTimeout(() => {
                        alert('Please select an action first!')
                    }, 500)
                } else {
                    self.confirmDialog = true
                }  
            }  
        },
        loanEvaluated() {
            const self = this
            self.loader = 'loading'
            let orderId = self.$route.params.orderId

            if(self.$refs.evaluationForm.validate()) {
                axios.put(`${api}/order/mcloan/save/oai`, {
                    status: 'Loan Approved',
                    orderId: orderId,
                    downpaymentPercent: self.loanDetails.downpaymentPercent,
                    downpaymentAmount: self.loanDetails.downpaymentAmount,
                    financedAmount: self.loanDetails.financedAmount,
                    term: self.loanDetails.term,
                    monthlyAmortization: self.loanDetails.monthlyAmortization,
                    PNAmount: self.loanDetails.PNAmount
                })
                .then((response) => {
                    let responseData = response.data
                    self.loader == null
                    self.$emit('successAlert')
                    self.$emit('openFormDialog', false)
                    self.$emit('getOrder', orderId)
                    self.$emit('show', true)
                })
                .catch((error) => {
                    self.showErrorMessage(error.message, false)
                })
            }
        },
        acceptDownpayment() {
            const self = this
            self.loader = 'loading'
            let isForReApproval = self.beforeSubmitForReEvaluate && self.loanDetails.reasonForCorrection
            let orderId = self.$route.params.orderId

            if(isForReApproval) {
                axios.put(`${api}/order/ca/reapproval`, {
                    orderId: orderId,
                    status: 'Pending',
                    reasonForCorrection: self.loanDetails.reasonForCorrection
                })
                .then((response) => {
                    let responseData = response.data
                    self.$emit('openFormDialog', false)
                    setTimeout(() => {
                        self.$emit('getOrder', orderId)
                        self.$emit('show', true)
                    }, 2000)
                })
                .catch((error) => {
                    self.showErrorMessage(error.message)
                })
            } else {
                if(self.$refs.hdForm.validate()) {
                    if(parseFloat(self.loanDetails.downpayment) < self.loanDetails.downpaymentAmount) {
                        alert('Accepted amount insufficient, add downpayment or application will be cancelled')
                        self.loader = null
                    } else {
                        axios.put(`${api}/order/mcloan/downpayment/update`, {
                            orderId: orderId,
                            downpayment: self.loanDetails.downpayment,
                            remarks: self.loanDetails.remarks
                        })
                        .then((response) => {
                            let responseData = response.data
                            self.$emit('openFormDialog', false)
                            setTimeout(() => {
                                self.loader = null
                                self.$emit('successAlert')
                                self.$emit('getOrder', orderId)
                                self.$emit('show', true)
                            }, 2000)
                        })
                        .catch((error) => {
                            self.showErrorMessage(error.message, false)
                        })
                    }
                }
            }
        },
        updateForm() {
            const self = this
            self.loader = 'loading'
            let orderId = self.$route.params.orderId

            if(self.$refs.updatingForm.validate()) {
                axios.put(`${api}/order/mcloan/complete/oai`, {
                    status: "For Stock Allocation",
                    branch: self.order.branch,
                    orderId: orderId,
                    applicationId: self.loanDetails.applicationId,
                    applicationDate: self.loanDetails.applicationDate,
                    NOCDReferenceNo: self.loanDetails.NOCDReferenceNo,
                })
                .then((response) => {
                    let responseData = response.data
                    self.$emit('openFormDialog', false)
                    setTimeout(() => {
                        self.loader = null
                        self.$emit('successAlert')
                        self.$emit('getOrder', orderId)
                        self.$emit('show', true)
                    }, 2000)
                })
                .catch((error) => {
                    self.showErrorMessage(error.message, false)
                })
            }
        },
        decline() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            let status = this.action === 'Disapproved' ?
                { status: "Loan Disapproved", applicationStatus: "Loan Disapproved" } :
                { status: "Loan Cancelled", applicationStatus: 'Loan Cancelled' }

            axios.put(`${api}/order/mcloan/prescreening/action`, {
                orderId: orderId,
                remarks: self.loanDetails.remarks,
                ...status
            })
            .then((response) => {
                self.action = ""
                self.loader = null
                self.confirmDialog = false
                self.$emit('errorAlert', response.data.message, false)
                self.$emit('openFormDialog', false)
                self.$emit('getOrder', orderId)
                self.$emit('show', true)
            })
            .catch((error) => {
                self.showErrorMessage(error.message, false)
            })
        },
        openAttachment(url) {
            const self = this
            window.open(url)
        },
        updateDialogClear() {
            const self = this
            self.updateDialog = false
            self.selectedReasons = []
        },
        showErrorMessage(message, success=true) {
            self.$emit(success ? 'successAlert' : 'errorAlert', message, success)
        }
    }
}
</script>