<template>
	<v-app id="app" light>
		<v-navigation-drawer  v-model="drawer" app>
			<div class="col-6 collapse-brand">
				<router-link to="/">
					<img :src="logo" width="200px;">
				</router-link>
			</div>
			<v-list dense>
				<v-list-item v-for="item in items" :key="item.title" @click="goToPage(item.routeName)">
					<v-list-item-action>
						<v-icon :color="item.color">{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content class="body-1 font-weight-regular">
						<v-list-item-title ref="title">{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
		</v-navigation-drawer>
		<v-content>
			<v-container fluid fill-height>
				<v-layout align-start justify-center>
					<v-flex>
						<router-view :key="$route.fullPath"></router-view>
					</v-flex>
				</v-layout>
			</v-container>
		</v-content>
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			drawer: null,
		}
	},

	computed: {
		...mapGetters({
			items: 'userMenus'
		})
	},

	props: {
		logo: {
			default: '/img/brand/green.png',
			description: 'Sidebar app logo'
		}
	},

    methods: {
		goToPage(routeName) {
			this.$router.push({ name: routeName })
		}
    }
}
</script>