<template>
	<v-app>
		<v-container style="height: 400px;">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col class="subtitle-1 text-center" cols="12">Logging out</v-col>
					<v-col cols="6">
						<v-progress-linear color="blue accent-4" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>
import firebase from "firebase";
export default {
	mounted() {
		let self = this
		self.$store.dispatch("logout");

		setTimeout(() => {
			firebase.auth().signOut()
			.then(() => {
				self.$router.push({ name: "Login" });
			})
			
		}, 3000);
	}
};
</script>