import Vue from 'vue'
import Vuex from 'vuex'
import Website from './Website'
import { api } from '../configs/api'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState()
	],
	state: {
		user: {},
		loggedIn: false,
		token: null,
		menus: []
	},

	getters: {
		user(state) {
			return state.user;
		},
		loggedIn(state) {
			return state.loggedIn;
		},
		token(state) {
			return state.token;
		},
		userMenus(state) {
			return state.menus;
		},
		bearerToken(state) {
			return `Bearer ${state.token}`
		}
	},

	mutations: {
		SET_LOGGED_IN(state, value) {
			state.loggedIn = value;
		},
		SET_TOKEN(state, payload) {
			state.token = payload;
			axios.defaults.headers.common['Authorization'] = this.getters.bearerToken
		},
		SET_USER(state, data) {
			state.user = data;
		},
		SET_MENUS(state, menus){
			let _menus = [
				{ title: 'Logout', icon: 'person_pin', routeName:"Logout" }
			], menuIndex = 0

			menus.forEach(rec => {
				_menus.splice(menuIndex, 0, rec)
				menuIndex++
			})

			state.menus = _menus
		}
	},

	actions: {
		fetchUser({ commit }, email) {
			if (email) {
				axios.get(`${api}/user/${email}`)
				.then((result) => {
					let response = result.data
					commit("SET_LOGGED_IN", true);
					commit("SET_USER", response);
				})
			} else {
				commit("SET_LOGGED_IN", false);
				commit("SET_USER", null);
			}
		},
		login({commit, dispatch}, { token, user }){
			commit("SET_LOGGED_IN", true);
			commit("SET_USER", user);
			commit("SET_TOKEN", token);
			dispatch('setMenu')
		},
		async setMenu({ commit, getters }) {
			const userId = getters.user.uid

			let response = await axios.get(`${api}/admin/user/menus/${userId}`)
			let items = []
			let data = response.data.data

			data.forEach(rec => {
				items.push({
					title: rec.routeMeta.title,
					icon: rec.routeMeta.icon,
					color: rec.routeMeta.color,
					routeName: rec.routeName
				})
			})

			commit('SET_MENUS', items)
		},
		logout({ commit }) {
			commit("SET_LOGGED_IN", false);
			commit("SET_USER", null);
			commit("SET_TOKEN", null);
			commit("SET_MENUS", []);
		},
		async setToken({ commit }, payload) {
			commit("SET_TOKEN", payload);
		},
	},
	modules: {
		Website
	},
})
