<template>
    <div>
        <v-select 
            :items="items"
            :return-object="isObjectValue"
            :value="defaultValue"
            label="Municipalities"
            item-text="name"
            item-value="code"
            dense
            @change="changeValue"
        />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'select-minicipalities',
    model: {
        prop: 'defaultValue',
        event: 'change'
    },
    props: {
        isObjectValue: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: [Object, String]
        },
        filters: {
            type: Object
        },
        province: {
            type: String
        }
    },
    data() {
        return {
            items: []
        }
    },
    watch: {
        province:{
            handler(val) {
                console.log(val);
                if(!val) return
                this.loadRecords(`/address/province/${val}/municipalities`)
            },
            immediate: true
        }
    },
    mounted() {
        if(!this.province) this.loadRecords()
    },
    methods: {
        loadRecords(path = '/address/municipalities'){
            let self = this

            axios.get(path, {
                params: this.filters
            })
            .then(response => {
                let responseData = response.data

                if(!responseData.success) alert(responseData.message)

                self.items = responseData.data
            }).catch((error) => {
                alert(error.message)
            })
        },
        changeValue(value){
            this.$emit('change', value)
        }
    }
}
</script>