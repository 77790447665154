<template>
	<div class="container-fluid mt--7">
		<div class="row">
			<div class="col-md-12">
				<v-card>
					<v-card-title>Order #: {{ $route.params.orderId }}
						<v-spacer></v-spacer>
						<v-btn color="info" @click="$router.push('/payments')">Back</v-btn>
					</v-card-title>
					<v-card-subtitle>Customer Name: {{ order.customerName }}</v-card-subtitle>
					<v-form>
						<v-container>
							<v-row>
								<v-col cols=12  md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Branch:</label>
									<v-text-field
										v-model="order.branchName"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col v-if="payment.type == 'Full Payment'" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Checkout Amount:</label>
									<v-text-field
										v-model="order.total"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col v-if="order.paymentMethod == 'Pay in Installment'" cols=12  md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Downpayment Amount:</label>
									<v-text-field
										v-model="dpAmount"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Total LTO Registration Fee:</label>
									<v-text-field
										v-model="order.ltoRegistrationFees"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Online Transaction Fee:</label>
									<v-text-field
										v-model="order.onlineTransactionFee"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<!-- <v-col v-if="order.paymentMethod == 'Pay in Installment' && payment.mode == 'Cash over the Counter'" cols=12  md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Downpayment Amount:</label>
									<v-text-field
										v-model="payment.amount"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col> -->
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Discount:</label>
									<v-text-field
										v-model="order.discount"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Shipping Fee:</label>
									<v-text-field
										v-model="order.shipping"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col v-if="order.paymentMethod == 'Pay in Installment'" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Amount Receivable:</label>
									<v-text-field
										v-model="order.total"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Response Code:</label>
									<v-text-field
										v-model="payment.responseCode"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Response Message:</label>
									<v-text-field
										v-model="payment.responseMessage"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Status:</label>
									<v-text-field
										v-model="order.status"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Payment Method:</label>
									<v-text-field
										v-model="payment.mode"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col v-if="payment.responseId" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Response ID / PT Number:</label>
									<v-text-field
										v-model="payment.responseId"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col v-if="!payment.responseId" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Response ID / PT Number:</label>
									<v-text-field
										v-model="response_id"
										dense
										outlined
										:rules="[rules.required]"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Order Date:</label>
									<v-text-field
										v-model="order.checkedOutAt"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Payment Status:</label>
									<v-text-field
										v-model="payment.status"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col v-if="payment.mode == 'Pay Online'" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Response Timestamp:</label>
									<v-text-field
										v-model="payment.timestamp"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
								<v-col v-if="payment.mode == 'Cash over the Counter'" cols=12 md="4" class="pt-0 pb-0 pr-0">
									<label class="body-2">Modified Date:</label>
									<v-text-field
										v-model="payment.acceptedAt"
										dense
										outlined
										disabled
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
				<v-row>
					<v-col md="9">
						<v-card>
							<v-card-title>Items</v-card-title>
								<v-simple-table class="table">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Quantity</th>
											<th scope="col">Publish Price</th>
											<th scope="col">Discount</th>
											<th scope="col">Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="entry in entries" :key="entry.uid">
											<td>{{ entry.name }}</td>
											<td>{{ parseInt(entry.quantity) }}</td>
											<td>{{ entry.price | thousandSeparator }}</td>
											<td>{{ entry.discount | thousandSeparator }}</td>
											<td>{{ entry.total | thousandSeparator }}</td>
										</tr>
									</tbody>
								</v-simple-table>
						</v-card>
					</v-col>
					<v-col md="3">
						<v-card min-height="160">
							<v-card-title v-show="show">Payment</v-card-title>
								<Control :order="order" :user="user" :payment="payment" :response_id="response_id" v-on:getOrder="getOrder" v-on:alert="alert = true"></Control>
						</v-card>
					</v-col>
				</v-row>
				<Snackbar v-model="alert" type="success" :message="successSnackBarMessage" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-business-days'
import { api } from '@/configs/api'
import DOMParser from 'dom-parser'
import xmlToJson from 'xml-to-json-stream'
import Control from '@/components/Payment/Control'
import Snackbar from '@/components/Snackbar'

export default {
	components: {
		Control,
		Snackbar
	},
    data: () => ({
        alert: false,
        order: {},
        payments: [],
		payment: {},
		response: {},
		entries: [],
		response_id: "",
		show: true,
		dpAmount: 0,
		successSnackBarText: "was updated",
        rules: {
          required: value => !!value || 'Required.',
        },
    }),
    computed: {
		...mapGetters(['user']),

		successSnackBarMessage: function () {
            return `Order #${this.order.orderId} ${this.successSnackBarText}`
		},
	},
    mounted() {
		this.getOrder(this.$route.params.orderId)
    },
    methods: {
        getOrder(orderId) {
			const self = this
			
            axios.get(`${api}/payment/order/view/${orderId}`)
            .then((result) => {
				self.order = result.data.data
				self.getBranch(self.order.branch)
				self.getPayment(orderId)
				self.getOrderEntries(orderId)
				self.show = true

				if(self.order.branch != null) self.getBranch(self.order.branch)
				else self.order.branchName = 'TCM MM Pasig'
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getPayment(orderId) {
			const self = this
			
			if(self.order.paymentMethod == 'Pay Online') {
				axios.get(`${api}/payment/details/payOnline/${orderId}`)
				.then(result => {
					self.payment = result.data.data
					self.xmlDoc()
				})
				.catch((error) => {
					self.showErrorMessage(error.message)
				})
			} else {
				if(self.order.downpaymentMethod === 'Pay Online') {
					axios.get(`${api}/payment/details/payOnline/${orderId}`)
					.then(result => {
						self.payment = result.data.data
						self.dpAmount = self.payment.applications.dpAmount
						self.xmlDoc()
					})
					.catch((error) => {
						self.showErrorMessage(error.message)
					})
				} else {
					axios.get(`${api}/payment/details/cashOverTheCounter/${orderId}`)
					.then(result => {
						self.payment = result.data.data
						self.dpAmount = self.payment.applications.dpAmount
					})
					.catch((error) => {
						self.showErrorMessage(error.message)
					})
				}
			}
        },
        getOrderEntries(orderId) {
            const self = this

            axios.get(`${api}/payment/order/entries/${orderId}`)
            .then((result) => {
				self.entries = result.data.data
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
		},
		getBranch(branchUid) {
            const self = this

            axios.get(`${api}/order/branch/${branchUid}`)
            .then((result) => {
				self.order.branchName = result.data.result.readableName
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
		xmlDoc() {
			const self = this
			let content = self.payment.responseDocument
			let buffer = new Buffer(content, "base64");
			let decodedContent = buffer.toString("ascii");
			let parser = new DOMParser()
			let xmlDoc = parser.parseFromString(decodedContent, "text/xml")

			self.parseXMLDoc(xmlDoc.rawHTML)
		},
		parseXMLDoc(xml) {
			const self = this
			let parser = xmlToJson({ attribute: false })

			parser.xmlToJson(xml, (err, json) => {
				if(err) {
					alert(err)
				} else {
					self.response = json.ServiceResponseWPF
					self.payment.responseId = self.response.application.response_id
					self.payment.timestamp = moment(self.response.application.timestamp).format("YYYY-MM-DD hh:mm:ss")
					self.payment.paymentReference = self.response.responseStatus.processor_response_id
					self.payment.responseCode = self.response.responseStatus.response_code
					self.payment.responseMessage = self.response.responseStatus.response_message
				}
			})
		},
		showErrorMessage(message) {
            alert(message)
		},
    }
}
</script>

<style>
.border-class {
    border-top: 5px solid blue !important;
}
</style>