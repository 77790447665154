<template>
    <v-container fluid fill-height>
        <v-layout row wrap justify-space-between>
            <v-flex xs12 sm10 md lg8>
                <v-card>
                    <v-card-text>
                        <v-simple-table>
                            <template>
                                <thead>
                                    <tr>
                                        <th class="text-left">Code</th>
                                        <th class="text-left">Description</th>
                                        <th class="text-left">Type</th>
                                        <th class="text-left">Subtract to</th>
                                        <th class="text-left">Value</th>
                                        <th class="text-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in discounts" :key="item.id">
                                        <td><router-link :to="`/discount/view/${item.uid}`">{{item.code}}</router-link></td>
                                        <td>{{item.description}}</td>
                                        <td>{{item.type}}</td>
                                        <td>{{item.minuend}}</td>
                                        <td>{{item.value}}</td>
                                        <td>{{item.status === 1 ? 'Active' : 'Inactive'}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-flex>

            <v-flex xs12 sm2 md4 lg4>
                <v-card class="ml-5">
                    <v-app-bar>
                        <v-toolbar-title>Create discount</v-toolbar-title>
                    </v-app-bar>

                    <v-container>
                        <v-row dense>
                            <v-col cols="12">
                                <v-text-field placeholder="Discount code" v-model="newDiscount.code" prepend-inner-icon="keyboard_arrow_right" solo></v-text-field>
                                
                                <v-text-field class="pt-0" placeholder="Description" v-model="newDiscount.description" prepend-inner-icon="keyboard_arrow_right" solo></v-text-field>

                                <v-text-field placeholder="Type" v-model="newDiscount.type" prepend-inner-icon="keyboard_arrow_right" solo></v-text-field>

                                <v-card>
                                    <span class="ml-2">Subtract to</span>
                                    <v-radio-group v-model="newDiscount.minuend">
                                        <v-radio class="ml-2" name="minuend" value="price" label="Price"></v-radio>
                                        <v-radio class="ml-2" name="minuend" value="LTORegistrationFee" label="LTO Registration Fee"></v-radio>
                                    </v-radio-group>
                                </v-card>

                                <v-text-field class="input-group-alternative mt-5" placeholder="Value" v-model="newDiscount.value" prepend-inner-icon="keyboard_arrow_right" solo></v-text-field>

                                <div>
                                    <v-btn type="primary" class="ma-2" outlined color="blue" @click="submit">Submit</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-dialog v-model="dialog" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Loading data
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import firebase from 'firebase'
import uuidv4 from 'uuid/v4'
import axios from 'axios'
import { api } from '@/configs/api'
export default {
    data: () => ({
        dialog: false,
        discounts: [],
        newDiscount: {
            uid: null,
            code: null,
            description: null,
            minuend: 'price',
            type: null,
            value: null
        },
    }),
    mounted() {
        this.getDiscounts()
    },
    methods: {
        getDiscounts() {
            const self = this
            self.dialog = true

            axios.get(`${api}/discounts`)
            .then((result) => {
                self.discounts = result.data.data
                self.dialog = false
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        async submit() {
            let self = this
            self.newDiscount.uid = uuidv4()

            let request = firebase.functions().httpsCallable('newDiscounts')
            let result = await request(self.newDiscount)

            self.newDiscount = {
                uid: null,
                code: null,
                description: null,
                minuend: 'price',
                type: null,
                value: null
            }

            self.getDiscounts()
        },
        showErrorMessage(message) {
            alert(message)
        },
    }
}
</script>
