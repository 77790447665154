<template>
	<div>
		<div class="container-fluid mt--7">
            <div class="row">
				<div class="col-md-6">
					<v-card header-tag="header" footer-tag="footer">
						<v-card-title>New Product</v-card-title>
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Name</label>
									<v-text-field
										type="text" v-model="newProduct.name" :rules="[rules.required]" placeholder="Product name" outlined
									></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="ccnumber">Department</label>
									<v-select v-model="newProduct.department" :items="departmentOptions" size="sm" class="mt-3" outlined placeholder="Product department" :rules="[rules.required]"></v-select>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="ccnumber">Variant Attribute</label>
									<v-select
									v-model="newProduct.variantId" :items="variantOptions" size="sm" class="mt-3"
									outlined
									placeholder="Variant attribute"
									:rules="[rules.required]"
									></v-select>
								</v-col>
							</v-row>
							<v-row class="ml-2" v-if="newProduct.variantId !== 3" dense>
								<v-col cols="12" sm="6" md="6">	
									<label for="name">Variant Value</label>
									<v-text-field type="text" v-model="newProduct.variantValue" :rules="[rules.required]" placeholder="Variant value" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Variant Display Name</label>
									<v-text-field type="text" v-model="newProduct.variantName" :rules="[rules.required]" placeholder="Product display name" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" v-if="newProduct.variantId === 3 || newProduct.variantId === 4" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="ccnumber">Size</label>
									<v-select
									v-model="newProduct.size" :items="sizeOptions" size="sm" class="mt-3"
									outlined
									placeholder="Product size"
									:rules="[rules.required]"
									></v-select>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="ccnumber">Short description</label>
									<v-textarea v-model="newProduct.shortDescription" :rules="[rules.required]" placeholder="Describe the product" outlined :rows="3" :max-rows="6" auto grow></v-textarea>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Category</label>
									<v-text-field type="text" v-model="newProduct.category" :rules="[rules.required]" placeholder="Product category" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Manufacturer</label>
									<v-text-field type="text" v-model="newProduct.manufacturer" :rules="[rules.required]" placeholder="Product manufacturer" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Material Code</label>
									<v-text-field type="number" v-model="newProduct.materialCode" :rules="[rules.required]" placeholder="Product material code" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">SKU</label>
									<v-text-field type="number" v-model="newProduct.sku" :rules="[rules.required]" placeholder="Product SKU" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Price</label>
									<v-text-field type="number" v-model="newProduct.price" :rules="[rules.required]" placeholder="Price" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" v-if="newProduct.department === 1" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">LTO Registration Fee</label>
									<v-text-field type="text" v-model="newProduct.LTORegistrationFee" placeholder="LTO Registration Fee" outlined :rules="[rules.required]"></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="name">Photo</label>
									<v-text-field type="url" v-model="newProduct.photo" :rules="[rules.required]" placeholder="Product Photo URL" outlined></v-text-field>
								</v-col>
							</v-row>
							<v-row class="ml-2" v-if="newProduct.department === 1" dense>
								<v-col cols="12" sm="6" md="6">
									<label for="ccnumber">Product Template</label>
									<v-select v-model="newProduct.productTemplate" :items="productTemplates" size="sm" class="mt-3" outlined :rules="[rules.required]"></v-select>
								</v-col>
							</v-row>
							<json-editor v-if="schemaReady" class="json-editor ml-5" ref="JsonEditor" :schema="schema" v-model="newProduct.longDescription">
								<button ref="removeThisButton"></button>
							</json-editor>
							<v-row v-if="newProduct.name" class="ml-2 pb-2" dense>
								<div class="text-center">
									<v-btn class="ma-2" outlined color="blue" @click="upload">Submit</v-btn>
								</div>
							</v-row>
						</v-form>
					</v-card>
				</div>
			</div>
			<div>
				<Snackbar v-model="alert" type="success" message="New product was successfully added." />
			</div>
		</div>
	</div>
</template>

<script>
import slug from 'slug'
import firebase from 'firebase'
import { VueEditor } from 'vue2-editor'
import JsonEditor from 'vue-json-ui-editor'

export default {
	data () {
		return {
			newProduct: {
				slug: null,
				name: '',
				variantName: null,
				variantId: null,
				variantValue: '',
                shortDescription: '',
				longDescription: {},
				department: 0,
                category: '',
                manufacturer: '',
				materialCode: '',
				sku: null,
				LTORegistrationFee: 0,
				photo: '',
				status: 1,
				productTemplate: null,
				size: null,
				price: 0,
			},
			variants: [],
			variantOptions: [],
			templates: [],
			productTemplates: [],
			attributes: null,
			departments: [],
			departmentOptions: [],
			schema: {},
			schemaReady: false,
			alert: false,
			rules: {
				required: value => !!value || 'This field is required',
			},
			valid: true,
			sizes: [],
			sizeOptions: [],
		}
	},
	async mounted() {
		let self = this
		
		let getVariants = firebase.functions().httpsCallable('getVariants')
		let result = await getVariants()
		self.variants = result.data

		let getProductTemplates = firebase.functions().httpsCallable('getProductTemplates')
		let templates = await getProductTemplates()
		self.templates = templates.data

		let getDepartments = firebase.functions().httpsCallable('getDepartments')
		let departments = await getDepartments()
		self.departments = departments.data

		let getSizes = firebase.functions().httpsCallable('getSizes')
		let sizes = await getSizes()
		self.sizes = sizes.data
	},
	methods: {
		/* No file was being uploaded atm */

		// onFileChange(e) {
		// 	let self = this

		// 	const files = e.target.files
		// 	if(files[0] !== undefined) {
		// 		self.file.name = files[0].name
		// 		if(self.file.name.lastIndexOf(".") <= 0) {
		// 			return
		// 		}
		// 		const fileReader = new FileReader ()
		// 		fileReader.readAsDataURL(files[0])
		// 		fileReader.addEventListener("load", () => {
		// 			self.file.url = fileReader.result
		// 			self.file.blob = files[0]
		// 			self.file.extension = self.file.name.split('.').pop()
		// 			self.file.exists = true
		// 		})
		// 	} else {
		// 		self.file.name = null
		// 		self.file.extension = null
		// 		self.file.url = null
		// 		self.file.blob = null
		// 		self.file.exists = false
		// 	}
		// },

		async upload() {
			let self = this

			if(self.$refs.form.validate()) {
				self.newProduct.slug = slug(self.newProduct.name.toLowerCase())
				self.newProduct.variantSlug = slug(self.newProduct.variantName.toLowerCase())
				self.newProduct.price = parseFloat(self.newProduct.price)
				self.newProduct.LTORegistrationFee = parseFloat(self.newProduct.LTORegistrationFee)
				self.newProduct.status = parseFloat(self.newProduct.status)
				self.newProduct.longDescription = JSON.stringify(self.newProduct.longDescription)

				let newProduct = firebase.functions().httpsCallable('newProduct')
				let result = await newProduct(self.newProduct)
				console.log(result)
				
				self.alert = true
				self.$router.push('/products')
			}
		}
	},
	
    components: {
        VueEditor,
		JsonEditor 
	},
	
	watch: {
		variants(after, before) {
			let self = this
			let list = []
			after.forEach(variant => {
				list.push({
					text: variant.attribute,
					value: variant.id
				})

				self.variantOptions = list
			})
		},
		'newProduct.name': {
            handler: function (after, before) {
                this.newProduct.variantName = `${after} - ${this.newProduct.variantValue}` 
            },
            deep: true
        },
		'newProduct.variantValue': {
            handler: function (after, before) {
                this.newProduct.variantName = `${this.newProduct.name} - ${after}`
            },
            deep: true
		},
		templates(after, before) {
			let self = this
			let list = []
			after.forEach(template => {
				list.push({
					text: template.name,
					value: template.id
				})

				self.productTemplates = list
			})
		},
		'newProduct.productTemplate': {
            handler: function (after, before) {
				let index = this.templates.map(e => e.id).indexOf(after)
				this.schema = JSON.parse(this.templates[index].attributes)
				this.schemaReady = true
				setTimeout(()=> {
					this.$refs.removeThisButton.remove()
				}, 2000)
            },
            deep: true
		},
		departments(after, before) {
			let self = this
			let list = []
			after.forEach(department => {
				list.push({
					text: department.name,
					value: department.id
				})

				self.departmentOptions = list
			})
		},
		'newProduct.variantId': {
			handler: function (after, before) {
				console.log(after)
			}
		},
		sizes(after, before) {
			let self = this
			let list = []
			after.forEach(size => {
				list.push({
					text: size.attribute,
					value: size.id
				})

				self.sizeOptions = list
			})
		}
	}
}
</script>

<style>
.json-editor form label {
		display: block;
		margin-bottom: 10px;
	}
	.json-editor form label span {
		display: inline-block;
		margin-right: 10px;
		width: 20%;
	}
	.json-editor form label input {
		border: 1px solid black !important;
		border-radius: 5px;
	}
	.sub-title {
		font-weight: bold;
	}
</style>

