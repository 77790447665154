<template>
    <div>
        <v-select 
            :items="items"
            :return-object="isObjectValue"
            :value="defaultValue"
            :append-icon="dropdownIcon"
            label="Brands"
            item-value="value"
            item-text="text"
            dense
            @change="changeValue"
        />
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'select-enabled-branches',
    model: {
        prop: 'defaultValue',
        event: 'change'
    },
    props: {
        isObjectValue: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: [Array, String]
        },
        filters: {
            type: Object
        },
        dropdownIcon: {
            type: String,
            default: '$dropdown'
        }
    },
    data() {
        return {
            items: []
        }
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems(){
            let self = this

            axios.get('/mc/enabled/brands')
            .then(response => {
                let responseData = response.data

                if(!responseData.success) alert(responseData.message)

                self.items = [
                    { text: "- All Brands -", value: null },
                    ...responseData.data
                ]
            }).catch((error) => {
                alert(error.message)
            })
        },
        changeValue(value){
            this.$emit('change', value)
        }
    }
}
</script>