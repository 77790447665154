<template>
	<v-card outlined>
		<Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
		<v-card-title>
			<v-img :src="customer.photo" max-height="70" max-width="70" class="mr-5" />
			{{customer.socialName}}
		</v-card-title>

		<v-card-title>
			<v-container>
				<v-row no-gutters >
					<v-col cols="12"> 
						<div class="blue px-2 py-1"><v-icon size="30" dark>description</v-icon> <span class="white--text">Infos</span></div>
						<v-divider class="mb-3 mt-1"/>
					</v-col>
					<v-col cols="4" >
						<v-text-field outlined densed readonly label="First Name" class="mr-2" v-model="customer.firstname" />
					</v-col>
					<v-col cols="4">
						<v-text-field outlined densed readonly label="Last Name" class="mr-2" v-model="customer.lastname" />
					</v-col>
					<v-col cols="4">
						 <v-text-field outlined densed readonly label="Gender" v-model="customer.gender" />
					</v-col>
					<v-col cols="12">
						<div class="blue px-2 py-1"><v-icon size="30" dark>contact_mail</v-icon> <span class="white--text">Contacts</span></div>
						<v-divider class="mb-3 mt-1"/>
					</v-col>
					<v-col cols="6">
						<v-text-field outlined densed readonly label="Email Address" class="mr-2" v-model="customer.email" />
					</v-col>
					<v-col cols="6">
						<v-text-field outlined densed readonly label="Contact Number" v-model="customer.contactNumber" />
					</v-col>
					<v-col cols="12">
						<div class="blue px-2 py-1"><v-icon size="30" dark>person_pin</v-icon> <span class="white--text">Location</span></div>
						<v-divider class="mb-3 mt-1"/>
					</v-col>
					<v-col cols="6">
						<v-text-field outlined densed readonly class="mr-2" label="Region" v-model="customer.region" />
						<v-text-field outlined densed readonly class="mr-2" label="Province" v-model="customer.province" />
						<v-text-field outlined densed readonly class="mr-2" label="City" v-model="customer.city" />
					</v-col>
					<v-col cols="6">
						<v-text-field outlined densed readonly label="Barangay" v-model="customer.barangay" />
						<v-textarea outlined densed readonly label="Street Address" v-model="customer.streetAddress" />
					</v-col>
					<v-col cols="12">
						<div class="blue px-2 py-1"><v-icon size="30" dark>outlined_flag</v-icon> <span class="white--text">Flags</span></div>
						<v-divider class="mb-3 mt-1"/>
						<v-row justify="space-around">
							<v-checkbox readonly label="Agreed with Privacy Policy" v-model="customer.privacyPolicy" />
						<v-checkbox readonly label="Verified" v-model="customer.verified" />
						<v-checkbox readonly label="Status" v-model="customer.status" />
						</v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-data-table
							dense 
							loading-text="Loading Items from server, please wait..."
							:loading="loading"
							:headers="headers" 
							:items="orderHistory"
							:server-items-length="serverItemTotal"
							:options.sync="pageOptions"
							@update:options="loadOrderHistory"
							:footer-props="footerProps"
						>
							<template v-slot:top>
								<v-toolbar dense flat short color="blue">
									<v-toolbar-title>
										<v-icon dark>local_grocery_store</v-icon>
										<span class="white--text">&nbsp; Order History</span>
									</v-toolbar-title>
									<v-spacer/>
									<v-toolbar-items>
										<v-text-field dark 
											class="mt-2" 
											single-line label="Search Order Id..." 
											append-icon="search" 
											v-model="orderFilters.orderId"
										/>
									</v-toolbar-items>
								</v-toolbar>
							</template>

							<template v-slot:item.action="{ item }">
								<v-tooltip bottom>
									<template v-slot:activator="{on}">
										<v-icon size="25" @click="viewOrder(item.orderId)" v-on="on">launch</v-icon>
									</template>
									<span>View Order</span>
								</v-tooltip>
							</template>

							<template v-slot:item.status="{ item }">
								<span v-if="item.status !== 'Completed'">{{item.status}}</span>
								<v-chip v-else small color="success">{{item.status}}</v-chip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-container>
		</v-card-title>
	</v-card>
</template>

<script>
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
	data() {
		return {
			loading: false,
			customer: {},
			orderHistory: [],
			serverItemTotal: 0,
			snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            orderFilters: {
                orderId: null
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 10
            },
            footerProps: {
                itemsPerPageOptions: [10, 40, 80, 100],
                itemsPerPageText: "Records per Page"
			},
			headers: [
                { text: 'Oder ID', value: 'orderId' },
                { text: 'Branch', value: 'branch_det.name' },
				{ text: 'Status', value: 'status', align: 'center', sortable: false },
				{ text: 'Action', value: 'action', sortable: false }
            ]
		}
	},
	computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        }
    },
	watch: {
		customer: {
			handler: 'loadOrderHistory'
		},
		orderFilters: {
			deep: true,
			handler() {
				this.pageOptions.page = 1
				this.loadOrderHistory()
			}
		}
	},
	mounted() {
		this.loadCustomerDetails()
	},
	methods: {
		loadCustomerDetails() {
			const self = this
			const customerId = self.$route.params.customerId
            this.$axios.get(`/customer/${customerId}/details`).then((result) => {
				self.customer = result.data.data
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
		},
		loadOrderHistory() {
			let self = this;
			let paging = {
				limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
			}
			
			self.loading = true
			this.$axios.get(`/customer/${this.customer.userId}/orders`, {
				params: { ...paging, orderId: this.orderFilters.orderId },
			}).then((result) => {
				self.orderHistory = result.data.rows
				self.serverItemTotal = result.data.count
				
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
		},
		viewOrder(orderId) {
			this.$router.push({ name: 'OrderView' , params: { orderId }})
		},
		showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
	}
}
</script>