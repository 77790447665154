import { isURL } from 'validator'
import isEmpty from 'is-empty'
import { isNull, isUndefined } from 'lodash'

function required (value) {
    if(typeof value === 'number') return true
    return !!value || "Required"
}

function alphabet(value) {
    let regex = new RegExp(/^[a-zA-Z]+$/)
    return regex.test(value) || "Input should be Alphabet only."
}

function numeric(value) {
    let regex = new RegExp(/^[0-9]*$/)
    return regex.test(value) || "Input should be Numbers only."
}

function validURL(value){
    if(!isEmpty(value)) {
        return isURL(value) || "Invalid URL"
    }

    return true
}

function minMaxValue(min, max) {
    return function(value) {
        if(value > max || value < min) {
            return `Value should not greater than ${max} or lesser than ${min}`
        } else return true
    }
}

function minMaxLength(min, max) {
    return function(value) {
        if (isUndefined(value)) return false
        else if (isNull(value)) return false
        else if (value.length > max || value.length < min) {
            return `Length should not greater than ${max} or lesser than ${min}`
        } else return true
    }
}

export { required, alphabet, validURL, minMaxValue, numeric, minMaxLength }
