import { db } from "../main";

const state =  {
	slides: []
};

const mutations = {
	SET_SLIDES (state, payload) {
		state.slides = payload;
    }
};

const actions = {
	fetchSlides({ commit }){
		return new Promise((resolve, reject) => {

                db.collection("slides").get().then(function(querySnapshot) {
                    let list = [];
                    querySnapshot.forEach(function(doc) {
                        list.push(doc.data());
                    });
                    commit("SET_SLIDES", list);
                    resolve(list);
                }).catch((error) => {
                    reject(error);
                })
			
        });
    },
    newSlide({ dispatch }, payload){
        return new Promise((resolve, reject) => {
            if(payload) {
                db.collection("slides").doc(payload.slug).set(payload).then(() => {
                    db.collection("users").doc(payload.author.uid).collection("slides").doc(payload.slug).set({
                        slug: payload.slug,
                        title: payload.title,
                        url: payload.url,
                        dateCreated: payload.dateCreated
                    }).then((result) => {
                        dispatch("fetchSlides").then(() => {
                            resolve(result);
                        }).catch(error => console.log(error));
                    }).catch(error => console.log(error));
                }).catch(error => console.log(error));
            } else {
                reject();
            }
        });
    }
};

const getters = {
	slides: state => state.slides
}

export default {
	state,
	mutations,
	actions,
	getters
};