<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Customers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
                single-line
                class="mt-5"
                label="Search for Customer Social Name..."
                append-icon="search"
                v-model="filters.name"
            ></v-text-field>
        </v-toolbar>

        <v-data-table 
            dense 
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            @update:options="loadCustomers"
            :footer-props="footerProps"
        >
            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="viewCustomer(item.id)" v-on="on">launch</v-icon>
                    </template>
                    <span>View Customer Details</span>
                </v-tooltip>
            </template>

            <template v-slot:item.status="{ item }">
                <v-simple-checkbox :value="Boolean(item.status)" disabled></v-simple-checkbox>
            </template>
            <template v-slot:item.privacyPolicy="{ item }">
                <v-simple-checkbox :value="Boolean(item.privacyPolicy)" disabled></v-simple-checkbox>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
    data() {
        return {
            loading: true,
            serverItemTotal: 0,
            filters: {
                name: null
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            records: [],
            headers: [
                { text: 'Social Name', value: 'socialName', sortable: false },
                { text: 'Email Address', value: 'email', sortable: false },
                { text: 'Contact', value: 'contactNumber', sortable: false },
                { text: 'Privacy Policy', value: 'privacyPolicy', align: 'center', sortable: false },
                { text: 'Active', value: 'status', align: 'center', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false }
            ]
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        }
    },
    watch: {
        filters: {
            deep: true,
            handler: 'loadCustomers'
        }
    },
    mounted() {
        this.loadCustomers()
    },
    methods: {
        loadCustomers() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`/customers`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.count
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        viewCustomer(customerId) {
            this.$router.push({ name: 'CustomerView', params: { customerId } })
        }
    }
}
</script>