<template>
    <v-card class="mx-auto" max-width="600">
        <v-toolbar
            flat
            color="error"
            dark
        >
            <v-toolbar-title>Reason for your request</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="closeDisapprovalModal"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <h2 class="body-1 mb-2 mt-5">Select the reason of disapproval.</h2>
            <v-chip-group
                v-model="selectedReasons"
                :value="reasons"
                column
                multiple
                class="mb-5"
            >
                <v-chip filter outlined :value="reason" v-for="reason in reasons" :key="reason">{{ reason }}</v-chip>
            </v-chip-group>
            <v-text-field v-model="remarks" placeholder="Add more details (optional)" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDisapprovalModal">Close</v-btn>
            <v-btn :loading="loading" color="error" @click="disapprove">Proceed</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { api } from '@/configs/api'

export default {
    name: 'disapprove-modal',
    data() {
        return {
            loader: null,
            loading: false,
            reasons: ["Multiple Orders of the Same Item within the day","Unusually Large Orders","Unexpected International Orders"],
            selectedReasons: [],
            remarks: ""
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    },
    methods: {
        closeDisapprovalModal() {
            const self = this
            self.selectedReasons = []
            self.$emit('disapprovalModal', false)
        },
        disapprove() {
            const self = this
            let orderId = self.$route.params.orderId
            self.loader = 'loading'

            axios.put(`${api}/order/fraud/disapproval`, {
                status: 'Cancelled',
                disapprovalReason: self.selectedReasons.toString(),
                disapprovalRemarks: self.remarks,
                orderId: orderId
            })
            .then(response => {
                let responseData = response.data
                self.loader = null
                self.showErrorMessage(responseData.message)
                self.$emit('disapprovalModal', false)
                self.selectedReasons = []
                setTimeout(() => {
                    self.$emit('getOrder', orderId)
                }, 2000)
            })
            .catch((error) => {
                self.showErrorMessage(error.message, false)
            })
        },
        showErrorMessage(message, success=true) {
            this.$emit(success ? 'successAlert' : 'errorAlert', message, success)
        }
    }
}
</script>