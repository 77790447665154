<template>
    <v-app>
        <v-content>
            <v-container class="deep-orange accent-1" fluid fill-height>
                <v-layout align-center justify-center style="text-align: center;">
                    <v-flex xs12 sm8 md4>
                        <v-icon size="200" color="deep-orange darken-4">report</v-icon>
                        <h2>{{errorMsg}}</h2>
                            <!-- v-if="errorCode === 401" -->
                        <v-btn 
                            outlined
                            v-if="errorCode === 401"
                            v-on:click="returnToLoginPage"
                        >Please Log In</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
export default {
    props: {
        errorCode: {
            type: Number,
            default: 404
        },
        errorMsg: { type: String }
    },
    methods: {
        returnToLoginPage(){
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>