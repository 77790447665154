<template>
<v-container>
    <v-layout row wrap justify-space-between>
        <v-flex xs12 sm10 md7 lg7>
            <v-card header-tag="header" footer-tag="footer" class="mr-5">
                <v-card-title>Slides</v-card-title>
					<v-card-text>
						<v-carousel style="text-shadow: 1px 1px 2px #333" background="#ababab" height=350 cycle interval="4000" show-arrows-on-hover>
							<v-carousel-item v-for="slide in slides" :key="slide.slug" :src="slide.url">
								<v-row align="center" justify="center" class="fill-height white--text">
									<div class="headline">{{ slide.title }}</div>
								</v-row>
							</v-carousel-item>
						</v-carousel>
					</v-card-text>
            </v-card>
        </v-flex>

        <v-flex xs12 sm2 md5 lg5>
            <v-card>
				<v-card-title>New Slide</v-card-title>
				<v-card-text>
					<v-form>
						<v-text-field outlined type="text" v-model="newSlide.title" required placeholder="Subject of the slide" label="Title"></v-text-field>

						<v-textarea outlined v-model="newSlide.description" required placeholder="Describe the slide" :rows="3" :max-rows="6" auto-grow label="Description"></v-textarea>

						<v-file-input label="File input" accept="image/*" @change="onFileChange($event)" outlined ref="fileInput"></v-file-input> 

						<v-row v-if="file.url">
							<v-col sm="12">
								<v-img :src="file.url" fluid :alt="newSlide.title" />
							</v-col>
						</v-row>
						<v-row v-if="newSlide.title && newSlide.description && file.exists">
							<v-col sm="12">
								<v-spacer></v-spacer>
								<v-btn dark color="blue" @click="upload" :disabled="!file.exists">
									Upload
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
        </v-flex>  
    </v-layout>
</v-container>
</template>

<script>
import firebase from 'firebase'
import { db, storage } from '../../main'
import moment from 'moment'
import slug from 'slug'
import { api } from '../../configs/api'
export default {
	data () {
		return {
			slides: [],
			slide: 0,
			sliding: null,
			slidesReady: false,
			newSlide: {
				slug: null,
				title: null,
				description: null,
				url: null,
				dateCreated: null,
				author: {},
				enabled: false
			},
			file: {
				blob: null,
				url: null,
				name: null,
				extension: null,
				exists: false
			}
		}
	},
	mounted() {
		let self = this
		self.getSlides()
	},
	methods: {
		async getSlides() {
			let request = await fetch(`${api}/slides`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`
				}
			});

			self.slides = await request.json()
			self.slidesReady = true
		},

		onSlideStart (slide) {
			let self = this
			self.sliding = true
		},
		onSlideEnd (slide) {
			let self = this
			self.sliding = false
		},
		pickFile() {
			let self = this
			self.$refs.fileInput.click();
		},
		onFileChange(file) {
			let self = this

			self.file.name = file.name
			if(self.file.name.lastIndexOf(".") <= 0) {
				return
			}
			const fileReader = new FileReader ()
			fileReader.readAsDataURL(file)
			fileReader.addEventListener("load", () => {
				self.file.url = fileReader.result
				self.file.blob = file
				self.file.extension = self.file.name.split('.').pop()
				self.file.exists = true
			})
		
		},
		upload() {
			let self = this
			var uid = self.$store.getters.user.uid

			self.file.name = self.file.name.replace("." + self.file.extension, "") + "-" + moment().format("x") + "." + self.file.extension

			let importRef = storage.child(self.file.name)
			importRef.put(self.file.blob).then((snapshot) => {
				snapshot.ref.getDownloadURL().then(function(downloadURL) {
					self.newSlide.slug = slug(self.newSlide.title.toLowerCase())
					self.newSlide.dateCreated = moment().format()
					self.newSlide.url = downloadURL
					self.newSlide.author = self.$store.getters.user
					self.newSlide.enabled = true

					db.collection("slides").doc(self.newSlide.slug).set(self.newSlide).then(() => {
						db.collection("users").doc(self.newSlide.author.uid).collection("slides").doc(self.newSlide.slug).set({
							slug: self.newSlide.slug,
							title: self.newSlide.title,
							description: self.newSlide.description,
							url: self.newSlide.url,
							dateCreated: self.newSlide.dateCreated
						}).then(() => {
							location.reload()
						}).catch(error => console.log(error));
					})              	
				})
			})
		}
	}	
}
</script>
