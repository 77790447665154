<template>
    <v-card flat>
        <v-card-title>{{title}} Setup</v-card-title>
        <v-divider></v-divider>
        
         <v-form ref="setupForm" class="px-8">
            <v-select
                label="Setups"
                item-text="name"
                item-value="code"
                :rules="[rules.required]"
                :items="setupTypeRecords"
                v-model="record.code"
            ></v-select>

            <v-text-field
                label="Amount"
                :rules="[rules.required]"
                v-model="record.value"
            >
            </v-text-field>
            <v-checkbox
                label="Active"
                v-model="record.active"
            >
            </v-checkbox>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="action === 'update'" @click="updateSetup">Update</v-btn>
            <v-btn color="primary" v-if="action === 'add'" @click="saveSetup">Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {required, minMaxValue} from '@/plugins/validation'

export default {
    props: {
        action: {
            type: String,
            default: 'view'
        },
        setupRecord: Object
    },
    data() {
        return {
            record: {
                code: null,
                amount: 0,
                active: true
            },
            selected: [],
            setupTypeRecords: [],
            rules: {
                required,
                minMaxValue
            }
        }
    },
    computed: {
        title() {
            return this.action[0].toUpperCase() + this.action.substr(1)
        }
    },
    watch: {
        setupRecord: {
            handler: function(value) {
                if(this.action === 'update') this.record = {...value}
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.loadSetupType()
    },
    methods: {
        loadSetupType() {
            let self = this

            this.$axios.get(`/lovs`, {
                params: {
                    groupCode: 'SETUPS'
                }
            })
            .then((response) => {
                let responseData = response.data.data
                self.setupTypeRecords = responseData
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        saveSetup() {
            let self = this
            const isValid = this.$refs.setupForm.validate()

            if(!isValid) return

            this.$axios.post(`/setup`, this.record).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        updateSetup() {
            let self = this
            const isValid = this.$refs.setupForm.validate()

            if(!isValid) return

            this.$axios.put(`/setup/${this.record.id}`, this.record).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.setupForm.reset()
            this.$refs.setupForm.resetValidation()
            this.$emit('close-form')
        }
    }
}
</script>