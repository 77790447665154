<template>
    <v-card flat>
        <v-card-title>Add Permission</v-card-title>
        <v-divider></v-divider>
        
         <v-form ref="addPermissionForm" class="px-8">
            <v-select
                chips
                multiple
                hide-selected
                deletable-chips
                label="Routes Permission"
                item-text="name"
                item-value="name"
                :items="records"
                v-model="selected"
            >
                <template v-slot:item="{item}">
                    <v-row>
                        <v-col cols="1">
                            <v-icon v-if="item.isMenu == 1" color="blue">widgets</v-icon>
                            <v-icon v-else>subdirectory_arrow_right</v-icon>
                        </v-col>
                        <v-col cols="9">
                            <span v-if="item.meta === null">
                                {{item.name}}
                            </span>
                            <span v-else-if="item.meta.title === undefined">
                                {{item.name}}
                            </span>
                            <span v-else>
                                {{item.meta.title}}
                            </span>
                        </v-col>
                    </v-row>
                </template>
            </v-select>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="savePermission">&nbsp; Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'

export default {
    props: {
        roleDetails: { type: Object }
    },
    data() {
        return {
            selected: [],
            records: []
        }
    },
    computed: {
        roleRouteNames() {
            return this.roleDetails.routes.map(rec => rec.routeName)
        }
    },
    watch: {
        roleDetails: {
            handler: 'loadPermissions',
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.loadPermissions()
    },
    methods: {
        loadPermissions() {
            let self = this

            this.$axios.get(`${api}/admin/routes`, {
                params: {
                    exclude: self.roleRouteNames
                }
            })
            .then((response) => {
                let responseData = response.data.data
                self.records = responseData
            }).catch((error) => {
                self.$emit('add-perm-response', error.message, false)
            })
        },
        savePermission() {
            let self = this
            let roleCode = this.roleDetails.roleCode
            const isValid = this.$refs.addPermissionForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/role/${roleCode}/permissions`, {
                permissions: this.selected
            })
            .then(response => {
                let responseData = response.data
                self.$emit('add-perm-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('add-perm-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.addPermissionForm.reset()
            this.$refs.addPermissionForm.resetValidation()
            this.$emit('close-add-permission-form')
        }
    }
}
</script>