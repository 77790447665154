<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Packaging Type Setups</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="addSetup" v-on="on"><v-icon>add</v-icon></v-btn>
                    </template>
                    <span>Add Package Setup</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            :footer-props="footerProps"
        >

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="editSetup(item.id)" v-on="on">edit</v-icon>
                    </template>
                    <span>Edit Setup</span>
                </v-tooltip>
            </template>
            
            <template v-slot:item.tag="{ item }">
                <v-simple-checkbox :value="Boolean(item.tag)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.active="{ item }">
                <v-simple-checkbox :value="Boolean(item.active)" disabled></v-simple-checkbox>
            </template>
        </v-data-table>

        <v-dialog v-model="showDialog" width="500" height="500">
            <Details 
                :action="action"
                :setupRecord="setupRecord"
                v-on:setup-response="showActionResponse"
                v-on:close-form="closeDialog"
            ></Details>
        </v-dialog>

    </v-container>
</template>

<script>
import Details from './Details'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Details,
        Snackbar
    },
    data() {
        return {
            action: null,
            showDialog: false,
            loading: false,
            serverItemTotal: 0,
            setupRecord: null,
            pageOptions: {
                page: 1,
                itemsPerPage: 20
            },
            footerProps: {
                itemsPerPageOptions: [20, 40, 80, 100],
                itemsPerPageText: "Records per Page"
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            records: [],
            headers: [
                { text: 'Type', value: 'type', sortable: false },
                { text: 'Packaging', value: 'packaging', sortable: false },
                { text: 'Display Name', value: 'name', align: 'center', sortable: false },
                { text: 'Promo', value: 'tag', sortable: false },
                { text: 'Length (cm)', value: 'dimension.length', align: 'center', sortable: false },
                { text: 'Width (cm)', value: 'dimension.width', align: 'center', sortable: false },
                { text: 'height (cm)', value: 'dimension.height', align: 'center', sortable: false },
                { text: 'Volume (cm)', value: 'dimension.volume', align: 'center', sortable: false },
                { text: 'Provider', value: 'provider.name', align: 'center', sortable: false },
                { text: 'Remarks', value: 'remarks', align: 'center', sortable: false },
                { text: 'Active', value: 'active', align: 'center', sortable: false },
                { text: 'Action', value: 'action', align: 'center', sortable: false }
            ]
        }
    },
    mounted() {
        this.loadSetups();
    },
    watch: {
        showDialog(value) {
            if(!value) this.loadSetups()
        }
    },
    methods: {
        loadSetups() {
            const self = this
            self.loading = true

            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`/setup/packaging-type/fetch`).then((result) => {
                self.records = result.data.data
                self.serverItemTotal = result.data.data.length
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        addSetup() {
            this.action = 'add'
            this.$router.push("/setups/packaging/new")
        },
        editSetup(id) {
            this.$router.push(`/setups/packaging/${id}`)
        },
        showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
        closeDialog() {
            this.showDialog = false
            this.setupRecord = null
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    },
}
</script>