<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short text color="blue">
            <v-toolbar-title>Motorcycle Freebies</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-text-field 
                    class="mt-2"
                    v-model="filters.materialNumber" 
                    append-icon="mdi-magnify" 
                    label="Material Number" 
                    single-line 
                    hide-details
                    clearable
                />
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showFilter = !showFilter" v-on="on"><v-icon>filter_alt</v-icon></v-btn>
                    </template>
                    <span>Show Filters</span>
                </v-tooltip>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showDialog('add')" v-on="on"><v-icon>mdi-plus-box</v-icon></v-btn>
                    </template>
                    <span>Set Freebies</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="showDialog('remove')" v-on="on"><v-icon>mdi-minus-box</v-icon></v-btn>
                    </template>
                    <span>Remove Freebies</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense 
            show-select
            loading-text="Loading Items from server, please wait..."
            item-key="materialNumber"
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverTotal"
            :options.sync="pageOptions"
            @update:options="loadItems"
            :footer-props="footerProps"
            v-model="selected"
        >
            <template v-slot:top="{}">
                <v-toolbar class="mb-4" v-show="showFilter" dense>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <Brands class="mt-4 mr-2" dropdownIcon="filter_list" v-model="filters.brand" />
                        <Models class="mt-4" dropdownIcon="filter_list" v-model="filters.model" />
                    </v-toolbar-items>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent width="500" height="500">
            <v-card>
                <v-card-title>
                    <span v-if="action == 'add'">Add Freebies</span>
                    <span v-if="action == 'remove'">Remove Freebies</span>
                </v-card-title>
                <v-divider />
                <v-container>
                    <div v-if="action == 'add'">
                        Selected motorcycles will be included with 
                        <v-flex xs12 v-for="(freebi,index) in freebies" :key="index" class="pt-0 pb-0 pl-3">
                            <v-checkbox class="pa-0" type="checkbox" :label="freebi" v-model="selectedMc.freebies" :value="freebi"/>
                        </v-flex>
                        <v-flex xs12 class="pt-0 pb-0 pl-3">
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="expirydate" label="Expiry Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="expirydate"
                                    @input="menu1 = false"
                                    
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </div>
                    <div v-if="action == 'remove'">
                        Would you like to proceed removing the freebies for these motorcycle/s?
                    </div>
                </v-container>
                <v-divider />
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="doAction" color="success">Save</v-btn>
                    <v-btn @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import Brands from '@/components/Select/EnableMcBrands'
import Models from '@/components/Select/EnableMcModels'
import moment from 'moment'

export default {
    components: {
        Snackbar,
        Brands,
        Models
    },
    data() {
        return {
            showFilter: false,
            dialog: false,
            loading: false,
            action: null,
            ltoFee: 0,
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            filters: {
                brand: null,
                model: null,
                materialNumber: null
            },
            headers: [
                { text: 'Material Number', value: 'materialNumber', sortable: false },
                { text: 'Description', value: 'purchaseDescription', sortable: false },
                { text: 'Model Name', value: 'model', sortable: false },
                { text: 'Brand', value: 'brand', sortable: false },
                { text: 'Freebies', value: 'freebies_detail.freebies', sortable: false },
                { text: 'Expiry Date', value: 'freebies_detail.date', sortable: false }
            ],
            records: [],
            serverTotal: 0,
            pageOptions: {
                page: 1,
                itemsPerPage: 10
            },
            footerProps: {
                itemsPerPageOptions: [10, 25, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            selected: [],
            selectedMc: { freebies: []},
            freebies: ["Helmet","Engine Oil"],
            menu1: false,
            expiryDate: moment(new Date).format("YYYY-MM-DD"),
            minimumDate: moment().format("YYYY-MM-DD"),
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
        selectedMaterial() {
            return this.selected.map(rec => rec.materialNumber)
        },
    },
    watch: {
        filters: {
            handler: 'loadItems',
            deep: true
        },
        dialog(value) {
            if(!value) this.action = null
        },
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        loadItems() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            self.$axios.get(`/mc/freebies`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverTotal = result.data.count
                self.loading = false
                self.selected = []
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        doAction() {
            switch (this.action) {
                case 'add':
                    this.includeFreebies()
                    break;
                case 'remove':
                    this.removeFreebies()
                    break;
                default:
                    break;
            }
        },
        async includeFreebies() {
            let isValid = this.selectedMc.freebies.length > 0 && this.expirydate
            if (!isValid) {
                this.showSnackbarMessage("Please select freebies and expiry date", false)
            } else {
                let response
                try{
                    response = await this.$axios.post('/mc/freebies', {
                        materialNumbers: this.selectedMaterial,
                        freebies: this.selectedMc.freebies,
                        date: this.expirydate
                    })
                    this.showSnackbarMessage(response.data.message)
                    this.loadItems()
                } catch(error) {
                    this.showSnackbarMessage(error.message, false)
                }
                this.dialog = false
            }
        },
        async removeFreebies() {
            let response
            try{
                this.validateSelectedMaterial()
                response = await this.$axios.delete('/mc/freebies', {
                    data: {
                        materialNumbers: this.selectedMaterial
                    }
                })
                this.showSnackbarMessage(response.data.message)
                this.loadItems()
            } catch(error) {
                this.showSnackbarMessage(error.message, false)
            }
            this.dialog = false
        },
        showDialog(action) {
            try {
                this.validateSelectedMaterial()
                this.action = action
                this.dialog = true
            } catch (error) {
                this.showSnackbarMessage(error.message, false)
            }
        },
        validateSelectedMaterial() {
            if(this.selectedMaterial.length < 1)
                throw new Error("Please select motorcycle/s first.")
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
    }
}
</script>