<template>
    <v-container fluid>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Item Details</v-toolbar-title>
        </v-toolbar>
        <v-subheader light>({{details.sku}}) - {{details.nsSalesDescription}} - {{details.partNumber}}</v-subheader>
        <v-form ref="form">
            <v-row dense>
                <v-col cols="6">
                    <v-text-field dense disabled label="Material No" v-model="details.sku"></v-text-field>
                    <v-text-field dense disabled label="Category" v-model="details.category"></v-text-field>
                    <v-text-field dense disabled label="Brand" v-model="details.brand"></v-text-field>
                    <v-text-field dense disabled label="Specification" v-model="details.nsSalesDescription"></v-text-field>
                    <v-text-field dense disabled label="Part Number" v-model="details.partNumber"></v-text-field>
                    <v-text-field dense disabled label="Color" v-model="details.color"></v-text-field>
                    <v-text-field dense disabled label="SRP" v-model="details.srp"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-img
                        contain
                        class="grey lighten-2"
                        aspect-ratio="1.76"
                        :src="imageSrc"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-linear indeterminate color="blue"></v-progress-linear>
                            </v-row>
                        </template>
                    </v-img>
                    <v-text-field 
                        dense
                        clearable
                        type="url"
                        label="Photo" 
                        class="mt-4"
                        v-model="details.photo"
                        :rules="[rules.validUrl, rules.required]"
                        prepend-inner-icon="insert_link"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <Product :exclude="['Motorcycles']" v-model="details.department"></Product>
                    <ProductGroup v-model="details.itemGroup" :defaultValue="details.itemGroup"></ProductGroup>
                    <v-text-field dense label="Model" v-model="details.model"></v-text-field>
                    <v-text-field dense label="Year Model" v-model="details.yearModel"></v-text-field>
                    <v-textarea dense label="Short Description" v-model="details.salesDescription" />
                </v-col>

                <v-col cols="6">
                    <v-checkbox dense label="Enable" v-model="details.enable"></v-checkbox>
                    <v-checkbox dense label="Featured Item" v-model="details.featured"></v-checkbox>
                    <v-checkbox dense disabled label="Active" v-model="details.active"></v-checkbox>
                </v-col>
            </v-row>
            <v-row dense><v-divider></v-divider></v-row>
            <v-row class="pt-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3" color="primary" @click="saveItem">
                    <v-icon left>save</v-icon>
                    Submit
                </v-btn>
                <v-btn color="warning" @click="resetForm">
                    <v-icon left>refresh</v-icon>
                    Reset
                </v-btn>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import isEmpty from 'is-empty'
import { required, validURL } from '@/plugins/validation'
import Product from '@/components/Select/Product'
import ProductGroup from '@/components/Select/ProductGroup'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Product,
        ProductGroup,
        Snackbar
    },
    data() {
        return {
            defImageSrc: require('../../../public/img/error.svg'),
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            details: {},
            rules: { required, validURL }
        }
    },
    computed: {
        imageSrc() {
            return !isEmpty(this.details.photo) ? this.details.photo : this.defImageSrc
        }
    },
    mounted() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            let self = this
            const itemId = self.$route.params.itemid

            self.$axios.get(`/parts/${itemId}`)
            .then((result) => {
                let responseData = result.data

                self.details = responseData.data
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        saveItem() {
            let self = this
            const isValid = this.$refs.form.validate()
            const itemId = this.$route.params.itemid

            if(!isValid) {
                this.showSnackbarMessage("Please fill up required fields.", false)
                return
            }

            self.$axios.put(`/parts/details/update/${itemId}`, this.details)
            .then(response => {
                let responseData = response.data
                self.showSnackbarMessage(responseData.message, responseData.success)
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        resetForm() {
            this.$refs.form.resetValidation()
        }
    }
}
</script>>