<template>
    <v-card flat>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-card-title class="pl-0">Delivery Fee Setup</v-card-title>
        <v-divider></v-divider>
        
        <v-form ref="deliveryFeeForm" class="px-8">
            <v-layout>
                <v-container>
                    <v-flex xs12>
                        <span class="pb-0">Product Type </span>
                        <v-radio-group :readonly="action==='update'" class="pt-0 pl-5" v-model="productType" :rules="[rules.required]">
                            <v-radio label="MOTORCYCLES" value="Motorcycles"></v-radio>
                            <v-radio label="PARTS AND ACCESSORIES" value="Parts and Accessories"></v-radio>
                        </v-radio-group>
                    </v-flex>
                </v-container>
            </v-layout>
            <v-divider></v-divider>
            <v-layout>
                <v-container>
                    <v-flex xs12>
                        <v-flex xs12>
                            <v-layout>
                                <v-checkbox v-model="setupTypes" 
                                label="Enable Free Delivery Fee"
                                value="Enable Free Delivery Fee"
                                ></v-checkbox>

                                <v-layout v-if="setupTypes.includes('Enable Free Delivery Fee')">
                                    <v-flex xs3>
                                        <p class="mt-5 ml-5 pl-5 text--secondary">Dates Covered</p>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-menu
                                            v-model="freeDeliveryFeeMenu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="freeDeliveryFee.deliveryEffectivityDate"
                                            label="Effectivity Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="freeDeliveryFee.deliveryEffectivityDate"
                                            @input="freeDeliveryFeeMenu1 = false"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-flex>

                                    <v-flex xs3>
                                        <v-menu
                                            v-model="freeDeliveryFeeMenu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="freeDeliveryFee.deliveryExpiryDate"
                                            label="Expiry Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="freeDeliveryFee.deliveryExpiryDate" 
                                            :min="freeDeliveryFee.deliveryEffectivityDate"
                                            @input="freeDeliveryFeeMenu2 = false"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout>
                                <v-checkbox v-model="setupTypes" 
                                label="Enable Flat Rate"
                                value="Enable Flat Rate"
                                ></v-checkbox>

                                <v-layout v-if="setupTypes.includes('Enable Flat Rate')">
                                    <v-flex xs2 class="pt-3 pl-5">
                                        <v-text-field type="number" v-model="flatRate.rate" outlined dense></v-text-field>
                                    </v-flex>

                                    <v-flex xs3>
                                        <p class="mt-5 ml-5 pl-5 text--secondary">Dates Covered</p>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-menu
                                            v-model="flatRateMenu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="flatRate.deliveryEffectivityDate"
                                            label="Effectivity Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="flatRate.deliveryEffectivityDate"
                                            @input="flatRateMenu1 = false"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-flex>

                                    <v-flex xs3>
                                        <v-menu
                                            v-model="flatRateMenu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="flatRate.deliveryExpiryDate"
                                            label="Expiry Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="flatRate.deliveryExpiryDate" 
                                            :min="flatRate.deliveryEffectivityDate"
                                            @input="flatRateMenu2 = false"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-checkbox v-model="setupTypes" readonly
                            label="Setup Matrix"
                            value="Setup Matrix"
                            ></v-checkbox>
                        </v-flex>
                    </v-flex>
                </v-container>
            </v-layout>
            <v-divider></v-divider>

            <v-layout v-if="setupTypes.includes('Setup Matrix')">
                <v-container grid-list-md>
                    <v-layout class="pb-3" xs2>
                        <input v-model="providerService" type="radio" value="Internal Delivery">
                            <label for="internal" class="ml-3 body-2 text--secondary">Internal Delivery</label>
                    </v-layout>
                    <v-flex xs12 v-if="providerService=='Internal Delivery'">
                        <v-layout>
                            <v-flex xs2>
                                <v-text-field
                                    v-model="internal.matrix.provider"
                                    outlined dense hide-details
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex xs2>
                                <p class="mt-5 ml-5 text--secondary">Dates Covered</p>
                            </v-flex>
                            <v-flex xs2>
                                <v-menu
                                    v-model="setupMatrixmenu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="internal.matrix.deliveryEffectivityDate"
                                    label="Effectivity Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="internal.matrix.deliveryEffectivityDate"
                                    @input="setupMatrixmenu1 = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-flex>

                            <v-flex xs2>
                                <v-menu
                                    v-model="setupMatrixmenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="internal.matrix.deliveryExpiryDate"
                                    label="Expiry Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="internal.matrix.deliveryExpiryDate" 
                                    :min="internal.matrix.deliveryEffectivityDate"
                                    @input="setupMatrixmenu2 = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs12  v-if="providerService=='Internal Delivery'">
                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs3>
                                    <p class="pt-2 subtitle-1 text--secondary">Select Coverage Area</p>
                                </v-flex>
                                
                                <v-flex xs4>
                                    <v-select :items="coverageAreas" v-model="internal.matrix.coverageArea" dense outlined></v-select>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout>
                                <v-flex xs3>
                                    <p class="pt-2 subtitle-1 text--secondary">Point of Origin</p>
                                </v-flex>
                                
                                <v-flex xs4>
                                    <v-select v-model="originHolder" :items="islandGroups" dense outlined></v-select>
                                </v-flex>
                                <v-flex xs4 class="pt-1">
                                    <v-btn @click="addOrigin" color="primary">Add Point of Origin</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <v-layout v-for="(list,index) in internal.matrix.origins" :key="index">
                                <v-flex xs12 v-if="internal.matrix.origins[index].origin!==''">
                                    <v-text-field v-model="internal.matrix.origins[index].origin" required outlined readonly
                                    append-outer-icon="cancel" @click:append-outer="removeOrigin(index)"></v-text-field>

                                    <v-flex xs12>
                                        <v-layout>
                                            <v-flex xs11></v-flex>
                                            <v-flex xs1>
                                                <v-icon @click="openModal(index)" class="mt-5">mdi-plus-circle</v-icon>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-simple-table :key="componentKey" dense class="mt-5 ml-5">
                                            <thead>
                                                <tr>
                                                    <th colspan="3" class="text--secondary" style="text-align: center;">
                                                        Distance Covered
                                                    </th>
                                                    <th colspan="26" class="text--secondary" style="text-align: center;">
                                                        Base Amount per Distance Covered and Destination
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <th class="text--secondary">
                                                    Min.
                                                </th>
                                                <th class="text--secondary">
                                                    Max.
                                                </th>
                                                <th class="text--secondary">
                                                    unit
                                                </th>
                                                <th
                                                    class="text--secondary"
                                                    v-for="item in islandGroups" :key="item"
                                                >
                                                    {{ item }}
                                                </th>
                                                <th>
                                                    <v-icon @click="deleteMatrixForInternal(index)">delete</v-icon>
                                                </th>
                                                <tr
                                                    v-for="(item, id) in internal.matrix.origins[index].table" 
                                                    :key="id"
                                                >
                                                    <td>
                                                        <v-edit-dialog
                                                            :return-value.sync="item.metric.min"
                                                            @save="save(item)"
                                                            > {{ item.metric.min}}
                                                            <template v-slot:input>
                                                                <v-text-field
                                                                    v-model="item.metric.min"
                                                                    label="Edit"
                                                                    single-line
                                                                ></v-text-field>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </td>
                                                    <td>
                                                        <v-edit-dialog
                                                            :return-value.sync="item.metric.max"
                                                            @save="save(item)"
                                                            > {{ item.metric.max}}
                                                            <template v-slot:input>
                                                                <v-text-field
                                                                    v-model="item.metric.max"
                                                                    label="Edit"
                                                                    single-line
                                                                ></v-text-field>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </td>
                                                    <td>{{ item.metric.unit}}</td>
                                                    <td
                                                        v-for="(area, areaIndex) in item.values" :key="areaIndex"
                                                    >
                                                        <v-edit-dialog
                                                            :return-value.sync="area.amount"
                                                            @save="save(item)"
                                                            > {{ area.amount }}
                                                            <template v-slot:input>
                                                                <v-text-field
                                                                    v-model="area.amount"
                                                                    label="Edit"
                                                                    single-line
                                                                ></v-text-field>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </td>
                                                    <td>
                                                        <input v-model="matrixForDeletion" type="checkbox" :value="item">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-flex>

                                    <v-flex xs12>
                                        <v-layout class="mt-3">
                                            <v-flex xs3>
                                                <p class="pt-2 subtitle-2 text--secondary">Additional amount per km</p>
                                            </v-flex>
                                            <v-flex xs2>
                                                <v-text-field type="number" v-model="internal.matrix.origins[index].additionalAmountperKm" :rules="[rules.required]" outlined dense></v-text-field>
                                            </v-flex>   
                                        </v-layout> 

                                        <v-layout>
                                            <v-flex xs3>
                                                <p class="subtitle-2 pt-2 text--secondary">Maximum ceiling amount</p>
                                            </v-flex>
                                            <v-flex xs2>
                                                <v-text-field type="number" v-model="internal.matrix.origins[index].maxCeilingAmount" :rules="[rules.required]" outlined dense></v-text-field>
                                            </v-flex>  
                                        </v-layout>  
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-flex>
                </v-container>
            </v-layout>

            <v-layout>
                <v-container grid-list-md>
                    <v-flex xs12>
                        <v-layout v-if="productType === 'Parts and Accessories'">
                            <v-flex class="pt-5" xs3>
                                <input v-model="providerService" type="radio" value="Third-Party Courier Service">
                                <label for="external" class="ml-3 body-2 text--secondary">Third-Party Courier Service</label>
                            </v-flex>

                            <v-layout class="pl-5" v-if="providerService=='Third-Party Courier Service'">
                                <v-flex xs6> 
                                    <v-select
                                        v-model="courierHolder" 
                                        :items="couriers"
                                        item-text="courier"
                                        item-value="courier"
                                        return-object 
                                        dense 
                                        outlined
                                    ></v-select>
                                </v-flex>
                                <v-flex class="ml-5" xs2>
                                    <v-btn color="primary" @click="addCourier">ADD COURIER SETUP</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                </v-container>
            </v-layout>

            <!-- redesign -->
            <div v-if="providerService=='Third-Party Courier Service'">
                <v-container grid-list-md>
                    <v-layout row wrap v-for="(list,index) in external.matrix.couriers" :key="index">
                        <v-flex xs12 v-if="external.matrix.couriers[index].courier!==''">
                            <v-text-field v-model="external.matrix.couriers[index].courier" required outlined readonly
                            append-outer-icon="cancel" @click:append-outer="removeCourier(index)"></v-text-field>
                        </v-flex>

                        <v-layout row wrap>
                            <v-flex xs12>
                            <v-layout>
                                <v-flex xs12>
                                    <v-checkbox v-if="external.matrix.couriers[index].courier!==''" v-model="external.matrix.couriers[index].setupTypes"
                                        label="Normal Rates"
                                        value="Normal Rates"
                                        @change="spNormalDatesValidation(index)"
                                    ></v-checkbox>
                                </v-flex>
                            </v-layout>

                            <v-layout v-if="external.matrix.couriers[index].setupTypes.includes('Normal Rates')">
                                <v-flex xs12>
                                    <v-layout>
                                        <v-flex xs3>
                                            <p class="mt-5 ml-5 pl-5 text--secondary">Dates Covered</p>
                                        </v-flex>
                                        <v-flex xs3>
                                            <v-menu
                                                v-model="external.matrix.couriers[index].normalRateMenu1"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="external.matrix.couriers[index].normalRates.deliveryEffectivityDate"
                                                label="Effectivity Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="external.matrix.couriers[index].normalRates.deliveryEffectivityDate"
                                                @input="partsNormalRatesDateEffectivityValidation(index)"
                                            ></v-date-picker>
                                            </v-menu>
                                        </v-flex>

                                        <v-flex xs3>
                                            <v-menu
                                                v-model="external.matrix.couriers[index].normalRateMenu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="external.matrix.couriers[index].normalRates.deliveryExpiryDate"
                                                label="Expiry Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="external.matrix.couriers[index].normalRates.deliveryExpiryDate"
                                                :min="external.matrix.couriers[index].normalRates.deliveryEffectivityDate"
                                                @input="partsNormalRatesDateExpiryValidation(index)"
                                            ></v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs12>
                                            <v-layout>
                                                <v-flex xs3>
                                                    <p class="pt-2 subtitle-1 text--secondary">Select Coverage Area</p>
                                                </v-flex>
                                                
                                                <v-flex xs4>
                                                    <v-select :items="coverageAreas" v-model="external.matrix.couriers[index].coverageArea" dense outlined></v-select>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs12>
                                            <v-layout>
                                                <v-flex xs3>
                                                    <p class="pt-2 subtitle-1 text--secondary">Point of Origin</p>
                                                </v-flex>
                                                
                                                <v-flex xs4>
                                                    <v-select v-model="external.matrix.couriers[index].originHolder" :items="islandGroups" dense outlined></v-select>
                                                </v-flex>
                                                <v-flex xs4 class="pt-1">
                                                    <v-btn @click="addCourierOrigin(index)" color="primary">Add Point of Origin</v-btn>
                                                </v-flex>
                                            </v-layout>

                                            <v-layout v-for="(list2,index2) in external.matrix.couriers[index].origins" :key="index2">
                                                <v-flex xs12 v-if="external.matrix.couriers[index].origins[index2].origin !==''">
                                                    <v-text-field v-model="external.matrix.couriers[index].origins[index2].origin" required outlined readonly
                                                    append-outer-icon="cancel" @click:append-outer="removeCourierOrigin(index,index2)"></v-text-field>

                                                    <v-layout>
                                                        <v-flex xs12>
                                                            <v-radio-group 
                                                                v-model="external.matrix.couriers[index].origins[index2].based"
                                                                @change="onBasedSelect(
                                                                    external.matrix.couriers[index],
                                                                    external.matrix.couriers[index].origins[index2],index,index2
                                                                )"
                                                            >
                                                                <v-radio
                                                                    :dense="true"
                                                                    class="caption"
                                                                    v-for="base in basedOn"
                                                                    :key="base"
                                                                    :label="`Based on ${base}`"
                                                                    :value="base"
                                                                >
                                                                </v-radio>
                                                            </v-radio-group>

                                                            <v-flex xs12>
                                                                <v-layout>
                                                                    <v-flex xs11></v-flex>
                                                                    <v-flex xs1>
                                                                        <v-icon v-if="external.matrix.couriers[index].origins[index2].based" @click="openModal(index, index2)" class="mt-5">mdi-plus-circle</v-icon>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>

                                                            <v-simple-table :key="componentKey" v-if="external.matrix.couriers[index].origins[index2].based" dense class="mt-5 ml-5 mb-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th v-if="external.matrix.couriers[index].origins[index2].based === 'kg'" colspan="3" class="text--secondary" style="text-align: center;">
                                                                            Weight
                                                                        </th>
                                                                        <th v-if="external.matrix.couriers[index].origins[index2].based === 'km'" colspan="3" class="text--secondary" style="text-align: center;">
                                                                            Distance Covered
                                                                        </th>
                                                                        <th colspan="26" class="text--secondary" style="text-align: center;">
                                                                            Base Amount per Distance Covered and Destination
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <th class="text--secondary">
                                                                        Min.
                                                                    </th>
                                                                    <th class="text--secondary">
                                                                        Max.
                                                                    </th>
                                                                    <th class="text--secondary">
                                                                        unit
                                                                    </th>
                                                                    <th
                                                                        class="text--secondary"
                                                                        v-for="item in islandGroups" :key="item"
                                                                    >
                                                                        {{ item }}
                                                                    </th>
                                                                    <th>
                                                                        <v-icon @click="deleteMatrixForExternal(index, index2)">delete</v-icon>
                                                                    </th>
                                                                    <tr
                                                                        v-for="(item, index) in external.matrix.couriers[index].origins[index2].table" 
                                                                        :key="index"
                                                                    >
                                                                        <td>
                                                                            <v-edit-dialog
                                                                                :return-value.sync="item.metric.min"
                                                                                @save="save(item)"
                                                                                > {{ item.metric.min }}
                                                                                <template v-slot:input>
                                                                                    <v-text-field
                                                                                        v-model="item.metric.min"
                                                                                        label="Edit"
                                                                                        single-line
                                                                                    ></v-text-field>
                                                                                </template>
                                                                            </v-edit-dialog>
                                                                        </td>
                                                                        <td>
                                                                            <v-edit-dialog
                                                                                :return-value.sync="item.metric.max"
                                                                                @save="save(item)"
                                                                                > {{ item.metric.max }}
                                                                                <template v-slot:input>
                                                                                    <v-text-field
                                                                                        v-model="item.metric.max"
                                                                                        label="Edit"
                                                                                        single-line
                                                                                    ></v-text-field>
                                                                                </template>
                                                                            </v-edit-dialog>
                                                                        </td>
                                                                        <td>
                                                                            <v-edit-dialog
                                                                                :return-value.sync="item.metric.unit"
                                                                                @save="save(item)"
                                                                                > {{ item.metric.unit }}
                                                                                <template v-slot:input>
                                                                                    <v-text-field
                                                                                        v-model="item.metric.unit"
                                                                                        label="Edit"
                                                                                        single-line
                                                                                    ></v-text-field>
                                                                                </template>
                                                                            </v-edit-dialog>
                                                                        </td>
                                                                        <td
                                                                            v-for="(area, index) in item.values" :key="index"
                                                                        >
                                                                            <v-edit-dialog
                                                                                :return-value.sync="area.amount"
                                                                                @save="save(item)"
                                                                                > {{ area.amount }}
                                                                                <template v-slot:input>
                                                                                    <v-text-field
                                                                                        v-model="area.amount"
                                                                                        label="Edit"
                                                                                        single-line
                                                                                    ></v-text-field>
                                                                                </template>
                                                                            </v-edit-dialog>
                                                                        </td>
                                                                        <td>
                                                                            <input v-model="matrixForDeletion" type="checkbox" :value="item">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </v-simple-table>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            </v-flex>

                            <v-flex v-if="external.matrix.couriers[index].courier === 'PH GLOBAL JET EXPRESS, INC.'" xs12>
                                <v-layout>
                                    <v-flex xs12>
                                        <v-checkbox v-model="external.matrix.couriers[index].setupTypes"
                                            label="Promo Rates"
                                            value="Promo Rates"
                                            @change="promoDatesValidation(index)"
                                        ></v-checkbox>
                                    </v-flex>
                                </v-layout>

                                <v-layout v-if="external.matrix.couriers[index].setupTypes.includes('Promo Rates')">
                                    <v-flex xs12>
                                        <v-layout>
                                            <v-flex xs3>
                                                <p class="mt-5 ml-5 pl-5 text--secondary">Dates Covered</p>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-menu
                                                    v-model="external.matrix.couriers[index].promoRateMenu1"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="external.matrix.couriers[index].promoRates.deliveryEffectivityDate"
                                                    label="Effectivity Date"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="external.matrix.couriers[index].promoRates.deliveryEffectivityDate"
                                                    @change="promoRatesDateEffectivityValidation(index)"
                                                ></v-date-picker>
                                                </v-menu>
                                            </v-flex>

                                            <v-flex xs3>
                                                <v-menu
                                                    v-model="external.matrix.couriers[index].promoRateMenu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="external.matrix.couriers[index].promoRates.deliveryExpiryDate"
                                                    label="Expiry Date"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="external.matrix.couriers[index].promoRates.deliveryExpiryDate" 
                                                    :min="external.matrix.couriers[index].promoRates.deliveryEffectivityDate"
                                                    @change="promoRatesDateExpiryValidation(index)"
                                                ></v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap v-if="external.matrix.couriers[index].setupTypes.includes('Promo Rates')">
                                    <v-flex xs12>
                                        <v-layout>
                                            <v-flex xs11></v-flex>
                                            <v-flex xs1>
                                                <v-icon @click="openPromoModal(index)" class="mt-5">mdi-plus-circle</v-icon>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-simple-table :key="promoComponent" dense class="mt-5 ml-5 mb-5">
                                            <tbody>
                                                <th class="text--secondary">
                                                    Origin
                                                </th>
                                                <th colspan="6" class="text--secondary">
                                                    Packaging
                                                </th>
                                                <th
                                                    class="text--secondary"
                                                    v-for="item in islandGroups" :key="item"
                                                >
                                                    {{ item }}
                                                </th>
                                                <th>
                                                    <v-icon @click="deleteMatrixForPromo(index)">delete</v-icon>
                                                </th>
                                                <tr
                                                    v-for="(item, index2) in external.matrix.couriers[index].promoRates.rates" 
                                                    :key="index2"
                                                >
                                                    <td>
                                                        {{ item.matrix.origin }}
                                                    </td>
                                                    <td colspan="6">
                                                        {{ item.matrix.packages.package }}
                                                    </td>
                                                    <td
                                                        v-for="(destination, index3) in item.matrix.packages.values"
                                                        :key="index3"
                                                    >
                                                        <v-edit-dialog
                                                            :return-value.sync="destination.amount"
                                                            @save="savePromoUpdate(item)"
                                                            > {{ destination.amount }}
                                                            <template v-slot:input>
                                                                <v-text-field
                                                                    v-model="destination.amount"
                                                                    label="Edit"
                                                                    single-line
                                                                ></v-text-field>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </td>
                                                    <td>
                                                        <input v-model="matrixForDeletion" type="checkbox" :value="item">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-layout>
                    <v-layout v-if="external.matrix.couriers.findIndex(element => element.courier == '')">
                        <v-flex xs12>
                            <v-dialog
                                v-model="summaryDialog"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <a class="caption" href="javascript:void(0)" v-bind="attrs" v-on="on">
                                        Summary page for created matrix on normal rates
                                    </a>
                                </template>
                                <v-card>
                                    <v-toolbar flat text dark color="primary">
                                        <v-btn
                                            icon
                                            dark
                                            @click="summaryDialog = false"
                                            >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title class="body-1">Delivery Fee</v-toolbar-title>
                                    </v-toolbar>
                                    <v-layout class="pa-3">
                                        <v-flex xs12>
                                            <p style="background-color: #E0E0E0;" class="pl-1 subtitle-2">Summary page for Normal Rates</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="ml-5 caption">
                                        <v-flex xs12>
                                            <strong>Please avoid duplicates by checking only one combination of point of origin and destination.</strong>
                                        </v-flex>  
                                    </v-layout>
                                    <v-layout class="pa-3">
                                        <v-flex xs12>
                                            <table v-if="external.matrix.couriers.find(a => a.courier === 'WALL STREET COURIER SERVICES INC.')" class="caption" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6">
                                                            Normal Rates for NinjaVan
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th rowspan="2">Point of Origin</th>
                                                        <th colspan="6">Destination</th>
                                                    </tr>
                                                    <tr>
                                                        <th v-for="island in islandGroups" :key="island">
                                                            {{ island }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        style="text-align:center"
                                                        v-for="(origin, index2) in ninjaTable.origins"
                                                        :key="index2"
                                                    >
                                                        <td>
                                                            {{ origin.origin }}
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin.origin}-ncr`" :value="`${origin.origin}-NCR`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-luzon" :value="`${origin.origin}-LUZON`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-visayas" :value="`${origin.origin}-VISAYAS`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-mindanao" :value="`${origin.origin}-MINDANAO`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-island" :value="`${origin.origin}-ISLAND`">
                                                        </td>
                                                    </tr>
                                                </tbody>    
                                            </table>

                                            <v-layout v-if="external.matrix.couriers.find(a => a.courier === 'WALL STREET COURIER SERVICES INC.')">
                                                <v-flex xs2>
                                                    <p class="mt-5 ml-5 text--secondary">Dates Covered</p>
                                                </v-flex>
                                                <v-flex xs2>
                                                    <v-text-field v-model="ninjaTable.normalRates.deliveryEffectivityDate" class="mt-3" dense outlined disabled></v-text-field>
                                                </v-flex>
                                                <v-flex xs2>
                                                    <v-text-field v-model="ninjaTable.normalRates.deliveryExpiryDate" class="ml-2 mt-3" dense outlined disabled></v-text-field>
                                                </v-flex>
                                            </v-layout>

                                            <table v-if="external.matrix.couriers.find(a => a.courier === 'PH GLOBAL JET EXPRESS, INC.')" class="mt-5 caption" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6">
                                                            Normal Rates for J&T
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th rowspan="2">Point of Origin</th>
                                                        <th colspan="6">Destination</th>
                                                    </tr>
                                                    <tr>
                                                        <th v-for="island in islandGroups" :key="island">
                                                            {{ island }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        style="text-align:center"
                                                        v-for="(origin, index3) in jntTable.origins"
                                                        :key="index3"
                                                    >
                                                        <td>
                                                            {{ origin.origin }}
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin.origin}-ncr`" :value="`${origin.origin}-NCR`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-luzon" :value="`${origin.origin}-LUZON`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-visayas" :value="`${origin.origin}-VISAYAS`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-mindanao" :value="`${origin.origin}-MINDANAO`">
                                                        </td>
                                                        <td>
                                                            <input type="checkbox" v-model="origin.routes" name="route" id="`${origin}`-island" :value="`${origin.origin}-ISLAND`">
                                                        </td>
                                                    </tr>
                                                </tbody>    
                                            </table>

                                            <v-layout v-if="external.matrix.couriers.find(a => a.courier === 'PH GLOBAL JET EXPRESS, INC.')">
                                                <v-flex xs2>
                                                    <p class="mt-5 ml-5 text--secondary">Dates Covered</p>
                                                </v-flex>
                                                <v-flex xs2>
                                                    <v-text-field v-model="jntTable.normalRates.deliveryEffectivityDate" class="mt-3" dense outlined disabled></v-text-field>
                                                </v-flex>
                                                <v-flex xs2>
                                                    <v-text-field v-model="jntTable.normalRates.deliveryExpiryDate" class="ml-2 mt-3" dense outlined disabled></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="removeSummaryRoutes" tile depressed>Clear all</v-btn>
                                        <v-btn @click="saveSummaryRoutes" class="ml-5 white--text" color="green" tile depressed>Submit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <!-- redesign -->
           
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="action === 'update'" @click="updateSetup">Update</v-btn>
                <v-btn color="primary" v-if="action === 'add'" @click="saveSetup">Save</v-btn>
                <v-btn color="warning" @click="resetForm(),$router.push('/setups/delivery-fee')">Cancel</v-btn>
            </v-card-actions>
        </v-form>

        <v-dialog
            v-model="modalAttribute.modal"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-toolbar
                dark
                color="primary"
                >
                <v-toolbar-title>Setup New Matrix</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="modalAttribute.modal = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card tile>
                <v-card-text>
                    <v-form ref="form">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-model="newMetric.metric.min"
                                        label="Min.*"
                                        outlined
                                        required
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-model="newMetric.metric.max"
                                        label="Max.*"
                                        outlined
                                        required
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-if="newMetric.metric.unit === 'km'"
                                        v-model="newMetric.metric.unit"
                                        label="unit*"
                                        outlined
                                        disabled
                                    ></v-text-field>
                                    
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-select
                                        v-if="newMetric.metric.unit === 'kg' || newMetric.metric.unit === 'g'"
                                        v-model="newMetric.metric.unit"
                                        label="unit*"
                                        outlined
                                        :items="['kg', 'g']"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <div>
                                <v-row 
                                
                                    v-for="(i, island) in newMetric.islandGrpValues" :key="island"
                                >
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        outlined
                                        :label="i.island"
                                        disabled                                  
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        v-model="i.amount"
                                        type="number"
                                        outlined
                                        label="Amount"
                                        :rules="[rules.required]"                                 
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </div> 
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelMetric" tile depressed>Cancel</v-btn>
                    <v-btn @click="savedMetric" lass="white--text" color="info" tile depressed>Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="promoModal"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-toolbar
                dark
                color="primary"
                >
                <v-toolbar-title>Setup New Matrix</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="promoModal = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card tile>
                <v-card-text>
                    <v-form ref="form">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-select
                                        v-model="newPromo.origin"
                                        label="Origin"
                                        outlined
                                        required
                                        :items="islandGroups"
                                        :rules="[rules.required]"
                                    >
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="8"
                                >
                                    <v-select
                                        v-model="newPromo.packages.package"
                                        label="Pouch"
                                        outlined
                                        required
                                        :items="pouchLists"
                                        :rules="[rules.required]"
                                    >
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <div>
                                <v-row 
                                
                                    v-for="(island, index) in newPromo.packages.values" :key="index"
                                >
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            outlined
                                            :label="island.destination"
                                            disabled                                  
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            v-model="island.amount"
                                            type="number"
                                            outlined
                                            label="Amount"
                                            :rules="[rules.required]"                                 
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </div> 
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="promoModal = false" tile depressed>Cancel</v-btn>
                    <v-btn @click="addPromoRate" class="white--text" color="info" tile depressed>Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import { api } from '@/configs/api'
import {required, minMaxValue} from '@/plugins/validation'
import Snackbar from '@/components/Snackbar'
import _ from 'lodash'
export default {
    components: {
        Snackbar
    },
    data() {
        return {
            productType: "",
            coverageAreas: ['Island Group'],
            providerService: "Internal Delivery",
            originHolder: "NCR",
            islandGroups:[
                "NCR",
                "LUZON",
                "VISAYAS",
                "MINDANAO",
                "ISLAND"
            ],
            matrices: [],
            couriers: [],
            rates: [],
            courierHolder: "",
            setupTypeHolder: "",
            basedOn: ['kg'],
            flatRate: {
                provider: "",
                rate: "",
                deliveryEffectivityDate: moment(new Date).format("YYYY-MM-DD"),
                deliveryExpiryDate:  moment(new Date).endOf('year').format("YYYY-MM-DD"),
                active: 0
            },
            freeDeliveryFee: {
                provider: "",
                deliveryEffectivityDate: moment(new Date).format("YYYY-MM-DD"),
                deliveryExpiryDate: moment(new Date).endOf('year').format("YYYY-MM-DD"),
                active: 0
            },
            setupTypes: ["Setup Matrix"],
            internal: {
                matrix: {
                    provider: "GIPI",
                    deliveryEffectivityDate: moment(new Date).format("YYYY-MM-DD"),
                    deliveryExpiryDate: moment(new Date).endOf('year').format("YYYY-MM-DD"),
                    coverageArea: "Island Group",
                    origins: [{
                        origin: "",
                        maxCeilingAmount: "",
                        additionalAmountperKm: "",
                        table: [],
                    }]
                },
            },
            external: {
                matrix: {
                    couriers: [{
                        courier: "",
                        coverageArea: "Island Group",
                        originHolder: "",
                        origins: [{
                            origin: "",
                            based: "",
                            routes: [],
                            table: []
                        }],
                        normalRates: {
                            deliveryEffectivityDate: moment(new Date).format("YYYY-MM-DD"),
                            deliveryExpiryDate: moment(new Date).endOf('year').format("YYYY-MM-DD"),
                            active: 0
                        },
                        promoRates: {
                            deliveryEffectivityDate: moment(new Date).format("YYYY-MM-DD"),
                            deliveryExpiryDate: moment(new Date).endOf('year').format("YYYY-MM-DD"),
                            rates: [],
                            active: 0
                        },
                        setupTypes: [],
                        normalRateMenu1: false,
                        normalRateMenu2: false,
                        promoRateMenu1: false,
                        promoRateMenu2: false,
                    }],
                },
            },
            newMetric: {
                type: '',
                area: 'island',
                mode: '',
                origin: '',
                courier: '',
                metric: {
                    min: null,
                    max: null,
                    unit: 'km'
                },
                islandGrpValues: [
                    {
                        island: 'NCR',
                        amount: 0
                    },
                    {
                        island: 'LUZON',
                        amount: 0
                    },
                    {
                        island: 'VISAYAS',
                        amount: 0
                    },
                    {
                        island: 'MINDANAO',
                        amount: 0
                    },
                    {
                        island: 'ISLAND',
                        amount: 0
                    }
                ],
            },
            flatRateMenu1: false,
            flatRateMenu2: false,
            freeDeliveryFeeMenu1: false,
            freeDeliveryFeeMenu2: false,
            setupMatrixmenu1: false,
            setupMatrixmenu2: false,
            summaryDialog: false,
            promoModal : false,
            records: [],
            format: "YYYY-MM-DD",
            rules: {
                required,
                minMaxValue
            },
            action: "",
            snackbar: {
				responseMessage: "",
				show: false,
				type: "success"
			},
            today: moment(new Date).format("YYYY-MM-DD"),
            yearEnd: moment(new Date).endOf('year').format("YYYY-MM-DD"),
            matrixForDeletion: [],
            componentKey: 0,
            promoComponent: 0,
            modalAttribute: {
                modal: false,
                courIndex: null,
                originIndex: null
            },
            promoCourIndex: null,
            newPromo: {
                courier: "",
                origin: "",
                packages: {
                    package: "",
                    values: [
                        {
                            destination: 'NCR',
                            amount: 0,
                        },
                        {
                            destination: 'LUZON',
                            amount: 0,
                        },
                        {
                            destination: 'VISAYAS',
                            amount: 0,
                        },
                        {
                            destination: 'MINDANAO',
                            amount: 0,
                        },
                        {
                            destination: 'ISLAND',
                            amount: 0,
                        }
                    ]
                }
            },
            freeDeliveryFeeHasValues: false,
            flatRateHasValues: false,
            promoRateHasValues: false,
            spNormalRatesHasValues: false,
            pouchLists: []
        }
    },
    watch: {
        productType(value) {
            if(value === 'Motorcycles') {
                let mode = 'internal'
                this.fetchMatrixTable(mode)
            } else {
                let mode = 'external'
                this.fetchMatrixTable(mode)
            }
        },
        setupTypes: {
            handler: function(val) {
                this.validation(val)

                if((val.includes('Enable Free Delivery Fee') && this.freeDeliveryFeeHasValues) && (val.includes('Enable Flat Rate') && this.flatRateHasValues)) {
                    let dateRangesOverlap = (this.flatRate.deliveryEffectivityDate > this.freeDeliveryFee.deliveryEffectivityDate ? this.flatRate.deliveryEffectivityDate : this.freeDeliveryFee.deliveryEffectivityDate) <= (this.flatRate.deliveryExpiryDate < this.freeDeliveryFee.deliveryExpiryDate ? this.flatRate.deliveryExpiryDate : this.freeDeliveryFee.deliveryExpiryDate)
                    if(dateRangesOverlap) {
                        this.showSnackbarMessage("Free delivery setup cannot overlap with Flat rate setups. Please select non-overlapping effectivity date and expiry date.", false)
                        this.flatRate.deliveryEffectivityDate = null
                        this.flatRate.deliveryExpiryDate = null
                        this.flatRateHasValues = false
                        this.freeDeliveryFee.deliveryEffectivityDate = null
                        this.freeDeliveryFee.deliveryExpiryDate = null
                        this.freeDeliveryFeeHasValues = false
                    }
                }
            },
            deep: true
        },
        providerService(val) {
            let self = this
            if (val === "Internal Delivery") {
                self.external.matrix.couriers = []
                self.external.matrix.couriers.push({
                    courier: "",
                    coverageArea: "Island Group",
                    originHolder: "",
                    origins: [{
                        origin: "",
                        based: "",
                        table: []
                    }],
                    normalRates: {
                        deliveryEffectivityDate: self.today,
                        deliveryExpiryDate: self.yearEnd,
                    },
                    promoRates: {
                        deliveryEffectivityDate: self.today,
                        deliveryExpiryDate: self.yearEnd,
                        rates: []
                    },
                    setupTypes: []
                })
            } else if (val === "Third-Party Courier Service") {
                self.internal.matrix.deliveryEffectivityDate = self.today,
                self.internal.matrix.deliveryExpiryDate = self.yearEnd,
                self.internal.matrix.origins = []
                self.internal.matrix.origins.push({
                    origin: "",
                    maxCeilingAmount: "",
                    additionalAmountperKm: "",
                })
            }
            // self.resetSetupTypes()
        },
        'internal.matrix.deliveryEffectivityDate': function (value) {
            if(value > this.internal.matrix.deliveryExpiryDate) {
                this.showSnackbarMessage("Expiry Date is a past date. Please provide the correct future date for Internal Delivery's Expiry Date.", false)
                this.internal.matrix.deliveryExpiryDate = null
            }
        },
        'freeDeliveryFee.deliveryEffectivityDate': function (value) {
            if(value > this.freeDeliveryFee.deliveryExpiryDate) {
                this.showSnackbarMessage("Expiry Date is a past date. Please provide the correct future date for Free Delivery Expiry Date.", false)
                this.freeDeliveryFee.deliveryExpiryDate = null
                this.freeDeliveryFeeHasValues = false
            }
        },
        'flatRate.deliveryEffectivityDate': function (value) {
            if(value > this.flatRate.deliveryExpiryDate) {
                this.showSnackbarMessage("Expiry Date is a past date. Please provide the correct future date for Flat Rate Expiry Date.", false)
                this.flatRate.deliveryExpiryDate = null
                this.flatRateHasValues = false
            }
        },
        freeDeliveryFee: {
            handler: function(value) {
                if(value.deliveryEffectivityDate != null && value.deliveryExpiryDate != null && value.active == 1) this.freeDeliveryFeeHasValues = true

                if(this.action === 'update') {
                    if(value && this.setupTypes.includes('Setup Matrix') && this.productType == 'Motorcycles' && this.freeDeliveryFee.active == 1) {
                        if(this.internal.matrix.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate 
                        && this.internal.matrix.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                            this.showSnackbarMessage("Default rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.freeDeliveryFee.deliveryEffectivityDate = null
                            this.freeDeliveryFee.deliveryExpiryDate = null
                            this.freeDeliveryFeeHasValues = false
                        }
                    }
                    if(value && this.productType == 'Parts and Accessories') {
                        for(let i = 0; i < this.external.matrix.couriers.length; i++) {
                            let activePromo = this.external.matrix.couriers[i].normalRates.active == 1 ? 'Normal Rates' : 'Promo Rates'
                            if(activePromo === 'Normal Rates') {
                                if(this.external.matrix.couriers[i].normalRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate
                                    && this.external.matrix.couriers[i].normalRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                                    this.showSnackbarMessage("Normal rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                                    this.freeDeliveryFee.deliveryEffectivityDate = null
                                    this.freeDeliveryFee.deliveryExpiryDate = null
                                    this.freeDeliveryFeeHasValues = false
                                }
                            } else {
                                if(this.external.matrix.couriers[i].promoRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate
                                    && this.external.matrix.couriers[i].promoRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                                    this.showSnackbarMessage("Promo rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                                    this.freeDeliveryFee.deliveryEffectivityDate = null
                                    this.freeDeliveryFee.deliveryExpiryDate = null
                                    this.freeDeliveryFeeHasValues = false
                                }
                            }
                        }
                    }
                }
            },
            deep: true
        },
        freeDeliveryFeeHasValues(value) {
            if(value && this.flatRateHasValues) {
                let dateRangesOverlap = (this.flatRate.deliveryEffectivityDate > this.freeDeliveryFee.deliveryEffectivityDate ? this.flatRate.deliveryEffectivityDate : this.freeDeliveryFee.deliveryEffectivityDate) <= (this.flatRate.deliveryExpiryDate < this.freeDeliveryFee.deliveryExpiryDate ? this.flatRate.deliveryExpiryDate : this.freeDeliveryFee.deliveryExpiryDate)
                if(dateRangesOverlap) {
                    this.showSnackbarMessage("Free delivery setup cannot overlap with Flat rate setups. Please select non-overlapping effectivity date and expiry date.", false)
                    this.flatRate.deliveryEffectivityDate = null
                    this.flatRate.deliveryExpiryDate = null
                    this.freeDeliveryFee.deliveryEffectivityDate = null
                    this.freeDeliveryFee.deliveryExpiryDate = null
                    this.flatRateHasValues = false
                    this.freeDeliveryFeeHasValues = false
                }
            }
            if(value && this.setupTypes.includes('Setup Matrix') && this.productType == 'Motorcycles') {
                if(this.internal.matrix.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate 
                && this.internal.matrix.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                    this.showSnackbarMessage("Default rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.freeDeliveryFee.deliveryEffectivityDate = null
                    this.freeDeliveryFee.deliveryExpiryDate = null
                    this.freeDeliveryFeeHasValues = false
                }
            }
            if(value && this.productType == 'Parts and Accessories') {
                for(let i = 0; i < this.external.matrix.couriers.length; i++) {
                    let activePromo = this.external.matrix.couriers[i].normalRates.active == 1 ? 'Normal Rates' : 'Promo Rates'
                    if(activePromo === 'Normal Rates') {
                        if(this.external.matrix.couriers[i].normalRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate
                            && this.external.matrix.couriers[i].normalRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                            this.showSnackbarMessage("Normal rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.freeDeliveryFee.deliveryEffectivityDate = null
                            this.freeDeliveryFee.deliveryExpiryDate = null
                            this.freeDeliveryFeeHasValues = false
                        }
                    } else {
                        if(this.external.matrix.couriers[i].promoRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate
                            && this.external.matrix.couriers[i].promoRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                            this.showSnackbarMessage("Promo rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.freeDeliveryFee.deliveryEffectivityDate = null
                            this.freeDeliveryFee.deliveryExpiryDate = null
                            this.freeDeliveryFeeHasValues = false
                        }
                    }
                }
            }
        },
        flatRate: {
            handler: function(value) {
                if(value.deliveryEffectivityDate != null && value.deliveryExpiryDate != null && value.active == 1) this.flatRateHasValues = true

                if(this.action === 'update') {
                    if(value && this.setupTypes.includes('Setup Matrix') && this.productType == 'Motorcycles' && this.flatRate.active == 1) {
                        if(this.internal.matrix.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate 
                        && this.internal.matrix.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                            this.showSnackbarMessage("Default rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.flatRate.deliveryEffectivityDate = null
                            this.flatRate.deliveryExpiryDate = null
                            this.flatRateHasValues = false
                        }
                    }
                    if(value && this.productType == 'Parts and Accessories') {
                        for(let i = 0; i < this.external.matrix.couriers.length; i++) {
                            let activePromo = this.external.matrix.couriers[i].normalRates.active == 1 ? 'Normal Rates' : 'Promo Rates'
                            if(activePromo === 'Normal Rates') {
                                if(this.external.matrix.couriers[i].normalRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate
                                    && this.external.matrix.couriers[i].normalRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                                    this.showSnackbarMessage("Normal rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                                    this.flatRate.deliveryEffectivityDate = null
                                    this.flatRate.deliveryExpiryDate = null
                                    this.flatRateHasValues = false
                                }
                            } else {
                                if(this.external.matrix.couriers[i].promoRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate
                                    && this.external.matrix.couriers[i].promoRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                                    this.showSnackbarMessage("Promo rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                                    this.flatRate.deliveryEffectivityDate = null
                                    this.flatRate.deliveryExpiryDate = null
                                    this.flatRateHasValues = false
                                }
                            }
                        }
                    }
                }
            },
            deep: true
        },
        flatRateHasValues(value) {
            if(value && this.freeDeliveryFeeHasValues) {
                let dateRangesOverlap = (this.freeDeliveryFee.deliveryEffectivityDate > this.flatRate.deliveryEffectivityDate ? this.freeDeliveryFee.deliveryEffectivityDate : this.flatRate.deliveryEffectivityDate) <= (this.freeDeliveryFee.deliveryExpiryDate < this.flatRate.deliveryExpiryDate ? this.freeDeliveryFee.deliveryExpiryDate : this.flatRate.deliveryExpiryDate)
                if(dateRangesOverlap) {
                    this.showSnackbarMessage("Free delivery setup cannot overlap with Flat rate setups. Please select non-overlapping effectivity date and expiry date.", false)
                    this.flatRate.deliveryEffectivityDate = null
                    this.flatRate.deliveryExpiryDate = null
                    this.freeDeliveryFee.deliveryEffectivityDate = null
                    this.freeDeliveryFee.deliveryExpiryDate = null
                    this.flatRateHasValues = false
                    this.freeDeliveryFeeHasValues = false
                }
            }
            if(value && this.setupTypes.includes('Setup Matrix') && this.productType == 'Motorcycles') {
                if(this.internal.matrix.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate 
                && this.internal.matrix.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                    this.showSnackbarMessage("Default rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.flatRate.deliveryEffectivityDate = null
                    this.flatRate.deliveryExpiryDate = null
                    this.flatRateHasValues = false
                }
            }
            if(value && this.productType == 'Parts and Accessories') {
                for(let i = 0; i < this.external.matrix.couriers.length; i++) {
                    let activePromo = this.external.matrix.couriers[i].normalRates.active == 1 ? 'Normal Rates' : 'Promo Rates'
                    if(activePromo === 'Normal Rates') {
                        if(this.external.matrix.couriers[i].normalRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate
                            && this.external.matrix.couriers[i].normalRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                            this.showSnackbarMessage("Normal rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.flatRate.deliveryEffectivityDate = null
                            this.flatRate.deliveryExpiryDate = null
                            this.flatRateHasValues = false
                        }
                    } else {
                        if(this.external.matrix.couriers[i].promoRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate
                            && this.external.matrix.couriers[i].promoRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                            this.showSnackbarMessage("Promo rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                            this.flatRate.deliveryEffectivityDate = null
                            this.flatRate.deliveryExpiryDate = null
                            this.flatRateHasValues = false
                        }
                    }
                }
            }
        },
    },
    computed: {
        ninjaTable() {
            let emptyCourier = this.external.matrix.couriers.find(c => c.courier === "")

            if(!emptyCourier) {
                let originObj = this.external.matrix.couriers.find(o => o.courier === 'WALL STREET COURIER SERVICES INC.')
                return originObj
            }
        },
        jntTable() {
            let emptyCourier = this.external.matrix.couriers.find(c => c.courier === "")

            if(!emptyCourier) {
                let originObj = this.external.matrix.couriers.find(o => o.courier === 'PH GLOBAL JET EXPRESS, INC.')
                return originObj
            }
        }
    },
    async mounted() {
        await this.fetchCouriers()
        await this.fetchMatrixTable()
        await this.fetchPackagingPouches()
        await this.fetchPromoRates()

        this.id = this.$route.params.id
        
		if (this.id === "new"){
            this.action = "add"
		} else {
            await this.loadSetup()
        }
    },
    methods: {
        getDefaultFilter() {
            return {
                type: '',
                area: 'island',
                mode: '',
                courier: '',
                metric: {
                    min: null,
                    max: null,
                    unit: 'km'
                },
                islandGrpValues: [
                    {
                        island: 'NCR',
                        amount: null
                    },
                    {
                        island: 'LUZON',
                        amount: null
                    },
                    {
                        island: 'VISAYAS',
                        amount: null
                    },
                    {
                        island: 'MINDANAO',
                        amount: null
                    },
                    {
                        island: 'ISLAND',
                        amount: null
                    }
                ],
            }
        },
        getDefaultPromo() {
            return {
                courier: "",
                origin: "",
                packages: {
                    package: "",
                    values: [
                        {
                            destination: 'NCR',
                            amount: 0,
                        },
                        {
                            destination: 'LUZON',
                            amount: 0,
                        },
                        {
                            destination: 'VISAYAS',
                            amount: 0,
                        },
                        {
                            destination: 'MINDANAO',
                            amount: 0,
                        },
                        {
                            destination: 'ISLAND',
                            amount: 0,
                        }
                    ]
                }
            }
        },
        async loadSetup() {
            const self = this
            await self.$axios.get(`/setup/delivery-fee/${self.id}`).then((result) => {
                self.records = result.data.data
            }).then(()=> {
                self.action = "update"
                self.internal = self.records.internal
                self.external = self.records.external
                self.productType = self.records.productType
                self.providerService = self.records.type
                self.freeDeliveryFee = self.records.freeDelivery
                self.flatRate = self.records.flatRate
                self.setupTypes = self.records.setups
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        async saveSetup() {
			const isValid = await this.$refs.deliveryFeeForm.validate()
            // const isStandart = await this.standardSetting()
			let self = this
            let body = {
                productType: self.productType,
                setups: self.setupTypes,
                type: self.providerService,
                internal: self.internal,
                external: self.external,
                freeDelivery: self.freeDeliveryFee,
                flatRate: self.flatRate,
            }

            if(!isValid) {
                return
            } else {
                self.$axios.post(`/setup/delivery-fee/create`, body).then(response => {
                    if(response.data.existing) {
                        self.showSnackbarMessage("Existing setup. Please update existing setup or add a different setup.", false)

                        setTimeout(() => {
                            self.resetForm()
                            self.$router.push("/setups/delivery-fee")
                        }, 3000)
                    } else {
                        self.resetForm()
                        self.$router.push("/setups/delivery-fee")
                    }
                })
            }
        },
        updateSetup() {
            let self = this
            let body = {
                productType: self.productType,
                setups: self.setupTypes,
                type: self.providerService,
                internal: self.internal,
                external: self.external,
                freeDelivery: self.freeDeliveryFee,
                flatRate: self.flatRate,
            }
            const isValid = this.$refs.deliveryFeeForm.validate()

            if(!isValid) return

            this.$axios.put(`/setup/delivery-fee/update/${this.id}`, body).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
				this.$router.push("/setups/delivery-fee")
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        async fetchCouriers() {
            const self = this
            try {
                let response = await self.$axios.get(`/setups/couriers`)
                self.couriers = response.data.data
                self.couriers.forEach(courier => {
                    courier.coverageArea = 'Island Group'
                })
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchMatrixTable() {
            const self = this

            try {
                let response = await self.$axios.get(`/setup/base/amount`)
                self.matrices = response.data.data
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchPackagingPouches() {
            const self = this
            
            try {
                let response = await self.$axios.get(`${api}/setup/pouch/sizes`)
                response.data.pouches.forEach((pouch) => {
                    self.pouchLists.push(pouch.name)
                })
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchPromoRates() {
            let self = this

            try {
                let response = await self.$axios.get(`${api}/setup/promo/rates`)
                self.rates = response.data.rates
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async addOrigin() {
            let self = this
            let selectedOrigin = self.internal.matrix.origins.find(element => element.origin == self.originHolder);
            let itemIndex = self.internal.matrix.origins.findIndex(element => element.origin == "");
            if(itemIndex >=0) {
                self.internal.matrix.origins.splice(itemIndex,1);
            }
            if (selectedOrigin) {
                self.showSnackbarMessage("This origin already exists.", false)
            } else {
                self.internal.matrix.origins.push({
                    origin: self.originHolder,
                    maxCeilingAmount: "",
                    additionalAmountperKm: "",
                    table: await self.matrices.filter(r => r.origin === self.originHolder && r.mode === 'internal'  && r.metric.unit === 'km')
                })
            }
        },
        removeOrigin(index) {
            let self = this 
            self.internal.matrix.origins.splice(index,1)
        },
        addCourier() {
            let self = this 
            let selectedCourier = self.external.matrix.couriers.find(element => element.courier == self.courierHolder.courier);
            let itemIndex = self.external.matrix.couriers.findIndex(element => element.courier == "");
            if(itemIndex >=0) {
                self.external.matrix.couriers.splice(itemIndex,1);
            }
            
            if (selectedCourier) {
                self.showSnackbarMessage("This courier already exists.", false)
            } else {
                self.external.matrix.couriers.push({
                    courier: self.courierHolder.courier,
                    originHolder: "",
                    coverageArea: "Island Group",
                    origins: [{origin:"", based: "", table: [], routes: []}],
                    normalRates: {
                        deliveryEffectivityDate: self.today,
                        deliveryExpiryDate: self.yearEnd,
                    },
                    promoRates: {
                        deliveryEffectivityDate: self.today,
                        deliveryExpiryDate: self.yearEnd,
                        rates: self.courierHolder.courier === 'PH GLOBAL JET EXPRESS, INC.' ? self.rates : [],
                        active: 0
                    },
                    setupTypes: []
                })
            }
        },
        removeCourier(index) {
            let self = this 
            self.external.matrix.couriers.splice(index,1)
        },
        addCourierOrigin(index) {
            let self = this 
            let selectedCourierOrigin = self.external.matrix.couriers[index].origins.find(element => element.origin == self.external.matrix.couriers[index].originHolder);
            let itemIndex = self.external.matrix.couriers[index].origins.findIndex(element => element.origin == "");
            if(itemIndex >=0) {
                self.external.matrix.couriers[index].origins.splice(itemIndex,1);
            }
            if(selectedCourierOrigin) {
                self.showSnackbarMessage("This origin already exists.", false)
            } else {
                self.external.matrix.couriers[index].origins.push({
                    origin: self.external.matrix.couriers[index].originHolder,
                    based: "",
                    routes: [],
                    table: []
                })
            }
        },
        removeCourierOrigin(index1, index2) {
            let self = this 
            self.external.matrix.couriers[index1].origins.splice(index2,1)
        },
        resetForm() {
            this.$refs.deliveryFeeForm.reset()
            this.$refs.deliveryFeeForm.resetValidation()
            this.$emit('close-form')
        },
        async save(item) {
            this.showSnackbarMessage("Date saved", true)

            let result = await this.$axios.put(`${api}/setup/base/amount/update`, item)
            console.log(result)
        },
        async savePromoUpdate(item) {
            this.showSnackbarMessage("Date saved", true)

            let response = await this.$axios.put(`${api}/setup/promo/update`, item)
            console.log(response)
        },
        async onBasedSelect(courIndex, originIndex,index,index2) {
            const self = this
            let mode = 'external'
            self.external.matrix.couriers[index].origins[index2].table = await self.matrices.filter(m => m.courier === courIndex.courier && (m.mode === mode && m.metric.unit === originIndex.based || 'g'))
        },
        openModal(index, index2) {
            const self = this
            console.log(index)

            if(self.productType === 'Motorcycles') {
                self.newMetric.mode = 'internal'
                self.newMetric.origin = self.internal.matrix.origins[index].origin
                self.modalAttribute.originIndex = index
                self.modalAttribute.modal = true
            } else if(self.productType === 'Parts and Accessories'){
                self.newMetric.mode = 'external'
                self.newMetric.metric.unit = self.external.matrix.couriers[index].origins[index2].based === 'kg' ? 'kg' : 'km'
                self.newMetric.courier = self.external.matrix.couriers[index].courier
                self.modalAttribute.courIndex = index
                self.modalAttribute.originIndex = index2
                self.modalAttribute.modal = true
            } else {
                self.showSnackbarMessage("Please select Product type", false)
            }

        },
        cancelMetric() {
            this.modalAttribute.modal = false
        },
        async savedMetric() {
            this.newMetric.type = this.productType

            try {
                if(this.$refs.form.validate()) {
                    let response = await this.$axios.post(`${api}/setup/new/metric`, this.newMetric)
                    let responseData = response.data.data
                    console.log(responseData)

                    if(this.productType === 'Motorcycles') {
                        if(this.action === 'add') {
                            this.reloadMCinternalTable(this.modalAttribute.originIndex, this.newMetric.origin)
                        } else {
                            this.reloadMCinternalTable(this.modalAttribute.originIndex, this.newMetric.origin)
                        }
                    } else {
                        this.reloadPartsTable(this.modalAttribute.courIndex, this.modalAttribute.originIndex)
                    }

                    this.modalAttribute.modal = false
                    this.newMetric = this.getDefaultFilter()
                } else {
                    this.showSnackbarMessage("Fill up required fields.", false)
                }
            } catch (error) {
                this.showSnackbarMessage(error.message, false)
            }
        },
        async reloadMCinternalTable(index, origin) {
            if(this.action === 'add') {
                await this.fetchMatrixTable()
                this.internal.matrix.origins[index].table = await this.matrices.filter(r => r.origin === origin && r.mode === 'internal' && r.metric.unit === 'km')
                this.forceRenderer()
            } else {
                await this.fetchMatrixTable()
                this.internal.matrix.origins[index].table = await this.matrices.filter(r => r.origin === origin && r.mode === 'internal' && r.metric.unit === 'km')
                this.forceRenderer()
            }
        },
        async reloadPartsTable(index1, index2) {
            if(this.action === 'add') {
                await this.fetchMatrixTable()
                this.external.matrix.couriers[index1].origins[index2].table = await this.matrices.filter(m => m.courier === this.external.matrix.couriers[index1].courier && (m.mode === 'external' && m.metric.unit === 'kg' || m.metric.unit === 'g'))
                this.forceRenderer()
            } else {
                await this.fetchMatrixTable()
                this.external.matrix.couriers[index1].origins[index2].table = await this.matrices.filter(m => m.courier === this.external.matrix.couriers[index1].courier && (m.mode === 'external' && m.metric.unit === 'kg' || m.metric.unit === 'g'))
                this.forceRenderer()
            }
           
        },
        async reloadPromoTable(index) {
            this.external.matrix.couriers[index].promoRates.rates = await this.rates
            this.promoRenderer()
        },
        forceRenderer() {
            this.componentKey +=1
        },
        promoRenderer() {
            this.promoComponent +=1
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        validation(val) {
            let self = this
            if (val.includes('Enable Free Delivery Fee')) {
                self.freeDeliveryFee.provider = self.providerService
                self.freeDeliveryFee.active = 1
            } else {
                self.freeDeliveryFee.provider = ""
                self.freeDeliveryFee.active = 0
            }
            if (self.setupTypes.includes('Enable Flat Rate')) {
                self.flatRate.provider = self.providerService
                self.flatRate.active = 1
            } else {
                self.flatRate.rate = ""
                self.flatRate.provider = ""
                self.flatRate.active = 0
            }
        },
        standardSetting() {
            let self = this
            let list = []
            let isNormalRateDateEmpty, isNormalRateExpirationDateValid, isFlatRateDateEmpty,isFlatRateExpirationDateValid, isFreeDeliveryDateEmpty, isFreeDeliveryExpirationDateValid = null
            if (self.providerService === "Internal Delivery") {
                isNormalRateDateEmpty = self.internal.matrix.deliveryEffectivityDate != "" &&
                self.internal.matrix.deliveryExpiryDate != ""
                isNormalRateDateEmpty == true ? isNormalRateDateEmpty = true : isNormalRateDateEmpty = "Invalid Expiry Date. Please provide a valid Expiry Date for Normal Rates."

                isNormalRateExpirationDateValid = 
                self.internal.matrix.deliveryExpiryDate > self.internal.matrix.deliveryEffectivityDate
                isNormalRateExpirationDateValid == true ? isNormalRateExpirationDateValid = true : isNormalRateExpirationDateValid = "Expiry Date is a past date. Please provide the correct future date for Normal Rates' Expiry Date."
                
                list.push(isNormalRateDateEmpty)
                list.push(isNormalRateExpirationDateValid)
            }
            if (self.setupTypes.includes("Enable Flat Rate")) {
                isFlatRateDateEmpty = self.flatRate.deliveryEffectivityDate != ""  && self.internal.matrix.deliveryExpiryDate != ""
                isFlatRateDateEmpty == true ? isFlatRateDateEmpty = true : isFlatRateDateEmpty = "Invalid Expiry Date. Please provide a valid Expiry Date for Flat Rates."

                isFlatRateExpirationDateValid = self.flatRate.deliveryExpiryDate > self.flatRate.deliveryEffectivityDate
                isFlatRateExpirationDateValid == true ?  isFlatRateExpirationDateValid = true : isFlatRateExpirationDateValid = "Expiry Date is a past date. Please provide the correct future date for Flat Rates' Expiry Date."
                list.push(isFlatRateDateEmpty)
                list.push(isFlatRateExpirationDateValid)
            }
            
            if (self.setupTypes.includes("Enable Free Delivery Fee")) {
                isFreeDeliveryDateEmpty = self.freeDeliveryFee.deliveryEffectivityDate != ""  && self.internal.matrix.deliveryExpiryDate != ""
                isFreeDeliveryDateEmpty == true ? isFreeDeliveryDateEmpty = true : isFreeDeliveryDateEmpty = "Invalid Expiry Date. Please provide a valid Expiry Date for Free Delivery."

                isFreeDeliveryExpirationDateValid = self.freeDeliveryFee.deliveryExpiryDate > self.freeDeliveryFee.deliveryEffectivityDate 
                isFreeDeliveryExpirationDateValid == true ? isFreeDeliveryExpirationDateValid = true : isFreeDeliveryExpirationDateValid = "Expiry Date is a past date. Please provide the correct future date for Free Delivery' Expiry Date."

                list.push(isFreeDeliveryDateEmpty)
                list.push(isFreeDeliveryExpirationDateValid)
            }

            return containsBool(list)
        },
        async saveSummaryRoutes() {
            const self = this
            let ninjaRoutesArray = []
            let jntRoutesArray = []
            let ninjaVan = self.external.matrix.couriers.find(a => a.courier === 'WALL STREET COURIER SERVICES INC.')
            let jnt = self.external.matrix.couriers.find(a => a.courier === 'PH GLOBAL JET EXPRESS, INC.')
            
            if(ninjaVan) {
                ninjaVan.origins.forEach(origin => {
                    origin.routes.forEach(route => {
                        ninjaRoutesArray.push(route)
                    })
                })
            }

            if(jnt) {
                jnt.origins.forEach(origin => {
                    origin.routes.forEach(route => {
                        jntRoutesArray.push(route)
                    })
                })
            }
            
            let isFoundIdentical = ninjaRoutesArray.some(r => jntRoutesArray.includes(r))

            if(isFoundIdentical) {
                self.showSnackbarMessage("Duplicate routes for NinjaVan and J&T Express was found", false)
                self.removeSummaryRoutes()
            } else {
                self.summaryDialog = false
            }
        },
        removeSummaryRoutes() {
            const self = this

            self.external.matrix.couriers.forEach(courier => {
                courier.origins.forEach(origin => {
                    origin.routes = []
                })
            })
        },
        async deleteMatrixForInternal(index) {
            let isEmpty = !this.matrixForDeletion.length

            try {
                if(isEmpty) {
                    this.showSnackbarMessage("No matrix was selected", false)
                } else {
                    this.matrixForDeletion.forEach(async (metric) => {
                        let response = await this.$axios.put(`${api}/setup/delete/metric`, metric)
                        console.log(response)
                    })
                    this.reloadMCinternalTable(index, this.internal.matrix.origins[index].origin)
                }
            } catch (error) {
                this.showSnackbarMessage(error.message, false)
            }
        },
        async deleteMatrixForExternal(index1, index2) {
            const self = this
            let isEmpty = !self.matrixForDeletion.length

            if(isEmpty) {
                self.showSnackbarMessage("No matrix was selected", false)
            } else {
                self.matrixForDeletion.forEach(async (metric) => {
                    let response = await self.$axios.put(`${api}/setup/delete/metric`, metric)
                    console.log(response)
                })

                self.fetchMatrixTable()

                setTimeout(() => {
                    self.reloadPartsTable(index1, index2)
                }, 2000)
            }
        },
        async deleteMatrixForPromo(index) {
            const self = this
            let isEmpty = !self.matrixForDeletion.length

            if(isEmpty) {
                self.showSnackbarMessage("No matrix was selected", false)
            } else {
                self.matrixForDeletion.forEach(async (metric) => {
                    let response = await self.$axios.put(`${api}/setup/promo/delete`, metric)
                    console.log(response)
                })

                self.fetchPromoRates()

                setTimeout(() => {
                    self.reloadPromoTable(index)
                }, 2000)
            }
        },
        partsNormalRatesDateEffectivityValidation(index) {
            let effectivityDate = this.external.matrix.couriers[index].normalRates.deliveryEffectivityDate
            let expiryDate = this.external.matrix.couriers[index].normalRates.deliveryExpiryDate

            if(effectivityDate != null && expiryDate != null) this.spNormalRatesHasValues = true

            if(effectivityDate > expiryDate) {
                this.external.matrix.couriers[index].normalRateMenu1 = false
                this.showSnackbarMessage("Expiry Date is a past date. Please provide the correct future date for Normal Rate's Expiry Date.", false)
                this.external.matrix.couriers[index].normalRates.deliveryExpiryDate = null
            } else {
                this.external.matrix.couriers[index].normalRateMenu1 = false
            }

            if(this.spNormalRatesHasValues) this.spNormalRatesAndOtherOptionsValidation(index)
        },
        partsNormalRatesDateExpiryValidation(index) {
            let effectivityDate = this.external.matrix.couriers[index].normalRates.deliveryEffectivityDate
            let expiryDate = this.external.matrix.couriers[index].normalRates.deliveryExpiryDate

            this.external.matrix.couriers[index].normalRateMenu2 = false
            if(effectivityDate != null && expiryDate != null) this.spNormalRatesHasValues = true
            if(this.spNormalRatesHasValues) this.spNormalRatesAndOtherOptionsValidation(index)
        },
        promoRatesDateEffectivityValidation(index) {
            let effectivityDate = this.external.matrix.couriers[index].promoRates.deliveryEffectivityDate
            let expiryDate = this.external.matrix.couriers[index].promoRates.deliveryExpiryDate

            if(effectivityDate != null && expiryDate != null) this.promoRateHasValues = true

            if(effectivityDate > expiryDate) {
                this.external.matrix.couriers[index].promoRateMenu1 = false
                this.showSnackbarMessage("Expiry Date is a past date. Please provide the correct future date for Promo Rate's Expiry Date.", false)
                this.external.matrix.couriers[index].promoRates.deliveryExpiryDate = null
            } else {
                this.external.matrix.couriers[index].promoRateMenu1 = false
            }

            if(this.promoRateHasValues) this.promoRatesAndOtherOptionsValidation()
        },
        promoRatesDateExpiryValidation(index) {
            let effectivityDate = this.external.matrix.couriers[index].promoRates.deliveryEffectivityDate
            let expiryDate = this.external.matrix.couriers[index].promoRates.deliveryExpiryDate

            this.external.matrix.couriers[index].promoRateMenu2 = false
            if(effectivityDate != null && expiryDate != null) this.promoRateHasValues = true
            if(this.promoRateHasValues) this.promoRatesAndOtherOptionsValidation()
        },
        async addPromoRate() {
            try {
                if(this.$refs.form.validate()) {
                    let response = await this.$axios.post(`${api}/setup/promo/create`, this.newPromo)
                    this.promoModal = false
                    this.newPromo = this.getDefaultPromo()
                    this.fetchPromoRates()

                    setTimeout(() => {
                        this.reloadPromoTable(this.promoCourIndex)
                    }, 2000)
                } else {
                    this.showSnackbarMessage("All fields are required.", false)
                }
            } catch (error) {
                this.showSnackbarMessage(error.message, false)
            }
        },
        promoDatesValidation(index) {
            const self = this
            let types = self.external.matrix.couriers[index].setupTypes
            let jntIndex = self.external.matrix.couriers.findIndex(element => element.courier === 'PH GLOBAL JET EXPRESS, INC.')

            if(self.external.matrix.couriers[jntIndex].promoRates.deliveryEffectivityDate != null && self.external.matrix.couriers[jntIndex].promoRates.deliveryExpiryDate != null) self.promoRateHasValues = true

            if(types.includes('Promo Rates')) {
                self.external.matrix.couriers[jntIndex].promoRates.active = 1
            } else {
                self.external.matrix.couriers[jntIndex].promoRates.active = 0
            }

            this.promoRatesAndOtherOptionsValidation()
        },
        spNormalDatesValidation(index) {
            if(this.external.matrix.couriers[index].normalRates.deliveryEffectivityDate != null && this.external.matrix.couriers[index].normalRates.deliveryExpiryDate != null) this.spNormalRatesHasValues = true

            if(this.external.matrix.couriers[index].setupTypes.includes('Normal Rates')) {
                this.external.matrix.couriers[index].normalRates.active = 1
            } else {
                this.external.matrix.couriers[index].normalRates.active = 0
            }
            this.spNormalRatesAndOtherOptionsValidation(index)
        },
        openPromoModal(courIndex) {
            this.promoModal = true, 
            this.newPromo.courier = this.courierHolder.courier
            this.promoCourIndex = courIndex
        },
        promoRatesAndOtherOptionsValidation() {
            let condition = (this.promoRateHasValues && (this.freeDeliveryFeeHasValues || this.flatRateHasValues))
            
            console.log(condition)
            if(condition) {
                let jntIndex = this.external.matrix.couriers.findIndex(element => element.courier === 'PH GLOBAL JET EXPRESS, INC.')
            
                if(this.external.matrix.couriers[jntIndex].promoRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate 
                && this.external.matrix.couriers[jntIndex].promoRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                    this.showSnackbarMessage("Promo rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.external.matrix.couriers[jntIndex].promoRates.deliveryEffectivityDate = null
                    this.external.matrix.couriers[jntIndex].promoRates.deliveryExpiryDate = null
                    this.promoRateHasValues = false
                    this.freeDeliveryFee.deliveryEffectivityDate = null
                    this.freeDeliveryFee.deliveryExpiryDate = null
                    this.freeDeliveryFeeHasValues = false
                }

                if(this.external.matrix.couriers[jntIndex].promoRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate 
                && this.external.matrix.couriers[jntIndex].promoRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                    this.showSnackbarMessage("Promo rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.external.matrix.couriers[jntIndex].promoRates.deliveryEffectivityDate = null
                    this.external.matrix.couriers[jntIndex].promoRates.deliveryExpiryDate = null
                    this.promoRateHasValues = false
                    this.flatRate.deliveryEffectivityDate = null
                    this.flatRate.deliveryExpiryDate = null
                    this.flatRateHasValues = false
                }
            }
        },
        spNormalRatesAndOtherOptionsValidation(index) {
            let condition = (this.spNormalRatesHasValues && (this.freeDeliveryFeeHasValues || this.flatRateHasValues))

            if(condition) {
                if(this.external.matrix.couriers[index].normalRates.deliveryEffectivityDate == this.freeDeliveryFee.deliveryEffectivityDate 
                && this.external.matrix.couriers[index].normalRates.deliveryExpiryDate == this.freeDeliveryFee.deliveryExpiryDate) {
                    this.showSnackbarMessage("Normal rate setup and Free Delivery setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.freeDeliveryFee.deliveryEffectivityDate = null
                    this.freeDeliveryFee.deliveryExpiryDate = null
                    this.freeDeliveryFeeHasValues = false
                    this.freeDeliveryFee.active = 0
                }

                if(this.external.matrix.couriers[index].normalRates.deliveryEffectivityDate == this.flatRate.deliveryEffectivityDate 
                && this.external.matrix.couriers[index].normalRates.deliveryExpiryDate == this.flatRate.deliveryExpiryDate) {
                    this.showSnackbarMessage("Normal rate setup and Flat Rate setups must have a different value. Please select another effectivity date and expiry date.", false)
                    this.flatRate.deliveryEffectivityDate = null
                    this.flatRate.deliveryExpiryDate = null
                    this.flatRateHasValues = false
                    this.flatRate.active = 0
                }
            }
        }
    },
}

function containsBool(checkBool) {
    for (let i = 0; i < checkBool.length; i++) {
        if (typeof checkBool[i] !== "boolean") {
            return checkBool[i];
        }
    }
    return true;
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
.unstyled {
    border: 0px;
}
</style>