<template>
    <v-card flat>
        <v-card-title>Add Role</v-card-title>
        <v-divider></v-divider>
        <v-form ref="addRoleForm" class="px-8">
            <v-select
                multiple
                clearable
                chips
                deletable-chips
                hide-selected
                item-text="name"
                item-value="roleCode"
                label="Roles"
                :items="items"
                :rules="[rules.required]"
                v-model="selected.roleCodes"
            >
            </v-select>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveUserRoles">Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import {api} from '@/configs/api'

export default {
    props: {
        userRoles: { type: Array },
        userId: { type: Number }
    },

    data() {
        return {
            items: [],
            selected: {
                roleCodes: []
            },
            rules: {
                required(value) {
                    return value.length < 1 ? "Required" : true
                }
            }
        }
    },

    watch: {
        userRoles: {
            handler: 'loadRoles',
            immediate: true
        }
    },

    methods: {
        loadRoles() {
            let self = this

            this.$axios.get(`${api}/admin/roles`, {
                params: {
                    exclude: this.userRoles,
                    active: 1
                }
            }).then(response => {
                let responseData = response.data.data

                self.items = responseData
            })
        },
        saveUserRoles() {
            let self = this
            let userId = this.userId
            const isValid = this.$refs.addRoleForm.validate()

            if(!isValid) return

            this.$axios.post(`${api}/admin/user/${userId}/roles`, this.selected)
            .then(response => {
                let responseData = response.data
                self.$emit('add-role-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('add-role-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.addRoleForm.reset()
            this.$refs.addRoleForm.resetValidation()
            this.$emit('cancel-add-role')
        }
    }
}
</script>