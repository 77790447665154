<template>
    <div class="container-fluid mt--7">
        <v-card max-width="1250" class="mx-auto">
            <v-toolbar dense short flat color="blue">
                <v-toolbar-title>Review conversation for {{ review.product }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-list three-line>
                    <template v-for="(entry, index) in entries">
                        <v-list-item
                            :key="index"
                        >
                            <v-list-item-avatar>
                                <v-img :src="entry.photo"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content >
                                <v-list-item-title v-html="entry.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="entry.message"></v-list-item-subtitle>
                                <span class="caption">{{ entry.createdAt }}</span>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="col-12">
                    <v-textarea v-model="reply.message" placeholder="Write your reply..." rows="3" full-width outlined auto-grow></v-textarea>
                    <v-btn color="info" @click="submit">Post</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import firebase from 'firebase'
import { mapGetters } from 'vuex'
import moment from 'moment-business-days'
import axios from 'axios'
import { api } from '@/configs/api'
import isEmpty from 'is-empty'

export default {
    data: () => {
        return {
            review: {},
            entries: [],
            checkbox: false,
            reply: {
                reviewId: null,
                parentId: null,
                name: null,
                message: null,
                photo: null,
                user: null,
                sku: null,
                product: null,
            },
            selected: []
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted() {
        let self = this
        self.getReviewId(self.$route.params.reviewId)
    },
    methods: {
        getReviewId(reviewId) {
            const self = this

            axios.get(`${api}/review/details/${reviewId}`)
            .then(response => {
                self.review = response.data.result
                self.getConversations(self.review.parentId)
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        getConversations(parentId) {
           const self = this

           axios.get(`${api}/review/entries/conversation/${parentId}`)
           .then(response => {
               self.entries = response.data.results
           })
           .catch((error) => {
               self.showErrorMessage(error.message)
           })
        },
        submit(){
            const self = this
            self.reply.name = self.$store.getters.user.displayName
            self.reply.parentId = self.review.parentId
            self.reply.photo = self.$store.getters.user.photoURL
            self.reply.product = self.review.product
            self.reply.sku = self.review.sku
            self.reply.user = self.$store.getters.user.uid

            axios.post(`${api}/review/submit/reply`, {
                reply: self.reply
            })
            .then(response => {
                self.reply = {}
                self.getReviewId(self.$route.params.reviewId)
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        setAsSelected(entry) {
            const self = this
            self.selected.push(entry)
        },
        update(){
            const self = this
            if(!self.selected.length) {
                alert("No selected review(s)")
            } else {
                self.selected.forEach(review => {
                    if(review.testimonials == true) {
                        axios.put(`${api}/review/testimonial/enable`, {
                        testimonials: 1,
                        reviewIds: self.testimonials
                        })
                        .then(response => {
                            let responseData = response.data
                            if(!responseData.success) return
                            
                            alert(responseData.message)
                            self.selected = []
                            self.checkbox = false
                        })
                        .catch((error) => {
                            self.showErrorMessage(error.message)
                        })
                    } else {
                        axios.put(`${api}/review/testimonial/enable`, {
                        testimonials: 0,
                        reviewIds: self.testimonials
                        })
                        .then(response => {
                            let responseData = response.data
                            if(!responseData.success) return
                            
                            alert(responseData.message)
                            self.selected = []
                            self.checkbox = false
                        })
                        .catch((error) => {
                            self.showErrorMessage(error.message)
                        })
                    }
                })
                    
            } 
        },
        showErrorMessage(message) {
            alert(message)
        },
    },
}

</script>

<style scoped>
body{margin-top:20px;}

.comment-wrapper .panel-body {
    max-height:650px;
    overflow:auto;
}

.comment-wrapper .media-list .media img {
    width:64px;
    height:64px;
    border:2px solid #e5e7e8;
}

.comment-wrapper .media-list .media {
    border-bottom:1px dashed #efefef;
    margin-bottom:25px;
}

/* Chat containers */
.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

/* Darker chat container */
.darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}
</style>