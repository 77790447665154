<template>
    <v-container>
        <v-toolbar text flat>
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-icon @click="$router.push('/setups/new/product_type')">mdi-plus-circle</v-icon>
            </v-toolbar-items>
        </v-toolbar>
        <v-card flat tile>
            <v-data-table
                dense
                loading-text="Loading Items from server, please wait..."
                :loading="loading"
                :headers="headers" 
                :items="items"
                :items-per-page="20"
            >
            <template v-slot:[`item.freeDelivery.isEnableDeliveryFee`]="{ item }">
                <v-simple-checkbox :value="item.freeDelivery.isEnableDeliveryFee" :ripple="false"></v-simple-checkbox>
            </template>
            <template v-slot:[`item.flatRate.isEnableFlatRate`]="{ item }">
                <v-simple-checkbox :value="item.flatRate.isEnableFlatRate" :ripple="false"></v-simple-checkbox>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mb-2"
                    @click="openItem(item)"
                >
                    mdi-settings-helper
                </v-icon>
            </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import { api } from '@/configs/api'

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                message: '',
                type: 'success',
                alert: false
            },
            items: [],
            headers: [
                { text: 'Product Type', value: 'productType', sortable: false },
                { text: 'Type of Delivery', value: 'setupMatrix.matrixRadio', sortable: false },
                { text: 'Provider', value: 'provider', sortable: false },
                { text: 'Free Delivery Fee', value: 'freeDelivery.isEnableDeliveryFee', align: 'center', sortable: false },
                { text: 'Enable Flat Rate', value: 'flatRate.isEnableFlatRate', align: 'center', sortable: false },
                { text: 'Flat Rate', value: 'flatRate.flatRate', align: 'center', sortable: false },
                { text: 'More', value: 'actions', align: 'center', sortable: false }
            ],
        }
    },
    mounted() {
        this.fetchCreatedSetups()
    },
    methods: {
        async fetchCreatedSetups() {
            const self = this
            
            try {
                let response = await axios.get(`${api}/setups/delivery/fees`)
                self.items = response.data.data
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }

            self.provider()
        },
        provider() {
            const array = this.items

            for(let i = 0; i < array.length; i++) {
                if(array[i].setupMatrix.matrixRadio === 'Internal Delivery') {
                    array[i].provider = array[i].setupMatrix.internalDelivery.internalProvider
                } else {
                    array[i].provider = getProviders(array[i].setupMatrix.externalProvider.selectedCouriers)
                }
            }
        },
        openItem(item) {
            if(item.productType === 'Motorcycles') {
                this.$router.push(`/setups/view/product_type/mc/${item.id}`)
            } else {
                this.$router.push(`/setups/view/product_type/parts/${item.id}`)
            }
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    },
}

function getProviders(items) {
    const filteredArray = []

    for(let i = 0; i < items.length; i++) {
        filteredArray.push(items[i].alias)
    }
    return filteredArray.toString()
}
</script>