<template>
    <v-card flat>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-card-title class="pl-0">Third Party Courier Setup</v-card-title>
        <v-divider></v-divider>
        
        <v-form ref="thirdPartyForm" class="px-8">
        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0  pb-0 subtitle-2">Third party Courier Service</v-card-text>
                            <v-select
                                :readonly="action==='update'"
                                v-model="courier"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                :items="couriers"
                                item-text="name"
                                item-value="nsVendorId"
                            >
                            </v-select>
                        </v-flex>

                        <v-flex xs4>
                            <v-card-text class="pl-0  pb-0 subtitle-2">VAT Percentage on Delivery Cost</v-card-text>
                            <v-text-field
                                v-model="vatPercentage"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                            <v-card-text class="pt-0 pl-0 pb-0 caption font-italic red--text">Once VAT percentage is set to zero, delivery cost is VAT-inclusive</v-card-text>
                        </v-flex>

                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Volumetric Factor</v-card-text>
                            <v-text-field
                                v-model="volumetric"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                            <v-card-text class="pt-0 pl-0 pb-0 caption font-italic red--text">If computation of delivery fee is based on actual weight only, set Volumetric factor to zero</v-card-text>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-card-text class="pt-0 pl-0 pb-0 subtitle-1 font-weight-bold">COD Service Fee</v-card-text>
                </v-flex>

                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Fixed Amount</v-card-text>
                            <v-text-field
                                v-model="codServiceFee.fixedAmount"
                                outlined dense hide-details
                                :rules="codServiceFee.percentageAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>

                            <v-card-text class="pl-0 pb-0 subtitle-2">Percentage of total order amount</v-card-text>
                            <v-text-field
                                v-model="codServiceFee.percentageAmount"
                                outlined dense hide-details
                                :rules="codServiceFee.fixedAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="codServiceFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense">
                                </v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="codServiceFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="codServiceFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="codServiceFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="codServiceFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-card-text class="pt-0 pl-0 pb-0 subtitle-1 font-weight-bold">Cancellation Fee</v-card-text>
                </v-flex>

                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Fixed Amount</v-card-text>
                            <v-text-field
                                v-model="cancellationFee.fixedAmount"
                                outlined dense hide-details
                                :rules="cancellationFee.percentageAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>

                            <v-card-text class="pl-0 pb-0 subtitle-2">Percentage of Delivery Fee</v-card-text>
                            <v-text-field
                                v-model="cancellationFee.percentageAmount"
                                outlined dense hide-details
                                :rules="cancellationFee.fixedAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="cancellationFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense"></v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="cancellationFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="cancellationFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="cancellationFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="cancellationFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-card-text class="pt-0 pl-0 pb-0 subtitle-1 font-weight-bold">Insurance Fee</v-card-text>
                </v-flex>

                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Fixed Amount</v-card-text>
                            <v-text-field
                                v-model="insuranceFee.fixedAmount"
                                outlined dense hide-details
                                :rules="insuranceFee.percentageAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>

                            <v-card-text class="pl-0 pb-0 subtitle-2">Percentage of Delivery Fee</v-card-text>
                            <v-text-field
                                v-model="insuranceFee.percentageAmount"
                                outlined dense hide-details
                                :rules="insuranceFee.fixedAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="insuranceFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense"></v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="insuranceFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="insuranceFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="insuranceFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="insuranceFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-card-text class="pt-0 pl-0 pb-0 subtitle-1 font-weight-bold">Return to Sender (RTS) Fee</v-card-text>
                </v-flex>

                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Fixed Amount</v-card-text>
                            <v-text-field
                                v-model="rtsFee.fixedAmount"
                                outlined dense hide-details
                                :rules="rtsFee.percentageAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>

                            <v-card-text class="pl-0 pb-0 subtitle-2">Percentage of Delivery Fee</v-card-text>
                            <v-text-field
                                v-model="rtsFee.percentageAmount"
                                outlined dense hide-details
                                :rules="rtsFee.fixedAmount ? false:[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="rtsFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense"></v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="rtsFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="rtsFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="rtsFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="rtsFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Handling Fee</v-card-text>
                            <v-text-field
                                v-model="handlingFee.fixedAmount"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="handlingFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense"></v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="handlingFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="handlingFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="handlingFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="handlingFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs4>
                            <v-card-text class="pl-0 pb-0 subtitle-2">Additional Stop Fee</v-card-text>
                            <v-text-field
                                v-model="addStopFee.fixedAmount"
                                outlined dense hide-details
                                :rules="[rules.required]"
                                type="number"
                            >
                            </v-text-field>
                        </v-flex>

                        <v-flex xs4>
                            <v-radio-group class="pt-5 pl-5 ml-5" :rules="[rules.required]" v-model="addStopFee.expense">
                                <v-radio label="Customer Expense" value="Customer Expense"></v-radio>
                                <v-radio label="Company Expense" value="Company Expense"></v-radio>
                                <v-radio label="Both" value="Both"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <v-flex xs4>
                            <div v-if="addStopFee.expense=='Both'">
                                <v-card-text class="pl-0 pb-0 subtitle-2">Customer Share(%)</v-card-text>
                                <v-text-field
                                    v-model="addStopFee.customerShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                            
                            <div v-if="addStopFee.expense=='Both'"> 
                                <v-card-text class="pl-0 pb-0 subtitle-2">Company Share(%)</v-card-text>
                                <v-text-field
                                    v-model="addStopFee.companyShare"
                                    outlined dense hide-details
                                    :rules="[rules.required]"
                                    type="number"
                                >
                                </v-text-field>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs4>
                    <v-card-text class="pl-0 pb-0 subtitle-2">Minimum Number of Parcel per Branch Pickup</v-card-text>
                    <v-text-field
                        v-model="minOfParcel"
                        outlined dense hide-details
                        :rules="[rules.required]"
                        type="number"
                    >
                    </v-text-field>
                </v-flex>
            </v-container>
        </v-layout>
        <v-divider></v-divider>

        <v-layout>
            <v-container grid-list-md>
                <v-flex xs12>
                    <v-card-text class="pt-3 pl-0 pb-0 subtitle-1 font-weight-bold">Other Fees</v-card-text>
                    <v-card-text class="pl-0 pb-0 subtitle-2">Surcharge Amount if Minimum No. of Parcels per Branch Pickup is not met</v-card-text>
                    <v-flex xs4>
                        <v-text-field
                            v-model="surchargeForParcel"
                            outlined dense hide-details
                            :rules="[rules.required]"
                            type="number"
                        ></v-text-field>
                    </v-flex>

                    <v-card-text class="pl-0 pb-0 subtitle-2">After Working Hours Surcharge Amount</v-card-text>
                    <v-flex xs12>
                        <v-layout>
                            <v-flex xs6>
                                <v-layout>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" outlined dense hide-details label="HH" :items="hours" v-model="surchargeForHrs.time.from.hh"
                                    ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" outlined dense hide-details label="MM" :items="minutes" v-model="surchargeForHrs.time.from.mm"
                                    ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" dense hide-details outlined label="AMPM" :items="hourClock" v-model="surchargeForHrs.time.from.cc"
                                    ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            
                            <v-flex xs1>
                                <v-card-text class="pl-0 pb-0 subtitle-2"></v-card-text>
                            </v-flex>

                            <v-flex xs6>
                                <v-layout>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" outlined dense hide-details label="HH" :items="hours" v-model="surchargeForHrs.time.to.hh"
                                    ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" outlined dense hide-details label="MM" :items="minutes" v-model="surchargeForHrs.time.to.mm"
                                    ></v-select>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-select :rules="[rules.required]" dense hide-details outlined label="AMPM" :items="hourClock" v-model="surchargeForHrs.time.to.cc"
                                    ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs4>
                        <v-text-field class="pt-2"
                            v-model="surchargeForHrs.amount"
                            label="Amount"
                            outlined dense hide-details
                            :rules="[rules.required]"
                            type="number"
                        ></v-text-field>
                    </v-flex>

                    <v-flex xs4>
                        <v-card-text class="pl-0 pb-0 subtitle-2">Holiday Surcharge Amount</v-card-text>
                        <v-text-field
                            v-model="surchargeHoliday"
                            outlined dense hide-details
                            :rules="[rules.required]"
                            type="number"
                        ></v-text-field>
                    </v-flex>
                </v-flex>
                <v-checkbox
                    v-if="action==='update'"
                    label="Active"
                    v-model="active"
                >
                </v-checkbox>
            </v-container>
        </v-layout>
        <v-divider></v-divider>
        
        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="action === 'update'" @click="updateSetup">Update</v-btn>
            <v-btn color="primary" v-if="action === 'add'" @click="saveSetup">Save</v-btn>
            <v-btn color="warning"  @click="resetForm(),$router.push('/setups/thirdparty')">Cancel</v-btn>
        </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import {required, minMaxValue} from '@/plugins/validation'
import Snackbar from '@/components/Snackbar'
export default {
    components: {
        Snackbar
    },
    data() {
        return {
            couriers:[],
            courier: "",
            vatPercentage: null,
            volumetric: null,
            codServiceFee: {
                fixedAmount:"",
                percentageAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            cancellationFee: {
                fixedAmount:"",
                percentageAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            insuranceFee: {
                fixedAmount:"",
                percentageAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            rtsFee: {
                fixedAmount:"",
                percentageAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            handlingFee: {
                fixedAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            addStopFee: {
                fixedAmount:"",
                expense: "",
                companyShare:"",
                customerShare: ""
            },
            minOfParcel: null,
            surchargeForParcel: null,
            surchargeForHrs: {
                amount: null,
                time: {
                    from: {hh:'',mm:'',cc:''},
                    to: {hh:'',mm:'',cc:''}
                },
            },
            surchargeHoliday: null,
            hourClock: ["AM","PM"],
            hours: ["00","01","02","03","04","05","06","07","08","09","10","11","12"],
            minutes: [],
            selected: [],
            records: [],
            rules: {
                required,
                minMaxValue
            },
            id: null,
            action: "",
            snackbar: {
				responseMessage: "",
				show: false,
				type: "success"
			},
            active: 0
        }
    },
    watch: {
        
    },
    async mounted() {
        await this.fetchCouriers()
        await this.fetchLoad()
        this.minutes = ["00"];
        for (var i = 1; i <= 59; i++) {
            this.minutes.push(i);
        }

		this.id = this.$route.params.id
		if (this.id !== "new"){
			// let result = this.records.find(o => o.id === Number(this.id));
            this.loadSetup()
		} else {
            this.action = "add"
        }
    },
    methods: {
        async fetchCouriers() {
            this.couriers = []
            await this.$axios.get(`/setup/couriers/fetch`).then((result) => {
                this.couriers = result.data.data
            })
        },
        async fetchLoad() {
            await this.$axios.get(`/setup/third-party-deliveries/fetch`).then((result) => {
                this.records = result.data.data
            })
        },
        async loadSetup() {
            const self = this
            await this.$axios.get(`/setup/third-party-deliveries/${this.id}`).then((result) => {
                self.records = result.data.data
            }).then(()=> {
                self.action = "update"
                self.courier = self.records.nsVendorId
                self.vatPercentage= self.records.vatPercentage
                self.volumetric= self.records.volumetric
                self.codServiceFee= self.records.codServiceFee
                self.cancellationFee= self.records.cancellationFee
                self.insuranceFee= self.records.insuranceFee
                self.rtsFee= self.records.rtsFee
                self.handlingFee= self.records.handlingFee
                self.addStopFee= self.records.addStopFee
                self.minOfParcel= self.records.minOfParcel
                self.surchargeForParcel= self.records.surchargeForParcel
                self.surchargeForHrs= self.records.surchargeForHrs
                self.surchargeHoliday= self.records.surchargeHoliday
                self.active = self.records.active
            }).catch((error) => {
                console.log(error)
                self.showSnackbarMessage(error.message, false)
            })
        },
        saveSetup() {
			const isValid = this.$refs.thirdPartyForm.validate()
			let self = this
            let load = []
            let selectedCourier = self.couriers.find(element => element.nsVendorId == self.courier);
            console.log(selectedCourier);
            self.records.forEach((res)=> {
                if (res.courier === self.courier) {
                    load.push(res)
                }
            })
            self.codServiceFee.fixedAmount = Number(self.codServiceFee.fixedAmount).toFixed(2)
            self.cancellationFee.fixedAmount = Number(self.cancellationFee.fixedAmount).toFixed(2)
            self.insuranceFee.fixedAmount = Number(self.insuranceFee.fixedAmount).toFixed(2)
            self.rtsFee.fixedAmount = Number(self.rtsFee.fixedAmount).toFixed(2)
            self.addStopFee.fixedAmount = Number(self.addStopFee.fixedAmount).toFixed(2)
            self.handlingFee.fixedAmount = Number(self.handlingFee.fixedAmount).toFixed(2)
            self.surchargeForHrs.amount = Number(self.surchargeForHrs.amount).toFixed(2)
            let body = {
                courier: selectedCourier.name,
                nsVendorId: selectedCourier.nsVendorId,
                vatPercentage: self.vatPercentage,
                volumetric: self.volumetric,
                codServiceFee: self.codServiceFee,
                cancellationFee: self.cancellationFee,
                insuranceFee: self.insuranceFee,
                rtsFee: self.rtsFee,
                handlingFee: self.handlingFee,
                addStopFee: self.addStopFee,
                minOfParcel: self.minOfParcel,
                surchargeForParcel: self.surchargeForParcel,
                surchargeForHrs: self.surchargeForHrs,
                surchargeHoliday: self.surchargeHoliday,
            }

            if(!isValid) return
            if(load.length>0) {
                self.showSnackbarMessage("This courier already exists.", false)
            } else {
                self.$axios.post(`/setup/third-party-deliveries/create`, body).then(response => {
                let responseData = response.data
                    console.log(responseData)
                    self.resetForm()
                    self.$router.push("/setups/thirdParty")
                }).catch((error) => {
                    console.log(error)
                })
            }
        },
        updateSetup() {
            let self = this
            let selectedCourier = self.couriers.find(element => element.nsVendorId == self.courier);
            self.codServiceFee.fixedAmount = Number(self.codServiceFee.fixedAmount).toFixed(2)
            self.cancellationFee.fixedAmount = Number(self.cancellationFee.fixedAmount).toFixed(2)
            self.insuranceFee.fixedAmount = Number(self.insuranceFee.fixedAmount).toFixed(2)
            self.rtsFee.fixedAmount = Number(self.rtsFee.fixedAmount).toFixed(2)
            self.addStopFee.fixedAmount = Number(self.addStopFee.fixedAmount).toFixed(2)
            self.handlingFee.fixedAmount = Number(self.handlingFee.fixedAmount).toFixed(2)
            self.surchargeForHrs.amount = Number(self.surchargeForHrs.amount).toFixed(2)
			let body = {
                active: self.active,
                courier: selectedCourier.name,
                nsVendorId: selectedCourier.nsVendorId,
                vatPercentage: self.vatPercentage,
                volumetric: self.volumetric,
                codServiceFee: self.codServiceFee,
                cancellationFee: self.cancellationFee,
                insuranceFee: self.insuranceFee,
                rtsFee: self.rtsFee,
                handlingFee: self.handlingFee,
                addStopFee: self.addStopFee,
                minOfParcel: self.minOfParcel,
                surchargeForParcel: self.surchargeForParcel,
                surchargeForHrs: self.surchargeForHrs,
                surchargeHoliday: self.surchargeHoliday,
            }
            const isValid = this.$refs.thirdPartyForm.validate()

            if(!isValid) return

            this.$axios.put(`/setup/third-party-deliveries/update/${this.id}`, body).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
				this.$router.push("/setups/thirdParty")
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.thirdPartyForm.reset()
            this.$refs.thirdPartyForm.resetValidation()
            this.$emit('close-form')
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    }
}
</script>