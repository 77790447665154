<template>
    <v-card flat tile>
        <v-card-title class="body-1">Delivery Fee</v-card-title>
        <v-divider></v-divider>
        <v-layout>
            <v-container>
                <v-flex xs12>
                    <p style="background-color: #E0E0E0;" class="subtitle-2">Step 1: Product Type</p>
                    <v-radio-group v-model="product" column>
                        <v-radio label="MOTORCYCLES" value="Motorcycles"></v-radio>
                        <v-radio label="PARTS AND ACCESSORIES" value="Parts and Accessories"></v-radio>
                    </v-radio-group>
                </v-flex>

                <v-flex xs12>
                    <p style="background-color: #E0E0E0;" class="subtitle-2">Step 2: Set up Delivery Fee</p>
                </v-flex>

                <v-flex xs12>
                    <v-layout>
                        <v-flex xs3>
                            <v-checkbox v-model="freeDeliveryJson.isEnableDeliveryFee" label="Enable Delivery Fee"></v-checkbox>
                        </v-flex>

                        <v-flex xs1></v-flex>

                        <v-flex class="pt-5" xs2>
                            <p v-if="freeDeliveryJson.isEnableDeliveryFee" class="text--secondary">Dates Covered</p>
                        </v-flex>

                        <v-flex xs2>
                            <v-menu
                                v-if="freeDeliveryJson.isEnableDeliveryFee"
                                v-model="freeEnableEffectivityDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="freeDeliveryJson.freeEnableEffectivityDate"
                                    label="Effectivity Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="freeDeliveryJson.freeEnableEffectivityDate"
                                @input="freeEnableEffectivityDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>

                        <v-flex xs2>
                            <v-menu
                                v-if="freeDeliveryJson.isEnableDeliveryFee"
                                v-model="freeEnableExpiryDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="freeDeliveryJson.freeEnableExpiryDate"
                                    label="Expiry Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="freeDeliveryJson.freeEnableExpiryDate"
                                @input="freeEnableExpiryDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                </v-flex>
                
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs2>
                            <v-checkbox v-model="flatRateJson.isEnableFlatRate" label="Enable Flat Rate"></v-checkbox>
                        </v-flex>

                        <v-flex class="pt-3" xs1>
                            <v-text-field type="number" v-if="flatRateJson.isEnableFlatRate" v-model="flatRateJson.flatRate" outlined dense></v-text-field>
                        </v-flex>

                        <v-flex class="pl-5" xs1></v-flex>

                        <v-flex class="pt-5 pl-5" xs2>
                            <p v-if="flatRateJson.isEnableFlatRate" class="text--secondary">Dates Covered</p>
                        </v-flex>

                        <v-flex xs2>
                            <v-menu
                                v-if="flatRateJson.isEnableFlatRate"
                                v-model="flatRateEffectivityDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="flatRateJson.flatRateEffectivityDate"
                                    label="Effectivity Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="flatRateJson.flatRateEffectivityDate"
                                @input="flatRateEffectivityDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>

                        <v-flex xs2>
                            <v-menu
                                v-if="flatRateJson.isEnableFlatRate"
                                v-model="flatRateExpiryDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="flatRateJson.flatRateExpiryDate"
                                    label="Expiry Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="flatRateJson.flatRateExpiryDate"
                                @input="flatRateExpiryDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12>
                    <v-checkbox v-model="isSetupMatrix" label="Setup Matrix"></v-checkbox>
                </v-flex>

                <v-flex v-if="isSetupMatrix" xs12>
                    <Internal
                        :parentData.sync="defaultMcTemplate"
                        :radio.sync="matrixRadio"
                        :effectiveMenu.sync="internalDeliveryEffectivityMenu"
                        :expiryMenu.sync="internalDeliveryExpiryMenu"
                        :tableHeader.sync="headerTableBuilderInternal"
                        :tableReference="referenceTableForInternal"
                        v-on:openMetricModal="metricModal = true"
                        v-on:update-matrices="onMatricesUpdate"
                        v-on:radio-select="onRadioSelect"
                    ></Internal>
                    <External
                        :parentData.sync="defaultPartsTemplate"
                        :radio.sync="matrixRadio"
                        :normalRatesEffectivityMenu.sync="normalRatesEffectivityMenu"
                        :normalRatesExpiryMenu.sync="normalRatesExpiryMenu"
                        :promoRatesEffectivityMenu.sync="promoRatesEffectivityMenu"
                        :promoRatesExpiryMenu.sync="promoRatesExpiryMenu"
                        :promoRates.sync="promoRates"
                        :buildTable.sync="buildExternalTable"
                        v-on:openMetricModal="metricModal = true"
                        v-on:area-select="onAreaSelect"
                        v-on:selected-courier="courierSelect"
                        v-on:origin-changes="onOriginChange"
                        v-on:metric-change="onMetricChange"
                        v-on:update-matrices="onMatricesUpdate"
                        v-on:radio-select="onRadioSelect"
                    ></External>
                </v-flex>
            </v-container>
        </v-layout>

        <v-layout class="mt-5">
            <v-flex xs10></v-flex>
            <v-flex xs1>
                <v-btn @click="cancel" tile depressed>Cancel</v-btn>
            </v-flex>
            <v-flex xs>
                <v-btn @click="submit" class="ml-5 white--text" color="green" tile depressed>Submit</v-btn>
            </v-flex>
        </v-layout>
        
        <v-dialog
            v-model="metricModal"
            transition="dialog-top-transition"
            max-width="600"
            persistent
        >
            <v-toolbar
                dark
                color="primary"
                >
                <v-toolbar-title>Setup New Metric</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="metricModal = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card tile>
                <v-card-text>
                    <v-form ref="form" v-model="validated">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-model="newMetric.metric.min"
                                        label="Min.*"
                                        outlined
                                        required
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-model="newMetric.metric.max"
                                        label="Max.*"
                                        outlined
                                        required
                                        :rules="rules"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        v-model="newMetric.metric.unit"
                                        label="unit*"
                                        outlined
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <div v-if="isIslandGroupSelected">
                                <v-row 
                                
                                    v-for="(i, island) in newMetric.islandGrpValues" :key="island"
                                >
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        outlined
                                        :label="i.island"
                                        disabled                                  
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        v-model="i.amount"
                                        type="number"
                                        outlined
                                        label="Amount"
                                        :rules="rules"                                  
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-if="isClusterGroupSelected">
                                <v-row 
                                
                                    v-for="(i, cluster) in newMetric.clusterGrpValues" :key="cluster"
                                >
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        outlined
                                        :label="i.cluster"
                                        disabled                                  
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                        v-model="i.amount"
                                        type="number"
                                        outlined
                                        label="Amount"  
                                        :rules="rules"                                
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </div>  
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelMetric" tile depressed>Cancel</v-btn>
                    <v-btn @click="savedMetric" class="white--text" color="info" :loading="loading" :disabled="!validated" tile depressed>Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="alert"
            :timeout="5000"
        >
            {{ snackBarMessage }}
        </v-snackbar>  
    </v-card>
</template>

<script>
import moment from 'moment-business-days'
import axios from 'axios'
import { api } from '@/configs/api'
import _ from 'lodash'
import Internal from '@/components/Setups/Internal'
import External from '@/components/Setups/External'

export default {
    components: { 
        Internal,
        External
    },
    data() {
        return {
            loading: false,
            validated: false,
            alert: false,
            snackBarArgs: '',
            product: "Motorcycles",
            radioBasedOn: 'kilogram',
            matrixRadio: 'Internal Delivery',
            isSetupMatrix: true,
            freeEnableEffectivityDateMenu: false,
            freeEnableExpiryDateMenu: false,
            flatRateEffectivityDateMenu: false,
            flatRateExpiryDateMenu: false,
            internalDeliveryEffectivityMenu: false,
            internalDeliveryExpiryMenu: false,
            normalRatesEffectivityMenu: false,
            normalRatesExpiryMenu: false,
            promoRatesEffectivityMenu: false,
            promoRatesExpiryMenu: false,
            metricModal: false,
            defaultMcTemplate: {},
            defaultPartsTemplate: {},
            freeDeliveryJson: {
                isEnableDeliveryFee: false,
                freeEnableEffectivityDate: null,
                freeEnableExpiryDate: null,
            },
            flatRateJson: {
                isEnableFlatRate: false,
                flatRate: 500,
                flatRateEffectivityDate: null,
                flatRateExpiryDate: null
            },
            newMetric: {
                type: '',
                area: '',
                metric: {
                    min: null,
                    max: null,
                    unit: 'km'
                },
                islandGrpValues: [
                    {
                        island: 'NCR',
                        amount: null
                    },
                    {
                        island: 'LUZON',
                        amount: null
                    },
                    {
                        island: 'VISAYAS',
                        amount: null
                    },
                    {
                        island: 'MINDANAO',
                        amount: null
                    },
                    {
                        island: 'ISLAND',
                        amount: null
                    }
                ],
                clusterGrpValues: [
                    {
                        cluster: 'NCR/SOUTH',
                        amount: null,
                    },
                    {
                        cluster: 'NCR/NORTH',
                        amount: null,
                    },
                    {
                        cluster: 'NCR/RIZAL',
                        amount: null,
                    },
                    {
                        cluster: 'BULACAN',
                        amount: null,
                    },
                    {
                        cluster: 'CAVITE',
                        amount: null,
                    },
                    {
                        cluster: 'MINDORO',
                        amount: null,
                    },
                    {
                        cluster: 'PALAWAN',
                        amount: null,
                    },
                    {
                        cluster: 'BATANGAS',
                        amount: null,
                    },
                    {
                        cluster: 'LAGUNA',
                        amount: null,
                    },
                    {
                        cluster: 'BICOL',
                        amount: null,
                    },
                    {
                        cluster: 'BATAAN',
                        amount: null,
                    },
                    {
                        cluster: 'PAMPANGA',
                        amount: null,
                    },
                    {
                        cluster: 'TARLAC',
                        amount: null,
                    },
                    {
                        cluster: 'NUEVA ECIJA',
                        amount: null,
                    },
                    {
                        cluster: 'ISABELA/NUEVA VIZCAYA',
                        amount: null,
                    },
                    {
                        cluster: 'PANGASINAN',
                        amount: null,
                    },
                    {
                        cluster: 'LAOAG',
                        amount: null,
                    },
                    {
                        cluster: 'PANAY ISLAND',
                        amount: null,
                    },
                    {
                        cluster: 'NEGROS',
                        amount: null,
                    },
                    {
                        cluster: 'CEBU',
                        amount: null,
                    },
                    {
                        cluster: 'SAMAR LEYTE',
                        amount: null,
                    },
                    {
                        cluster: 'SOUTHERN MIN',
                        amount: null,
                    },
                    {
                        cluster: 'CENTRAL MIN',
                        amount: null,
                    },
                    {
                        cluster: 'EASTERN MIN',
                        amount: null,
                    },
                    {
                        cluster: 'CARAGA',
                        amount: null,
                    },
                    {
                        cluster: 'NORTHERN MIN',
                        amount: null,
                    }
                ]
            },
            headers: null,
            snackbar: {
                message: '',
                type: 'success',
                alert: false
            },
            courierHolder: '',
            islands: [],
            clusters: [],
            promoRates: [],
            referenceTableForInternal: [],
            referenceTableForExternal : [],
            rules: [
                v => !!v || 'Amount is required',
            ],
            buildExternalTable: false
        }
    },
    async mounted() {
        await this.fetchDefaultTemplates()
        await this.fetchCoverageGroups()
        await this.fetchPromoRates()
    },
    watch: {
        // isSetupMatrix(value) {
        //     if(!value) this.matrixRadio = null
        // },
        product(newValue) {
            this.matrixRadio = newValue === 'Motorcycles' ? 'Internal Delivery' : 'Third-party Courier Service'

            if(newValue === 'Motorcycles') {
                this.internalTableBuilder()
                this.newMetric.area = 'island'
                this.newMetric.metric.unit = 'km'
            } else {
                this.buildExternalTable = true
                this.newMetric.area = 'cluster'
                this.newMetric.metric.unit = 'kg'
            } 
        }
    },
    computed: {
        headerTableBuilderInternal() {
            if(this.defaultMcTemplate.setupMatrix.internalDelivery.coverageArea === 'Island Group') {
                this.newMetric.area === 'island'
                this.referenceTableForInternal = this.islands.filter(e => e.area == 'island' && e.metric.unit == 'km' && e.type != 'Parts and Accessories')

                return this.defaultMcTemplate.setupMatrix.internalDelivery.baseAmountChart.islandGroups
            } else {
                this.newMetric.area === 'cluster'
                this.referenceTableForInternal = this.clusters.filter(e => e.area == 'cluster' && e.metric.unit == 'km')

                return this.defaultMcTemplate.setupMatrix.internalDelivery.baseAmountChart.clusterOrigins
            }
        },
        isIslandGroupSelected() {
            const condition = (
                (this.product === 'Motorcycles' &&
                this.defaultMcTemplate.setupMatrix.internalDelivery.coverageArea === 'Island Group') ||
                (this.product === 'Parts and Accessories' &&
                this.defaultPartsTemplate.setupMatrix.externalProvider.coverageArea === 'Island Group')
            )

            if(condition) this.newMetric.area = 'island'

            return condition
        },
        isClusterGroupSelected() {
            const condition = (
                (this.product === 'Motorcycles' &&
                this.defaultMcTemplate.setupMatrix.internalDelivery.coverageArea === 'Cluster') ||
                (this.product === 'Parts and Accessories' &&
                this.defaultPartsTemplate.setupMatrix.externalProvider.coverageArea === 'Cluster')
            )

            if(condition) this.newMetric.area = 'cluster'

            return condition
        },
        snackBarMessage() {
            var message = ''

            switch(this.snackBarArgs) {
                case 'save-origin':
                    message = 'Origin setup has beed saved!'
                    break;
                case 'save-setup':
                    message = 'Delivery setup has been saved!'
                    break;
                case 'delete-courier':
                    message = 'Saved Origin setup has been removed!'
            }

            return message;
        }
    },
    methods: {
        getDefaultFilter() {
            return {
                type: '',
                area: '',
                metric: {
                    min: null,
                    max: null,
                    unit: 'km'
                },
                islandGrpValues: [
                    {
                        island: 'NCR',
                        amount: null
                    },
                    {
                        island: 'LUZON',
                        amount: null
                    },
                    {
                        island: 'VISAYAS',
                        amount: null
                    },
                    {
                        island: 'MINDANAO',
                        amount: null
                    },
                    {
                        island: 'ISLAND',
                        amount: null
                    }
                ],
                clusterGrpValues: [
                    {
                        cluster: 'NCR/SOUTH',
                        amount: null,
                    },
                    {
                        cluster: 'NCR/NORTH',
                        amount: null,
                    },
                    {
                        cluster: 'NCR/RIZAL',
                        amount: null,
                    },
                    {
                        cluster: 'BULACAN',
                        amount: null,
                    },
                    {
                        cluster: 'CAVITE',
                        amount: null,
                    },
                    {
                        cluster: 'MINDORO',
                        amount: null,
                    },
                    {
                        cluster: 'PALAWAN',
                        amount: null,
                    },
                    {
                        cluster: 'BATANGAS',
                        amount: null,
                    },
                    {
                        cluster: 'LAGUNA',
                        amount: null,
                    },
                    {
                        cluster: 'BICOL',
                        amount: null,
                    },
                    {
                        cluster: 'BATAAN',
                        amount: null,
                    },
                    {
                        cluster: 'PAMPANGA',
                        amount: null,
                    },
                    {
                        cluster: 'TARLAC',
                        amount: null,
                    },
                    {
                        cluster: 'NUEVA ECIJA',
                        amount: null,
                    },
                    {
                        cluster: 'ISABELA/NUEVA VIZCAYA',
                        amount: null,
                    },
                    {
                        cluster: 'PANGASINAN',
                        amount: null,
                    },
                    {
                        cluster: 'LAOAG',
                        amount: null,
                    },
                    {
                        cluster: 'PANAY ISLAND',
                        amount: null,
                    },
                    {
                        cluster: 'NEGROS',
                        amount: null,
                    },
                    {
                        cluster: 'CEBU',
                        amount: null,
                    },
                    {
                        cluster: 'SAMAR LEYTE',
                        amount: null,
                    },
                    {
                        cluster: 'SOUTHERN MIN',
                        amount: null,
                    },
                    {
                        cluster: 'CENTRAL MIN',
                        amount: null,
                    },
                    {
                        cluster: 'EASTERN MIN',
                        amount: null,
                    },
                    {
                        cluster: 'CARAGA',
                        amount: null,
                    },
                    {
                        cluster: 'NORTHERN MIN',
                        amount: null,
                    }
                ]
            }
        },
        async fetchDefaultTemplates() {
            const self = this

            try {
                let response = await axios.get(`${api}/setups/templates`)
                let responseData = response.data.data
                self.defaultMcTemplate = responseData.find(e => e.productType === 'Motorcycles')
                self.defaultPartsTemplate = responseData.find(e => e.productType === 'Parts and Accessories')
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchCoverageGroups() {
            const self = this
            
            try {
                let response = await axios.get(`${api}/setup/base/amount`)
                self.islands = response.data.islands
                self.clusters = response.data.clusters
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchPromoRates() {
            let self = this

            try {
                let response = await axios.get(`${api}/setup/promo/rates`)
                self.promoRates = response.data.rates
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        internalTableBuilder() {
            if(this.defaultMcTemplate.setupMatrix.internalDelivery.coverageArea == 'Island Group') {
                this.referenceTableForInternal = this.islands.filter(e => e.area == 'island' && e.metric.unit == 'km')
            } else {
                this.referenceTableForInternal = this.clusters.filter(e => e.area == 'cluster' && e.metric.unit == 'km')
            }
        },
        submit() {
            let self = this
            let newObject = {}

            newObject.productType = self.product
            newObject.freeDelivery = self.freeDeliveryJson
            newObject.flatRate = self.flatRateJson
            newObject.setupMatrix = self.product  === 'Motorcycles' ? self.defaultMcTemplate.setupMatrix : self.defaultPartsTemplate.setupMatrix

            self.$axios.post(`/setup/new`, newObject)
            .then(response => {
                let result = response.data
                console.log(result)

                self.$router.push("/setups/delivery_fee")
            })
            .catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        cancel() {
            this.$router.push("/setups/delivery_fee")
        },
        savedMetric() {
            let self = this
            self.loading = true
            self.newMetric.type = self.product

            if(self.newMetric.area === 'island') delete self.newMetric.clusterGrpValues
            else delete self.newMetric.islandGrpValues
            
            self.$axios.post(`/setup/new/metric`, self.newMetric)
            .then(response => {
                let result = response.data
                console.log(result)

                self.loading = false
                self.metricModal = false
                self.fetchCoverageGroups()
                self.newMetric = self.getDefaultFilter()
            })
            .catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
            
        },
        cancelMetric() {
            this.$refs.form.reset()
            this.metricModal = false
        },
        onChildSelect(value) {
            this.radioBasedOn = value
        },
        onAreaSelect(value) {
            this.defaultPartsTemplate.setupMatrix.externalProvider.coverageArea = value
        },
        courierSelect(value) {
            this.defaultPartsTemplate.setupMatrix.externalProvider.selectedCouriers.push(value)
        },
        onOriginChange(value) {
            this.snackBarArgs = value
            this.alert = true
        },
        onMetricChange(value) {
            this.newMetric.metric.unit = value
        },
        async onMatricesUpdate(value) {
            const self = this

            let result = await self.$axios.put(`${api}/setup/base/amount/update`, value)
            console.log(result)
            self.fetchCoverageGroups()
        },
        onRadioSelect(value) {
            const self = this

            self.matrixRadio = value
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    },
}
</script>