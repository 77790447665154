<template>
    <div>
        <v-row>
            <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRange.fromDate"
                    label="From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange.fromDate"
                    @input="emitDateFrom"
                ></v-date-picker>
            </v-menu>
            <v-icon class="mb-3 mr-2">mdi-calendar</v-icon>
            <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRange.toDate"
                    label="To"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange.toDate"
                    @input="emitDateTo"
                ></v-date-picker>
            </v-menu>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { api } from '../../configs/api'

export default {
    name: 'filter-by-date-range',
    data () {
        return {
            fromDateMenu: false,
            toDateMenu: false,
            dateRange: {
                fromDate: null,
                toDate: null
            }
            
        }
    },
    watch: {
        dateRange: {
            handler: function() {
                this.checkDateRange()
            },
            deep: true
        },
    },
    methods: {
        emitDateFrom() {
            const self = this
            let date = moment(self.dateRange.fromDate).startOf('day').format('YYYY-MM-DD hh:mm:ss')
            self.$emit('update:dateFrom', date)
            self.fromDateMenu = false
        },
        emitDateTo() {
            const self = this
            let date = moment(self.dateRange.toDate).endOf('day').format('YYYY-MM-DD hh:mm:ss')
            self.$emit('update:dateTo', date)
            self.toDateMenu = false
        },
        checkDateRange() {
            const self = this
            if(self.dateRange.fromDate === null && self.dateRange.toDate === null) {
                self.$emit('update:dateFrom', null)
                self.$emit('update:dateTo', null)
                self.$emit('getOrders')
            }
        }
    }
}
</script>