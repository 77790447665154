<template>
    <v-container>
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Email Management</v-toolbar-title>
        </v-toolbar>

        <v-card>
            <v-btn
                class="mt-5 ml-5"
                @click="testConnection"
            >
                Test connection
            </v-btn>
            <span v-if="verified"><v-icon color="success">mdi-check-bold</v-icon> Connected</span>

            <v-form
                class="pa-5"
                ref="form"
                v-model="isValid"
                lazy-validation
            >
                <v-text-field
                    v-model="template.recipient"
                    label="To:"
                    required
                    outlined
                    disabled
                ></v-text-field>

                <v-text-field
                    v-model="template.subject"
                    :rules="infoRules"
                    label="Subject"
                    required
                    outlined
                ></v-text-field>

                <v-textarea
                    v-model="template.body"
                    :rules="infoRules"
                    label="Body"
                    required
                    outlined
                    auto-grow
                ></v-textarea>

                <v-btn
                    :disabled="!isValid"
                    color="info"
                    class="mr-4"
                    @click="sendEmail"
                >
                    Send
                </v-btn>

                <!-- <v-btn
                    color="error"
                    class="mr-4"
                    @click="reset"
                >
                    Reset Template
                </v-btn> -->

            </v-form>
        </v-card>

        <Snackbar v-model="snackbar.alert" :type="snackbar.type" :message="message" />
    </v-container>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { api } from '@/configs/api'
import { mapGetters } from 'vuex'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        Snackbar
    },
    data () {
        return {
            search: '',
            isValid: true,
            verified: false,
            snackbar: {
                message: '',
                alert: false,
                type: 'success'
            },
            template: {
                recipient: '',
                subject: '',
                body: '',
            },
            testTemplate: {
                recipient: '',
                subject: 'Transcycle Email Test',
                body: 'This is a test email generated by Transcycle Email Service.'
            },
            infoRules: [
                v => !!v || 'This field is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted () {
        const self = this

        self.template.recipient = self.$store.getters.user.email
        self.testTemplate.recipient = self.$store.getters.user.email
        self.template.name = self.$store.getters.user.displayName
        self.testTemplate.name = self.$store.getters.user.displayName
    },
    methods: {
        testConnection () {
            const self = this

            axios.post(`${api}/email/send`, {
                template: self.testTemplate
            })
            .then((response) => {
                let responseData = response.data

                if(responseData.success) self.verified = true
            })
            .catch((error) => {
                self.showErrorMessage(error.message)
            })
        },
        sendEmail () {
            const self = this

            if(self.$refs.form.validate()) {
                axios.post(`${api}/email/send`, {
                    template: self.template
                })
                .then((response) => {
                    let responseData = response.data
                    self.template = {}
                    self.$refs.form.reset()
                    self.showSnackbarMessage(responseData.message, false)
                })
                .catch((error) => {
                    self.showSnackbarMessage(error.messages, false)
                })
            }
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.essage = message
            this.snackbar.alert = true
        },
        // reset () {
        //     this.$refs.form.reset()
        // },
    },
}
</script>