<template>
    <v-container fluid>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>User Management</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="createUser" v-on="on"><v-icon>person_add</v-icon></v-btn>
                    </template>
                    <span>Add User</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{on}">
                        <v-btn icon @click="revokeUser" v-on="on"><v-icon>person_add_disabled</v-icon></v-btn>
                    </template>
                    <span>Revoke User</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            dense
            loading-text="Loading Items from server, please wait..."
            :loading="loading"
            :headers="headers" 
            :items="records"
            :server-items-length="serverItemTotal"
            :options.sync="pageOptions"
            @update:options="loadNextPage"
            :footer-props="footerProps"
            v-model="selected"
        >

            <template v-slot:item.verified="{ item }">
                <v-simple-checkbox :value="Boolean(item.verified)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.status="{ item }">
                <v-simple-checkbox :value="Boolean(item.status)" disabled></v-simple-checkbox>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="addRole(item)" v-on="on">accessibility</v-icon>
                    </template>
                    <span>Add Role</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="addBranch(item)" v-on="on">business</v-icon>
                    </template>
                    <span>Add Branch</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon size="25" @click="editUser(item.id)" v-on="on">launch</v-icon>
                    </template>
                    <span>Edit User</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="showDialog" width="500" height="500">    
            <CreateUser
                v-if="userAction === 'createuser'"
                v-on:create-user-response="showActionResponse"
                v-on:cancel-create-user="closeDialog"
            >
            </CreateUser>

            <AddRole
                v-if="userAction === 'addrole'"
                :user-roles="userRoles"
                :user-id="userId"
                v-on:add-role-response="showActionResponse"
                v-on:cancel-add-role="closeDialog"
            ></AddRole>

            <AddBranch
                v-if="userAction === 'addbranch'"
                :user-id="userId"
                v-on:add-branch-response="showActionResponse"
                v-on:cancel-add-branch="closeDialog"
            >
            </AddBranch>

            <RevokeUser 
                v-if="userAction === 'revokeuser'"
                v-on:revoke-user-response="showActionResponse"
                v-on:cancel-revoke-user="closeDialog"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import {api} from '@/configs/api'
import CreateUser from './Create'
import AddRole from './AddRole'
import AddBranch from './AddBranch'
import RevokeUser from './RevokeUser'
import Snackbar from '@/components/Snackbar'

export default {
    components: {
        CreateUser,
        AddRole,
        AddBranch,
        RevokeUser,
        Snackbar
    },
    data() {
        return {
            loading: true,
            showDialog: false,
            userAction: null,
            userId: null,
            userRoles: [],
            records: [],
            selected: [],
            footerProps: {
                itemsPerPageOptions: [25, 50, 100],
                itemsPerPageText: "Records per Page"
            },
            pageOptions: {
                page: 1,
                itemsPerPage: 25
            },
            snackbar: {
                responseMessage: "",
                show: false,
                type: "success"
            },
            serverItemTotal: 0,
            headers: [
                { text: 'Name', value: 'socialName', sortable: false },
                { text: 'Email', value: 'email', sortable: false },
                { text: 'Contact Number', value: 'contactNumber', sortable: false },
                { text: 'Verified', value: 'verified', align: 'center', sortable: false },
                { text: 'Active', value: 'status', align: 'center', sortable: false },
                { text: 'Actions', value: 'action', align: 'center', sortable: false }
            ]
        }
    },
    computed: {
        pageOffset() {
            return (this.pageOptions.itemsPerPage * this.pageOptions.page) - this.pageOptions.itemsPerPage
        },
        selectedIds() {
            return this.selected.map(rec => rec.id )
        }
    },
    watch: {
        filters: {
            handler: function() {
                this.loadItems()
            },
            deep: true
        },
        showDialog(value) {
            if(!value) this.loadUsers()
        }
    },
    mounted() {
        this.loadUsers();
    },
    methods: {
        loadUsers() {
            const self = this
            self.loading = true
            let paging = {
                limit: self.pageOptions.itemsPerPage,
                offset: self.pageOffset
            }

            this.$axios.get(`${api}/admin/users`, {
                params: {
                    ...paging,
                    ...self.filters
                }
            }).then((result) => {
                self.records = result.data.rows
                self.serverItemTotal = result.data.count
                self.loading = false
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },
        createUser(){
            this.showDialog = true
            this.userAction = 'createuser'
        },
        revokeUser() {
            this.showDialog = true
            this.userAction = 'revokeuser'
        },
        addRole(item){
            this.showDialog = true
            this.userAction = 'addrole'
            this.userRoles = item.roles.map(rec => rec.roleCode)
            this.userId = item.id
        },
        addBranch(item){
            this.showDialog = true
            this.userAction = 'addbranch'
            // this.userRoles = item.roles.map(rec => rec.roleCode)
            this.userId = item.id
        },
        editUser(userId) {
            this.$router.push({ name: 'UserEdit', params: { id: userId } })
        },
        showActionResponse(message, success = true) {
            this.closeDialog()
            this.showSnackbarMessage(message, success)
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        },
        closeDialog() {
            this.showDialog = false
        },
        loadNextPage() {
            this.loadUsers()
        }
    }
}
</script>