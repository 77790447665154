<template>
    <v-container>
        <Snackbar v-model="snackbar.show" :type="snackbar.type" :message="snackbar.responseMessage" />
        <v-toolbar dense short flat color="blue">
            <v-toolbar-title>Delivery Setup</v-toolbar-title>
        </v-toolbar>
		<v-layout>
			<v-form ref="deliveryDatesForm" class="px-8">
			<v-container>
				<v-flex xs12>
					<span class="pb-0">Product Type </span>
					<v-radio-group :readonly="action==='update'" class="pt-0 pl-5" v-model="productType" :rules="[rules.required]">
						<v-radio label="MOTORCYCLES" value="Motorcycles"></v-radio>
						<v-radio label="PARTS AND ACCESSORIES" value="Parts and Accessories"></v-radio>
					</v-radio-group>
				</v-flex>
				<v-divider></v-divider>	
				<v-flex xs12>
					<span class="pb-0">Set up Delivery Lead Time</span>
					<v-container fluid>
						<v-layout row>
							<v-flex xs3>
								<v-card>
									<br><br>
									<v-card-text class="title text-center pb-5 pt-5">Point of Origin</v-card-text>
									<br><br>
								</v-card> 
								<v-card v-for="province in provincials" :key="province">
									<v-card-text class="subtitle-2 text-center">{{province}}</v-card-text>
								</v-card>
							</v-flex>
							<v-flex xs9>
								<v-card>
									<v-card-text class="subtitle-2 text-center">Destination</v-card-text>
								</v-card>
								<v-layout>
									<v-flex xs2 class="mr-5">
									</v-flex>
									<v-flex xs10 class="pl-2">
										<v-card color="blue" dark>
											<v-card-text class="subtitle-2 text-center">PROVINCIAL</v-card-text>
										</v-card>
									</v-flex>
								</v-layout>
								<v-layout>
									<v-flex v-for="province in provincials" :key="province" xs3>
										<v-card color="white" light>
										<v-card-text class="subtitle-2 text-center">{{province}}</v-card-text>
										</v-card>
									</v-flex>
								</v-layout>

								<v-layout v-if="details.values[0].origin==='NCR'">
									<v-card v-for="(detail,index) in details.values[0].leadTime" :key="index" color="white" light>
										<v-card-text class="subtitle-2 text-center pa-0">
											<v-text-field outlined hide-details v-model="detail.days" type="number"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-layout>

								<v-layout v-if="details.values[1].origin==='LUZON'">
									<v-card v-for="(detail,index) in details.values[1].leadTime" :key="index" color="white" light>
										<v-card-text class="subtitle-2 text-center pa-0">
											<v-text-field outlined hide-details v-model="detail.days" type="number"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-layout>

								<v-layout v-if="details.values[2].origin==='VISAYAS'">
									<v-card v-for="(detail,index) in details.values[2].leadTime" :key="index" color="white" light>
										<v-card-text class="subtitle-2 text-center pa-0">
											<v-text-field outlined hide-details v-model="detail.days" type="number"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-layout>

								<v-layout v-if="details.values[3].origin==='MINDANAO'">
									<v-card v-for="(detail,index) in details.values[3].leadTime" :key="index" color="white" light>
										<v-card-text class="subtitle-2 text-center pa-0">
											<v-text-field outlined hide-details v-model="detail.days" type="number"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-layout>

								<v-layout v-if="details.values[4].origin==='ISLAND'">
									<v-card v-for="(detail,index) in details.values[4].leadTime" :key="index" color="white" light>
										<v-card-text class="subtitle-2 text-center pa-0">
											<v-text-field outlined hide-details v-model="detail.days" type="number"
											></v-text-field>
										</v-card-text>
									</v-card>
								</v-layout>

								<v-card-text class="pt-1 caption font-italic red--text text-end">All values should be based on Number of Days</v-card-text>
							</v-flex>
						</v-layout>
					</v-container>	
				</v-flex>
				<v-divider></v-divider>	
				<v-flex xs6>
					<span>Set up Delivery Window Time</span>
					<v-container>
						<v-text-field v-model="details.windowTime" label="Delivery Window Time" outlined dense hide-details :rules="[rules.required]" type="number"
						></v-text-field>
						<span class="caption font-italic red--text">All values should be based on Number of Days</span>
					</v-container>	
				</v-flex>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="warning" @click="resetForm(),$router.push('/delivery-dates-details')">Cancel</v-btn>
					<v-btn v-if="action==='add'" color="primary" @click="save()">Submit</v-btn>
					<v-btn v-if="action==='update'" color="primary" @click="updateSetup()">Update</v-btn>
				</v-card-actions>
			</v-container>
			</v-form>
		</v-layout>
    </v-container>
</template>

<script>
import Snackbar from '@/components/Snackbar'
import {required, minMaxValue} from '@/plugins/validation'
export default {
    components: {
        Snackbar
    },
    data() {
        return {
			id: "",
			rules: {
                required,
                minMaxValue
            },
			snackbar: {
				responseMessage: "",
				show: false,
				type: "success"
			},
			provincials: ["NCR","LUZON","VISAYAS","MINDANAO","ISLAND"],
			productType: "",
			details: {
				values: [	
					{
						origin: "NCR",
						productType: "",
						leadTime: [
							{destination: "NCR", days: ""},
							{destination: "LUZON", days: ""},
							{destination: "VISAYAS", days: ""},
							{destination: "MINDANAO", days: ""},
							{destination: "ISLAND", days: ""}
						]
					},
					{
						origin: "LUZON",
						productType: "",
						leadTime: [
							{destination: "NCR", days: ""},
							{destination: "LUZON", days: ""},
							{destination: "VISAYAS", days: ""},
							{destination: "MINDANAO", days: ""},
							{destination: "ISLAND", days: ""}
						]
					},
					{
						origin: "VISAYAS",
						productType: "",
						leadTime: [
							{destination: "NCR", days: ""},
							{destination: "LUZON", days: ""},
							{destination: "VISAYAS", days: ""},
							{destination: "MINDANAO", days: ""},
							{destination: "ISLAND", days: ""}
						]
					},
					{
						origin: "MINDANAO",
						productType: "",
						leadTime: [
							{destination: "NCR", days: ""},
							{destination: "LUZON", days: ""},
							{destination: "VISAYAS", days: ""},
							{destination: "MINDANAO", days: ""},
							{destination: "ISLAND", days: ""}
						]
					},
					{
						origin: "ISLAND",
						productType: "",
						leadTime: [
							{destination: "NCR", days: ""},
							{destination: "LUZON", days: ""},
							{destination: "VISAYAS", days: ""},
							{destination: "MINDANAO", days: ""},
							{destination: "ISLAND", days: ""}
						]
					},
				],
				windowTime: "",
			},
			records: [],
			types: [],
			action: "add",
        }
    },
    async mounted() {
		await this.loadSetups()
		this.id = this.$route.params.id
		if (this.id !== "new"){
			let result = this.records.find(o => o.id === Number(this.id));
			this.details = result.leadTime
			this.productType = result.leadTime.values[0].productType
			this.action = "update"
		}
    },
    watch: {
      
    },
    methods: {
		async loadSetups() {
            const self = this
			self.types = []
            await this.$axios.get(`/setup/delivery-dates/fetch`).then((result) => {
                self.records = result.data.data
            }).then(()=> {
				if (self.records) {
					self.records.forEach(function(record) {
						record.leadTime.values.forEach(function(res){
							self.types.push(res.productType)
						})
					})
				}
            }).catch((error) => {
                self.showSnackbarMessage(error.message, false)
            })
        },

		save() {
			this.details.values.forEach(detail => {
				console.log(detail)
				detail.productType = this.productType
			});

			let body = {
				leadTime: this.details,
			}
			const isValid = this.$refs.deliveryDatesForm.validate()
			
            if(!isValid) return
			if (this.types.includes(this.productType)) {
				this.showSnackbarMessage("This product type already exists.", false)
			} else { 
				this.$axios.post(`/setup/delivery-dates`, body).then(response => {
					let responseData = response.data
					console.log(responseData)
					this.resetForm()
					this.$router.push("/delivery-dates-details")
				}).catch((error) => {
					console.log(error)
				})
			}
		},

		updateSetup() {
            let self = this
			self.details.values.forEach(detail => {
				console.log(detail)
				detail.productType = self.productType
			});

			let body = {
				leadTime: this.details,
			}
            const isValid = this.$refs.deliveryDatesForm.validate()

            if(!isValid) return

            this.$axios.put(`/setup/delivery-dates/update/${this.id}`, body).then(response => {
                let responseData = response.data
                self.$emit('setup-response', responseData.message, responseData.success)
                self.resetForm()
				this.$router.push("/delivery-dates-details")
            }).catch((error) => {
                self.$emit('setup-response', error.message, false)
            })
        },
		resetForm() {
            this.$refs.deliveryDatesForm.reset()
            this.$refs.deliveryDatesForm.resetValidation()
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.responseMessage = message
            this.snackbar.show = true
        }
    },
}
</script>