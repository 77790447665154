<template>
    <div class="container-fluid mt--7">
        <div class="row">
            <div class="col-md-12">
                <v-card header-tag="header" footer-tag="footer">
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <router-link class="body-1" :to="`/product/edit/${$route.params.slug}`">EDIT</router-link>
                    </v-card-title>
                    <v-card-text>
                        <v-layout>
                            <v-flex xs12 md3>
                                <img :alt="`Photo for ${product.slug}`" class="img-fluid" :src="product.photo" style="width: 200px; height: 150px;"/>
                            </v-flex>
                            <v-flex md9 xs12>
                                <v-flex xs12 md9>
                                    <span class="subtitle-1">{{ product.model }}</span>
                                </v-flex>
                                <v-flex xs12 md9>
                                    <span class="subtitle-2">{{ product.category }}</span>
                                </v-flex>
                                <v-flex xs12 md9>
                                    <span class="body-2">{{ product.shortDescription }}</span>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-dialog v-model="beforeRouteDialog" hide-overlay persistent width="300">
                        <v-card color="primary" dark>
                            <v-card-text>
                                Please wait
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card>
                <br>
                <v-card header-tag="header" footer-tag="footer">
                    <v-card-title>Variants</v-card-title>
                    <v-data-table :headers="headers" :items="variants">
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon class="ml-2" v-on="on" @click="openDetails(item)">mdi-file-edit-outline</v-icon>
                                </template>
                                <span>View</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialog" hide-overlay persistent width="300">
                        <v-card color="primary" dark>
                            <v-card-text>
                                Loading data
                                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import firebase, { firestore } from 'firebase'
import Tabulator from 'tabulator-tables'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import { mapGetters } from 'vuex'
import axios from 'axios'
import { api } from '../../configs/api'
export default {
	data () {
		return {
            dialog: false,
            beforeRouteDialog: false,
            product: {},
            variants: [],
            headers: [
                { text: 'Mat Group Desc', value: 'model', sortable: false },
                { text: 'Colors', value: 'colors', sortable: false },
                { text: 'Purchase Description', value: 'purchaseDescription', sortable: false },
                { text: 'Material No.', value: 'materialNumber', sortable: false },
                { text: 'Price', value: 'srp', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false }
            ]
		}
    },
    
    computed: {
        ...mapGetters({
            user: "user"
        }),
    },
    
	mounted() {
        this.fetchProduct()
	},
    methods: {
        fetchProduct() {
            const self = this
            let slug = self.$route.params.slug
            self.dialog = true

            axios.get(`${api}/mc/model/view/${slug}`)
            .then((result) => {
                let responseData = result.data

                self.product = responseData.data
                self.fetchVariants()
                self.dialog = false
            }).catch((error) => {
                alert(error.message)
            })
        },
        fetchVariants() {
            const self = this
            let model = encodeURIComponent(self.product.model)

            axios.get(`${api}/mc/model/variants/${model}`)
            .then((result) => {
                let responseData = result.data

                self.variants = responseData.results
            }).catch((error) => {
                alert(error.message)
            })
        },
        openDetails(item) {
            const self = this
            self.dialog = true
            self.$router.push(`/product/edit/${item.slug}`)
        },
    },
}
</script>

<style>
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
@import '../../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
</style>