import { render, staticRenderFns } from "./MCTemplate.vue?vue&type=template&id=6d38345f&scoped=true"
import script from "./MCTemplate.vue?vue&type=script&lang=js"
export * from "./MCTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d38345f",
  null
  
)

export default component.exports