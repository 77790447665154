<template>
    <v-container>
        <v-card flat tile>
            <v-card-title class="body-1">Delivery Fee</v-card-title>
            <v-divider></v-divider>
            <v-layout>
                <v-flex xs12>
                    <p style="background-color: #E0E0E0;" class="pl-5 subtitle-2">Product Type</p>
                    <p class="ml-5">MOTORCYCLES</p>
                    <p style="background-color: #E0E0E0;" class="pl-5 subtitle-2">Delivery Fee Matrix</p>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs2>
                     <p class="ml-5 mt-2 body-2">Internal Delivery</p>
                </v-flex>
                <v-flex xs2>
                     <v-text-field v-model="item.setupMatrix.internalDelivery.internalProvider" dense outlined disabled></v-text-field>
                </v-flex>
                <v-flex xs2>
                    <p class="ml-5 mt-2 body-2">Dates Covered</p>
                </v-flex>
                <v-flex xs2>
                    <v-text-field v-model="item.setupMatrix.internalDelivery.InternalDeliveryEffectivityDate" dense outlined disabled></v-text-field>
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex xs2>
                    <v-text-field v-model="item.setupMatrix.internalDelivery.InternalDeliveryExpiryDate" dense outlined disabled></v-text-field>
                </v-flex>  
            </v-layout>
            <v-layout>
                <v-flex xs2>
                    <p class="ml-5 mt-2 body-2">Coverage Area</p>
                </v-flex>
                <v-flex xs2>
                     <v-text-field v-model="item.setupMatrix.internalDelivery.coverageArea" dense outlined disabled></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs12>
                    <p style="background-color: #E0E0E0;" class="pl-5 subtitle-2">Point of Origin</p>
                </v-flex>
            </v-layout>
            <v-layout> 
                <v-flex xs12>
                    <v-simple-table dense class="mt-5 ml-5">
                        <caption class="caption text--secondary">Base Amount</caption>
                        <thead>
                            <tr>
                                <th colspan="3" class="text--secondary">
                                    Distance Covered
                                </th>
                                <th colspan="26" class="text--secondary">
                                    Provincial
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <th class="text--secondary">
                                Min.
                            </th>
                            <th class="text--secondary">
                                Max.
                            </th>
                            <th class="text--secondary">
                                unit
                            </th>
                            <th
                                class="text--secondary"
                                v-for="item in headerBuilder" :key="item"
                            >
                                {{ item }}
                            </th>
                            <tr
                            v-for="(item, id) in referenceTable" 
                            :key="id"
                            >
                            <td>{{ item.metric.min}}</td>
                            <td>{{ item.metric.max}}</td>
                            <td>{{ item.metric.unit}}</td>
                            <td
                                v-for="(area, index) in item.values" :key="index"
                            >
                                {{ area.amount }}
                            </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-flex>
            </v-layout>
            <v-layout class="mt-5 ml-5">
                <v-flex xs3>
                    <p class="mt-3 subtitle-2 text--secondary">Additional amount per km</p>
                </v-flex>
                <v-flex xs1>
                    <v-text-field v-model="item.setupMatrix.internalDelivery.baseAmountChart.additionalAmountperKm" outlined dense disabled></v-text-field>
                </v-flex>    
            </v-layout>
            <v-layout class="mt-5 ml-5">
                <v-flex xs3>
                    <p class="mt-3 subtitle-2 text--secondary">Maximum ceiling amount</p>
                </v-flex>
                <v-flex xs1>
                    <v-text-field v-model="item.setupMatrix.internalDelivery.baseAmountChart.maximumCeilingAmount" outlined dense disabled></v-text-field>
                </v-flex>    
            </v-layout>
            <v-layout>
                <v-flex xs12>
                    <p style="background-color: #E0E0E0;" class="pl-5 subtitle-2">Other Setup Option</p>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs12>
                    <v-layout>
                        <v-flex xs3>
                            <v-checkbox v-model="item.freeDelivery.isEnableDeliveryFee" label="Enable Free Delivery Fee" disabled></v-checkbox>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex class="pt-5" xs2>
                            <p class="text--secondary">Dates Covered</p>
                        </v-flex>
                        <v-flex xs2>
                            <v-text-field v-model="item.freeDelivery.freeEnableEffectivityDate" dense outlined disabled></v-text-field>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex xs2>
                            <v-text-field v-model="item.freeDelivery.freeEnableExpiryDate" dense outlined disabled></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs2>
                    <v-checkbox v-model="item.flatRate.isEnableFlatRate" label="Enable Flat Rate" disabled></v-checkbox>
                </v-flex>
                <v-flex class="pt-3" xs1>
                    <v-text-field v-model="item.flatRate.flatRate" outlined dense disabled></v-text-field>
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex class="pt-5" xs2>
                    <p class="text--secondary">Dates Covered</p>
                </v-flex>
                <v-flex xs2>
                    <v-text-field v-model="item.flatRate.flatRateEffectivityDate" outlined dense disabled></v-text-field>
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex xs2>
                    <v-text-field v-model="item.flatRate.flatRateExpiryDate" outlined dense disabled></v-text-field>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex xs11></v-flex>
                <v-flex xs1>
                    <v-card-actions>
                        <v-btn @click="back" tile depressed color="info">BACK</v-btn>
                    </v-card-actions>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import { api } from '@/configs/api'

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                message: '',
                type: 'success',
                alert: false
            },
            item: {},
            islands: [],
            clusters: [],
            referenceTable: [],
            islandGroupOrigins: ['NCR', 'LUZON', 'VISAYAS', 'MINDANAO', 'ISLAND']
        }
    },
    computed: {
        headerBuilder() {
            this.referenceTable = this.islands.filter(e => e.type === 'Motorcycles' && (e.area == 'island' && e.metric.unit == 'km'))
            return this.islandGroupOrigins
        }
    },
    mounted() {
        this.fetchCoverageGroups()
        this.fetchItemDetails()
    },
    methods: {
        async fetchItemDetails() {
            const self = this
            const record_id = this.$route.params.id

            try {
                let response = await axios.get(`${api}/setups/view/product/${record_id}`)
                self.item = response.data.data
                console.log(self.item)
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        async fetchCoverageGroups() {
            let self = this
            
            try {
                let response = await axios.get(`${api}/setup/base/amount`)
                self.islands = response.data.islands
                self.clusters = response.data.clusters
                console.log(self.islands)
            } catch (error) {
                self.showSnackbarMessage(error.message, false)
            }
        },
        back() {
            this.$router.push("/setups/delivery_fee")
        },
        showSnackbarMessage(message, success = true) {
            this.snackbar.type = success ? "success" : "error"
            this.snackbar.message = message
            this.snackbar.show = true
        },
    }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}