<template>
    <v-card flat>
        <v-card-title>{{title}} Fee</v-card-title>
        <v-divider></v-divider>
        
         <v-form ref="feeForm" class="px-8">
            <v-select
                label="Fees"
                item-text="name"
                item-value="code"
                :rules="[rules.required]"
                :items="feeTypeRecords"
                v-model="record.code"
            ></v-select>

            <v-text-field
                type="number"
                label="Amount"
                :rules="[rules.required]"
                v-model="record.amount"
            >
            </v-text-field>
            <v-text-field
                type="number"
                label="Rate"
                :rules="[rules.required, rules.minMaxValue(0, 100)]"
                v-model="record.rate"
            >
            </v-text-field>
            <v-checkbox
                label="Active"
                v-model="record.active"
            >
            </v-checkbox>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="action === 'update'" @click="updateFee">Update</v-btn>
            <v-btn color="primary" v-if="action === 'add'" @click="saveFee">Save</v-btn>
            <v-btn color="warning" @click="resetForm">Cancel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import isEmpty from 'is-empty'
import {required, minMaxValue} from '@/plugins/validation'

export default {
    props: {
        action: {
            type: String,
            default: 'view'
        },
        feeRecord: Object
    },
    data() {
        return {
            record: {
                code: null,
                amount: 0,
                rate: 0,
                active: true
            },
            selected: [],
            feeTypeRecords: [],
            rules: {
                required,
                minMaxValue
            }
        }
    },
    computed: {
        title() {
            return this.action[0].toUpperCase() + this.action.substr(1)
        }
    },
    watch: {
        feeRecord: {
            handler: function(value) {
                if(this.action === 'update') this.record = {...value}
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        this.loadFeeType()
    },
    methods: {
        loadFeeType() {
            let self = this

            this.$axios.get(`/lovs`, {
                params: {
                    groupCode: 'FEES'
                }
            })
            .then((response) => {
                let responseData = response.data.data
                self.feeTypeRecords = responseData
            }).catch((error) => {
                self.$emit('fee-response', error.message, false)
            })
        },
        saveFee() {
            let self = this
            const isValid = this.$refs.feeForm.validate()

            if(!isValid) return

            this.$axios.post(`/fee`, this.record)
            .then(response => {
                let responseData = response.data
                self.$emit('fee-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('fee-response', error.message, false)
            })
        },
        updateFee() {
            let self = this
            const isValid = this.$refs.feeForm.validate()

            if(!isValid) return

            this.$axios.put(`/fee/${this.record.id}`, this.record)
            .then(response => {
                let responseData = response.data
                self.$emit('fee-response', responseData.message, responseData.success)
                self.resetForm()
            }).catch((error) => {
                self.$emit('fee-response', error.message, false)
            })
        },
        resetForm() {
            this.$refs.feeForm.reset()
            this.$refs.feeForm.resetValidation()
            this.$emit('close-form')
        }
    }
}
</script>